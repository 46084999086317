import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik } from 'formik';
import isEqual from 'lodash.isequal';

import {
  LoadMask,
  Page,
  Button,
  DialogModal,
} from '../../../../../components';
import PermissionsTable from './permissionsTable';
import { fieldPermissionsNames, filtersPermissionsInitialState } from '../../../../../constants/stock';
import FilterIndicator from '../../../../../components/FilterIndicator';
import Filters from './filters';
import { ITEMS_PER_PAGE } from '../../../../../constants/Paginate';
import { HttpService } from '../../../../../services';
import { useDidUpdateEffect } from '../../../../../utils/hooks/did-update-effect';
import { getNumberOfAppliedFilters } from '../../../../../utils';
import MaterialModalTable from './permissionsTable/MaterialModalTable';
import { StockPermissionRoutePaths } from '../../../../../constants/RoutePaths';
import { statusPermission } from '../../../../../constants/stock/enums';

const PageActionButton = styled(Button).attrs(({ variant }) => ({
  size: 'large',
  color: 'secondary',
  variant: variant || 'contained',
}))`
  margin-left: 24px;
`;

const orderByInitialState = {
  field: undefined,
  orderType: undefined,
};

const searchInitialState = {
  type: undefined,
  term: '',
};

const BoldText = styled.span`
  font-weight: bold;
`;

export const updateQueryStringInPermissionsList = (history, {
  filters, search, skip, take,
}) => {
  history.push(`?${HttpService.serializeQueryString({
    materialType: Object.keys(filters.materialType)
      .map((key) => filters.materialType[key] ? key : null),
    agentList: Object.keys(filters.agentList)
      .map((key) => filters.agentList[key] ? key : null),
    quantityAlert: Object.keys(filters.quantityAlert)
      .map((key) => filters.quantityAlert[key] ? key : null),
    status: Object.keys(filters.status)
      .map((key) => filters.status[key] ? key : null),
    searchTerm: search.term,
    take,
    skip,
  })}`);
};

const StockPermissionsLayout = ({
  userData,
  title,
  loadingDialog,
  onRequestAgentList,
  onRequestPermissionsList,
  onRequestRemovePermission,
  materialList,
  agentList,
  permissionsList,
  onRequestMaterialList,
}) => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE);
  const [countAppliedFilters, setCountAppliedFilters] = useState(0);
  const [appliedFilters, setAppliedFilters] = useState(filtersPermissionsInitialState);
  const [orderBy, setOrderBy] = useState(orderByInitialState);
  const [search, setSearch] = useState(searchInitialState);
  const { search: locationSearch } = useLocation();
  const [isOpenRemovePermissionDialog, setIsOpenRemovePermissionDialog] = useState(false);
  const [showMaterialModal, setShowMaterialModal] = useState(false);
  const [stockPermissionIdRemove, setStockPermissionIdRemove] = useState(0);
  const [materialSkip, setMaterialSkip] = useState(24);
  const _requestSearchFilter = (data, requestFn, increase) => {
    requestFn({
      skip: data.skip || increase ? materialSkip : 0,
      take: data.take || 24,
      filters: {
        standard: data.term && data.type ? [
          {
            attribute: data.type,
            value: data.term,
          },
        ] : [],
      },
    }, increase);
  };

  const _buildOrderRequestFromQueryString = () => {
    const queryObject = HttpService.unserializeQueryString(locationSearch);
    const skip = queryObject.skip || 0;
    const take = queryObject.take || ITEMS_PER_PAGE;

    if (locationSearch === '') {
      return ({
        filters: {
          ...filtersPermissionsInitialState,
        },
        take,
        skip,
        orderBy: {
          ...orderByInitialState,
        },
        search: {
          ...searchInitialState,
        },
      });
    }

    return ({
      filters: {
        materialType: HttpService.unserializeQueryStringCheckboxObject(queryObject.materialType, Number, filtersPermissionsInitialState.materialType),
        agentList: HttpService.unserializeQueryStringCheckboxObject(queryObject.agentList, null, { ...filtersPermissionsInitialState.agentList }),
        quantityAlert: HttpService.unserializeQueryStringCheckboxObject(queryObject.quantityAlert, null, { ...filtersPermissionsInitialState.quantityAlert }),
        status: HttpService.unserializeQueryStringCheckboxObject(queryObject.status, null, { ...filtersPermissionsInitialState.status }),
      },
      take,
      skip,
      orderBy: {
        field: queryObject.orderByField,
        orderType: Number(queryObject.orderByType),
      },
      search: {
        term: queryObject.searchTerm,
        type: queryObject.searchType,
      },
    });
  };

  useEffect(() => {
    const {
      filters,
      orderBy,
      search,
    } = _buildOrderRequestFromQueryString();

    setAppliedFilters(filters);
    setOrderBy(orderBy);
    setSearch(search);
  }, []);

  useDidUpdateEffect(() => {
    const requestParams = {
      filters: appliedFilters,
      take: itemsPerPage,
      skip: (currentPage - 1) * itemsPerPage,
      orderBy,
      search,
    };
    setCountAppliedFilters(getNumberOfAppliedFilters(filtersPermissionsInitialState, appliedFilters));
    onRequestPermissionsList(requestParams);
  }, [
    currentPage,
    itemsPerPage,
    appliedFilters,
    orderBy,
    search,
  ]);

  const _onPageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const _onChangeItemsPerPage = (event, newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const _onChangeDataOrder = (field, orderType) => {
    setOrderBy({
      field,
      orderType,
    });
  };

  const _onFiltersChange = ({ ...newFilters }) => {
    setSearch(searchInitialState);
    setAppliedFilters(newFilters);
    setCurrentPage(1);
  };

  const _onSearch = (value) => {
    setAppliedFilters({
      ...filtersPermissionsInitialState,
    });
    setSearch(value);
    setCurrentPage(1);
  };

  const _onClearSearch = (handleReset) => {
    if (handleReset) handleReset();
    setSearch(searchInitialState);
    _onClearFilter();
  };

  const _onClearFilter = (handleReset) => {
    if (handleReset) handleReset();
    setAppliedFilters({ ...filtersPermissionsInitialState });
    setCurrentPage(1);
  };

  const _onRemovePermission = (id) => {
    setIsOpenRemovePermissionDialog(true);
    setStockPermissionIdRemove(id);
  };

  const _onRemovePermissionDialog = () => {
    onRequestRemovePermission(stockPermissionIdRemove);
    setIsOpenRemovePermissionDialog(false);
    setStockPermissionIdRemove(0);
  };

  return (
    <>
      <Page
        userData={userData}
        title={title}
        loadingDialog={loadingDialog}
        renderRight={() => (
          <>
            <PageActionButton
              onClick={() => { setShowMaterialModal(true); }}
            >
              Nova permissão
            </PageActionButton>
          </>
        )}
      >
        <Formik
          initialValues={{
            [fieldPermissionsNames.MATERIAL_TYPE]: appliedFilters[fieldPermissionsNames.MATERIAL_TYPE],
            [fieldPermissionsNames.AGENT]: appliedFilters[fieldPermissionsNames.AGENT],
            [fieldPermissionsNames.ALERT]: appliedFilters[fieldPermissionsNames.ALERT],
            [fieldPermissionsNames.STATUS]: appliedFilters[fieldPermissionsNames.STATUS],
          }}
          onSubmit={_onFiltersChange}
          enableReinitialize
        >
          {({ handleReset }) => (
            <>
              <Filters
                agentList={agentList}
                onRequestAgentList={onRequestAgentList}
                onSearch={_onSearch}
                listCountResults={permissionsList.list.length}
                appliedFilters={appliedFilters}
              />
              {(!isEqual(filtersPermissionsInitialState, appliedFilters) || search.term) && (
                <FilterIndicator
                  userData={userData}
                  onClear={() => _onClearFilter(handleReset)}
                  onClearSearch={() => _onClearSearch(handleReset)}
                  appliedFilters={countAppliedFilters}
                  searchTerm={search.term}
                  listCountResults={permissionsList.list.length}
                />
              )}
              <PermissionsTable
                userData={userData}
                data={permissionsList.list ? permissionsList.list : []}
                loading={false}
                currentPage={currentPage}
                totalItems={permissionsList.total ? permissionsList.total : 0}
                itemsPerPage={itemsPerPage}
                onPageChange={_onPageChange}
                onChangeItemsPerPage={_onChangeItemsPerPage}
                onRemovePermission={_onRemovePermission}
                onChangeDataOrder={_onChangeDataOrder}
                dataOrder={orderBy}
              />
            </>
          )}
        </Formik>
        <MaterialModalTable
          open={showMaterialModal}
          onConfirm={(selectedData) => {
            history.push(StockPermissionRoutePaths.NEW, {
              material: {
                materialId: selectedData[0].id,
                status: statusPermission.ACTIVE,
                name: selectedData[0].name,
                availableQuantity: selectedData[0].availableQuantity,
              },
            });
          }}
          onDismiss={() => setShowMaterialModal(false)}
          tableData={materialList.list}
          loading={materialList.loading}
          width={810}
          take={24}
          onSearch={(data) => _requestSearchFilter(data, onRequestMaterialList)}
          onLoad={(data) => {
            setMaterialSkip(materialSkip + 24);
            _requestSearchFilter(data, onRequestMaterialList, true);
          }}
          disableConfirm
        />
        <DialogModal
          title="Excluir permissão"
          description={(
            <span>
              Tem certeza que deseja excluir a permissão deste material?
              <BoldText> (Essa ação não poderá ser desfeita)</BoldText>
              .
            </span>
          )}
          confirmButtonText="Excluir permissão"
          cancelButtonText="Cancelar"
          handleConfirmDialog={() => { _onRemovePermissionDialog(); }}
          handleCloseDialog={() => { setIsOpenRemovePermissionDialog(false); }}
          isOpenDialog={isOpenRemovePermissionDialog}
        />
      </Page>
      {permissionsList.loading ? <LoadMask /> : null}
    </>
  );
};

StockPermissionsLayout.propTypes = {
  userData: PropTypes.object,
  title: PropTypes.string,
  loading: PropTypes.bool,
  companyDialogList: PropTypes.object,
  loadingDialog: PropTypes.bool,
  handleOpenCompanyDialog: PropTypes.func,
  handleOutputCompanyDialog: PropTypes.func,
  onRequestMaterialList: PropTypes.func,
};

StockPermissionsLayout.defaultProps = {
  userData: {},
  title: '',
  loading: false,
  companyDialogList: {},
  loadingDialog: false,
  handleOpenCompanyDialog: () => { },
  handleOutputCompanyDialog: () => { },
  onRequestMaterialList: () => { },
};

export default StockPermissionsLayout;
