import React from 'react';
import styled from 'styled-components';

import { Container } from '@material-ui/core/';
import { Colors } from '../constants';

const EmptyStateContent = styled.div`
  width: calc(100% - 64px);
  min-height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  padding: 48px 16px;
  margin: 0 16px;
  border-radius: 8px;
  color: ${Colors.GRAY_DARKEST};
  border: 1px dashed ${Colors.GRAY_LIGHT};
`;

const EmptyState = ({
  text,
}) => (
  <EmptyStateContent>
    <Container maxWidth="lg">
      {text}
    </Container>
  </EmptyStateContent>
);

export default EmptyState;
