import React from 'react';
import styled from 'styled-components';

import {
  FilterCheckbox,
  FilterSearch,
  FilterWrapper,
  FilterCheckboxSearch,
} from '../../../../../../components/Filter';
import {
  fieldAgentNames,
  regionOptionList,
} from '../../../../../../constants/agent';
import FastFieldWrapper from '../../../../../../components/form/FastFieldWrapper';

const StyledFilterWrapper = styled(FilterWrapper)`
  transition: height ease-out 0.3s;
`;

const SingleFilterContainer = styled.div`
  margin-left: 24px;
`;

const Filters = ({
  handleReset,
  onSearch,
  tagList,
  onRequestTagList,
  onCitySearch,
  appliedFilters,
}) => {
  const defaultDataListMap = ({
    id,
    name,
  }) => ({
    label: name,
    value: id,
  });
  const renderFilters = () => (
    <>
      <SingleFilterContainer>
        <FastFieldWrapper
          name={fieldAgentNames.TAG}
          label="Tag"
          placeholder="Nome"
          component={FilterCheckboxSearch}
          submitOnChange
          optionList={tagList.list}
          optionMap={defaultDataListMap}
          loading={tagList.loading}
          onSearch={onRequestTagList}
          minChar={1}
          appliedFilters={appliedFilters}
          fieldNames={fieldAgentNames}
        />
      </SingleFilterContainer>
      <SingleFilterContainer>
        <FastFieldWrapper
          name={fieldAgentNames.CITY}
          label="Cidade"
          placeholder="Cidade"
          component={FilterCheckboxSearch}
          submitOnChange
          optionList={[]}
          onSearch={onCitySearch}
          minChar={2}
          appliedFilters={appliedFilters}
          fieldNames={fieldAgentNames}
        />
      </SingleFilterContainer>
      <SingleFilterContainer>
        <FastFieldWrapper
          name={fieldAgentNames.REGION}
          label="Região"
          component={FilterCheckbox}
          submitOnChange
          optionList={regionOptionList}
          handleReset={handleReset}
          appliedFilters={appliedFilters}
          fieldNames={fieldAgentNames}
        />
      </SingleFilterContainer>
    </>
  );

  return (
    <StyledFilterWrapper
      renderLeft={() => (
        <>
          {renderFilters()}
        </>
      )}
      renderRight={() => (
        <>
          <FilterSearch
            name={fieldAgentNames.SEARCH}
            placeholder="Nome do representante"
            component={FilterSearch}
            submitOnChange
            handleOutput={onSearch}
            placement="bottom-end"
          />
        </>
      )}
    />
  );
};

export default Filters;
