import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Colors, BreakpointValues } from '../constants';
import DialogModal from './DialogModal';
import { Table, TableWrapper } from './Table/Table';
import Button from './Button';
import Spinner from './Spinner';
import EmptyStateWithAction from './EmptyStateWithAction';
import { mediaHelper, useWindowSize } from '../utils';

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% + 16px);

  margin: -8px -24px;

  ${({ onlyTable }) => onlyTable && `
    margin: -16px -24px;
  `}
  
  ${mediaHelper.lg`
    ${({ width }) => width && `
      width: ${width}px;
    `}
  `}
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Colors.GRAY_LIGHT};
  margin-bottom: 8px;
  z-index: 10;

  ${({ noMargin }) => noMargin && `
    margin-bottom: 0;
  `}
`;

const SpinnerContainer = styled.div`
  display: flex;
  padding: 16px;
  justify-content: center;
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: ${Colors.Alpha(Colors.PRIMARY_BLUE, 0.08)};
  flex: 1;
  overflow-y: auto;

  ${mediaHelper.md`
    flex: none;
    max-height: 450px;
  `}

  & .infinite-scroll-component {
    overflow: hidden !important;
  }
`;

const TableContainer = styled.div`
  border: 1px solid ${Colors.GRAY_LIGHTER};
  background-color: #FFF;
  border-radius: 8px;
  flex: 1;

  ${mediaHelper.md`
    min-height: 413px;
  `}

  ${({ loadingContainer }) => loadingContainer && `
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

const StyledTable = styled(Table)`
  && ${TableWrapper} {
    box-shadow: none;
  }
`;

const SelectedCountLabel = styled.div`
  display: flex;
  align-items: center;
  height: 26px;
  font-size: 14px;
  margin: 0 12px;
`;

const StyledEmptyStateWithAction = styled(EmptyStateWithAction)`
  padding: 80px 16px 0 16px;
  flex-direction: column;
`;

const StyledEmptyActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;

const ModalTable = ({
  name,
  title,
  open,
  tableData = [],
  tableComponents,
  tableColumnConfig,
  tableHandleHeaderTitle,
  value,
  loading,
  take,
  onLoad,
  onDismiss,
  onConfirm,
  loadNextData,
  renderContentAbove,
  renderContentBellow,
  defaultCheckAll,
  handleRowCheck,
  handleHeaderCheck,
  width,
  handleRefresh,
  isSearch,
  showManageMaterialModal,
  radiobutton,
  disableConfirm,
  forceDisable,
  material,
}) => {
  const [checkedData, setCheckedData] = useState(value || []);
  const [skip, setSkip] = useState(0);
  const [lastTableDataLength, setLastTableDataLength] = useState(tableData.length);
  const currentWindowSize = useWindowSize();

  const _fillCheckboxes = (selected) => tableData.map((item) => ({ data: item, selected }));
  const _getCheckboxesFromValue = (checkedOnes) => checkedOnes.map((item) => {
    const valueItem = value.find((valItem) => isEqual(valItem, item.data));
    return {
      ...item,
      selected: !!valueItem,
    };
  });

  const [checkAll, setCheckAll] = useState(defaultCheckAll);
  const [checkboxes, setCheckboxes] = useState(_getCheckboxesFromValue(_fillCheckboxes(defaultCheckAll)));
  const [checkboxesCount, setCheckboxesCount] = useState(defaultCheckAll ? tableData.length : 0);
  const [enableConfirm, setEnableConfirm] = useState(false);

  const [lastRow, setLastRow] = useState({ index: null, checked: null });
  const [lastHeaderCheck, setLastHeaderCheck] = useState(defaultCheckAll);

  useEffect(() => {
    if (open && !defaultCheckAll && !showManageMaterialModal) {
      setCheckAll(false);
      setCheckboxes(_fillCheckboxes(false));
      setCheckedData([]);
      setCheckboxesCount(0);
    }
    if (disableConfirm) {
      setEnableConfirm(false);
    } else {
      setEnableConfirm(true);
    }
  }, [open]);

  useEffect(() => {
    if (checkboxesCount > 0) {
      if (!forceDisable) {
        setEnableConfirm(true);
      }
    }
  }, [checkboxesCount]);

  useEffect(() => {
    if (!loading && isSearch) {
      setCheckAll(false);
      setCheckboxes(_fillCheckboxes(false));
      setCheckedData([]);
      setCheckboxesCount(0);
    }
  }, [loading]);

  useEffect(() => {
    if (lastRow.index !== undefined && lastRow.index !== null && checkboxes && checkboxes[lastRow.index]) {
      handleRowCheck(lastRow.index, lastRow.checked);
    }
  }, [lastRow]);

  useEffect(() => {
    handleHeaderCheck(lastHeaderCheck);
  }, [lastHeaderCheck]);

  useEffect(() => {
    if (material && material.agentList.some((obj) => obj.qty === 0 && obj.active)) {
      setEnableConfirm(false);
    } else {
      setEnableConfirm(true);
    }
  }, [material]);

  const _handleChangeCheck = (dataIndex, checked) => {
    let tmpCheckedData = radiobutton ? [] : cloneDeep(checkedData);
    const tmpCheckboxes = radiobutton ? [] : cloneDeep(checkboxes);

    if (checked) {
      tmpCheckedData.push(tableData[dataIndex]);
      tmpCheckboxes[dataIndex] = {
        data: tableData[dataIndex],
        selected: true,
      };
    } else {
      tmpCheckedData = tmpCheckedData.filter((item) => !isEqual(item, tableData[dataIndex]));
      tmpCheckboxes[dataIndex].selected = false;
    }

    if (checkboxesCount + 1 === tableData.length && checked) {
      setCheckAll(true);
    } else if (checkboxesCount === tableData.length && !checked) {
      setCheckAll(false);
    }

    setCheckboxesCount(tmpCheckedData.length);
    setCheckboxes(tmpCheckboxes);
    setCheckedData(tmpCheckedData);

    setLastRow({
      index: dataIndex,
      checked,
    });

    if (tmpCheckedData.length > 0) {
      setEnableConfirm(true);
    } else {
      setEnableConfirm(false);
    }
  };

  const _handleHeaderCheck = (checked) => {
    setCheckAll(checked);
    setCheckboxes(_fillCheckboxes(checked));
    if (checked) {
      if (tableData.some((item) => Object.keys(item).includes('availableQuantity'))) {
        const tableDataFiltered = tableData.filter((item) => (item.availableQuantity > 0));
        setCheckedData(tableDataFiltered);
        setCheckboxesCount(tableDataFiltered.length);
      } else {
        setCheckedData(tableData);
        setCheckboxesCount(tableData.length);
      }
    } else {
      setCheckedData([]);
      setCheckboxesCount(0);
    }

    setLastHeaderCheck(checked);
  };

  const _loadNext = () => {
    setLastTableDataLength(tableData.length);

    if (loadNextData.skip) {
      setSkip(loadNextData.skip);
    } else {
      setSkip(skip + take);
    }

    onLoad({
      take,
      skip: skip + take,
      ...loadNextData,
      // type,
      // term,
    });
  };

  const _renderLoadMore = (
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  );

  return (
    <DialogModal
      title={title}
      size="xl"
      confirmButtonText="Confirmar"
      isOpenDialog={open}
      loadingDialog={loading}
      enableConfirm={enableConfirm || !disableConfirm}
      handleCloseDialog={() => {
        setCheckedData(value);
        onDismiss();
      }}
      fullScreen={currentWindowSize.width <= BreakpointValues.md}
      handleConfirmDialog={() => {
        setEnableConfirm(false);
        setTimeout(() => {
          if (enableConfirm) {
            onConfirm(checkedData);
          }
        }, 500);
      }}
      renderLeftActions={() => checkboxesCount && !disableConfirm ? (
        <>
          <SelectedCountLabel>
            {checkboxesCount}
            {checkboxesCount > 1 ? ' selecionados' : ' selecionado'}
          </SelectedCountLabel>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setCheckAll(false);
              setCheckboxes(_fillCheckboxes(false));
              setCheckedData([]);
              setCheckboxesCount(0);
            }}
          >
            Limpar
          </Button>
        </>
      ) : null}
    >
      <ModalContentWrapper onlyTable={!renderContentAbove} width={width}>
        {title ? (
          <Divider />
        ) : null}
        {renderContentAbove && renderContentAbove()}
        {(tableData.length || loading) && renderContentAbove ? (
          <Divider noMargin />
        ) : null}
        <ScrollContainer id={`table-container-${name}`}>
          <TableContainer
            loadingContainer={!tableData.length || !tableComponents || !tableColumnConfig}
          >
            {tableData.length && tableComponents && tableColumnConfig ? (
              <InfiniteScroll
                dataLength={tableData.length}
                next={_loadNext}
                hasMore={!!onLoad && lastTableDataLength < tableData.length}
                loader={_renderLoadMore}
                scrollableTarget={`table-container-${name}`}
                hasChildren
              >
                <StyledTable
                  name={`${name}-table`}
                  handleHeaderTitle={tableHandleHeaderTitle || ((key) => key)}
                  noFooter
                  multiselect
                  onRowCheck={_handleChangeCheck}
                  onHeaderCheck={_handleHeaderCheck}
                  columnConfig={tableColumnConfig}
                  data={tableData}
                  components={tableComponents}
                  checkAllControl={checkAll}
                  checkboxesControl={checkboxes}
                  radiobutton={radiobutton}
                />
              </InfiniteScroll>
            ) : (
              <>
                {loading ? (
                  _renderLoadMore
                ) : (
                  <StyledEmptyStateWithAction
                    alignCenter
                    emptyStateText="Nenhum resultado pra exibir :("
                    renderAction={() => (
                      <StyledEmptyActionsContainer>
                        <Button
                          variant="outlined"
                          onClick={() => { handleRefresh(); }}
                        >
                          Atualizar
                        </Button>
                      </StyledEmptyActionsContainer>
                    )}
                  />
                )}
              </>
            )}
          </TableContainer>
        </ScrollContainer>
        {renderContentBellow && renderContentBellow()}
      </ModalContentWrapper>
    </DialogModal>
  );
};

ModalTable.propTypes = {
  name: PropTypes.any.isRequired,
  loading: PropTypes.any,
  open: PropTypes.any,
  tableColumnConfig: PropTypes.any.isRequired,
  tableComponents: PropTypes.any.isRequired,
  tableData: PropTypes.array.isRequired,
  title: PropTypes.any,
  value: PropTypes.array,
  onConfirm: PropTypes.func,
  onDismiss: PropTypes.func,
  tableHandleHeaderTitle: PropTypes.func.isRequired,
  loadNextData: PropTypes.object,
  renderContentAbove: PropTypes.func,
  renderContentBellow: PropTypes.func,
  width: PropTypes.number,
  defaultCheckAll: PropTypes.bool,
  handleRowCheck: PropTypes.func,
  handleHeaderCheck: PropTypes.func,
  handleRefresh: PropTypes.func,
  isSearch: PropTypes.bool,
  showManageMaterialModal: PropTypes.bool,
  radiobutton: PropTypes.bool,
};

ModalTable.defaultProps = {
  loading: false,
  open: false,
  value: [],
  onConfirm: () => { },
  onDismiss: () => { },
  loadNextData: {},
  renderContentAbove: null,
  renderContentBellow: null,
  width: 600,
  title: null,
  defaultCheckAll: false,
  handleRowCheck: () => { },
  handleHeaderCheck: () => { },
  handleRefresh: () => { },
  isSearch: false,
  showManageMaterialModal: false,
  radiobutton: false,
};

export default ModalTable;
