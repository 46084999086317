import { useState, useEffect } from 'react';

export const getWindowSize = () => ({
  height: window.innerHeight,
  width: window.innerWidth,
});

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  function handleResize() {
    setWindowSize(getWindowSize());
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  return windowSize;
};
