// import { HttpService, ClientError, HttpStatusCode } from './http';

import { HttpService } from './http';
import { API_BASE_URL } from '../constants/API';

export class ChangeViewService {
  static async changeCompanyView(companyId) {
    const response = await HttpService.patch(`${API_BASE_URL}/users/associate-company`,
      {
        wmsId: companyId,
      },
    );
    return response;
  }

  static async fetchCompanyDialogList() {
    const response = await HttpService.get(`${API_BASE_URL}/companies`);
    return response;
  }
}
