import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import { Tooltip } from '@material-ui/core';
import {
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import { Visibility, Delete } from '@material-ui/icons';
import { Table, ThContainer } from '../../../../../../components/Table/Table';
import { Button, Text, EmptyStateWithAction } from '../../../../../../components';
import { Colors } from '../../../../../../constants';
import { columnDetailLabel, columnDetailType } from '../../../../../../constants/agent';
import { statusPermission } from '../../../../../../constants/stock';
import { StockPermissionRoutePaths } from '../../../../../../constants/RoutePaths';

const StyledEmptyStateWithAction = styled(EmptyStateWithAction)`
  padding: 40px 16px;
  border-bottom: 1px solid ${Colors.GRAY_LIGHTER};
  flex-direction: column;
`;

const StyledEmptyActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80px;
  margin-left: auto;
  color: ${Colors.PRIMARY_BLUE};
`;

const StatusTagWrapper = styled.div`
  border: 1px solid ${Colors.DANGER};
  border-radius: 16px;
  padding: 4px 10px;
  color: ${Colors.DANGER};
`;

const TableStyled = styled(Table)`
  & ${ThContainer} {
    padding-left: 16px;
  }
`;

const StockPermissionAgentTable = ({
  userData,
  data,
  loading,
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
  onChangeItemsPerPage,
  onChangeDataOrder,
  dataOrder,
  multiselect,
  radiobutton,
  onRemoveMaterialPermissionDialog,
  ...props
}) => {
  const history = useHistory();
  const { search: locationSearch } = useLocation();

  const themeTooltip = createMuiTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: '12px',
        },
      },
    },
  });

  const _renderBasicTextCell = (value) => (
    <Text>
      {value || '--'}
    </Text>
  );

  const _renderNumberCell = (value) => (
    <NumberFormat
      value={value}
      displayType="text"
      decimalSeparator=","
      thousandSeparator={true, '.'}
    />
  );

  const _renderStatusCell = (value) => (
    <>
      {value === statusPermission.INACTIVE && (
        <MuiThemeProvider
          theme={themeTooltip}
        >
          <Tooltip
            title="Permissão desativada."
            placement="left"
            arrow
          >
            <StatusTagWrapper>
              Desativada
            </StatusTagWrapper>
          </Tooltip>
        </MuiThemeProvider>
      )}
    </>
  );

  const columnConfig = {
    [columnDetailType.MATERIAL_NAME]: {
      order: 1,
      width: 280,
    },
    [columnDetailType.TYPE]: {
      order: 2,
      noSorting: true,
    },
    [columnDetailType.AUTHORIZED_QUANTITY]: {
      order: 3,
      noSorting: true,
    },
    [columnDetailType.CURRENT_BALANCE]: {
      order: 4,
      noSorting: true,
    },
    [columnDetailType.AVAILABLE_QUANTITY]: {
      order: 5,
      noSorting: true,
    },
    [columnDetailType.STATUS]: {
      order: 6,
      noSorting: true,
    },
    [columnDetailType.ACTION]: {
      order: 7,
      noHeader: true,
      noSorting: true,
    },
  };

  const components = {
    [columnDetailType.MATERIAL_NAME]: _renderBasicTextCell,
    [columnDetailType.TYPE]: _renderBasicTextCell,
    [columnDetailType.AUTHORIZED_QUANTITY]: _renderNumberCell,
    [columnDetailType.CURRENT_BALANCE]: _renderNumberCell,
    [columnDetailType.AVAILABLE_QUANTITY]: _renderNumberCell,
    [columnDetailType.STATUS]: _renderStatusCell,
    [columnDetailType.ACTION]: (fieldData, data, rowIndex) => {
      const rowData = data[rowIndex];
      return (
        <ActionsContainer>
          <Visibility onClick={() => { history.push(`${StockPermissionRoutePaths.PERMISSIONS}/${rowData.id}`); }} />
          <Delete onClick={() => { onRemoveMaterialPermissionDialog(rowData.id); }} />
        </ActionsContainer>
      );
    },
  };

  return (
    <>
      <TableStyled
        name="permission-agent-table"
        isLoading={loading}
        data={data}
        handleHeaderTitle={(key) => columnDetailLabel[key]}
        columnConfig={columnConfig}
        components={components}
        footerText="Itens por página: "
        paginationProps={{
          currentPage,
          totalItems,
          itemsPerPage,
          onPageChange,
        }}
        onChangeItemsPerPage={onChangeItemsPerPage}
        renderEmptyState={() => (
          <StyledEmptyStateWithAction
            alignCenter
            emptyStateText="Nenhuma permissão pra exibir :("
            renderAction={() => (
              <StyledEmptyActionsContainer>
                <Button
                  variant="outlined"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Atualizar
                </Button>
              </StyledEmptyActionsContainer>
            )}
          />
        )}
      />
    </>
  );
};

StockPermissionAgentTable.propTypes = {
  data: PropTypes.array.isRequired,
  totalItems: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  handleRowCheck: PropTypes.func,
  currentPage: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  onChangeItemsPerPage: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRemoveMaterialPermissionDialog: PropTypes.func,
};

StockPermissionAgentTable.defaultProps = {
  loading: false,
  handleRowCheck: () => {},
  onRemoveMaterialPermissionDialog: () => {},
};

export default StockPermissionAgentTable;
