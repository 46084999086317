import React from 'react';
import {
  useField, useFormikContext,
} from 'formik';

const FastFieldWrapper = ({
  name,
  component,
  submitOnChange,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const { handleSubmit, setFieldValue } = useFormikContext();

  return React.createElement(component, {
    ...props,
    name,
    handleOutput: (value) => {
      helpers.setValue(value);

      if (submitOnChange) {
        handleSubmit();
      }
    },
    initialState: meta.initialValue,
    value: field.value,
    submitOnChange,
  });
};

export default FastFieldWrapper;
