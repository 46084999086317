import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import {
  showErrorToast,
} from '../../components';
import { RoutePaths } from '../../constants/RoutePaths';
import { UserService } from '../../services';

import ForgotPasswordLayout from './forgot-password-layout';

class ForgotPasswordPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      success: false,
    };
  }

  componentWillUnmount() {
    this.setState = () => { };
  }

  _getValidations = () => Yup.object().shape({
    email: Yup.string().email('Digite seu e-mail corretamente').required('Digite seu e-mail'),
  });

  _handleBack = () => {
    const { history } = this.props;

    history.push(RoutePaths.HOME);
  }

  _forgotPassword = async (values) => {
    const { dispatch } = this.props;

    try {
      this.setState({
        loading: true,
      });

      await UserService.forgotPassword(values);

      this.setState(
        {
          loading: false,
          success: true,
        },
      );
    } catch (error) {
      this.setState(
        {
          loading: false,
        },
        () => {
          console.error(error.message);
          dispatch(showErrorToast(error.message));
        },
      );
    }
  };

  render() {
    const { loading, success } = this.state;

    return (
      <ForgotPasswordLayout
        loading={loading}
        success={success}
        handleValidations={this._getValidations}
        handleForgotPassword={this._forgotPassword}
        handleBack={this._handleBack}
      />
    );
  }
}

export default ForgotPasswordPage;
