import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import cloneDeep from 'lodash.clonedeep';
import { useHistory, useLocation } from 'react-router-dom';

import { Chip } from '@material-ui/core';
import {
  Page,
  Button,
  LoadMask,
  DialogModal,
} from '../../../../components';
import {
  Colors,
  MaterialQtdMode,
  USER_ROLES,
  MODULES,
  SUB_MODULES,
} from '../../../../constants';
import AgentsModalTable from './AgentsModalTable';
import MaterialModalTable from './MaterialModalTable';
import ProductModalTable from './ProductModalTable';
import FastFieldWrapper from '../../../../components/form/FastFieldWrapper';
import ManageMaterialModalTable from './ManageMaterialModalTable';
import SelectedMaterialRow from './SelectedMaterialRow';
import SelectedAgentRow from './SelectedAgentRow';
import { RoutePaths } from '../../../../constants/RoutePaths';
import { mediaHelper } from '../../../../utils';

const SectionWrapper = styled.div`
  display: flex;
  margin-bottom: 48px;
  flex-direction: column;

  ${mediaHelper.md`
    flex-direction: row;
  `}
`;

const SectionTitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 270px;
  margin-right: 24px;
  padding-bottom: 16px;

  ${mediaHelper.md`
    justify-content: flex-end;
    padding: 0px;
  `}
`;

const SectionTitleLabel = styled.div`
  width: 240px;
  font-size: 24px;
  color: ${Colors.SECONDARY_MEDIUM};

  ${mediaHelper.md`
    text-align: right;
  `}
`;

const CardSection = styled.div`
  flex: 1;
  display: flex;
  background-color: #FFF;
  border-radius: 8px;
  border: 1px solid ${Colors.GRAY_LIGHT};
  box-sizing: border-box;
  flex-direction: column;

  ${mediaHelper.md`
    flex-direction: row;
    
    ${({ flexColumn }) => flexColumn && `
      flex-direction: column;
    `}
  `}
`;

const CardContent = styled.div`
  padding: 24px;

  ${({ block }) => block && `
    flex: 1;
  `}

  ${({ blueBg }) => blueBg && `
    background-color: ${Colors.Alpha(Colors.PRIMARY_BLUE, 0.04)};
  `}
`;

const CardTitleStyle = css`
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.15px;
`;

const CardTitleBlue = styled.div`
  ${CardTitleStyle}

  color: ${Colors.PRIMARY_BLUE};
`;

const CardTitleBlack = styled.div`
  ${CardTitleStyle}

  color: #000;
`;

const CardSubTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: ${Colors.GRAY_DARKER};
`;

const SectionDivider = styled.div`
  width: 100%;
  height: 1px;
  flex: 1;
  background-color: ${Colors.GRAY_LIGHT};

  ${mediaHelper.md`
    height: 100%;
    width: 1px;

    ${({ horizontal }) => horizontal && `
      width: 100%;
      height: 1px;
      flex: 1;
    `}
  `}
`;

const PageActionButton = styled(Button).attrs(({ variant }) => ({
  size: 'large',
  color: 'secondary',
  variant: variant || 'contained',
}))`
  ${mediaHelper.md`
    margin-left: 24px;
  `}
`;

const CardButton = styled(Button).attrs({
  size: 'large',
  color: 'primary',
  variant: 'contained',
})`
  margin-top: 16px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  button + button {
    margin-left: 16px;
  }
  
  ${mediaHelper.md`
    margin-bottom: 0px;
    justify-content: flex-end;
  `}
`;

const StyledTextField = styled(Field)`
  width: 100%;

  ${mediaHelper.md`
    width: 330px;
  `}
`;

const StyledSelectedMaterialRow = styled(SelectedMaterialRow)`
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  background-color: #FFF;
  margin-top: 16px;
`;

const StyledSelectedAgentRow = styled(SelectedAgentRow)`
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  background-color: #FFF;
  margin-top: 16px;
`;

const ChipContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  flex-wrap: wrap;
`;

const SelectActionButton = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;

  button + button {
    margin-left: 16px;
  }

  ${mediaHelper.md`
    width: auto;
    margin-top: 0px;
  `}
`;

const StyledChip = styled(Chip)`
  margin-right: 8px;
  margin-bottom: 8px;
`;

const Divider = styled.div`
  width: 100%;
  height: 8px;

  ${mediaHelper.md`
    height: 24px;
  `}
`;

const NewOrderLayout = ({
  userData,
  title,
  loading,
  agentList,
  materialList,
  productList,
  onRequestAgentList,
  onRequestMaterialList,
  onRequestProductList,
  handleNewOrderSubmit,
  handleValidations,
  handleConfirmDialog,
  loadingDialog,
  newOrderApproved,
  newOrderCreated,
  tmpMaterialSelected,
  tmpAgentSelected,
  tmpProductSelected,
  commomParams,
}) => {
  const [showAgentsModal, setShowAgentsModal] = useState(false);
  const [showMaterialModal, setShowMaterialModal] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);
  const [showManageMaterialModal, setShowManageMaterialModal] = useState(false);
  const [selectedMaterialIndex, setSelectedMaterialIndex] = useState(null);
  const [isOpenNewOrderDialog, setIsOpenNewOrderDialog] = useState(false);
  const [isOpenCancelOrderDialog, setIsOpenCancelOrderDialog] = useState(false);
  const [agentSkip, setAgentSkip] = useState(24);
  const [materialSkip, setMaterialSkip] = useState(24);
  const [disableCreateOrder, setDisableCreateOrder] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const nextLoadType = {
    agent: 0,
    material: 1,
  };
  const isAgentView = userData && userData.userRole === USER_ROLES.AGENT;

  useEffect(() => () => {
    tmpMaterialSelected.splice(0, tmpMaterialSelected.length);
    tmpAgentSelected.splice(0, tmpAgentSelected.length);
    tmpProductSelected.splice(0, tmpProductSelected.length);
  }, []);

  const _requestSearchFilter = (data, requestFn, increase, type) => {
    requestFn({
      skip: data.skip || increase ? (type === nextLoadType.agent ? agentSkip : materialSkip) : 0,
      take: data.take || 24,
      filters: {
        standard: data.term && data.type ? [
          {
            attribute: data.type,
            value: data.term,
          },
        ] : [],
      },
    }, increase);
  };

  const _getMaterialAmountQty = (material) => material.agentList.reduce((sum, agent) => sum + agent.qty, 0);

  const _handleMaterialRemove = ({
    materialByAgentList,
    index,
    setFieldValue,
  }) => {
    const copy = cloneDeep(materialByAgentList);
    copy.splice(index, 1);
    tmpMaterialSelected.splice(index, 1);

    setFieldValue('materialByAgentList', copy);
  };

  const _handleMaterialModeChange = ({
    checked,
    materialByAgentList,
    setFieldValue,
    index,
  }) => {
    const copy = cloneDeep(materialByAgentList);
    copy[index].mode = checked
      ? MaterialQtdMode.AUTOMATIC
      : MaterialQtdMode.MANUAL;

    if (checked) {
      copy[index].agentList = copy[index].agentList.map((agent) => ({
        ...agent,
        qty: copy[index].qty,
      }));
    }

    setFieldValue('materialByAgentList', copy);
  };

  const _handleMaterialQtyChange = ({
    qty,
    materialByAgentList,
    setFieldValue,
    index,
  }) => {
    const copy = cloneDeep(materialByAgentList);
    copy[index].mode = MaterialQtdMode.AUTOMATIC;

    copy[index].qty = qty;
    copy[index].agentList = copy[index].agentList.map((agent) => ({
      ...agent,
      qty,
    }));

    setFieldValue('materialByAgentList', copy);
  };

  const _handleProductRemove = ({
    productList,
    index,
    setFieldValue,
  }) => {
    const copy = cloneDeep(productList);
    copy.splice(index, 1);
    tmpProductSelected.splice(index, 1);

    setFieldValue('productList', copy);
  };

  const _handleAgentRemove = ({
    agentList,
    index,
    setFieldValue,
    values,
  }) => {
    const copy = cloneDeep(agentList);
    copy.splice(index, 1);
    tmpAgentSelected.splice(index, 1);

    setFieldValue('agentList', copy);

    const newMaterialArray = values.materialByAgentList.map((selectedMaterial) => ({
      ...selectedMaterial,
      agentList: selectedMaterial.agentList.splice(index, 1),
    }));

    newMaterialArray.forEach((material) => {
      _getMaterialAmountQty(material);
    });
  };

  const _changeToUppercaseValue = ({
    field,
    value,
    setFieldValue,
  }) => {
    const uppercaseValue = value.toUpperCase();
    setFieldValue(field, uppercaseValue);
  };

  const _handleNewOrderIsValid = (values) => {
    const materialQtyByAgent = values.materialByAgentList.filter((item) => item.agentList.some((obj) => obj.qty === 0 && obj.active));
    if (materialQtyByAgent.length > 0) {
      return true;
    }
    if (values.agentList.length <= 0) {
      return true;
    }
    // if (values.productList.length <= 0) {
    //   return true;
    // }
    if (values.materialList.length <= 0) {
      return true;
    }
    if (values.materialByAgentList.length <= 0) {
      return true;
    }
    return false;
  };

  const _onCloseAndGoToPage = () => {
    setIsOpenNewOrderDialog(false);
    history.push(RoutePaths.ORDER);
  };

  const _onConfirmCancelDialog = () => {
    setIsOpenCancelOrderDialog(false);
    history.push(RoutePaths.ORDER);
  };

  const _returnMaterialList = (data, values) => {
    const result = data.filter((item) => values.materialByAgentList.every((obj) => obj.id !== item.id));
    return result;
  };

  const _returnAgentList = (data, values) => {
    const result = data.filter((item) => values.agentList.every((obj) => obj.id !== item.id));
    return result;
  };

  const _returnProductList = (data, values) => {
    const result = data.filter((item) => values.productList.every((obj) => obj.id !== item.id));
    return result;
  };

  const _handleMaterialData = (selectedData, setFieldValue, values) => {
    if (location.state && location.state.material && values.materialByAgentList.length > 0) {
      tmpMaterialSelected.push(location.state.material);
      location.state = {};
    }

    selectedData.forEach((item) => {
      values.materialByAgentList.push({
        ...item,
        active: true,
        qty: 0,
        mode: values.agentList.length > item.availableQuantity ? MaterialQtdMode.MANUAL : MaterialQtdMode.AUTOMATIC,
        agentList: values.agentList.map((agent) => {
          const lastAgent = values.agentList.find((agentItem) => agentItem.id === agent.id);
          return lastAgent || {
            ...agent,
            active: true,
            qty: 0,
          };
        }),
      });
      tmpMaterialSelected.push(item);
    });
    const hasMaterial = values.materialByAgentList.map((item) => item.id);
    if (location.state && location.state.material && values.materialByAgentList.length > 0) {
      if (!hasMaterial.includes(values.materialByAgentList[0].id)) {
        values.materialByAgentList.unshift(location.state.material);
      }
    }
    setFieldValue('materialByAgentList', values.materialByAgentList);
    setFieldValue('materialList', tmpMaterialSelected);
  };

  const _handleAgentData = (selectedData, setFieldValue, values) => {
    selectedData.forEach((item) => {
      tmpAgentSelected.push(item);
    });
    setFieldValue('agentList',
      tmpAgentSelected.map((agent) => {
        const lastAgent = values.agentList.find((agentItem) => agentItem.id === agent.id);
        return lastAgent || {
          ...agent,
          active: true,
          qty: 0,
        };
      }),
    );

    if (values.materialByAgentList.length) {
      setFieldValue(
        'materialByAgentList',
        values.materialByAgentList.map((selectedMaterial) => ({
          ...selectedMaterial,
          mode: selectedMaterial.mode,
          agentList: tmpAgentSelected.map((agent) => {
            const lastAgent = selectedMaterial.agentList.find((agentItem) => agentItem.id === agent.id);
            return lastAgent || {
              ...agent,
              active: true,
              qty: selectedMaterial.qty,
            };
          }),
        })),
      );
    }

    values.materialByAgentList.forEach((material) => {
      _getMaterialAmountQty(material);
    });
  };

  const _handleProductData = (selectedData, setFieldValue) => {
    selectedData.forEach((item) => {
      tmpProductSelected.push(item);
    });

    setFieldValue('productList', tmpProductSelected);
  };

  const _handleManageMaterial = (material, setFieldValue, values) => {
    setFieldValue('materialByAgentList', values.materialByAgentList.map((obj) => obj.id === material.id ? {
      ...obj,
      mode: material.mode,
      qty: _getMaterialAmountQty(material),
      agentList: material.agentList,
    } : obj));
    _handleTotalMaterialByAgent(material);
  };

  const _handleTotalMaterialByAgent = (material) => {
    if ((material.qty * material.agentList.length) > material.availableQuantity) {
      setDisableCreateOrder(true);
      return;
    }
    setDisableCreateOrder(false);
  };

  const _renderSelectedMaterial = ({
    material,
    index,
    values,
    setFieldValue,
  }) => (
    <StyledSelectedMaterialRow
      key={`materialByAgent-${index}`}
      title={material.name}
      canEdit={material.mode === MaterialQtdMode.AUTOMATIC}
      availableCount={parseInt(material.availableQuantity, 10)}
      qtdMode={material.mode}
      qty={material.qty}
      agentTotal={values.agentList.length}
      total={_getMaterialAmountQty(material)}
      handleQtdChange={(e) => {
        _handleMaterialQtyChange({
          qty: parseInt(e.target.value, 10),
          materialByAgentList: values.materialByAgentList,
          setFieldValue,
          index,
        });
        _handleTotalMaterialByAgent(material);
      }}
      handleModeChange={(value, checked) => {
        _handleMaterialModeChange({
          checked,
          materialByAgentList: values.materialByAgentList,
          setFieldValue,
          index,
        });
      }}
      handleRemove={() => {
        _handleMaterialRemove({
          materialByAgentList: values.materialByAgentList,
          index,
          setFieldValue,
        });
      }}
      handleEdit={() => {
        setSelectedMaterialIndex(index);
        setShowManageMaterialModal(true);
      }}
      isAgentView={isAgentView}
    />
  );

  const _renderSelectedAgent = ({
    agent,
    index,
    values,
    setFieldValue,
  }) => (
    <StyledSelectedAgentRow
      key={`agent-${index}`}
      title={agent.name}
      city={agent.city}
      uf={agent.uf}
      tags={agent.tags ? agent.tags : []}
      handleRemove={() => {
        _handleAgentRemove({
          agentList: values.agentList,
          index,
          setFieldValue,
          values,
        });
      }}
    />
  );

  return (
    <Formik
      initialValues={{
        agentList: isAgentView ? [{
          id: userData.id,
          name: userData.name,
          active: true,
          qty: 0,
        }] : [],
        productList: [],
        promotionalCicleName: '',
        materialList: location.state && location.state.material ? [
          location.state.material,
        ] : [],
        materialByAgentList: location.state && location.state.material ? [
          location.state.material,
        ] : [],
      }}
      validationSchema={handleValidations}
    >
      {({
        values,
        setFieldValue,
      }) => (
        <>
          <Page
            userData={userData}
            title={title}
            smallTitle
            originPath={RoutePaths.ORDER}
            originPathName="Novo pedido"
            renderRight={() => (
              <SelectActionButton>
                <PageActionButton
                  variant="outlined"
                  type="button"
                  onClick={() => setIsOpenCancelOrderDialog(true)}
                >
                  cancelar
                </PageActionButton>
                <PageActionButton
                  type="submit"
                  onClick={() => {
                    setIsOpenNewOrderDialog(true);
                    const newValues = {
                      ...values,
                      materialByAgentList: values.materialByAgentList.map((element) => ({
                        ...element,
                        agentList: element.agentList.filter((agent) => agent.qty > 0),
                      })),
                    };
                    handleNewOrderSubmit(newValues);
                  }}
                  disabled={_handleNewOrderIsValid(values) || disableCreateOrder}
                >
                  Enviar pedido
                </PageActionButton>
              </SelectActionButton>
            )}
          >
            <Divider />
            {!isAgentView && (
              <SectionWrapper>
                <SectionTitleContainer>
                  <SectionTitleLabel>Destinatário</SectionTitleLabel>
                </SectionTitleContainer>
                <CardSection flexColumn>
                  <CardContent>
                    {values.agentList.length ? (
                      <>
                        <CardTitleBlack>
                          {values.agentList.length}
                          {' '}
                          representantes selecionados
                        </CardTitleBlack>
                        <CardSubTitle>Revise os representantes na lista abaixo</CardSubTitle>
                      </>
                    ) : (
                      <>
                        <CardTitleBlack>Nenhum representante selecionado</CardTitleBlack>
                        <CardSubTitle>Selecione um representante</CardSubTitle>
                      </>
                    )}
                    <CardButton onClick={() => { setShowAgentsModal(true); }}>
                      Adicionar
                    </CardButton>
                  </CardContent>
                  {values.agentList.length ? (
                    <>
                      <div>
                        <SectionDivider horizontal />
                      </div>
                      <CardContent blueBg>
                        <CardTitleBlue>Representantes selecionados</CardTitleBlue>
                        {values.agentList.map((agent, index) => _renderSelectedAgent({
                          agent,
                          index,
                          values,
                          setFieldValue,
                        }))}
                      </CardContent>
                    </>
                  ) : null}
                </CardSection>
              </SectionWrapper>
            )}
            <SectionWrapper>
              <SectionTitleContainer>
                <SectionTitleLabel>Produtos relacionados a esse pedido</SectionTitleLabel>
              </SectionTitleContainer>
              <CardSection>
                <CardContent>
                  {values.productList.length ? (
                    <>
                      <CardTitleBlack>
                        {values.productList.length}
                        {' '}
                        produtos vinculados
                      </CardTitleBlack>
                      <CardSubTitle>Revise os produtos na lista</CardSubTitle>
                    </>
                  ) : (
                    <>
                      <CardTitleBlack>Nenhum produto selecionado</CardTitleBlack>
                      <CardSubTitle>Vincule produtos a este pedido (opcional)</CardSubTitle>
                    </>
                  )}
                  <CardButton onClick={() => { setShowProductModal(true); }}>Escolher produto</CardButton>
                </CardContent>
                <div>
                  <SectionDivider />
                </div>
                <CardContent block>
                  <CardTitleBlue>Produtos selecionados</CardTitleBlue>
                  <ChipContent>
                    {values.productList.map((item, index) => (
                      <StyledChip
                        key={item.id}
                        color="primary"
                        label={item.name}
                        onClick={() => { }}
                        onDelete={() => {
                          _handleProductRemove({
                            productList: values.productList,
                            index,
                            setFieldValue,
                          });
                        }}
                      />
                    ))}
                  </ChipContent>
                </CardContent>
              </CardSection>
            </SectionWrapper>
            {userData.userRole !== USER_ROLES.AGENT && (
              <SectionWrapper>
                <SectionTitleContainer>
                  <SectionTitleLabel>Ciclo promocional desse pedido</SectionTitleLabel>
                </SectionTitleContainer>
                <CardSection>
                  <CardContent>
                    <StyledTextField
                      name="promotionalCicleName"
                      type="text"
                      label="Ciclo promocional (opcional)"
                      variant="outlined"
                      component={TextField}
                      onChange={(e) => {
                        _changeToUppercaseValue({
                          field: 'promotionalCicleName',
                          value: e.target.value,
                          setFieldValue,
                        });
                      }}
                    />
                  </CardContent>
                </CardSection>
              </SectionWrapper>
            )}
            <SectionWrapper>
              <SectionTitleContainer>
                <SectionTitleLabel>Materiais a serem enviados</SectionTitleLabel>
              </SectionTitleContainer>
              <CardSection flexColumn>
                <CardContent>
                  {values.materialByAgentList.length ? (
                    <>
                      <CardTitleBlack>
                        {values.materialByAgentList.length}
                        {' '}
                        materiais selecionados
                      </CardTitleBlack>
                      <CardSubTitle>Revise a quantidade de material a ser enviada para cada representante na lista abaixo</CardSubTitle>
                    </>
                  ) : (
                    <>
                      <CardTitleBlack>Nenhum material selecionado</CardTitleBlack>
                      <CardSubTitle>Selecione pelo menos um material para enviar neste pedido</CardSubTitle>
                    </>
                  )}
                  <CardButton onClick={() => { setShowMaterialModal(true); }}>Adicionar material</CardButton>
                </CardContent>
                <div>
                  <SectionDivider horizontal />
                </div>
                {values.materialByAgentList.length ? (
                  <CardContent blueBg>
                    <CardTitleBlue>Materiais selecionados</CardTitleBlue>
                    {values.materialByAgentList.map((material, index) => _renderSelectedMaterial({
                      material,
                      index,
                      values,
                      setFieldValue,
                    }))}
                  </CardContent>
                ) : null}
              </CardSection>
            </SectionWrapper>
            <Footer>
              <PageActionButton
                variant="outlined"
                type="button"
                onClick={() => setIsOpenCancelOrderDialog(true)}
              >
                cancelar
              </PageActionButton>
              <PageActionButton
                type="submit"
                onClick={() => {
                  setIsOpenNewOrderDialog(true);
                  const newValues = {
                    ...values,
                    materialByAgentList: values.materialByAgentList.map((element) => ({
                      ...element,
                      agentList: element.agentList.filter((agent) => agent.qty > 0),
                    })),
                  };
                  handleNewOrderSubmit(newValues);
                }}
                disabled={_handleNewOrderIsValid(values) || disableCreateOrder}
              >
                Enviar pedido
              </PageActionButton>
            </Footer>
            {userData.userRole !== USER_ROLES.AGENT && (
              <FastFieldWrapper
                name="agentList"
                component={AgentsModalTable}
                width={810}
                open={showAgentsModal}
                onDismiss={() => setShowAgentsModal(false)}
                onConfirm={(selectedAgentList) => {
                  setShowAgentsModal(false);
                  _handleAgentData(selectedAgentList, setFieldValue, values);
                }}
                tableData={_returnAgentList(agentList.list, values)}
                loading={agentList.loading}
                onSearch={(data) => _requestSearchFilter(data, onRequestAgentList)}
                take={24}
                onLoad={(data) => {
                  setAgentSkip(agentSkip + 24);
                  _requestSearchFilter(data, onRequestAgentList, true, nextLoadType.agent);
                }}
                handleRefresh={() => {
                  onRequestAgentList(commomParams);
                }}
              />
            )}
            <FastFieldWrapper
              name="materialList"
              component={MaterialModalTable}
              width={810}
              open={showMaterialModal}
              onDismiss={() => setShowMaterialModal(false)}
              onConfirm={(selectedMaterialList) => {
                setShowMaterialModal(false);
                _handleMaterialData(selectedMaterialList, setFieldValue, values);
              }}
              tableData={_returnMaterialList(materialList.list, values)}
              loading={materialList.loading}
              onSearch={(data) => _requestSearchFilter(data, onRequestMaterialList)}
              take={24}
              onLoad={(data) => {
                setMaterialSkip(materialSkip + 24);
                _requestSearchFilter(data, onRequestMaterialList, true, nextLoadType.material);
              }}
              handleRefresh={() => {
                onRequestMaterialList(commomParams);
              }}
            />
            <FastFieldWrapper
              name="productList"
              component={ProductModalTable}
              width={810}
              open={showProductModal}
              onDismiss={() => setShowProductModal(false)}
              onConfirm={(selectedProductList) => {
                setShowProductModal(false);
                _handleProductData(selectedProductList, setFieldValue);
              }}
              tableData={_returnProductList(productList.list, values)}
              loading={productList.loading}
              onSearch={onRequestProductList}
              handleRefresh={() => {
                onRequestProductList(commomParams);
              }}
            />
            <FastFieldWrapper
              name="materialByAgentList"
              component={ManageMaterialModalTable}
              width={810}
              open={showManageMaterialModal}
              onDismiss={() => {
                setShowManageMaterialModal(false);
                setSelectedMaterialIndex(null);
              }}
              onConfirm={(data) => {
                setShowManageMaterialModal(false);
                setSelectedMaterialIndex(null);
                _handleManageMaterial(data, setFieldValue, values);
              }}
              materialIndex={selectedMaterialIndex}
              getMaterialAmountQty={_getMaterialAmountQty}
              showManageMaterialModal={showManageMaterialModal}
            />
            {loading ? <LoadMask /> : null}
            <DialogModal
              title={newOrderApproved ? 'Pedido enviado e aprovado com sucesso!' : newOrderCreated ? 'Pedido enviado com sucesso!' : 'Novo pedido'}
              description={newOrderApproved || newOrderCreated ? '' : userData.userRole === USER_ROLES.AGENT ? 'Tem certeza que deseja enviar seu pedido?' : 'Deseja enviar e aprovar o pedido ou apenas enviar?'}
              confirmButtonText={newOrderApproved || newOrderCreated ? '' : 'Enviar e aprovar'}
              cancelButtonText={newOrderApproved || newOrderCreated ? '' : userData.userRole === USER_ROLES.AGENT ? 'Enviar' : 'Apenas enviar'}
              hideCancelButton={newOrderApproved || newOrderCreated ? true : false}
              hideConfirmButton={(newOrderApproved || newOrderCreated) || (userData.userPermissions && !userData.userPermissions[SUB_MODULES[MODULES.DISTRIBUTIONS].APPROVAL_ORDERS][4]) ? true : false}
              closeButtonText={newOrderApproved || newOrderCreated ? 'Fechar' : 'Cancelar'}
              size="lg"
              showCloseButton
              handleConfirmDialog={() => { handleConfirmDialog(true); }}
              handleCloseDialog={() => { handleConfirmDialog(false); }}
              handleOnlyClose={() => newOrderApproved || newOrderCreated ? _onCloseAndGoToPage() : setIsOpenNewOrderDialog(false)}
              isOpenDialog={isOpenNewOrderDialog}
              loadingDialog={loadingDialog}
            />
            <DialogModal
              title="Descartar pedido"
              description="Tem certeza que deseja sair sem salvar as alterações?"
              confirmButtonText="Descartar pedido"
              handleConfirmDialog={() => { _onConfirmCancelDialog(); }}
              handleCloseDialog={() => { setIsOpenCancelOrderDialog(false); }}
              isOpenDialog={isOpenCancelOrderDialog}
            />
          </Page>
        </>
      )}
    </Formik>
  );
};

NewOrderLayout.propTypes = {
  userData: PropTypes.object,
  loading: PropTypes.bool,
  title: PropTypes.string,
  agentList: PropTypes.shape({
    list: PropTypes.any,
    loading: PropTypes.any,
    total: PropTypes.any,
  }).isRequired,
  materialList: PropTypes.shape({
    list: PropTypes.any,
    loading: PropTypes.any,
    total: PropTypes.any,
  }).isRequired,
  productList: PropTypes.shape({
    list: PropTypes.any,
    loading: PropTypes.any,
    total: PropTypes.any,
  }).isRequired,
  loadingDialog: PropTypes.bool,
  newOrderApproved: PropTypes.bool,
  newOrderCreated: PropTypes.bool,
  tmpMaterialSelected: PropTypes.array,
  tmpAgentSelected: PropTypes.array,
  tmpProductSelected: PropTypes.array,
  commomParams: PropTypes.object,
};

NewOrderLayout.defaultProps = {
  userData: {},
  loading: false,
  title: '',
  loadingDialog: false,
  newOrderApproved: false,
  newOrderCreated: false,
  tmpMaterialSelected: [],
  tmpAgentSelected: [],
  tmpProductSelected: [],
  commomParams: {},
};

export default NewOrderLayout;
