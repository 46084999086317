import React, { useRef } from 'react';
import { Tr } from './Table';

const RowWrapperComponent = ({
  rowWrapper,
  rowData,
  onRowClick,
  rowIndex,
  children,
  toggleCheckbox,
  ...props
}) => {
  const { RowWrapper, rowWrapperProps } = rowWrapper ? rowWrapper(rowData, rowIndex) : { RowWrapper: Tr, rowWrapperProps: {} };
  const mouseMoved = useRef(false);
  const oldX = useRef(0);
  const oldY = useRef(0);
  return (
    <RowWrapper
      onMouseUp={(e) => {
        if (Math.abs(e.clientX - oldX.current) > 4 || Math.abs(e.clientY - oldY.current) > 4) {
          mouseMoved.current = true;
        }
      }}
      onMouseDown={(e) => {
        mouseMoved.current = false;
        const currentX = e.clientX;
        const currentY = e.clientY;
        oldX.current = currentX;
        oldY.current = currentY;
      }}
      onClick={onRowClick ? (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!mouseMoved.current) {
          onRowClick(rowData, rowIndex, toggleCheckbox);
        }
      } : null}
      {...props}
      {...rowWrapperProps}
    >
      {children}
    </RowWrapper>
  );
};

export default RowWrapperComponent;
