import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import './materialUploadSlider.css';

import {
  PrevArrow,
  NextArrow,
} from './Arrows';

const SliderWrapper = styled(Slider)`
  margin-bottom: 8px;

  .slick-list {
    line-height: 0;
  }
`;

const SlideItem = styled.div`
  width: 100%;
  height: 250px;
  background-color: #F3F4F4;

  img {
    width: 100%;
    height: 250px;
    object-fit: contain;
  }
`;

const MaterialUploadSlider = ({
  handleCurrentData,
  sliderData,
  showLastUploadedImage = null,
}) => {
  const [nextArrowActive, setNextArrowActive] = useState(true);
  const [prevArrowActive, setPrevArrowActive] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(1);
  const slider = useRef(null);

  useEffect(() => {
    if (sliderData.length > 0) {
      if (!sliderData[currentSlide - 1]) {
        handleCurrentData({
          imageId: sliderData[0].id,
          name: sliderData[0].name,
        });
        setTimeout(() => {
          slider.current.slickGoTo(1);
        }, 1000);
      } else {
        handleCurrentData({
          imageId: sliderData[currentSlide - 1].id,
          name: sliderData[currentSlide - 1].name,
        });
        if (showLastUploadedImage) {
          handleCurrentData({
            imageId: sliderData[showLastUploadedImage - 1].id,
            name: sliderData[showLastUploadedImage - 1].name,
          });
          slider.current.slickGoTo(showLastUploadedImage - 1);
        }
      }
    }
  }, [
    currentSlide,
    sliderData,
  ]);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow nextArrowActive={nextArrowActive} />,
    prevArrow: <PrevArrow prevArrowActive={prevArrowActive} />,
    afterChange: (current) => {
      const activeSlide = current + 1;
      setCurrentSlide(activeSlide);
      if (current === 0) {
        setPrevArrowActive(false);
      } else {
        setPrevArrowActive(true);
      }
      if (current >= (sliderData.length - sliderSettings.slidesToShow)) {
        setNextArrowActive(false);
      } else {
        setNextArrowActive(true);
      }
    },
  };

  return (
    <div id="materialUploadSlider">
      {sliderData.length > 0 && (
        <SliderWrapper
          ref={slider}
          {...sliderSettings}
        >
          {sliderData.map((item) => (
            <SlideItem
              key={item}
            >
              <img src={item.url} alt="Material" />
            </SlideItem>
          ))}
        </SliderWrapper>
      )}
    </div>
  );
};

MaterialUploadSlider.propTypes = {
  sliderData: PropTypes.array.isRequired,
};

export default MaterialUploadSlider;
