import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Field } from 'formik';

import { FilterList } from '@material-ui/icons';
import {
  FilterDate,
  FilterRadio,
  FilterSearch,
  FilterWrapper,
  FilterCheckbox,
} from '../../../../../../components/Filter';
import {
  orderByOptionList,
  orderByAgentOptionList,
  searchTypeList,
  availabilityOptionList,
  stockTypeOptionList,
  fieldNames,
  searchType,
} from '../../../../../../constants/stock/filters';
import FastFieldWrapper from '../../../../../../components/form/FastFieldWrapper';
import { USER_ROLES, BreakpointValues } from '../../../../../../constants';
import { DropdownLabel } from '../../../../../../components/Dropdown';
import { useRect, useWindowSize, mediaHelper } from '../../../../../../utils';

const StyledFilterWrapper = styled(FilterWrapper)`
  ${mediaHelper.sm`
    transition: height ease-out 0.3s;
  `}

  ${({ showHiddenFilters, totalHeight }) => showHiddenFilters && `
    height: auto;
    ${mediaHelper.md`
      height: ${totalHeight}px;
    `}
  `}
`;

const SingleFilterContainer = styled.div`
  transition: opacity ease-out 0.3s;
  opacity: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-left: 0;

  ${mediaHelper.xs`
    margin-left: 16px;
    justify-content: flex-start;
  `}

  ${mediaHelper.md`
    width: auto;
    display: block;
    margin-top: 0;
    justify-content: center;
    margin-left: 24px;
  `}


  ${({ allFiltersRefSetted }) => !allFiltersRefSetted && `
    opacity: 0;
  `}
`;

const StyledFilterList = styled(({ active, ...props }) => <FilterList {...props} />)`
  transition: transform ease-out 0.3s;

  ${({ active }) => active && `
    transform: rotate(180deg);
  `}
`;

const FiltersContainer = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  transition: height ease-out 0.3s;
  flex-direction: column;
`;

const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`;

const HiddenFiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  transition: opacity ease-out 0.3s, height ease-out 0.3s;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  height: 0;
  padding-top: 16px;

  ${mediaHelper.sm`
    padding-top: 0;
  `}

  ${({ show }) => show && `
    opacity: 1;
    height: auto;
    pointer-events: auto;
    overflow: visible;
    padding-top: 0;
  `}
`;

const DropdownLabelStyled = styled(DropdownLabel)`
  margin: 0 auto;

  ${mediaHelper.xs`
    margin: 0 0 0 16px;
  `}
`;

const FieldWrapper = styled.div`
  margin-right: 16px;
`;

const Filters = ({
  userData,
  handleReset,
  values,
  onSearch,
  onChangeDataOrder,
  appliedFilters,
}) => {
  const isAgentView = userData && userData.userRole === USER_ROLES.AGENT;
  const [showHiddenFilters, setShowHiddenFilters] = useState(false);
  const filtersContainerRef = useRef();
  const filtersWrapperRef = useRef();
  const filtersWrapperBoundigRef = useRect(filtersWrapperRef);
  const hiddenFiltersContainerRef = useRef();
  const filtersBounding = useRect(filtersContainerRef);
  const hiddenFiltersBounding = useRect(hiddenFiltersContainerRef);
  const currentWindowSize = useWindowSize();

  const [allFiltersRefSetted, setAllFiltersRefSetted] = useState(false);
  const filtersRef = {
    [fieldNames.TYPE]: useRef(),
    [fieldNames.AVAILABILITY]: useRef(),
    ...(!isAgentView && { [fieldNames.DUE_DATE]: useRef() }),
  };
  const [filtersVisibilityControl, setFiltersVisibilityControl] = useState({
    [fieldNames.TYPE]: true,
    [fieldNames.AVAILABILITY]: true,
    ...(userData.userRole !== USER_ROLES.DUE_DATE && { [fieldNames.DUE_DATE]: true }),
  });

  useEffect(() => {
    const allSetted = Object.entries(filtersRef).every(([key, ref]) => {
      if ((!isAgentView && key === fieldNames.DUE_DATE)) {
        return true;
      }
      return !!ref.current;
    });
    if (allSetted) {
      setAllFiltersRefSetted(true);
      // If the distance from the top of the parent container is greater than the its own height, then this component must be hided
      setFiltersVisibilityControl(
        Object.entries(filtersRef).reduce((obj, [key, ref]) => ({
          ...obj,
          [key]: ref.current && ref.current.offsetTop < ref.current.offsetHeight && currentWindowSize.width >= BreakpointValues.md,
        }), {}),
      );
    }
  }, [
    userData,
    filtersRef[fieldNames.TYPE],
    filtersRef[fieldNames.AVAILABILITY],
    filtersRef[fieldNames.DUE_DATE],
    filtersBounding.rect.right,
  ]);

  useEffect(() => {
    setFiltersVisibilityControl(
      Object.entries(filtersRef).reduce((obj, [key]) => ({
        ...obj,
        [key]: currentWindowSize.width <= BreakpointValues.md ? false : true,
      }), {}),
    );
  }, [
    currentWindowSize.width,
  ]);

  const invertedFiltersVisibilityControl = Object.entries(filtersVisibilityControl).reduce((obj, [key, value]) => ({
    ...obj,
    [key]: !value,
  }), {});

  const renderFilters = (showFilters) => (
    <>
      {showFilters[fieldNames.TYPE] ? (
        <SingleFilterContainer
          ref={filtersRef[fieldNames.TYPE]}
          allFiltersRefSetted={allFiltersRefSetted}
          show={filtersVisibilityControl[fieldNames.TYPE]}
        >
          <FastFieldWrapper
            name={fieldNames.TYPE}
            label="Tipo"
            component={FilterCheckbox}
            submitOnChange
            optionList={stockTypeOptionList}
            handleReset={handleReset}
            appliedFilters={appliedFilters}
            fieldNames={fieldNames}
          />
        </SingleFilterContainer>
      ) : null}
      {showFilters[fieldNames.AVAILABILITY] ? (
        <SingleFilterContainer
          ref={filtersRef[fieldNames.AVAILABILITY]}
          allFiltersRefSetted={allFiltersRefSetted}
          show={filtersVisibilityControl[fieldNames.AVAILABILITY]}
        >
          <FastFieldWrapper
            name={fieldNames.AVAILABILITY}
            label={userData.userRole === USER_ROLES.AGENT ? 'Encomendado' : 'Reservado'}
            component={FilterRadio}
            submitOnChange
            optionList={availabilityOptionList}
            handleReset={handleReset}
            appliedFilters={appliedFilters}
            fieldNames={fieldNames}
          />
        </SingleFilterContainer>
      ) : null}
      {showFilters[fieldNames.DUE_DATE] && !isAgentView ? (
        <SingleFilterContainer
          ref={filtersRef[fieldNames.DUE_DATE]}
          allFiltersRefSetted={allFiltersRefSetted}
          show={filtersVisibilityControl[fieldNames.DUE_DATE]}
        >
          <FastFieldWrapper
            name={fieldNames.DUE_DATE}
            label="Validade"
            component={FilterDate}
            submitOnChange
            handleReset={handleReset}
            appliedFilters={appliedFilters}
            fieldNames={fieldNames}
          />
        </SingleFilterContainer>
      ) : null}
    </>
  );

  return (
    <StyledFilterWrapper
      showHiddenFilters={showHiddenFilters}
      totalHeight={filtersBounding.rect.height + hiddenFiltersBounding.rect.height + filtersWrapperBoundigRef.rect.height}
      renderLeft={() => (
        <>
          <FiltersContainer
            ref={filtersWrapperRef}
          >
            <FiltersWrapper ref={filtersContainerRef}>
              {renderFilters(filtersVisibilityControl)}
            </FiltersWrapper>
            <HiddenFiltersWrapper
              ref={hiddenFiltersContainerRef}
              show={showHiddenFilters}
            >
              {renderFilters(invertedFiltersVisibilityControl)}
            </HiddenFiltersWrapper>
          </FiltersContainer>
          {Object.values(invertedFiltersVisibilityControl).includes(true) && (
            <DropdownLabelStyled
              active={showHiddenFilters}
              onClick={() => {
                setShowHiddenFilters(!showHiddenFilters);
              }}
            >
              {currentWindowSize.width <= BreakpointValues.sm && (
                <>
                  {showHiddenFilters ? `${currentWindowSize.width >= BreakpointValues.md ? 'Menos filtros' : 'Filtros'}` : `${currentWindowSize.width >= BreakpointValues.md ? 'Mais filtros' : 'Filtros'}`}
                </>
              )}
              <StyledFilterList active={showHiddenFilters} />
            </DropdownLabelStyled>
          )}
        </>
      )}
      renderRight={() => (
        <>
          <FieldWrapper>
            <Field
              name={fieldNames.ORDER_BY}
              label="Ordernar"
              value={values[fieldNames.ORDER_BY]}
              component={FilterRadio}
              submitOnChange
              optionList={userData.userRole === USER_ROLES.AGENT ? orderByAgentOptionList : orderByOptionList}
              handleOutput={onChangeDataOrder}
            />
          </FieldWrapper>
          <FilterSearch
            name={fieldNames.SEARCH}
            placeholder={(selectedType) => selectedType.type === searchType.NAME ? 'Nome do material' : 'Cód. do material'}
            typeList={searchTypeList}
            component={FilterSearch}
            submitOnChange
            handleOutput={onSearch}
            placement="bottom-end"
          />
        </>
      )}
    />
  );
};

export default Filters;
