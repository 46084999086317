import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { useRect, clamp } from '../../utils';

export const FontWeight = {
  ExtraLight: '200',
  ExtraLightItalic: '200i',
  Light: '300',
  LightItalic: '300i',
  Regular: '400',
  Italic: '400i',
  Medium: '500',
  SemiBold: '600',
  SemiBoldItalic: '600i',
  Bold: '700',
  BoldItalic: '700i',
  ExtraBold: '800',
  ExtraBoldItalic: '800i',
  Black: '900',
  BlackItalic: '900i',
  None: 'none',
};

const propTypes = {
  secondary: PropTypes.bool,
  weight: PropTypes.string,
};

const defaultProps = {
  secondary: false,
  weight: FontWeight.SemiBold,
};

// Mixins

// Components

const StyledText = styled.span`
  letter-spacing: -0.25px;
  ${({ numberOfLines }) =>
    numberOfLines &&
    css`
      display: block;
    `}

  & strong {
    font-weight: bold;
  }
`;

const Text = ({ numberOfLines, children, ...props }) => {
  const textRef = useRef(null);
  const textBounding = useRect(numberOfLines ? textRef : null);

  useEffect(() => {
    if (numberOfLines && textRef.current) {
      clamp(textRef.current, { clamp: numberOfLines });
    }
  }, [numberOfLines, children, textBounding.rect.height]);

  return (
    <StyledText numberOfLines={numberOfLines} ref={textRef} {...props}>
      {children}
    </StyledText>
  );
};

Text.propTypes = propTypes;
Text.defaultProps = defaultProps;

export const RegularText = styled(Text).attrs({
  weight: FontWeight.Regular,
})``;

export const SemiBoldText = styled(Text).attrs({
  weight: FontWeight.SemiBold,
})``;

export const BoldText = styled(Text).attrs({
  weight: FontWeight.Bold,
})``;

export const BoldItalicText = styled(Text).attrs({
  weight: FontWeight.BoldItalic,
})``;

export const ExtraBoldText = styled(Text).attrs({
  weight: FontWeight.ExtraBold,
})``;

export default styled(Text)``;
