import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import {
  AgentService,
  StockService,
} from '../../../../services';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../components';
import PermissionDetailLayout from './permission-detail-layout';
import { typePermissionTerm, permissionRecurrence } from '../../../../constants/stock/enums';
import { agentType } from '../../../../constants/stock';

class PermissionDetailPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      agentList: {
        loading: false,
        list: [],
      },
      materialPermission: {
        loading: false,
        data: {},
      },
      requestDialog: {
        loading: false,
        isOpenDialog: false,
      },
    };
  }

  componentDidMount() {
    const commomParams = {
      skip: 0,
      take: 24,
    };

    this._requestAgentList(commomParams);
    this._requestStockPermissionDetail();
  }

  _requestAgentList = async (data, increase) => {
    const { dispatch } = this.props;
    const request = data;
    if (data.filters && data.filters.standard.length > 0) {
      if (data.filters.standard[0].attribute === agentType.TAG) {
        request.filters.tagName = data.filters.standard[0].value;
        request.filters.standard = [];
      }
    }

    try {
      this.setState(({ agentList }) => ({
        agentList: {
          ...agentList,
          loading: true,
        },
      }));

      const { data: { list, total } } = await AgentService.fetchAgentList(request);

      this.setState(({ agentList }) => ({
        agentList: {
          ...agentList,
          loading: false,
          list: increase ? [
            ...agentList.list,
            ...list,
          ] : list,
          total,
        },
      }));
    } catch (e) {
      this.setState(({ agentList }) => ({
        agentList: {
          ...agentList,
          loading: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  };

  _requestStockPermissionDetail = async () => {
    const { match, dispatch } = this.props;
    const materialId = match.params.id;
    if (materialId) {
      try {
        this.setState(() => ({
          materialPermission: {
            loading: true,
            data: {},
          },
        }));
        const { data } = await StockService.fetchStockPermissionDetail(materialId);
        this.setState(({ materialPermission }) => ({
          materialPermission: {
            ...materialPermission,
            loading: false,
            data,
          },
        }));
      } catch (e) {
        this.setState(({ agentList }) => ({
          agentList: {
            ...agentList,
            loading: false,
          },
        }));
        dispatch(showErrorToast(e.message));
      }
    }
  };

  _handleNewStockPermission = async (data) => {
    const { dispatch } = this.props;
    try {
      this.setState((prevState) => ({
        requestDialog: {
          ...prevState.requestDialog,
          loading: true,
          isOpenDialog: true,
        },
      }));
      await StockService.requestNewStockPermission(data);
      this.setState((prevState) => ({
        requestDialog: {
          ...prevState.requestDialog,
          loading: false,
          isOpenDialog: false,
        },
      }));
      dispatch(showSuccessToast('Permissão de estoque criada com sucesso!'));
      setTimeout(() => {
        window.history.back();
      }, 1000);
    } catch (e) {
      this.setState((prevState) => ({
        requestDialog: {
          ...prevState.requestDialog,
          loading: false,
          isOpenDialog: false,
        },
      }));
      dispatch(showErrorToast(e.message));
    }
  };

  _handleEditStockPermission = async (data) => {
    const { dispatch } = this.props;
    try {
      this.setState((prevState) => ({
        requestDialog: {
          ...prevState.requestDialog,
          loading: true,
          isOpenDialog: true,
        },
      }));
      await StockService.requestEditStockPermission(data);
      this.setState((prevState) => ({
        requestDialog: {
          ...prevState.requestDialog,
          loading: false,
          isOpenDialog: false,
        },
      }));
      dispatch(showSuccessToast('Permissão de estoque atualizada com sucesso!'));
      setTimeout(() => {
        window.history.back();
      }, 2000);
    } catch (e) {
      this.setState((prevState) => ({
        requestDialog: {
          ...prevState.requestDialog,
          loading: false,
          isOpenDialog: false,
        },
      }));
      dispatch(showErrorToast(e.message));
    }
  };

  _requestRemoveStockPermission = async (id) => {
    const { dispatch } = this.props;
    try {
      this.setState((prevState) => ({
        requestDialog: {
          ...prevState.requestDialog,
          loading: true,
          isOpenDialog: true,
        },
      }));
      await StockService.requestRemovePermissionStock(id);
      this.setState((prevState) => ({
        requestDialog: {
          ...prevState.requestDialog,
          loading: false,
          isOpenDialog: false,
        },
      }));
      dispatch(showSuccessToast('Permissão de estoque removida!'));
      setTimeout(() => {
        window.history.back();
      }, 1000);
    } catch (e) {
      this.setState((prevState) => ({
        requestDialog: {
          ...prevState.requestDialog,
          loading: false,
          isOpenDialog: false,
        },
      }));
      dispatch(showErrorToast(e.message));
    }
  }

  _getValidations = () => Yup.object().shape({
    validityPeriod: Yup.string()
      .when('typePermissionTerm', {
        is: `${typePermissionTerm.DETERMINED}`,
        then: Yup.string().required('Escolha o período.'),
      }),
    recurrenceQuantity: Yup.string()
      .when('permissionRecurrence', {
        is: `${permissionRecurrence.RECURRENT}`,
        then: Yup.string()
          .matches(
            /^[1-9][0-9]*$/i,
            'Somente números.',
            { excludeEmptyString: true },
          )
          .required('Digite a Qtde.'),
      }),
    recurrenceInterval: Yup.string()
      .when('permissionRecurrence', {
        is: `${permissionRecurrence.RECURRENT}`,
        then: Yup.string()
          .required('Escolha o intervalo.'),
      }),
    quantityDistribution: Yup.string()
      .matches(
        /^[0-9_]*$/i,
        'Somente números.',
        { excludeEmptyString: true },
      ),
    quantityMaterialDialog: Yup.string()
      .matches(
        /^[0-9_]*$/i,
        'Somente números.',
        { excludeEmptyString: true },
      ),
  });

  render() {
    const {
      userData,
    } = this.props;

    const {
      materialPermission,
      requestDialog,
      agentList,
    } = this.state;

    return (
      <PermissionDetailLayout
        userData={userData}
        materialPermission={materialPermission.data}
        requestDialog={requestDialog}
        loading={materialPermission.loading}
        onRequestAgentList={this._requestAgentList}
        onRequestRemovePermission={this._requestRemoveStockPermission}
        handleValidations={this._getValidations}
        agentList={agentList}
        handleNewStockPermission={this._handleNewStockPermission}
        handleEditStockPermission={this._handleEditStockPermission}
        commomParams={{
          skip: 0,
          take: 7,
        }}
        isStockPermission
      />
    );
  }
}

const mapStateToProps = (data) => data;

export default connect(mapStateToProps)(PermissionDetailPage);
