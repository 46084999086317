import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import NumberFormat from 'react-number-format';

import { IconButton, Switch } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { Colors, MaterialQtdMode } from '../../../../constants';
import { mediaHelper } from '../../../../utils';

const Container = styled.div`
  display: flex;
  position: relative;
  padding: 18px 24px;
  flex-wrap: wrap;

  ${mediaHelper.md`
    position: static;
  `}

  ${mediaHelper.lg`
    justify-content: flex-end;
    flex-direction: row;
    height: 56px;
  `}
`;

const ControlWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0 0 0;
  
  ${mediaHelper.md`
    margin: 0 36px 0 0;

    ${({ minWidth }) => minWidth && 'min-width: 352px;'}
  `}
`;

const UnitsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  padding-right: 28px;

  ${mediaHelper.md`
    padding-right: 0;
  `}
`;

const ValueText = styled.div`
  font-size: 14px;
  color: ${Colors.GRAY_DARKEST};

  ${({ error }) => error && `color: ${Colors.DANGER}`}
`;

const SwitchModeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 24px;
`;

const Title = styled.div`
  font-size: 16px;
  color: ${Colors.GRAY_DARKEST};
  font-weight: bold;
  margin-bottom: 4px;
`;

const StyledLabel = styled.div`
  font-size: 12px;
  color: ${Colors.GRAY_DARKEST};
  font-weight: bold;

  ${({ error }) => error && `color: ${Colors.DANGER}`}
`;

const SwitchLabel = styled.span`
  font-size: 12px;
  color: ${Colors.GRAY_DARKEST};

  ${({ selected }) => selected && `
    color: ${Colors.PRIMARY_BLUE};
    font-weight: bold;
  `}
`;

const StyledTextField = styled(Field)`
  width: 100%;

  ${mediaHelper.md`
    width: 120px;
    margin-right: 16px;
  `}
`;

const ActionButtonContainer = styled.div`
  padding-top: 4px;
  position: absolute;
  top: 0;
  right: 12px;

  ${mediaHelper.md`
    position: static;
  `}
`;

function SelectedMaterialRow({
  title,
  canEdit,
  availableCount,
  qtdMode,
  total,
  qty,
  agentTotal,
  handleRemove,
  handleEdit,
  handleQtdChange,
  handleModeChange,
  isAgentView,
  ...props
}) {
  return (
    <Container {...props}>
      <UnitsWrapper>
        <Title>{title}</Title>
        <ValueText>
          <NumberFormat
            value={availableCount || 0}
            displayType="text"
            decimalSeparator=","
            thousandSeparator={true, '.'}
          />
          {' disponíveis'}
        </ValueText>
      </UnitsWrapper>
      <ControlWrapper
        minWidth={!isAgentView}
      >
        {!isAgentView && (
          <SwitchModeContainer>
            <StyledLabel>Qtde por representante</StyledLabel>
            <div>
              <SwitchLabel selected={qtdMode === MaterialQtdMode.MANUAL}>Manual</SwitchLabel>
              <Switch
                color="primary"
                size="small"
                checked={qtdMode === MaterialQtdMode.AUTOMATIC}
                onChange={handleModeChange}
                disabled={agentTotal > availableCount ? true : false}
              />
              <SwitchLabel selected={qtdMode === MaterialQtdMode.AUTOMATIC}>Automatico</SwitchLabel>
            </div>
          </SwitchModeContainer>
        )}
        {canEdit && handleQtdChange ? (
          <StyledTextField
            name="materialQuantity"
            label="Quantidade"
            value={qty || 0}
            variant="outlined"
            onChange={handleQtdChange}
            onBlur={handleQtdChange}
            component={TextField}
            error={total > availableCount}
          />
        ) : null}
        {!isAgentView && (
          <>
            <UnitsWrapper>
              <StyledLabel error={total > availableCount}>Total</StyledLabel>
              <ValueText error={total > availableCount}>{total || 0}</ValueText>
            </UnitsWrapper>
          </>
        )}
      </ControlWrapper>
      <ActionButtonContainer>
        {!isAgentView && handleEdit ? (
          <IconButton
            color="primary"
            aria-label="Editar material"
            onClick={handleEdit}
          >
            <Edit />
          </IconButton>
        ) : null}
        {handleRemove ? (
          <IconButton
            color="primary"
            aria-label="Remover material"
            onClick={handleRemove}
          >
            <Delete />
          </IconButton>
        ) : null}
      </ActionButtonContainer>
    </Container>
  );
}

SelectedMaterialRow.propTypes = {
  availableCount: PropTypes.number,
  canEdit: PropTypes.bool,
  handleEdit: PropTypes.func,
  handleQtdChange: PropTypes.func,
  handleModeChange: PropTypes.func,
  handleRemove: PropTypes.func,
  qtdMode: PropTypes.number,
  title: PropTypes.string,
  total: PropTypes.number,
};

SelectedMaterialRow.defaultProps = {
  availableCount: 0,
  canEdit: false,
  handleEdit: null,
  handleQtdChange: null,
  handleModeChange: () => {},
  handleRemove: null,
  qtdMode: MaterialQtdMode.AUTOMATIC,
  title: '',
  total: 0,
};

export default SelectedMaterialRow;
