import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  MenuItem,
} from '@material-ui/core/';
import isEqual from 'lodash.isequal';

import Dropdown from '../Dropdown';
import { BreakpointValues } from '../../constants';
import { useWindowSize } from '../../utils';

const propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  optionList: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  })).isRequired,
  customIcon: PropTypes.any,
  handleOutput: PropTypes.func.isRequired,
  value: PropTypes.any,
};
const defaultProps = {
  customIcon: undefined,
  value: undefined,
  name: undefined,
};

const FormControlField = styled(FormControl)`
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  display: -webkit-box;
`;

const ListItem = styled(MenuItem)`
  padding-top: 0;
  padding-bottom: 0;
`;

const ListItemLabel = styled(FormControlLabel)`
  padding-top: 4px;
  padding-bottom: 4px;
`;

const FilterRadio = ({
  label,
  name,
  optionList,
  customIcon,
  handleOutput,
  value,
  appliedFilters,
  fieldNames,
}) => {
  const [state, setState] = useState(value);
  const currentWindowSize = useWindowSize();

  useEffect(() => {
    if (!isEqual(state, value)) {
      setState(value);
    }
  }, [value]);

  const handleChange = (event) => {
    setState(event.target.value);
  };

  return (
    <Dropdown
      labelText={label}
      hasConfirmButtons
      icon={customIcon}
      handleConfirm={() => {
        handleOutput(state);
      }}
      handleDismiss={() => {
        setState(value);
      }}
      placement={currentWindowSize.width <= BreakpointValues.md ? 'bottom' : 'bottom-start'}
      appliedFilters={appliedFilters}
      fieldNames={fieldNames}
      name={name}
    >
      <FormControlField component="fieldset">
        <RadioGroup
          aria-label={name}
          name={name}
          value={state}
          onChange={handleChange}
        >
          {optionList.map((option) => (
            <ListItem key={`${name}-${option.value}`}>
              <ListItemLabel
                value={option.value}
                control={<Radio color="primary" />}
                label={option.label}
              />
            </ListItem>
          ))}
        </RadioGroup>
      </FormControlField>
    </Dropdown>
  );
};

FilterRadio.propTypes = propTypes;
FilterRadio.defaultProps = defaultProps;

export default FilterRadio;
