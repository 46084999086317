import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import { showErrorToast } from '../../../../components';
import {
  MaterialService,
  ProductService,
  OrderService,
} from '../../../../services';
import NewOrderLayout from '../../../shared/order/new-order-layout';

class AgentNewOrderPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      agentList: {
        loading: false,
        list: [],
      },
      materialList: {
        loading: false,
        list: [],
      },
      productList: {
        loading: false,
        list: [],
      },
      newOrder: {
        data: {},
        loading: false,
        newOrderApproved: false,
        newOrderCreated: false,
      },
    };
  }

  componentDidMount() {
    const commomParams = {
      skip: 0,
      take: 7,
    };

    this._requestMaterialList(commomParams);
    this._requestProductList(commomParams);
  }

  _requestMaterialList = async (data, increase) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ materialList }) => ({
        materialList: {
          ...materialList,
          loading: true,
        },
      }));

      const { data: { list, total } } = await MaterialService.fetchMaterialList({
        ...data,
        noImages: true,
      });

      this.setState(({ materialList }) => ({
        materialList: {
          ...materialList,
          loading: false,
          list: increase ? [
            ...materialList.list,
            ...list,
          ] : list,
          total,
        },
      }));
    } catch (e) {
      this.setState(({ materialList }) => ({
        materialList: {
          ...materialList,
          loading: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  };

  _requestProductList = async (data) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ productList }) => ({
        productList: {
          ...productList,
          loading: true,
        },
      }));

      const { data: { list, total } } = await ProductService.fetchProductList(data);

      this.setState(({ productList }) => ({
        productList: {
          ...productList,
          loading: false,
          list,
          total,
        },
      }));
    } catch (e) {
      this.setState(({ productList }) => ({
        productList: {
          ...productList,
          loading: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  };

  _handleNewOrderSubmit = async (values) => {
    this.setState(({ newOrder }) => ({
      newOrder: {
        ...newOrder,
        data: values,
        newOrderApproved: false,
        newOrderCreated: false,
      },
    }));
  };

  _requestNewAndApproveOrder = async (approved) => {
    const { dispatch } = this.props;
    const { data } = this.state.newOrder;
    try {
      this.setState(({ newOrder }) => ({
        newOrder: {
          ...newOrder,
          loading: true,
          newOrderApproved: false,
          newOrderCreated: false,
        },
      }));
      Object.assign(data, { approveOrder: approved });
      await OrderService.createOrder(data);
      this.setState(({ newOrder }) => ({
        newOrder: {
          ...newOrder,
          loading: false,
          newOrderApproved: approved,
          newOrderCreated: true,
        },
      }));
    } catch (e) {
      this.setState(({ newOrder }) => ({
        newOrder: {
          ...newOrder,
          loading: false,
          newOrderApproved: false,
          newOrderCreated: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  _getValidations = () => Yup.object().shape({
    promotionalCicleName: Yup.string()
      .matches(
        /^[a-zA-Z0-9_]*$/i,
        'Não utilize símbolos ou espaços. Ex.: ABC123',
        { excludeEmptyString: true },
      ),
  });

  render() {
    const {
      userData,
    } = this.props;

    const {
      loading,
      agentList,
      materialList,
      productList,
      newOrder,
    } = this.state;

    return (
      <NewOrderLayout
        title="Criar novo pedido"
        userData={userData}
        loading={loading}
        agentList={agentList}
        materialList={materialList}
        productList={productList}
        onRequestMaterialList={this._requestMaterialList}
        onRequestProductList={this._requestProductList}
        handleValidations={this._getValidations}
        handleNewOrderSubmit={this._handleNewOrderSubmit}
        handleConfirmDialog={this._requestNewAndApproveOrder}
        loadingDialog={newOrder.loading}
        newOrderApproved={newOrder.newOrderApproved}
        newOrderCreated={newOrder.newOrderCreated}
        commomParams={{
          skip: 0,
          take: 7,
        }}
      />
    );
  }
}

const mapStateToProps = (data) => data;

export default connect(mapStateToProps)(AgentNewOrderPage);
