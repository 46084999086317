import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  CheckBox,
  CheckBoxOutlineBlank,
  IndeterminateCheckBox,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from '@material-ui/icons';

import Icon from './Icon';
import { Text, FontWeight } from './Text';
import { Colors } from '../constants';
import { mediaHelper } from '../utils';

const propTypes = {
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  radiobutton: PropTypes.bool,
};

const defaultProps = {
  checked: false,
  indeterminate: false,
  radiobutton: false,
};

const TAB_INDEX_DEFAULT = '-1';

const IconButton = styled.button`
  display: flex;
  height: 56px;
  padding: 0 24px 0 0;
  align-items: center;
  color: ${Colors.PRIMARY_BLUE};
  border: 0;
  background-color: transparent;
  cursor: pointer;

  ${({ width }) => width && `
    width: ${width}px;
  `}

  & ${Icon} {
    font-size: 14px;
    color: ${Colors.PRIMARY_BLUE};
  }

  ${mediaHelper.lg`
    padding: 0 8px 0 0;
  `}
`;

const StyledCheckbox = styled(CheckBoxOutlineBlank)`
  color: ${Colors.GRAY_LIGHT};
`;

const StyledRadioButton = styled(RadioButtonUnchecked)`
  color: ${Colors.GRAY_LIGHT};
`;

const HeaderCheckbox = ({
  label,
  checked,
  disabled,
  indeterminate,
  onChange,
  radiobutton,
  ...props
}) => {
  const _renderIcon = () => {
    if (indeterminate) {
      return <IndeterminateCheckBox />;
    }

    if (disabled) {
      return <StyledCheckbox />;
    }

    if (checked) {
      return <CheckBox />;
    }

    return <CheckBoxOutlineBlank />;
  };

  const _renderRadioIcon = () => {
    if (disabled) {
      return <StyledRadioButton />;
    }

    if (checked) {
      return <RadioButtonChecked />;
    }

    return <RadioButtonUnchecked />;
  };

  return (
    <IconButton
      onClick={(e) => {
        if (!disabled) {
          onChange(!checked);
        }

        e.preventDefault();
        e.stopPropagation();
      }}
      checked={checked}
      tabIndex={TAB_INDEX_DEFAULT}
      {...props}
    >
      {radiobutton ? _renderRadioIcon() : _renderIcon()}

      {label && (
        <Text weight={FontWeight.Bold}>{label}</Text>
      )}
    </IconButton>
  );
};

HeaderCheckbox.propTypes = propTypes;
HeaderCheckbox.defaultProps = defaultProps;

export default styled(HeaderCheckbox)``;
