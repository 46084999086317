import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ResizeObserver from 'resize-observer-polyfill';

const ANIMATION_TIME = 700;
const WRAPPER_ID = 'toggle-container';
const COLSPAN_WIDTH = '100%';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.element,
};

const defaultProps = {
  children: (<></>),
};

const Tr = styled.tr`
  border-bottom: none;
`;

const Td = styled.td`
  margin: 0;
  padding: 0;
  box-shadow: inset 0 20px 4px -20px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 20px 4px -20px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 20px 4px -20px rgba(0, 0, 0, 0.1);
`;

const Container = styled.div`
  position: relative;
  z-index: -1;
  height: 0px;
  transition: all .7s;  
  
  ${({ height }) => height && `height: ${height}px;`};
`;

const Wrapper = styled.div`
  overflow: hidden;
`;

const ToggleContent = ({
  isOpen,
  index,
  children,
  hideWrapper,
}) => {
  const elementId = `${WRAPPER_ID}-${index}`;
  const [height, setHeight] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const resizeRef = useRef(new ResizeObserver((entries) => {
    if (entries && entries.length) {
      setHeight(entries[0].contentRect.height);
    }
  }));
  const toggleContentRef = useRef(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isOpen) {
      const element = document.querySelector(`#${elementId}`);
      resizeRef.current.observe(element);

      setIsActive(true);
    } else {
      resizeRef.current.disconnect();
      setHeight(0);

      requestAnimationFrame(() => {
        setTimeout(() => {
          setIsActive(false);
        }, ANIMATION_TIME);
      });
    }
  }, [isOpen]);

  return (
    hideWrapper ? (
      <div id={elementId} ref={toggleContentRef}>
        {children}
      </div>
    ) : (
      <Tr>
        <Td colSpan={COLSPAN_WIDTH}>
          <Container height={height}>
            <Wrapper id={elementId} ref={toggleContentRef}>
              {isActive && children}
            </Wrapper>
          </Container>
        </Td>
      </Tr>
    )
  );
};

ToggleContent.propTypes = propTypes;
ToggleContent.defaultProps = defaultProps;

export default ToggleContent;
