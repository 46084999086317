import React, { useState, useRef } from 'react';
import { VariableSizeList as List } from 'react-window';
import memoize from 'memoize-one';

import { Td, Tr } from './Table';
import VirtualizedRow from './VirtualizedRow';

const LIST_CLASSNAME = 'List';
const LIST_HEIGHT = 1024;
const ROW_HEIGHT = 90;
const LOADER_HEIGHT = 85;
const SCROLL_BORDER_WIDTH = 2;
const PRE_LOAD_ITEMS = 5;
const OUTER_ELEMENT_TYPE = 'table';
const INNER_ELEMENT_TYPE = 'tbody';

const createItemData = memoize((
  values,
  name,
  rowWrapper,
  notGroupedHeaderCells,
  newColumnConfig,
  groupFirstColumns,
  multiselect,
  getCheckboxList,
  onRowClick,
  renderRowCellContent,
  showRightArrow,
  renderToggleContent,
  IconRowClick,
  updateRow,
  toggleConfig,
  toggleSize,
) => ({
  values,
  name,
  rowWrapper,
  notGroupedHeaderCells,
  newColumnConfig,
  groupFirstColumns,
  multiselect,
  getCheckboxList,
  onRowClick,
  renderRowCellContent,
  showRightArrow,
  renderToggleContent,
  IconRowClick,
  updateRow,
  toggleConfig,
  toggleSize,
}));

const VirtualizedTableRows = ({
  data,
  name,
  multiselect,
  getCheckboxList,
  toggleConfig,
  rowWrapper,
  notGroupedHeaderCells,
  newColumnConfig,
  groupFirstColumns,
  IconRowClick,
  showRightArrow,
  onRowClick,
  renderRowCellContent,
  renderToggleContent,
}) => {
  const initialValues = new Array(data.length).fill(ROW_HEIGHT);
  const [rowSize, setRowSize] = useState(initialValues);
  const listContainerRef = useRef(null);
  const listRef = useRef(null);
  const listStyle = { display: 'block', overflowX: 'hidden' };

  const toggleSize = (index) => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(index, false);
    }

    const row = Array.from(rowSize);
    row[index] = row[index] === ROW_HEIGHT
      ? ROW_HEIGHT + LOADER_HEIGHT
      : ROW_HEIGHT;

    setRowSize(row);
  };

  const getItemSize = (i) => {
    if (rowSize[i]) {
      return rowSize[i];
    }

    if (listRef.current) {
      listRef.current.resetAfterIndex(i, false);
    }

    const row = [...rowSize];
    row[i] = ROW_HEIGHT;

    setRowSize(row);

    return ROW_HEIGHT;
  };

  const getListWidth = () => {
    if (listContainerRef.current) {
      if (data.length === 0) {
        return 0;
      }

      return listContainerRef.current.offsetWidth - SCROLL_BORDER_WIDTH;
    }

    return 0;
  };

  const getListHeight = () => {
    if (data.length === 0) {
      return 0;
    }

    return LIST_HEIGHT;
  };

  const handleUpdateRow = (index, size) => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(index, false);
    }

    const row = [...rowSize];
    const openRowHeight = ROW_HEIGHT + size;

    if (openRowHeight !== row[index]) {
      row[index] = openRowHeight;

      setRowSize(row);
    }
  };

  const itemData = createItemData(
    data,
    name,
    rowWrapper,
    notGroupedHeaderCells,
    newColumnConfig,
    groupFirstColumns,
    multiselect,
    getCheckboxList,
    onRowClick,
    renderRowCellContent,
    showRightArrow,
    renderToggleContent,
    IconRowClick,
    handleUpdateRow,
    toggleConfig,
    toggleSize,
  );

  return (
    <Tr>
      <Td
        colSpan="100%"
        ref={listContainerRef}
      >
        <List
          ref={listRef}
          itemCount={data.length}
          className={LIST_CLASSNAME}
          itemSize={getItemSize}
          itemData={itemData}
          estimatedItemSize={ROW_HEIGHT}
          style={listStyle}
          width={getListWidth()}
          height={getListHeight()}
          overscanCount={PRE_LOAD_ITEMS}
          outerElementType={OUTER_ELEMENT_TYPE}
          innerElementType={INNER_ELEMENT_TYPE}
        >
          {VirtualizedRow}
        </List>
      </Td>
    </Tr>
  );
};

export default React.memo(VirtualizedTableRows);
