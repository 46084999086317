import { createMuiTheme } from '@material-ui/core/styles';
import { PaletteColors } from './PaletteColors';

export const ThemeButton = createMuiTheme(
  {
    palette: PaletteColors,
    shape: {
      borderRadius: 22,
    },
  },
);
