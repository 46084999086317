import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { areEqual } from 'react-window';

import RowWrapperComponent from './RowWrapperComponent';
import { Td, TdContainer } from './Table';

const REF_SIZE_DELAY = 200;

const ToggleContainer = styled.div`
  cursor: default;
`;

const VirtualizedRow = ({ data, index, style }) => {
  const {
    values,
    name,
    rowWrapper,
    notGroupedHeaderCells,
    newColumnConfig,
    groupFirstColumns,
    multiselect,
    getCheckboxList,
    onRowClick,
    renderRowCellContent,
    showRightArrow,
    renderToggleContent,
    IconRowClick,
    updateRow,
    toggleConfig,
    toggleSize,
  } = data;
  const rowIndex = index;
  const rowData = Object.values(values || {})[index];
  const toggleContentRef = useRef(null);

  useEffect(() => {
    if (toggleConfig[index] && toggleConfig[index].isOpen) {
      setTimeout(() => {
        if (toggleContentRef.current && toggleContentRef.current.offsetHeight) {
          updateRow(index, toggleContentRef.current.offsetHeight);
        }
      }, REF_SIZE_DELAY);
    }
  }, [values]);

  return (
    <RowWrapperComponent
      style={style}
      key={`table-tr-${name}-${rowIndex}`}
      checked={(getCheckboxList()[rowIndex] && getCheckboxList()[rowIndex].selected)}
      rowIndex={rowIndex}
      rowWrapper={rowWrapper}
      rowData={rowData}
      onRowClick={() => { }}
    >
      {notGroupedHeaderCells.map((key, colIndex) => (
        <Td
          key={`table-td-${name}-${key}-${colIndex}`}
          align={newColumnConfig && newColumnConfig[key] && newColumnConfig[key].align}
          noPadding={newColumnConfig && newColumnConfig[key] && newColumnConfig[key].noPadding}
          groupFirstColumns={groupFirstColumns}
          onClick={() => {
            onRowClick(rowData, rowIndex);
            toggleSize(rowIndex);
          }}
        >
          <TdContainer
            width={newColumnConfig && newColumnConfig[key] && newColumnConfig[key].width}
            align={newColumnConfig && newColumnConfig[key] && newColumnConfig[key].align}
            noPadding={newColumnConfig && newColumnConfig[key] && newColumnConfig[key].noPadding}
            multiselect={multiselect}
          >
            {renderRowCellContent(rowData, key, rowIndex, groupFirstColumns
              ? colIndex + groupFirstColumns : colIndex)}
          </TdContainer>
        </Td>
      ))}

      {showRightArrow
        && (
          <Td
            checked={getCheckboxList()[rowIndex]}
            onRowClick={() => {
              onRowClick(rowData, rowIndex);
              toggleSize(rowIndex);
            }}
          >
            <IconRowClick name="chevron-right" />
          </Td>
        )}

      <ToggleContainer
        ref={toggleContentRef}
      >
        {renderToggleContent(rowIndex, rowData, true)}
      </ToggleContainer>
    </RowWrapperComponent>
  );
};

export default React.memo(VirtualizedRow, areEqual);
