import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import {
  showErrorToast,
  showSuccessToast,
} from '../../../../components';
import MaterialDetailLayout from './detail-layout';
import { MaterialService } from '../../../../services';

class MaterialDetailPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      requestPageConfig: {
        loading: false,
        data: {},
      },
      materialEditData: {},
      requestDialog: {
        isOpenDialog: false,
        loading: false,
      },
      newMaterialImages: {
        loading: false,
        data: [],
      },
    };
    this._originStockSearch = (this.props.location.state || {}).originStockSearch;
  }

  componentDidMount() {
    this._fetchMaterialDetail();
  }

  _fetchMaterialDetail = async () => {
    const { match, dispatch } = this.props;
    const materialId = match.params.id;

    try {
      this.setState((prevState) => ({
        requestPageConfig: {
          ...prevState.requestPageConfig,
          loading: true,
        },
      }));

      const response = await MaterialService.fetchMaterialDetail(materialId);

      this.setState((prevState) => ({
        requestPageConfig: {
          ...prevState.requestPageConfig,
          loading: false,
          data: response.data,
        },
      }));
    } catch (error) {
      this.setState((prevState) => ({
        requestPageConfig: {
          ...prevState.requestPageConfig,
          loading: false,
        },
      }), () => {
        console.error(error.message);
        dispatch(showErrorToast(error.message));
      });
    }
  }

  _getValidations = () => Yup.object().shape({
    // alias: Yup.string().required('Digite o Nome do material'),
    description: Yup.string().required('Digite a Descrição'),
  });

  _handleOpenDialog = (values, materialId, files) => {
    this.setState((prevState) => ({
      requestDialog: {
        ...prevState.requestDialog,
        isOpenDialog: true,
      },
      materialEditData: {
        id: materialId,
        formData: values,
        files,
      },
    }));
  }

  _handleConfirmDialog = async () => {
    const { materialEditData } = this.state;
    const requestUpload = {
      files: materialEditData.files,
      materialId: materialEditData.id,
    };

    this.setState((prevState) => ({
      requestDialog: {
        ...prevState.requestDialog,
        loading: true,
      },
    }));

    await MaterialService.requestMaterialChangeData(
      {
        id: materialEditData.id,
        description: materialEditData.formData.description,
      },
    );

    await MaterialService.requestMaterialUpload(requestUpload);

    window.location.reload();
  }

  _handleCloseDialog = () => {
    this.setState((prevState) => ({
      requestDialog: {
        ...prevState.requestDialog,
        isOpenDialog: false,
      },
    }));
  }

  _handleRemoveImage = async (imageId) => {
    const { dispatch } = this.props;
    try {
      this.setState((prevState) => ({
        newMaterialImages: {
          ...prevState.newMaterialImages,
          loading: true,
          data: [],
        },
      }));

      await MaterialService.requestDeteleImageMaterial(imageId);

      this.setState((prevState) => ({
        newMaterialImages: {
          ...prevState.newMaterialImages,
          loading: false,
        },
      }));
      dispatch(showSuccessToast('Foto excluída com sucesso!'));
      window.location.reload();
    } catch (error) {
      this.setState((prevState) => ({
        newMaterialImages: {
          ...prevState.newMaterialImages,
          loading: false,
        },
      }), () => {
        console.error(error.message);
        dispatch(showErrorToast(error.message));
      });
    }
  };

  render() {
    const {
      userData,
    } = this.props;
    const {
      requestPageConfig,
      requestDialog,
      newMaterialImages,
    } = this.state;

    const {
      material,
    } = requestPageConfig.data;

    return (
      <MaterialDetailLayout
        userData={userData}
        requestPageConfig={requestPageConfig}
        materialData={material}
        newMaterialImages={newMaterialImages}
        stockData={requestPageConfig.data}
        originStockSearch={this._originStockSearch}
        handleValidations={this._getValidations}
        handleOpenDialog={this._handleOpenDialog}
        handleCloseDialog={this._handleCloseDialog}
        handleConfirmDialog={this._handleConfirmDialog}
        isOpenDialog={requestDialog.isOpenDialog}
        loadingDialog={requestDialog.loading}
        handleDeleteImage={this._handleRemoveImage}
      />
    );
  }
}

const mapStateToProps = (data) => data;

export default connect(mapStateToProps)(MaterialDetailPage);
