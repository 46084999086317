import { HttpService } from './http';

const Endpoint = {
  viaCEP: 'https://viacep.com.br/ws',
};

export class ThirdPartService {
  static async requestZipCodeInfo(request) {
    const response = await HttpService.getExternal({
      endpoint: `${Endpoint.viaCEP}/${request}/json`,
    });
    return response;
  }
}

export default ThirdPartService;
