const SAVE_SESSION = 'luft-promo/auth/SAVE_SESSION';
const SET_USER_DATA = 'luft-promo/auth/SET_USER_DATA';
const LOGOUT = 'luft-promo/auth/LOGOUT';
const SET_TOKEN = 'luft-promo/auth/SET_TOKEN';
const IS_AUTHORIZED = 'luft-promo/auth/IS_AUTHORIZED';

export const AuthTypes = {
  IS_AUTHORIZED,
  SAVE_SESSION,
  SET_USER_DATA,
  SET_TOKEN,
  LOGOUT,
};
