import { HttpService } from './http';
import { API_BASE_URL } from '../constants/API';

import { fieldNames, luftFieldNames, agentType } from '../constants/order';
import { OrderTypes } from '../constants';
import { dateObjToService } from '../components/Filter/date';
import MaterialService from './MaterialService';
import AgentService from './AgentService';
import {
  MAX_SLIDES_NUMBER,
  COMPANY_ID_ACTIVE,
} from '../constants/PairingInitialValues';

const Endpoint = {
  fetchOrderList: `${API_BASE_URL}/orders/WMSlist`,
  fetchOrderDetail: `${API_BASE_URL}/orders/detailWMS`,
  createOrder: `${API_BASE_URL}/orders`,
  multipleApproveOrder: `${API_BASE_URL}/orders/approve`,
  downloadSelectedData: `${API_BASE_URL}/orders/export`,
  downloadFilteredData: `${API_BASE_URL}/orders/filter-export`,
  fetchWmsOrder: `${API_BASE_URL}/pairing/wmsorders`,
  fetchOrderToPairList: `${API_BASE_URL}/pairing/orders`,
};

export class OrderService {
  static getDefaultRequest = () => ({
    skip: 0,
    take: 100,
    filters: {},
  });

  static getDefaultOrderToPairRequest = () => ({
    skip: 0,
    take: 100,
    filters: {},
    companyId: COMPANY_ID_ACTIVE,
  });

  static transformCheckFilter = (checkFilter, parseFunc) => Object.entries(checkFilter || {}).reduce((final, [key, value]) => {
    if (value) {
      return [
        ...final,
        parseFunc ? parseFunc(key) : key,
      ];
    }

    return final;
  }, []);

  static transformFiltersToRequest = (filters, search) => {
    const filtersRequest = {};
    filtersRequest[fieldNames.MATERIAL] = OrderService.transformCheckFilter(filters[fieldNames.MATERIAL]);
    filtersRequest[fieldNames.PROMOTIONAL_CICLE] = OrderService.transformCheckFilter(filters[fieldNames.PROMOTIONAL_CICLE]);
    filtersRequest[fieldNames.PRODUCT] = OrderService.transformCheckFilter(filters[fieldNames.PRODUCT]);
    filtersRequest[fieldNames.STATUS] = OrderService.transformCheckFilter(filters[fieldNames.STATUS]);
    const agentList = OrderService.transformCheckFilter(filters[fieldNames.AGENT]);

    filtersRequest.standard = [];

    if (filters[fieldNames.CREATE_DATE].from) {
      filtersRequest.standard.push({
        attribute: fieldNames.CREATE_DATE,
        value: dateObjToService(filters[fieldNames.CREATE_DATE]),
      });
    }

    if (filters[fieldNames.DELIVERY_DATE].from) {
      filtersRequest.standard.push({
        attribute: fieldNames.DELIVERY_DATE,
        value: dateObjToService(filters[fieldNames.DELIVERY_DATE]),
      });
    }

    if (search && search.term && search.term.length) {
      filtersRequest.standard.push({
        attribute: fieldNames.SEARCH,
        value: search.term,
      });
    }
    if (agentList.length) {
      filtersRequest.standard.push({
        attribute: fieldNames.AGENT,
        value: agentList,
      });
    }

    const companyList = Object.keys(filters[luftFieldNames.COMPANY] || {});
    if (companyList.length) {
      filtersRequest.standard.push({
        attribute: luftFieldNames.COMPANY,
        value: companyList,
      });
    }

    return filtersRequest;
  }

  static async fetchOrderList(data) {
    const request = OrderService.getDefaultRequest();

    request.skip = data.skip;
    request.take = data.take;
    request.filters = OrderService.transformFiltersToRequest(data.filters, data.search);

    if (data.orderBy && data.orderBy.orderType) {
      request.orderBy = {
        attribute: data.orderBy.field,
        value: data.orderBy.orderType === OrderTypes.ASC ? 'ASC' : 'DESC',
      };
    }

    if (!data.orderBy.orderType) {
      request.orderBy = {
        attribute: 'id',
        value: 'DESC',
      };
    }
    const response = await HttpService.post(Endpoint.fetchOrderList, request);

    return response;
  }

  static async createOrder(data) {
    const request = {
      productList: [],
      materialList: [],
      promotionalCicleName: '',
      approveOrder: false,
    };

    const newProductList = data.productList.map((obj) => obj.id);
    const newMaterialList = data.materialByAgentList.map((obj) => (
      {
        id: obj.id,
        agentList: obj.agentList.map((item) => (
          {
            id: item.id,
            qty: item.qty,
          }),
        ),
      }
    ));

    request.productList.push(...newProductList);
    request.materialList.push(...newMaterialList);
    request.promotionalCicleName = data.promotionalCicleName;
    request.approveOrder = data.approveOrder;

    const response = await HttpService.post(Endpoint.createOrder, request);

    return response;
  }

  static async fetchMaterialList(data) {
    const request = OrderService.getDefaultRequest();

    if (data.term && data.term.length) {
      request.filters = {
        standard: [
          {
            attribute: data.type,
            value: data.term,
          },
        ],
      };
    }

    request.noImages = true;

    return MaterialService.fetchMaterialList(request);
  }

  static async fetchAgentList(data) {
    const request = OrderService.getDefaultRequest();

    if (data.type === agentType.TAG && data.term && data.term.length) {
      request.filters.tagName = data.term;
    } else if (data.type === agentType.NAME && data.term && data.term.length) {
      request.filters.standard = [
        {
          attribute: data.type,
          value: data.term,
        },
      ];
    }

    return AgentService.fetchAgentList(request);
  }

  static async downloadFile({
    endpoint,
    data,
    fileName,
    autoDownload = true,
  }) {
    const blob = await HttpService.blob({
      endpoint,
      data,
    });

    const promise = new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(blob);

      reader.onerror = (e) => {
        reject(e);
      };

      reader.onloadend = () => {
        const base64 = reader.result;

        if (autoDownload && fileName) {
          const link = document.createElement('a');

          link.href = base64;
          link.download = fileName;
          link.click();
          link.remove();
        }

        resolve(base64);
      };
    });

    return promise;
  }

  static async downloadSelectedData(orderList, fileName) {
    const response = await OrderService.downloadFile({
      endpoint: Endpoint.downloadSelectedData,
      data: { orderList },
      fileName,
    });

    return response;
  }

  static async downloadFilteredData(filters, fileName, search) {
    const request = OrderService.transformFiltersToRequest(filters, search);
    const response = await OrderService.downloadFile({
      endpoint: Endpoint.downloadFilteredData,
      data: { filters: request },
      fileName,
    });

    return response;
  }

  static async fetchOrderDetail(orderId) {
    const response = await HttpService.get(`${Endpoint.fetchOrderDetail}/${orderId}`);
    return response;
  }

  static async approveOrder(orderId) {
    const response = await HttpService.patch(`${Endpoint.fetchOrderDetail}/${orderId}/approve`);
    return response;
  }

  static async multipleApproveOrder(ordersId) {
    const request = {
      ordersId: [],
    };

    request.ordersId = ordersId;

    const response = await HttpService.post(`${Endpoint.multipleApproveOrder}`, request);
    return response;
  }

  static async refuseOrder(orderId) {
    const response = await HttpService.delete(`${Endpoint.fetchOrderDetail}/${orderId}/refuse`);
    return response;
  }

  static async cancelOrder(orderId) {
    const request = { cancel: true };
    const response = await HttpService.patch(`${Endpoint.createOrder}/cancel/${orderId}`, request);
    return response;
  }

  static async fetchWmsOrderList(data) {
    const request = {
      skip: 0,
      take: MAX_SLIDES_NUMBER,
      companyId: COMPANY_ID_ACTIVE,
      filters: {
        standard: [],
      },
    };
    request.skip = data.skip;
    request.take = data.take;
    request.companyId = data.companyId;

    if (data.searchWms) {
      request.filters.standard.push({
        attribute: 'orderNumber',
        value: data.searchWms,
      });
    }
    const response = await HttpService.post(Endpoint.fetchWmsOrder, request);
    return response;
  }

  static async fetchOrderToPairList(data) {
    const request = OrderService.getDefaultOrderToPairRequest();

    request.skip = data.skip;
    request.take = data.take;
    request.filters = OrderService.transformFiltersToRequest(data.filters, data.search);
    request.companyId = data.companyId;

    if (data.orderBy && data.orderBy.orderType) {
      request.orderBy = {
        attribute: data.orderBy.field,
        value: data.orderBy.orderType === OrderTypes.ASC ? 'ASC' : 'DESC',
      };
    }

    if (data.zipCode) {
      request.cep = data.zipCode;
    }

    const response = await HttpService.post(Endpoint.fetchOrderToPairList, request);

    return response;
  }

  static async pairOrder(data) {
    const request = { orderNumber: data.orderNumber };
    const response = await HttpService.patch(`${Endpoint.fetchOrderToPairList}/${data.orderId}`, request);
    return response;
  }
}
