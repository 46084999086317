import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import { Tooltip } from '@material-ui/core';
import {
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import { Edit, Delete, Error } from '@material-ui/icons';
import { Table, ThContainer } from '../../../../../../components/Table/Table';
import { Button, Text, EmptyStateWithAction } from '../../../../../../components';
import { Colors } from '../../../../../../constants';
import { columnLabel, columnType, statusPermission } from '../../../../../../constants/stock';
import { StockPermissionRoutePaths } from '../../../../../../constants/RoutePaths';

const StyledEmptyStateWithAction = styled(EmptyStateWithAction)`
  padding: 40px 16px;
  border-bottom: 1px solid ${Colors.GRAY_LIGHTER};
  flex-direction: column;
`;

const StyledEmptyActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;

const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: ${Colors.PRIMARY_BLUE};
`;

const AlertIconWrapper = styled.div`
  color: ${Colors.ORANGE};
  text-indent: 24px;
`;

const StatusTagWrapper = styled.div`
  border: 1px solid ${Colors.DANGER};
  border-radius: 16px;
  padding: 4px 10px;
  color: ${Colors.DANGER};
`;

const TableStyled = styled(Table)`
  & ${ThContainer} {
    padding-left: 16px;
  }
`;

const PermissionsTable = ({
  userData,
  data,
  loading,
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
  onChangeItemsPerPage,
  onChangeDataOrder,
  dataOrder,
  pairingActive,
  multiselect,
  radiobutton,
  slideDataActive,
  handleRowCheck,
  checkOrderNumber,
  onPairOrder,
  onRemovePermission,
  ...props
}) => {
  const history = useHistory();
  const { search: locationSearch } = useLocation();

  const themeTooltip = createMuiTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: '12px',
        },
      },
    },
  });

  const _renderBasicTextCell = (value) => (
    <Text>
      {value || '--'}
    </Text>
  );

  const _renderBasicNumberCell = (value) => (
    <Text>
      <NumberFormat
        value={value}
        displayType="text"
        decimalSeparator=","
        thousandSeparator={true, '.'}
      />
    </Text>
  );

  const _renderAlertCell = (value) => (
    <AlertIconWrapper>
      {value && (
        <MuiThemeProvider
          theme={themeTooltip}
        >
          <Tooltip
            title="Quantidade autorizada incompatível com quantidade estocada."
            placement="left"
            arrow
          >
            <Error />
          </Tooltip>
        </MuiThemeProvider>
      )}
    </AlertIconWrapper>
  );

  const _renderStatusCell = (value) => (
    <>
      {value === statusPermission.INACTIVE && (
        <MuiThemeProvider
          theme={themeTooltip}
        >
          <Tooltip
            title="Permissão desativada."
            placement="left"
            arrow
          >
            <StatusTagWrapper>
              Desativada
            </StatusTagWrapper>
          </Tooltip>
        </MuiThemeProvider>
      )}
    </>
  );

  const columnConfig = {
    [columnType.MATERIAL_NAME]: {
      order: 1,
    },
    [columnType.AVAILABLE_QUANTITY]: {
      order: 2,
    },
    [columnType.AGENTS]: {
      order: 3,
    },
    [columnType.AUTHORIZED_QUANTITY]: {
      order: 4,
    },
    [columnType.ALERT_QUANTITY]: {
      order: 5,
    },
    [columnType.STATUS]: {
      order: 6,
    },
    [columnType.EDIT]: {
      order: 7,
      noHeader: true,
      noSorting: true,
    },
    [columnType.REMOVE]: {
      order: 8,
      noHeader: true,
      noSorting: true,
    },
  };

  const components = {
    [columnType.MATERIAL_NAME]: _renderBasicTextCell,
    [columnType.AVAILABLE_QUANTITY]: _renderBasicNumberCell,
    [columnType.AGENTS]: _renderBasicNumberCell,
    [columnType.AUTHORIZED_QUANTITY]: _renderBasicNumberCell,
    [columnType.ALERT_QUANTITY]: _renderAlertCell,
    [columnType.STATUS]: _renderStatusCell,
    [columnType.EDIT]: (fieldData, data, rowIndex) => {
      const rowData = data[rowIndex];
      return (
        <ActionsContainer>
          <Edit onClick={() => { history.push(`${StockPermissionRoutePaths.PERMISSIONS}/${rowData.id}`, { originStockSearch: locationSearch, material: { materialWmsId: rowData.materialWmsId } }); }} />
        </ActionsContainer>
      );
    },
    [columnType.REMOVE]: (fieldData, data, rowIndex) => {
      const rowData = data[rowIndex];
      return (
        <ActionsContainer>
          <Delete
            onClick={() => { onRemovePermission(rowData.id); }}
          />
        </ActionsContainer>
      );
    },
  };
  return (
    <>
      <TableStyled
        name="permissions-material-table"
        isLoading={loading}
        footerText="Itens por página: "
        multiselect={multiselect}
        radiobutton={radiobutton}
        data={data}
        handleHeaderTitle={(key) => columnLabel[key]}
        columnConfig={columnConfig}
        components={components}
        paginationProps={{
          currentPage,
          totalItems,
          itemsPerPage,
          onPageChange,
        }}
        onChangeItemsPerPage={onChangeItemsPerPage}
        dataOrder={dataOrder}
        onOrderColumn={onChangeDataOrder}
        renderEmptyState={() => (
          <StyledEmptyStateWithAction
            alignCenter
            emptyStateText="Nenhuma permissão de material pra exibir :("
            renderAction={() => (
              <StyledEmptyActionsContainer>
                <Button
                  variant="outlined"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Atualizar
                </Button>
              </StyledEmptyActionsContainer>
            )}
          />
        )}
      />
    </>
  );
};

PermissionsTable.propTypes = {
  currentPage: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  onChangeItemsPerPage: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onChangeDataOrder: PropTypes.func,
  pairingActive: PropTypes.bool,
  multiselect: PropTypes.bool,
  radiobutton: PropTypes.bool,
  slideDataActive: PropTypes.object,
  handleRowCheck: PropTypes.func,
  checkOrderNumber: PropTypes.number,
  onRemovePermission: PropTypes.func,
};

PermissionsTable.defaultProps = {
  loading: false,
  onChangeDataOrder: undefined,
  pairingActive: false,
  multiselect: false,
  radiobutton: false,
  slideDataActive: {},
  handleRowCheck: () => {},
  checkOrderNumber: null,
  onRemovePermission: () => {},
};

export default PermissionsTable;
