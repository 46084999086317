import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Pagination from '@material-ui/lab/Pagination';
import {
  TablePagination,
  Grid,
  makeStyles,
} from '@material-ui/core/';

import {
  DEFAULT_PAGE,
  ITEMS_PER_PAGE,
  OPTIONS_ITEMS_PER_PAGE,
} from '../constants/Paginate';

import { BreakpointValues } from '../constants';
import { mediaHelper, useWindowSize } from '../utils';

const propTypes = {
  currentPage: PropTypes.number,
  totalItems: PropTypes.number,
  itemsPerPage: PropTypes.number,
  onPageChange: PropTypes.func,
  labelItemsPerPage: PropTypes.string,
};

const defaultProps = {
  currentPage: DEFAULT_PAGE,
  totalItems: ITEMS_PER_PAGE,
  itemsPerPage: ITEMS_PER_PAGE,
  onPageChange: () => { },
  labelItemsPerPage: 'Itens por página:',
};

const PaginationLeft = styled.div`
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;

  & > .MuiToolbar-regular {
    padding-left: 0;
  }

  ${mediaHelper.lg`
    margin: 32px 0;
    justify-content: flex-start;
  `};
`;

const PaginationRight = styled.div`
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaHelper.lg`
    margin: 32px 0;
    justify-content: flex-end;
  `};
`;

const Paginate = ({
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
  onChangeItemsPerPage,
  labelItemsPerPage,
}) => {
  const currentWindowSize = useWindowSize();
  const useStyles = makeStyles({
    root: {
      '& .MuiTablePagination-actions': {
        display: 'none',
      },
      '& .MuiToolbar-gutters': {
        paddingLeft: 0,
      },
    },
  });

  const classes = useStyles();

  return totalItems > 0 ? (
    <>
      <Grid container spacing={3}>
        {totalItems > itemsPerPage ? (
          <>
            <Grid
              item
              lg={6}
              md={6}
              xs={12}
            >
              <PaginationLeft>
                <TablePagination
                  rowsPerPageOptions={OPTIONS_ITEMS_PER_PAGE}
                  component="div"
                  count={totalItems}
                  page={currentPage - 1}
                  onChangePage={onPageChange}
                  rowsPerPage={itemsPerPage}
                  onChangeRowsPerPage={(e) => {
                    onChangeItemsPerPage(e, e.target.value);
                  }}
                  labelRowsPerPage={labelItemsPerPage}
                  className={classes.root}
                />
              </PaginationLeft>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xs={12}
            >
              <PaginationRight>
                <Pagination
                  color="secondary"
                  count={Math.ceil(totalItems / itemsPerPage)}
                  page={currentPage}
                  onChange={onPageChange}
                  siblingCount={currentWindowSize.width >= BreakpointValues.md ? 2 : 0}
                />
              </PaginationRight>
            </Grid>
          </>
        ) : null}
      </Grid>
    </>
  ) : null;
};

Paginate.propTypes = propTypes;
Paginate.defaultProps = defaultProps;

export default Paginate;
