import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { format } from 'date-fns';
import { useHistory, useLocation } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { TextField } from 'formik-material-ui';

import {
  Container,
  Grid,
} from '@material-ui/core/';
import {
  PageDetail,
  LoadMask,
  Button,
  DialogModal,
  showErrorToast,
} from '../../../../../components';
import FastFieldWrapper from '../../../../../components/form/FastFieldWrapper';
import { StockPermissionRoutePaths, RoutePaths } from '../../../../../constants/RoutePaths';
import { ITEMS_PER_PAGE } from '../../../../../constants/Paginate';
import { Colors, OrderTypes } from '../../../../../constants';
import AgentTable from './agentTable';
import AgentsModalTable from './agentTable/AgentsModalTable';
import {
  typePermissionTerm,
  permissionRecurrence,
  typeQuantityDistribution,
  statusPermission,
  permissionRecurrenceInterval,
} from '../../../../../constants/stock/enums';
import { columnType } from '../../../../../constants/stock';
import FormValidationContext from '../../../../../contexts/stock/formValidationContext';

const TopContent = styled.div`
  padding-top: 32px;
`;

const TitleText = styled.div`
  font-size: 24px;
  color: ${Colors.SECONDARY_MEDIUM};
`;

const ButtonStyled = styled(Button)`
  float: right;
`;

const TextFieldStyled = styled(Field)`
  max-width: 160px;
  margin-right: 16px;
`;

const DiaglogWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;
`;

const QuantityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const QuantityTotalLabel = styled.span`
  font-size: 12px;
  font-weight: bold;
`;

const NumberFormatStyled = styled(NumberFormat)`
  color: ${Colors.GRAY_DARKER};
  font-size: 14px;
`;

const orderByInitialState = {
  field: undefined,
  orderType: undefined,
};

const BoldText = styled.span`
  font-weight: bold;
`;

const PermissionDetailLayout = ({ ...props }) => {
  const {
    userData,
    materialPermission,
    loading,
    onRequestAgentList,
    agentList,
    commomParams,
    handleValidations,
    handleNewStockPermission,
    handleEditStockPermission,
    requestDialog,
    onRequestRemovePermission,
    isStockPermission,
  } = props;
  const [showAgentsModal, setShowAgentsModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE);
  const [isAutomaticQuantity, setIsAutomaticQuantity] = useState(true);
  const [permissionActive, setPermissionActive] = useState(false);
  const [isOpenQuantityDialog, setIsOpenQuantityDialog] = useState(false);
  const [isOpenRemovePermissionDialog, setIsOpenRemovePermissionDialog] = useState(false);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [hasErrorField, setHasErrorField] = useState(true);
  const [dataAgentList, setDataAgentList] = useState([]);
  const [agentLoad, setAgentLoad] = useState(false);
  const [agentSelectedTotal, setAgentSelectedTotal] = useState(0);
  const [orderBy, setOrderBy] = useState(orderByInitialState);
  const [agentSelected, setAgentSelected] = useState({});
  const [agentsTotalAuthorized, setAgentsTotalAuthorized] = useState(0);
  const [agentsTotalBalance, setAgentsTotalBalance] = useState(0);
  const [totalAgents, setTotalAgents] = useState(0);
  const [automaticValue, setAutomaticValue] = useState(0);
  const [hasErrorQuantity, setHasErrorQuantity] = useState(false);
  const [agentSkip, setAgentSkip] = useState(24);
  const [agentsToRemove, setAgentsToRemove] = useState([]);
  const [isEditingStockPermission, setIsEditingStockPermission] = useState(false);
  const [isOpenCancelPermissionDialog, setIsOpenCancelPermissionDialog] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    setDataAgentList(materialPermission.agents ? materialPermission.agents : []);
    setAgentsTotalBalance(materialPermission.currentBalance ? materialPermission.currentBalance : 0);
    setAgentsTotalAuthorized(materialPermission.authorizedQuantity ? materialPermission.authorizedQuantity : 0);
    setTotalAgents(materialPermission.agents ? materialPermission.agents.length : 0);
    if (Object.keys(materialPermission).length) {
      setIsEditingStockPermission(true);
      setIsAutomaticQuantity(materialPermission.typeQuantityDistribution === typeQuantityDistribution.AUTOMATIC);
      setAutomaticValue(materialPermission.quantityDistribution ? materialPermission.quantityDistribution : 0);
    }
  }, [
    materialPermission,
  ]);

  useEffect(() => {
    setIsOpenConfirmDialog(requestDialog.isOpenDialog);
  }, [
    requestDialog,
  ]);

  const _onPageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const _onChangeItemsPerPage = (event, newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const _handleMaterialAutomatic = () => {
    setIsAutomaticQuantity(!isAutomaticQuantity);
  };

  const _requestSearchFilter = (data, requestFn, increase) => {
    requestFn({
      skip: data.skip || increase ? agentSkip : 0,
      take: data.take || 24,
      filters: {
        standard: data.term && data.type ? [
          {
            attribute: data.type,
            value: data.term,
          },
        ] : [],
      },
    }, increase);
  };

  const _handlePermissionActive = (active) => {
    setPermissionActive(active);
  };

  const _returnAgentList = (data) => {
    const result = data ? data.filter((item) => dataAgentList.every((obj) => obj.userId !== item.id)) : [];
    return result;
  };

  const _handleNewAgents = (selectedData, setFieldValue) => {
    const newData = [...dataAgentList];
    selectedData.forEach((item) => {
      newData.push({
        [columnType.USERID]: item.id,
        [columnType.AGENT]: item.name,
        [columnType.AUTHORIZED_QUANTITY]: automaticValue ? automaticValue : 0,
        [columnType.CURRENT_BALANCE]: automaticValue ? automaticValue : 0,
        [columnType.TAGS]: item.tags,
        [columnType.CITY]: item.city,
      });
    });
    if (_checkAvailableStockQuantity(newData, setFieldValue)) {
      setDataAgentList(newData);
      setFieldValue('agentList', newData);
    }
  };

  const _getMaterialAmountQty = (selectData) => {
    const newTotal = dataAgentList.reduce((sum, agent) => selectData.find((item) => item.data.id === agent.id) ? sum + parseInt(agent.authorizedQuantity, 10) : sum, 0);
    setAgentSelectedTotal(newTotal);
  };

  const _handleDialogQtyChange = (values, setFieldValue) => {
    const newData = dataAgentList.map((agent) => agentSelected.find((item) => item.data.id === agent.id) ? {
      ...agent,
      [columnType.CURRENT_BALANCE]: (parseInt(values.quantityMaterialDialog, 10) - parseInt(agent[columnType.AUTHORIZED_QUANTITY], 10)) + parseInt(agent[columnType.CURRENT_BALANCE], 10),
      [columnType.AUTHORIZED_QUANTITY]: parseInt(values.quantityMaterialDialog, 10),
    } : agent);
    if (_checkAvailableStockQuantity(newData, setFieldValue)) {
      setIsOpenQuantityDialog(false);
      if (agentSelected.length > 0) {
        setAgentLoad(true);
        setDataAgentList([]);
        setIsAutomaticQuantity(_checkQuantityAllAgents(newData, values.quantityMaterialDialog, setFieldValue));
        setHasErrorField(true);

        setTimeout(() => {
          setDataAgentList(newData);
          setFieldValue('agentList', newData);
          setAgentLoad(false);
        }, 500);
      }
    }
  };

  const _handleQuantityDistribution = (value, setFieldValue) => {
    if (_checkHasAgentList(dataAgentList)) {
      setTimeout(() => {
        const newData = value ? dataAgentList.map((agent) => ({
          ...agent,
          [columnType.CURRENT_BALANCE]: (parseInt(value, 10) - parseInt(agent[columnType.AUTHORIZED_QUANTITY], 10)) + parseInt(agent[columnType.CURRENT_BALANCE], 10),
          [columnType.AUTHORIZED_QUANTITY]: parseInt(value, 10),
        })) : dataAgentList;
        if (_checkAvailableStockQuantity(newData, setFieldValue)) {
          setAgentLoad(true);
          setDataAgentList([]);
          setTimeout(() => {
            setDataAgentList(newData);
            setFieldValue('agentList', newData);
            setFieldValue('quantityDistribution', parseInt(value, 10));
            setAgentLoad(false);
          }, 500);
        }
      }, 500);
    }
    setAutomaticValue(value);
  };

  const _handleManualQtyChange = (value, id, setFieldValue) => {
    const newData = dataAgentList.map((agent) => agent.userId === id ? {
      ...agent,
      [columnType.CURRENT_BALANCE]: (parseInt(value, 10) - parseInt(agent[columnType.AUTHORIZED_QUANTITY], 10)) + parseInt(agent[columnType.CURRENT_BALANCE], 10),
      [columnType.AUTHORIZED_QUANTITY]: parseInt(value, 10),
    } : agent);
    if (_checkAvailableStockQuantity(newData, setFieldValue)) {
      setAgentLoad(true);
      setDataAgentList([]);

      setIsAutomaticQuantity(_checkQuantityAllAgents(newData, value, setFieldValue));

      setTimeout(() => {
        setDataAgentList(newData);
        setFieldValue('agentList', newData);
        setAgentLoad(false);
      }, 500);
    }
  };

  const _handleRemoveAgents = (selectedData, setFieldValue) => {
    const allEqual = (arr) => arr.every((val) => val.authorizedQuantity === arr[0].authorizedQuantity);
    const newData = dataAgentList.filter((agent) => !selectedData.some((item) => item.data.userId === agent.userId));
    setDataAgentList([]);
    setDataAgentList(newData);
    setFieldValue('agentList', newData);
    _checkAvailableStockQuantity(newData, setFieldValue);
    const newDataDelete = [...agentsToRemove];
    selectedData.forEach((item) => {
      newDataDelete.push(item.data.userId);
    });
    setAgentsToRemove(newDataDelete);
    setIsAutomaticQuantity(allEqual(newData));
  };

  const _checkAvailableStockQuantity = (data, setFieldValue) => {
    const totalStock = materialPermission.totalStock || ((location.state && location.state.material) && location.state.material.availableQuantity);
    const agentsAuthorizedTotal = data.reduce((sum, agent) => sum + parseInt(agent[columnType.AUTHORIZED_QUANTITY], 10), 0);
    const agentsCurrentBalanceTotal = data.reduce((sum, agent) => sum + (parseInt(agent[columnType.CURRENT_BALANCE], 10) > 0 ? parseInt(agent[columnType.CURRENT_BALANCE], 10) : 0), 0);
    if (agentsCurrentBalanceTotal > totalStock) {
      setHasErrorQuantity(true);
      dispatch(showErrorToast('Quantidade autorizada incompatível com quantidade estocada.'));
      setTimeout(() => {
        setHasErrorQuantity(false);
      }, 6000);
      return false;
    }
    setAgentsTotalAuthorized(agentsAuthorizedTotal);
    setAgentsTotalBalance(agentsCurrentBalanceTotal < 0 ? 0 : agentsCurrentBalanceTotal);
    setTotalAgents(data.length);
    setHasErrorQuantity(false);
    setFieldValue('currentBalance', agentsCurrentBalanceTotal);
    setFieldValue('authorizedQuantity', agentsAuthorizedTotal);
    return true;
  };

  const _checkQuantityAllAgents = (data, value, setFieldValue) => {
    let isSameQuantity = true;

    if (data.length === 1) {
      isSameQuantity = false;
    } else {
      data.map((item) => {
        if (item[columnType.AUTHORIZED_QUANTITY] !== parseInt(value, 10)) {
          isSameQuantity = false;
          return false;
        }
        return true;
      });
    }

    if (isSameQuantity && automaticValue !== parseInt(value, 10)) {
      setAutomaticValue(parseInt(value, 10));
      setFieldValue('quantityDistribution', parseInt(value, 10));
    }

    return isSameQuantity;
  };

  const _checkHasAgentList = (data) => {
    if (data.length <= 0) {
      dispatch(showErrorToast('Nenhum representante foi adicionado.'));
      return false;
    }
    return true;
  };

  const _onConfirmCancelDialog = () => {
    setIsOpenCancelPermissionDialog(false);
    history.push(StockPermissionRoutePaths.PERMISSIONS);
  };

  const _onChangeDataOrder = (field, orderType) => {
    setOrderBy({
      field,
      orderType,
    });
    setAgentLoad(true);
    setDataAgentList([]);
    setTimeout(() => {
      setDataAgentList(dataAgentList.sort((a, b) => (orderType === OrderTypes.ASC ? a.currentBalance - b.currentBalance : orderType === OrderTypes.DESC ? b.currentBalance - a.currentBalance : a.id - b.id)));
      setAgentLoad(false);
    }, 500);
  };

  const _onRemovePermissionDialog = () => {
    onRequestRemovePermission(materialPermission.id);
    setIsOpenRemovePermissionDialog(false);
  };

  const _handleSubmit = (values) => {
    const newStockPermissionData = {
      materialId: (location.state && location.state.material) ? location.state.material.materialId : null,
      status: permissionActive ? statusPermission.ACTIVE : statusPermission.INACTIVE,
      typePermissionTerm: values.typePermissionTerm ? values.typePermissionTerm : null,
      authorizedQuantity: values.authorizedQuantity ? values.authorizedQuantity : materialPermission.authorizedQuantity,
      currentBalance: values.currentBalance ? values.currentBalance : materialPermission.currentBalance,
      typeQuantityDistribution: isAutomaticQuantity ? typeQuantityDistribution.AUTOMATIC : typeQuantityDistribution.MANUAL,
      quantityDistribution: values.quantityDistribution ? values.quantityDistribution : null,
      permissionRecurrence: values.permissionRecurrence ? values.permissionRecurrence : null,
      permissionTermInitialDate: values.permissionTermInitialDate ? values.permissionTermInitialDate : null,
      permissionTermEndDate: values.permissionTermEndDate ? values.permissionTermEndDate : null,
      permissionRecurrenceQuantity: values.recurrenceQuantity ? values.recurrenceQuantity : 0,
      permissionRecurrenceInterval: values.recurrenceInterval ? values.recurrenceInterval : permissionRecurrenceInterval.DAYS,
      agents: values.agentList.map((item) => ({
        userId: item.userId,
        authorizedQuantity: item.authorizedQuantity,
        currentBalance: item.currentBalance,
      })),
    };
    const editStockPermissionData = {
      id: materialPermission.id ? materialPermission.id : null,
      materialId: (location.state && location.state.material) ? location.state.material.materialWmsId : materialPermission.materialId,
      status: permissionActive ? statusPermission.ACTIVE : statusPermission.INACTIVE,
      typePermissionTerm: values.typePermissionTerm ? values.typePermissionTerm : null,
      permissionRecurrence: values.permissionRecurrence ? values.permissionRecurrence : null,
      authorizedQuantity: values.authorizedQuantity ? values.authorizedQuantity : materialPermission.authorizedQuantity,
      currentBalance: values.currentBalance ? values.currentBalance : materialPermission.currentBalance,
      typeQuantityDistribution: isAutomaticQuantity ? typeQuantityDistribution.AUTOMATIC : typeQuantityDistribution.MANUAL,
      quantityDistribution: values.quantityDistribution ? values.quantityDistribution : 0,
      permissionTermInitialDate: values.permissionTermInitialDate ? values.permissionTermInitialDate : null,
      permissionTermEndDate: values.permissionTermEndDate ? values.permissionTermEndDate : null,
      permissionRecurrenceQuantity: values.recurrenceQuantity ? values.recurrenceQuantity : 0,
      permissionRecurrenceInterval: values.recurrenceInterval ? values.recurrenceInterval : 1,
    };
    if (values.agentList.length) {
      editStockPermissionData.agents = values.agentList.map((item) => ({
        id: item.id,
        userId: item.userId,
        authorizedQuantity: item.authorizedQuantity,
        currentBalance: item.currentBalance,
      }));
    }
    if (agentsToRemove.length) {
      editStockPermissionData.removeAgents = agentsToRemove;
    }
    if (isEditingStockPermission) {
      handleEditStockPermission(editStockPermissionData);
    } else {
      handleNewStockPermission(newStockPermissionData);
    }
  };

  const _handleCancel = (isNewPermission) => {
    if (isNewPermission) {
      setIsOpenCancelPermissionDialog(true);
    } else {
      setIsOpenRemovePermissionDialog(true);
    }
  };

  const initialValues = {
    typePermissionTerm: materialPermission.typePermissionTerm ? materialPermission.typePermissionTerm : typePermissionTerm.INDETERMINATE,
    validityPeriod: materialPermission.permissionTermInitialDate ? `${format(new Date(materialPermission.permissionTermInitialDate), 'dd/MM/yyyy')} a ${format(new Date(materialPermission.permissionTermEndDate), 'dd/MM/yyyy')}` : '',
    permissionTermInitialDate: materialPermission.permissionTermInitialDate ? materialPermission.permissionTermInitialDate : '',
    permissionTermEndDate: materialPermission.permissionTermEndDate ? materialPermission.permissionTermEndDate : '',
    permissionRecurrence: materialPermission.permissionRecurrence ? materialPermission.permissionRecurrence.toString() : permissionRecurrence.UNIQUE.toString(),
    recurrenceQuantity: materialPermission.permissionRecurrenceQuantity ? materialPermission.permissionRecurrenceQuantity : 0,
    recurrenceInterval: materialPermission.permissionRecurrenceInterval ? materialPermission.permissionRecurrenceInterval : permissionRecurrenceInterval.DAYS,
    authorizedQuantity: materialPermission.authorizedQuantity ? materialPermission.authorizedQuantity : 0,
    agentList: materialPermission && materialPermission.agents ? materialPermission.agents : [],
    quantityDistribution: materialPermission.quantityDistribution ? materialPermission.quantityDistribution : '',
    quantityMaterialDialog: 0,
  };

  return (
    <FormValidationContext.Provider value={[hasErrorField, setHasErrorField]}>
      <Formik
        initialValues={initialValues}
        validationSchema={handleValidations}
        validateOnBlur
        validateOnChange
        enableReinitialize
        onSubmit={() => { setIsOpenConfirmDialog(true); }}
      >
        {({
          handleSubmit,
          isSubmitting,
          setFieldValue,
          isValid,
          values,
          errors,
        }) => (
          <PageDetail
            title={materialPermission && materialPermission.name ? materialPermission.name : (location.state && location.state.material) ? location.state.material.name : 'Sem título'}
            userData={userData}
            materialPermission={materialPermission}
            originPath={StockPermissionRoutePaths.PERMISSIONS}
            originPathName="Permissões de estoque"
            isPermissions
            isNewPermission={location.pathname === StockPermissionRoutePaths.NEW ? true : false}
            handleSubmit={handleSubmit}
            handleCancel={_handleCancel}
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
            handleMaterialAutomatic={() => {
              _handleMaterialAutomatic();
              if (!isAutomaticQuantity) {
                _handleQuantityDistribution(values.quantityDistribution, setFieldValue);
              }
            }}
            isAutomaticQuantity={isAutomaticQuantity}
            handlePermissionActive={_handlePermissionActive}
            availableQuantity={(location.state && location.state.material) && location.state.material.availableQuantity}
            materialId={(location.state && location.state.material) && location.state.material.materialId}
            handleQuantityDistribution={(value) => { _handleQuantityDistribution(value, setFieldValue); }}
            values={values}
            errors={errors}
            agentsTotalAuthorized={agentsTotalAuthorized}
            agentsTotalBalance={agentsTotalBalance}
            totalAgents={totalAgents}
            isPermissionValid={isValid && hasErrorField}
            hasErrorQuantity={hasErrorQuantity}
            recurrenceIntervalValue={materialPermission.permissionRecurrenceInterval ? materialPermission.permissionRecurrenceInterval : permissionRecurrenceInterval.DAYS}
            isStockPermission={isStockPermission}
          >
            <Container maxWidth="lg">
              <TopContent>
                <Grid container spacing={3}>
                  <Grid
                    item
                    lg={8}
                    md={8}
                  >
                    <TitleText>
                      Representantes autorizados
                    </TitleText>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={4}
                  >
                    <ButtonStyled
                      size="large"
                      color="secondary"
                      variant="contained"
                      handleClick={() => { setShowAgentsModal(true); }}
                      disabled={!permissionActive}
                    >
                      Adicionar
                    </ButtonStyled>
                  </Grid>
                </Grid>
              </TopContent>
              <Grid container spacing={3}>
                <Grid
                  item
                  lg={12}
                  md={12}
                >
                  {materialPermission && (
                    <AgentTable
                      data={dataAgentList}
                      loading={loading || agentLoad}
                      itemsPerPage={itemsPerPage}
                      currentPage={currentPage}
                      totalItems={values.agentList ? values.agentList.length : 0}
                      onPageChange={_onPageChange}
                      onChangeItemsPerPage={_onChangeItemsPerPage}
                      handleQtdChange={(value, id) => { _handleManualQtyChange(value, id, setFieldValue); }}
                      isAutomaticQuantity={isAutomaticQuantity}
                      disabled={!permissionActive}
                      handleOpenQuantityDialog={(selectData) => {
                        setFieldValue('quantityMaterialDialog', 0);
                        setIsOpenQuantityDialog(true);
                        _getMaterialAmountQty(selectData);
                        setAgentSelected(selectData);
                      }}
                      onChangeDataOrder={_onChangeDataOrder}
                      dataOrder={orderBy}
                      hasErrorQuantity={hasErrorQuantity}
                      handleAgentRemove={(selectedData) => { _handleRemoveAgents(selectedData, setFieldValue); }}
                    />
                  )}
                </Grid>
              </Grid>
            </Container>
            <FastFieldWrapper
              name="agentList"
              component={AgentsModalTable}
              width={810}
              open={showAgentsModal}
              onDismiss={() => setShowAgentsModal(false)}
              onConfirm={(selectedAgentList) => {
                setShowAgentsModal(false);
                _handleNewAgents(selectedAgentList, setFieldValue, values);
              }}
              tableData={_returnAgentList(agentList.list)}
              loading={agentList.loading}
              onSearch={(data) => _requestSearchFilter(data, onRequestAgentList)}
              take={24}
              onLoad={(data) => {
                setAgentSkip(agentSkip + 24);
                _requestSearchFilter(data, onRequestAgentList, true);
              }}
              handleRefresh={() => {
                onRequestAgentList(commomParams);
              }}
            />
            <DialogModal
              title="Alterar quantidade de material autorizada"
              confirmButtonText="Confirmar"
              handleConfirmDialog={() => { _handleDialogQtyChange(values, setFieldValue); }}
              handleCloseDialog={() => { setIsOpenQuantityDialog(false); }}
              isOpenDialog={isOpenQuantityDialog}
              enableConfirm={!errors.quantityMaterialDialog}
              hasBorder
            >
              <DiaglogWrapper>
                <TextFieldStyled
                  key="quantityMaterialDialog"
                  name="quantityMaterialDialog"
                  label="Quantidade"
                  variant="outlined"
                  size="small"
                  component={TextField}
                  error={hasErrorQuantity}
                />
                <QuantityWrapper>
                  <QuantityTotalLabel>
                    Total
                  </QuantityTotalLabel>
                  <NumberFormatStyled
                    value={agentSelectedTotal}
                    displayType="text"
                    decimalSeparator=","
                    thousandSeparator={true, '.'}
                  />
                </QuantityWrapper>
              </DiaglogWrapper>
            </DialogModal>
            <DialogModal
              title="Salvar alterações"
              description="Tem certeza que deseja salvar as alterações?"
              confirmButtonText="Salvar"
              handleCloseDialog={() => { setIsOpenConfirmDialog(false); }}
              handleConfirmDialog={() => { _handleSubmit(values); }}
              isOpenDialog={isOpenConfirmDialog}
              loadingDialog={requestDialog.loading}
            />
            <DialogModal
              title="Excluir permissão"
              description={(
                <span>
                  Tem certeza que deseja excluir a permissão deste material?
                  <BoldText> (Essa ação não poderá ser desfeita)</BoldText>
                  .
                </span>
              )}
              confirmButtonText="Excluir permissão"
              cancelButtonText="Cancelar"
              handleConfirmDialog={() => { _onRemovePermissionDialog(); }}
              handleCloseDialog={() => { setIsOpenRemovePermissionDialog(false); }}
              isOpenDialog={isOpenRemovePermissionDialog}
            />
            <DialogModal
              title="Descartar permissão"
              description="Tem certeza que deseja sair sem salvar as alterações?"
              confirmButtonText="Descartar permissão"
              handleConfirmDialog={() => { _onConfirmCancelDialog(); }}
              handleCloseDialog={() => { setIsOpenCancelPermissionDialog(false); }}
              isOpenDialog={isOpenCancelPermissionDialog}
            />
          </PageDetail>
        )}
      </Formik>
      {loading ? <LoadMask /> : null}
    </FormValidationContext.Provider>
  );
};

PermissionDetailLayout.propTypes = {
  userData: PropTypes.object,
  materialPermission: PropTypes.object,
  loading: PropTypes.bool,
  onRequestAgentList: PropTypes.func,
  agentList: PropTypes.object,
  commomParams: PropTypes.object,
};

PermissionDetailLayout.defaultProps = {
  userData: {},
  materialPermission: {},
  loading: false,
  onRequestAgentList: () => { },
  agentList: {},
  commomParams: {},
};

export default PermissionDetailLayout;
