import { HttpService } from './http';
import { API_BASE_URL } from '../constants/API';

export class UserService {
  static async forgotPassword(data) {
    const response = await HttpService.post(`${API_BASE_URL}/users/forgot-password`,
      {
        email: data.email,
      },
    );
    return response;
  }

  static async resetPassword(data, token) {
    const response = await HttpService.post(`${API_BASE_URL}/users/reset-password`,
      {
        token,
        password: data.password,
        password_confirmation: data.passwordConfirmation,
      },
    );
    return response;
  }

  static async verifyTokenResetPassword(token) {
    const response = await HttpService.get(`${API_BASE_URL}/users/verify-token-reset-password/${token}`);
    return response;
  }
}
