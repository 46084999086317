import React, { Component } from 'react';
import { connect } from 'react-redux';

import AgentManagementLayout, { updateQueryStringInAgentList } from './agent-management-layout';
import {
  showErrorToast,
} from '../../../../components';
import {
  AgentService,
  TagService,
} from '../../../../services';

class AgentManagementPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      requestPageConfig: {
        loading: false,
      },
      agentList: {
        loading: false,
        list: [],
      },
      tagList: {
        loading: false,
        list: [],
      },
    };
  }

  _requestAgentList = async (data) => {
    const { dispatch, history } = this.props;

    try {
      this.setState(({ agentList }) => ({
        agentList: {
          ...agentList,
          loading: true,
        },
      }));

      updateQueryStringInAgentList(history, data);

      const { data: { list, total } } = await AgentService.fetchAgentList(data);

      this.setState(({ agentList }) => ({
        agentList: {
          ...agentList,
          loading: false,
          list,
          total,
        },
      }));
    } catch (e) {
      this.setState(({ agentList }) => ({
        agentList: {
          ...agentList,
          loading: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  _requestTagList = async (data) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ tagList }) => ({
        tagList: {
          ...tagList,
          loading: true,
        },
      }));

      const { data: { list, total } } = await TagService.fetchTagList(data);

      this.setState(({ tagList }) => ({
        tagList: {
          ...tagList,
          loading: false,
          list,
          total,
        },
      }));
    } catch (e) {
      this.setState(({ tagList }) => ({
        tagList: {
          ...tagList,
          loading: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  render() {
    const {
      requestPageConfig,
      agentList,
      tagList,
    } = this.state;
    const {
      userData,
    } = this.props;
    return (
      <AgentManagementLayout
        userData={userData}
        title="Representantes"
        loading={requestPageConfig.loading}
        agentList={agentList}
        onRequestAgentList={this._requestAgentList}
        onRequestTagList={this._requestTagList}
        tagList={tagList}
      />
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  userData: auth.userData,
  isLogged: auth.sessionToken ? true : false,
});

export default connect(mapStateToProps)(AgentManagementPage);
