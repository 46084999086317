import TagManager from 'react-gtm-module';
import { AuthTypes } from './types';
import {
  saveSessionToken,
  saveSessionUserData,
  clearSession,
  getSessionUserData,
  getSessionToken,
} from '../../utils';

const _setUserData = (userData) => ({
  type: AuthTypes.SET_USER_DATA,
  userData,
});

const _setToken = (token) => ({
  type: AuthTypes.SET_TOKEN,
  token,
});

const _isAuthorized = (authorized) => ({
  type: AuthTypes.IS_AUTHORIZED,
  authorized,
});

export class AuthActions {
  // hydrate store from localStorage
  static hydrateUserDataStorage = () => function (dispatch) {
    const userData = getSessionUserData();
    const token = getSessionToken();

    if (JSON.parse(userData) && token) {
      dispatch(_setUserData(JSON.parse(userData)));
      dispatch(_setToken(token));
    }
  };

  static setUserData = (userData) => {
    const data = {
      ...userData,
    };

    TagManager.dataLayer({ dataLayer: { userId: (userData || {}).id } });
    saveSessionUserData(data);
    return _setUserData(data);
  };

  static setToken = (token) => {
    saveSessionToken(token);
    return _setToken(token);
  };

  static isAuthorized = (authorized) => _isAuthorized(authorized);

  static logout = () => {
    clearSession();
    return { type: AuthTypes.LOGOUT };
  };

  static isAuthorized = (authorized) => _isAuthorized(authorized);

  static logout = () => {
    clearSession();

    return { type: AuthTypes.LOGOUT };
  };
}
