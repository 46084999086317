import actionTypes from './actions';

// actions


// reducer

const initialState = false;

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.HYDRATE:
      return true;

    // Default
    default:
      return state;
  }
}

export default reducer;
