import React from 'react';
import styled from 'styled-components';

import './font/style.css';

const Icon = ({ name, className, ...props }) => (
  <i className={`icon-${name} ${className || ''}`} {...props} />
);

export default styled(Icon)``;
