import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'react-slick';
import NumberFormat from 'react-number-format';
import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import 'slick-carousel/slick/slick.css';
import './ordersWmsSlider.css';

import { Grid } from '@material-ui/core';
import {
  PrevArrow,
  NextArrow,
} from './Arrows';
import { Colors } from '../../../../constants';
import {
  COMPANY_ID_ACTIVE,
} from '../../../../constants/PairingInitialValues';

const SliderWrapper = styled(Slider)`
  margin: ${({ showSearch }) => showSearch ? '24px 0 8px 0' : '0'};
  overflow: ${({ showSearch }) => showSearch ? 'visible' : 'hidden'};
  max-height: ${({ showSearch }) => showSearch ? 'initial' : '0'};
  min-height: ${({ showSearch }) => showSearch ? '140px' : '0'};
`;

const SlideItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 48px) !important;
  border-radius: 8px;
  background-color: white;
  padding: 24px 24px;
  -webkit-box-shadow: inset 0px 0px 0px 1px ${Colors.GRAY};
  -moz-box-shadow: inset 0px 0px 0px 1px ${Colors.GRAY};
  box-shadow: inset 0px 0px 0px 1px ${Colors.GRAY};
`;

const SliderCol = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
`;

const SliderColFirst = styled(Grid)`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 8px 0;
`;

const SliderLabel = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  color: ${Colors.PRIMARY_BLUE};
`;

const SliderValue = styled.div`
  margin-top: 8px;
  color: ${Colors.GRAY_DARKEST};
  min-height: 40px;
  max-height: 40px;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
`;

const MaterialValue = styled.span`
  display: inline-block;
`;

const StyledNumberFormat = styled.span`
  font-weight: bold;
`;

const SliderLabelBig = styled.div`
  font-size: 16px;
  color: ${Colors.SECONDARY_MEDIUM};
  text-transform: uppercase;
`;

const SliderValueBig = styled.div`
  font-size: 34px;
  color: ${Colors.PRIMARY_BLUE};
`;

const CurrentSlideWrapper = styled.span`
  position: absolute;
  width: 100px;
  font-size: 12px;
  top: 20px;
  left: 290px;
  color: ${Colors.GRAY_DARKEST};
`;

const SliderLeftContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledField = styled(Field)`
  width: 270px;
`;

const SliderContainer = styled.div`
  width: 100%;
  height: auto;
`;

const OrdersWmsSlider = ({ ...props }) => {
  const {
    sliderData,
    sliderDataSearch,
    totalItems,
    handleValidations,
    handleSearchWms,
    handleCurrentSlide,
    handleCurrentData,
    searchWms,
    companyState,
  } = props;

  const [nextArrowActive, setNextArrowActive] = useState(true);
  const [prevArrowActive, setPrevArrowActive] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(1);

  useEffect(() => {
    if (searchWms && sliderDataSearch.length > 0) {
      handleCurrentData({
        orderNumber: sliderDataSearch[0].orderNumber,
        cep: sliderDataSearch[0].cep,
      });
      return;
    }
    if (sliderData.length > 0) {
      handleCurrentData({
        orderNumber: sliderData[currentSlide - 1].orderNumber,
        cep: sliderData[currentSlide - 1].cep,
      });
    }
  }, [
    currentSlide,
    sliderData,
    sliderDataSearch,
    searchWms,
    companyState,
  ]);

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow nextArrowActive={nextArrowActive} />,
    prevArrow: <PrevArrow prevArrowActive={prevArrowActive} />,
    afterChange: (current) => {
      const activeSlide = current + 1;
      setCurrentSlide(activeSlide);
      handleCurrentSlide(activeSlide);
      if (current === 0) {
        setPrevArrowActive(false);
      } else {
        setPrevArrowActive(true);
      }
      if (current >= (sliderData.length - sliderSettings.slidesToShow)) {
        setNextArrowActive(false);
      } else {
        setNextArrowActive(true);
      }
    },
  };

  return (
    <div id="ordersWmsSlider">
      <SliderContainer>
        {sliderData && sliderData.length > 0 && (
          <SliderWrapper
            {...sliderSettings}
            showSearch={searchWms ? false : true}
          >
            {sliderData.map((item) => (
              <SlideItem
                key={`order-${item.orderNumber ? item.orderNumber : '0'}`}
              >
                <Grid container spacing={3}>
                  <SliderColFirst
                    lg={3}
                    md={4}
                    sm={4}
                    xs={4}
                    item
                  >
                    <SliderValueBig>
                      {item.orderNumber ? item.orderNumber : '--'}
                    </SliderValueBig>
                    <SliderLabelBig>
                      Pedido WMS
                    </SliderLabelBig>
                  </SliderColFirst>
                  <SliderCol
                    item
                    lg={4}
                    md={5}
                    sm={4}
                    xs={4}
                  >
                    <SliderLabel>
                      Endereço de entrega
                    </SliderLabel>
                    <SliderValue>
                      {item.address ? item.address : '--'}
                      {` - CEP ${item.cep ? item.cep : '--'}`}
                    </SliderValue>
                  </SliderCol>
                  <SliderCol
                    item
                    lg={5}
                    md={6}
                    sm={4}
                    xs={4}
                  >
                    <SliderLabel>
                      Materiais do pedido
                    </SliderLabel>
                    <SliderValue>
                      {item.materialList.length > 0 ? item.materialList.map((elm, index) => (
                        <MaterialValue key={`${elm.name}-${elm.quantity}`}>
                          {` ${elm.name ? elm.name : '--'} `}
                          <StyledNumberFormat>
                            {' ('}
                            <NumberFormat
                              value={elm.quantity ? elm.quantity : '--'}
                              displayType="text"
                              decimalSeparator=","
                              thousandSeparator={true, '.'}
                            />
                            )
                          </StyledNumberFormat>
                          {index === item.materialList.length - 1 ? '' : ', '}
                          &nbsp;
                        </MaterialValue>
                      )) : (
                        <>
                          Nenhum material
                        </>
                      )}
                    </SliderValue>
                  </SliderCol>
                </Grid>
              </SlideItem>
            ))}
          </SliderWrapper>
        )}
        {sliderDataSearch && sliderDataSearch.length > 0 && (
          <SliderWrapper
            {...sliderSettings}
            showSearch={searchWms ? true : false}
          >
            {sliderDataSearch.map((item) => (
              <SlideItem
                key={`order-${item.orderNumber ? item.orderNumber : '0'}`}
              >
                <Grid container spacing={3}>
                  <SliderColFirst
                    lg={3}
                    md={4}
                    sm={4}
                    xs={4}
                    item
                  >
                    <SliderValueBig>
                      {item.orderNumber ? item.orderNumber : '--'}
                    </SliderValueBig>
                    <SliderLabelBig>
                      Pedido WMS
                    </SliderLabelBig>
                  </SliderColFirst>
                  <SliderCol
                    item
                    lg={4}
                    md={5}
                    sm={4}
                    xs={4}
                  >
                    <SliderLabel>
                      Endereço de entrega
                    </SliderLabel>
                    <SliderValue>
                      {item.address ? item.address : '--'}
                      {` - CEP ${item.cep ? item.cep : '--'}`}
                    </SliderValue>
                  </SliderCol>
                  <SliderCol
                    item
                    lg={5}
                    md={6}
                    sm={4}
                    xs={4}
                  >
                    <SliderLabel>
                      Materiais do pedido
                    </SliderLabel>
                    <SliderValue>
                      {item.materialList.length > 0 ? item.materialList.map((elm, index) => (
                        <MaterialValue key={`${index}-${elm.name}`}>
                          {` ${elm.name ? elm.name : '--'} `}
                          <StyledNumberFormat>
                            {' ('}
                            <NumberFormat
                              value={elm.quantity ? elm.quantity : '--'}
                              displayType="text"
                              decimalSeparator=","
                              thousandSeparator={true, '.'}
                            />
                            )
                          </StyledNumberFormat>
                          {index === item.materialList.length - 1 ? '' : ', '}
                          &nbsp;
                        </MaterialValue>
                      )) : (
                        <>
                          Nenhum material
                        </>
                      )}
                    </SliderValue>
                  </SliderCol>
                </Grid>
              </SlideItem>
            ))}
          </SliderWrapper>
        )}
      </SliderContainer>
      {(!searchWms && sliderData.length > 0) || (searchWms && sliderDataSearch.length > 0) ? (
        <SliderLeftContent>
          <Formik
            initialValues={{
              search: searchWms,
            }}
            validationSchema={handleValidations}
            enableReinitialize
            onSubmit={(values) => handleSearchWms(values)}
          >
            {({ handleSubmit }) => (
              <>
                <StyledField
                  component={TextField}
                  name="search"
                  type="tel"
                  label="Buscar pedido"
                  variant="outlined"
                  disabled={searchWms ? true : false}
                  onBlur={handleSubmit}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit();
                    }
                  }}
                />
              </>
            )}
          </Formik>
          <CurrentSlideWrapper>
            {`${searchWms ? 1 : currentSlide} de ${totalItems}`}
          </CurrentSlideWrapper>
        </SliderLeftContent>
      ) : (
        null
      )}
    </div>
  );
};

OrdersWmsSlider.propTypes = {
  sliderData: PropTypes.array,
  totalItems: PropTypes.number,
  handleValidations: PropTypes.func,
  searchWms: PropTypes.string,
  companyState: PropTypes.any,
};

OrdersWmsSlider.defaultProps = {
  sliderData: [],
  totalItems: 0,
  handleValidations: () => { },
  searchWms: '',
  companyState: COMPANY_ID_ACTIVE,
};

export default OrdersWmsSlider;
