import { checkboxDefaultInitialState } from '../../components/Filter/checkbox';

export const fieldNames = {
  TYPE: 'materialType',
  AVAILABILITY: 'availability',
  DUE_DATE: 'dueDateRange',
  ORDER_BY: 'orderBy',
  SEARCH: 'search',
  ALERT: 'quantityAlert',
};

/**
 * MATERIAL TYPE ENUM
 */
export const materialType = {
  FREE_SAMPLE: 385,
  PAPERS: 1476,
  PROMOTIONAL_GIFT: 1475,
};

/**
 * SEARCH
 */
export const searchType = {
  NAME: 'name',
  CODE: 'code',
};

export const searchTypeLabel = {
  [searchType.NAME]: 'Nome',
  [searchType.CODE]: 'Código',
};

export const searchTypeList = [
  {

    value: searchType.NAME,
    label: searchTypeLabel[searchType.NAME],
  },
  {
    value: searchType.CODE,
    label: searchTypeLabel[searchType.CODE],
  },
];

/**
 * ORDER BY
 */
export const orderByType = {
  ALPHABETICAL: 'alphabeticalOrder',
  GREATER_QUANTITY: 'moreQuantityOrder',
  LESS_QUANTITY: 'lessQuantityOrder',
  VALIDITY: 'validityCloseOrder',
};

export const orderByTypeLabel = {
  [orderByType.ALPHABETICAL]: 'Ordem alfabética',
  [orderByType.GREATER_QUANTITY]: 'Maior quantidade',
  [orderByType.LESS_QUANTITY]: 'Menor quantidade',
  [orderByType.VALIDITY]: 'Validade próxima',
};

export const orderByOptionList = [
  {
    value: orderByType.ALPHABETICAL,
    label: orderByTypeLabel[orderByType.ALPHABETICAL],
  },
  {
    value: orderByType.GREATER_QUANTITY,
    label: orderByTypeLabel[orderByType.GREATER_QUANTITY],
  },
  {
    value: orderByType.LESS_QUANTITY,
    label: orderByTypeLabel[orderByType.LESS_QUANTITY],
  },
  {
    value: orderByType.VALIDITY,
    label: orderByTypeLabel[orderByType.VALIDITY],
  },
];

export const orderByAgentOptionList = [
  {
    value: orderByType.ALPHABETICAL,
    label: orderByTypeLabel[orderByType.ALPHABETICAL],
  },
  {
    value: orderByType.GREATER_QUANTITY,
    label: orderByTypeLabel[orderByType.GREATER_QUANTITY],
  },
  {
    value: orderByType.LESS_QUANTITY,
    label: orderByTypeLabel[orderByType.LESS_QUANTITY],
  },
];

/**
 * AVAILABILITY
 */
export const availabilityType = {
  HAS_ACTIVE_ORDER: 'reservedOrder',
  STOCK_FREE: 'freeStock',
};

export const availabilityTypeLabel = {
  [availabilityType.HAS_ACTIVE_ORDER]: 'Reservado em pedidos',
  [availabilityType.STOCK_FREE]: 'Estoque livre',
};

export const availabilityOptionList = [
  {
    value: availabilityType.HAS_ACTIVE_ORDER,
    label: availabilityTypeLabel[availabilityType.HAS_ACTIVE_ORDER],
  },
  {
    value: availabilityType.STOCK_FREE,
    label: availabilityTypeLabel[availabilityType.STOCK_FREE],
  },
];

/**
 * STOCK TYPE
 */
export const stockType = {
  FREE_SAMPLE: materialType.FREE_SAMPLE,
  PAPERS: materialType.PAPERS,
  PROMOTIONAL_GIFT: materialType.PROMOTIONAL_GIFT,
};

export const stockTypeLabel = {
  [stockType.FREE_SAMPLE]: 'Amostra grátis',
  [stockType.PAPERS]: 'Papelaria',
  [stockType.PROMOTIONAL_GIFT]: 'Brindes',
};

export const stockTypeOptionList = [
  {
    value: stockType.FREE_SAMPLE,
    label: stockTypeLabel[stockType.FREE_SAMPLE],
  },
  {
    value: stockType.PAPERS,
    label: stockTypeLabel[stockType.PAPERS],
  },
  {
    value: stockType.PROMOTIONAL_GIFT,
    label: stockTypeLabel[stockType.PROMOTIONAL_GIFT],
  },
];

export const filtersInitialState = {
  [fieldNames.TYPE]: checkboxDefaultInitialState(stockTypeOptionList),
  [fieldNames.AVAILABILITY]: null,
  [fieldNames.DUE_DATE]: {
    from: null,
    to: null,
  },
  [fieldNames.ORDER_BY]: orderByOptionList[0].value,
};

/**
 * STOCK PERMISSIONS
 */
export const fieldPermissionsNames = {
  MATERIAL_TYPE: 'materialType',
  AGENT: 'agentList',
  ALERT: 'quantityAlert',
  STATUS: 'status',
  ORDER_BY: 'orderBy',
  SEARCH: 'name',
};

export const filtersPermissionsInitialState = {
  [fieldPermissionsNames.MATERIAL_TYPE]: checkboxDefaultInitialState(stockTypeOptionList),
  [fieldPermissionsNames.AGENT]: {},
  [fieldPermissionsNames.ALERT]: {},
  [fieldPermissionsNames.STATUS]: {},
  [fieldPermissionsNames.SEARCH]: {},
  [fieldPermissionsNames.ORDER_BY]: {},
};

/**
 * REPRESENTATIVE TYPES
 */
export const agentType = {
  NAME: 'name',
  TAG: 'tagName',
};

export const agentTypeLabel = {
  [agentType.NAME]: 'Nome',
  [agentType.TAG]: 'Tag',
};

export const agentTypeOptionList = [
  {
    value: agentType.NAME,
    label: agentTypeLabel[agentType.NAME],
  },
  {
    value: agentType.TAG,
    label: agentTypeLabel[agentType.TAG],
  },
];

export const stockAlertType = {
  ALERT_ON: 'alertOn',
  ALERT_OFF: 'alertOff',
};

export const stockAlertLabel = {
  [stockAlertType.ALERT_ON]: 'Com alerta',
  [stockAlertType.ALERT_OFF]: 'Sem alerta',
};

export const stockAlertOptionList = [
  {
    value: stockAlertType.ALERT_ON,
    label: stockAlertLabel[stockAlertType.ALERT_ON],
  },
  {
    value: stockAlertType.ALERT_OFF,
    label: stockAlertLabel[stockAlertType.ALERT_OFF],
  },
];

export const stockStatusType = {
  STATUS_DISABLE: 2,
  STATUS_ACTIVE: 1,
};

export const stockStatusLabel = {
  [stockStatusType.STATUS_DISABLE]: 'Desativada',
  [stockStatusType.STATUS_ACTIVE]: 'Ativada',
};

export const stockStatusOptionList = [
  {
    value: stockStatusType.STATUS_DISABLE,
    label: stockStatusLabel[stockStatusType.STATUS_DISABLE],
  },
  {
    value: stockStatusType.STATUS_ACTIVE,
    label: stockStatusLabel[stockStatusType.STATUS_ACTIVE],
  },
];
