import React from 'react';
import PropTypes from 'prop-types';
import { Search } from '@material-ui/icons';
import styled from 'styled-components';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { Table, Tr, ThContainer } from '../../../../../../components/Table/Table';
import { Button, Text, EmptyStateWithAction } from '../../../../../../components';
import { Colors, USER_ROLES, OrderStatusText } from '../../../../../../constants';
import { columnLabel, columnType } from '../../../../../../constants/order';
import IconCompany from '../../../../../../images/icon-company.svg';
import IconLuft from '../../../../../../images/icon-luft.svg';
import IconAgent from '../../../../../../images/icon-agent.svg';
import { RoutePaths, OrderRoutePaths } from '../../../../../../constants/RoutePaths';

const AuthorWrapper = styled.div`
  display: flex;
`;

const PersonContainer = styled.div`
  margin-right: 6px;
`;

const AuthorName = styled.div`
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
`;

const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const StyledEmptyStateWithAction = styled(EmptyStateWithAction)`
  padding: 40px 16px;
  border-bottom: 1px solid ${Colors.GRAY_LIGHTER};
  flex-direction: column;
`;

const StyledEmptyActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;

const ButtonDivider = styled.div`
  width: 16px;
  height: 1px;
`;

const RowWrapper = styled(Tr)`
  &:hover {
    background-color: ${Colors.Alpha(Colors.GRAY_LIGHT, 0.3)};
  }

  ${({ checked }) => checked && `
    background-color: ${Colors.Alpha(Colors.PRIMARY_BLUE, 0.05)};
  `}
`;

const StyledTable = styled(Table)`
  margin-bottom: 48px;

  & ${ThContainer} {
    padding-left: 16px;
  }
`;

const SearchStyled = styled(Search)`
  color: ${Colors.SECONDARY_DARK};
`;

const OrderAgentTable = ({
  userData,
  data,
  loading,
  totalItems,
  onRequestOrderDetail,
  handleRowCheck,
  ...props
}) => {
  const history = useHistory();

  const _renderBasicTextCell = (value) => (
    <Text>
      {value || '--'}
    </Text>
  );

  const _renderNumberLinesTextCell = (value) => (
    <Text numberOfLines={2}>
      {value || '--'}
    </Text>
  );

  const _renderStatusTextCell = (status) => (
    <Text>
      {OrderStatusText[status] || 'Sem status'}
    </Text>
  );

  const columnConfig = {
    [columnType.ORDER_NUMBER]: {
      order: 1,
    },
    [columnType.WMS_ORDER_NUMBER]: {
      order: 2,
    },
    [columnType.CREATE_DATE]: {
      order: 3,
    },
    [columnType.DELIVERY]: {
      order: 4,
    },
    [columnType.AUTHOR]: {
      order: 5,
      noSorting: true,
    },
    [columnType.MATERIAL]: {
      order: 6,
      width: 300,
      noSorting: true,
    },
    [columnType.STATUS]: {
      order: 7,
    },
    [columnType.ACTION]: {
      order: 8,
      noHeader: true,
      noSorting: true,
    },
  };

  const components = {
    [columnType.ORDER_NUMBER]: _renderBasicTextCell,
    [columnType.WMS_ORDER_NUMBER]: _renderBasicTextCell,
    [columnType.CREATE_DATE]: (value) => _renderBasicTextCell(value && format(new Date(value), 'dd/MM/yyyy')),
    [columnType.DELIVERY]: (value) => _renderBasicTextCell(value && format(new Date(value), 'dd/MM/yyyy')),
    [columnType.AUTHOR]: (requester) => (
      <AuthorWrapper>
        <PersonContainer>
          {requester.role === USER_ROLES.MASTER && (
            <img src={IconLuft} alt={requester.name} />
          )}
          {requester.role === USER_ROLES.COMPANY && (
            <img src={IconCompany} alt={requester.name} />
          )}
          {requester.role === USER_ROLES.AGENT && (
            <img src={IconAgent} alt={requester.name} />
          )}
        </PersonContainer>
        <AuthorName>
          {requester.name}
        </AuthorName>
      </AuthorWrapper>
    ),
    [columnType.MATERIAL]: (value) => {
      const materials = value.map((material, index) => (`${material.materialName} (${material.quantity})${value.length > index + 1 ? ', ' : ''}`));
      return _renderNumberLinesTextCell(materials);
    },
    [columnType.STATUS]: _renderStatusTextCell,
    [columnType.ACTION]: () => (
      <ActionsContainer>
        <SearchStyled />
      </ActionsContainer>
    ),
  };

  const handleOnRequestOrderDetail = async (data) => {
    onRequestOrderDetail(data);
  };

  return (
    <>
      <StyledTable
        name="orders-agent-table"
        isLoading={loading}
        footerText="Pedidos por página: "
        data={data}
        handleHeaderTitle={(key) => columnLabel[key]}
        columnConfig={columnConfig}
        components={components}
        noFooter
        rowWrapper={() => ({ RowWrapper, rowWrapperProps: {} })}
        onRowClick={(data) => { handleOnRequestOrderDetail(data); }}
        renderEmptyState={() => (
          <StyledEmptyStateWithAction
            alignCenter
            emptyStateText="Nenhum pedido pra exibir :("
            renderAction={() => (
              <StyledEmptyActionsContainer>
                <Button
                  variant="outlined"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Atualizar
                </Button>
                <ButtonDivider />
                <Button
                  variant="contained"
                  onClick={() => { history.push(`${RoutePaths.ORDER}${OrderRoutePaths.NEW}`); }}
                >
                  Fazer pedido
                </Button>
              </StyledEmptyActionsContainer>
            )}
          />
        )}
      />
    </>
  );
};

OrderAgentTable.propTypes = {
  data: PropTypes.array.isRequired,
  totalItems: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  onRequestOrderDetail: PropTypes.func.isRequired,
  handleRowCheck: PropTypes.func,
};

OrderAgentTable.defaultProps = {
  loading: false,
  handleRowCheck: () => {},
};

export default OrderAgentTable;
