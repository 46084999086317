// Module imports
import queryString from 'query-string';
import { ClientError, ServerError } from './error';
import { HttpStatusCode } from './status-code';
import { handleSessionExpired, removeEmptyFields } from '../../utils';

let _reduxStoreRef = null;

export class HttpService {
  static set store(ref) {
    _reduxStoreRef = ref;
  }

  static async fetchBase(endpoint, config, useSessionToken = true) {
    const authState = _reduxStoreRef.getState().auth;
    const sessionToken = authState ? authState.sessionToken : null;
    const handledConfig = removeEmptyFields(config);
    const fetchConfig = {
      ...handledConfig,
      headers: new Headers({
        ...(useSessionToken && sessionToken && sessionToken !== 'null'
          ? { Authorization: `bearer ${sessionToken}` }
          : {}),
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...(handledConfig.headers || {}),
      }),
    };

    let response = {};

    try {
      response = await fetch(endpoint, fetchConfig);
    } catch (error) {
      throw new Error('Sem conexão');
    }

    return response;
  }

  static async fetch(endpoint, config, useSessionToken = true) {
    const authState = _reduxStoreRef.getState().auth;
    const sessionToken = authState ? authState.sessionToken : null;
    const response = await HttpService.fetchBase(endpoint, config, useSessionToken);

    let responseJson = {};

    if (
      useSessionToken
      && sessionToken
      && response.status === HttpStatusCode.UNAUTHORIZED
    ) {
      handleSessionExpired(
        ((authState.userData || {})),
      );

      throw new ServerError(response.status, 'Sua sessão expirou. Por favor efetue o login novamente.', responseJson);
    }

    try {
      responseJson = await response.json();
    } catch (error) { }

    if (response.status >= HttpStatusCode.INTERNAL_SERVER_ERROR) {
      throw new ServerError(response.status, 'Ocorreu um problema em nosso sistema. Aguarde um pouco e tente novamente.', responseJson);
    }

    if (
      response.status >= HttpStatusCode.BAD_REQUEST
      && response.status < HttpStatusCode.INTERNAL_SERVER_ERROR
    ) {
      throw new ClientError(response.status, responseJson.message || response.statusText || response.status, responseJson);
    }

    return responseJson;
  }

  static async blob({
    endpoint,
    data,
    method = 'POST',
    useSessionToken = true,
  }) {
    const authState = _reduxStoreRef.getState().auth;
    const sessionToken = authState ? authState.sessionToken : null;
    const response = await HttpService.fetchBase(endpoint, {
      method,
      body: data && JSON.stringify(removeEmptyFields(data)),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }, useSessionToken);
    let responseBlob = {};

    if (
      useSessionToken
      && sessionToken
      && response.status === HttpStatusCode.UNAUTHORIZED
    ) {
      handleSessionExpired(
        ((authState.userData || {})),
      );

      return null;
    }

    try {
      responseBlob = await response.blob();
    } catch (error) {}

    if (response.status >= HttpStatusCode.INTERNAL_SERVER_ERROR) {
      throw new ServerError(response.status, 'Ocorreu um problema em nosso sistema. Aguarde um pouco e tente novamente.', responseBlob);
    }

    if (
      response.status >= HttpStatusCode.BAD_REQUEST
      && response.status < HttpStatusCode.INTERNAL_SERVER_ERROR
    ) {
      throw new ClientError(response.status, response.statusText || response.status, {});
    }

    return responseBlob;
  }

  static post(endpoint, requestBody, useSessionToken) {
    return HttpService.fetch(
      endpoint,
      {
        method: 'POST',
        body: requestBody && JSON.stringify(removeEmptyFields(requestBody)),
      },
      useSessionToken,
    );
  }

  static get(endpoint, params, useSessionToken) {
    const serializedParams = HttpService.serializeUrlParams(params);

    return HttpService.fetch(
      serializedParams !== ''
        ? `${endpoint}?${HttpService.serializeUrlParams(params)}`
        : endpoint,
      { method: 'GET' },
      useSessionToken,
    );
  }

  static delete(endpoint, useSessionToken, requestBody) {
    return HttpService.fetch(
      endpoint,
      {
        method: 'DELETE',
        body: requestBody && JSON.stringify(removeEmptyFields(requestBody)),
      },
      useSessionToken,
    );
  }

  static put(endpoint, requestBody, useSessionToken) {
    return HttpService.fetch(
      endpoint,
      {
        method: 'PUT',
        body: requestBody && JSON.stringify(removeEmptyFields(requestBody)),
      },
      useSessionToken,
    );
  }

  static patch(endpoint, requestBody, useSessionToken) {
    return HttpService.fetch(
      endpoint,
      {
        method: 'PATCH',
        body: requestBody && JSON.stringify(removeEmptyFields(requestBody)),
      },
      useSessionToken,
    );
  }

  static patchWithEmptyFields(endpoint, requestBody, useSessionToken) {
    return HttpService.fetch(
      endpoint,
      {
        method: 'PATCH',
        body: requestBody && JSON.stringify(requestBody),
      },
      useSessionToken,
    );
  }

  static mock(payload, timeout = 200) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          ...payload,
          success:
            typeof payload.success === 'undefined' ? true : payload.success,
          notifications: payload.notifications || [],
          data: payload.data || [],
        });
      }, timeout);
    });
  }

  static getParams() {
    return HttpService.unserializeUrlParams(window.location.search);
  }

  static serializeUrlParams(...args) {
    return queryString.stringify(...args);
  }

  static unserializeUrlParams(...args) {
    return queryString.parse(...args);
  }

  static serializeQueryString(string, options, ...args) {
    return HttpService.serializeUrlParams(
      string,
      {
        ...options,
        skipNull: true,
        skipEmptyString: true,
        arrayFormat: 'comma',
      },
      ...args);
  }

  static unserializeQueryString(string, options, ...args) {
    return HttpService.unserializeUrlParams(
      string,
      {
        ...options,
        skipNull: true,
        skipEmptyString: true,
        arrayFormat: 'comma',
      },
      ...args);
  }

  static unserializeQueryStringArray(array, parseFunc) {
    if (!array) return [];
    return Array.isArray(array)
      ? array.map((el) => parseFunc ? parseFunc(el) : el) : [parseFunc ? parseFunc(array) : array];
  }

  static unserializeQueryStringCheckboxObject(array, parseFunc, initialObject) {
    return HttpService.unserializeQueryStringArray(array, parseFunc).reduce((acc, cur) => {
      acc[cur] = true;
      return acc;
    }, { ...initialObject });
  }

  static async upload({
    endpoint,
    data,
    method = 'POST',
    useSessionToken = true,
  }) {
    const authState = _reduxStoreRef.getState().auth;
    const sessionToken = authState ? authState.sessionToken : null;
    const response = await fetch(endpoint, {
      headers: {
        authorization: `Bearer ${sessionToken}`,
      },
      method,
      body: data,
    });

    let responseJson = {};

    if (
      useSessionToken
      && sessionToken
      && response.status === HttpStatusCode.UNAUTHORIZED
    ) {
      handleSessionExpired(
        ((authState.userData || {})),
      );

      throw new ServerError(response.status, 'Sua sessão expirou. Por favor efetue o login novamente.', responseJson);
    }

    try {
      responseJson = await response.json();
    } catch (error) { }

    if (response.status >= HttpStatusCode.INTERNAL_SERVER_ERROR) {
      throw new ServerError(response.status, 'Ocorreu um problema em nosso sistema. Aguarde um pouco e tente novamente.', responseJson);
    }

    if (
      response.status >= HttpStatusCode.BAD_REQUEST
      && response.status < HttpStatusCode.INTERNAL_SERVER_ERROR
    ) {
      throw new ClientError(response.status, responseJson.message || response.statusText || response.status, responseJson);
    }

    return responseJson;
  }

  static async getExternal({
    endpoint,
    method = 'GET',
    useSessionToken = true,
  }) {
    const authState = _reduxStoreRef.getState().auth;
    const sessionToken = authState ? authState.sessionToken : null;
    const response = await fetch(endpoint, {
      headers: {},
      method,
    });

    let responseJson = {};

    if (
      useSessionToken
      && sessionToken
      && response.status === HttpStatusCode.UNAUTHORIZED
    ) {
      handleSessionExpired(
        ((authState.userData || {})),
      );

      throw new ServerError(response.status, 'Sua sessão expirou. Por favor efetue o login novamente.', responseJson);
    }

    try {
      responseJson = await response.json();
    } catch (error) {
      
    }
    if (response.status >= HttpStatusCode.INTERNAL_SERVER_ERROR) {
      throw new ServerError(response.status, 'Ocorreu um problema em nosso sistema. Aguarde um pouco e tente novamente.', responseJson);
    }
    if (response.status >= HttpStatusCode.INTERNAL_SERVER_ERROR) {
      throw new ServerError(response.status, 'Ocorreu um problema em nosso sistema. Aguarde um pouco e tente novamente.', responseJson);
    }

    if (
      response.status >= HttpStatusCode.BAD_REQUEST
      && response.status < HttpStatusCode.INTERNAL_SERVER_ERROR
    ) {
      throw new ClientError(response.status, responseJson.message || response.statusText || response.status, responseJson);
    }

    return responseJson;
  }
}
