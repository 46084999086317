import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { Formik } from 'formik';

import {
  Container,
  Grid,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Avatar,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from '@material-ui/core/';
import {
  ExitToApp,
  Visibility,
  Assignment,
  PersonPinCircle,
  SwapHoriz,
  Home,
  HowToReg,
  LocalShipping,
  TrackChanges,
  HelpOutline,
} from '@material-ui/icons';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {
  handleLogout,
  mediaHelper,
} from '../utils';
import {
  EmptyState,
  DialogModal,
} from '.';
import { Colors, USER_ROLES } from '../constants';
import LuftLogo from '../images/luft-logo.svg';
import {
  RoutePaths,
  StaticRoutes,
  StockPermissionRoutePaths,
  AgentRoutePaths,
} from '../constants/RoutePaths';

const HeaderWrapper = styled.header`
  position: relative;
  width: 100%;
  height: 64px;
  background-color: ${Colors.SECONDARY_MEDIUM};
  z-index: 999;
  box-shadow: rgba(0,0,0,.25) 0px 0px 16px;
`;

const LogoWrapper = styled.div`
  width: 32px;
  height: 64px;
  display: flex;
  align-items: center;
  overflow: hidden;

  a {
    height: 32px;

    ${mediaHelper.lg`
      height: 40px;
    `}
  }

  ${mediaHelper.lg`
    width: 100%;
  `};
`;

const LogoImage = styled.img`
  width: 105px;

  ${mediaHelper.lg`
    width: 120px;
  `};
`;

const AvatarWrapper = styled.div`
  width: 32px;
  height: calc(100% - 3px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  cursor: pointer;
  transition: background-color .2s ease-in-out, border-color .2s ease-in-out;
  border-bottom: ${({ active }) => active ? `3px solid${Colors.PRIMARY_BLUE}` : '3px solid transparent'};
  background-color: ${({ active }) => active ? Colors.PRIMARY_DARK : 'transparent'};
  float: right;

  &:hover {
    background-color: ${Colors.PRIMARY_DARK}
  }

  ${mediaHelper.lg`
    width: 100%;
    float: none;
  `};
`;

const AvatarInfo = styled.div`
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 148px;
  font-size: 14px;

  ${mediaHelper.lg`
    display: flex;
  `};
`;

const UsernameText = styled.span`
  max-width: 148px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: white;
  font-size: 14px;
`;

const CompanyNameText = styled.div`
  max-width: 148px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${Colors.PRIMARY_BLUE};
`;

const AvatarFrame = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  overflow: hidden;
  
  ${mediaHelper.lg`
    width: 40px;
    height: 40px;
    border-radius: 40px;
    margin-left: 16px;
    margin-right: 8px;
  `};
`;

const DropdownMenuWrapper = styled(Popper)`
  width: calc(100% - 16px);
  pointer-events: all;
  position: fixed !important;
  top: 64px !important;
  left: 8px !important;
  transform: none !important;

  ${mediaHelper.lg`
    position: absolute !important;
    left: 0 !important;
    transform: initial !important;
  `};
`;

const SubmenuMenuWrapper = styled(Popper)`
  width: calc(100% - 16px);
  pointer-events: all;
  position: fixed !important;
  top: 64px !important;
  left: 8px !important;
  transform: none !important;

  ${mediaHelper.lg`
    width: 150%;
    position: absolute !important;
    left: 0 !important;
    transform: initial !important;
  `};
`;

const NavigationWrapper = styled.nav`
  width: auto;
  display: flex;
  height: 64px;
`;

const NavText = styled.span`
  opacity: .2;
`;

const NavigationItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  text-transform: uppercase;
  color: white;
  font-size: 13px;
  list-style: none;
  font-weight: bold;
  cursor: pointer;
  transition: all .3s ease-in-out;
  border-bottom: 3px solid transparent;
  text-decoration: none;
  pointer-events: ${({ disabled }) => disabled ? 'none' : 'all'};

  &.active {
    border-bottom: 3px solid ${Colors.PRIMARY_BLUE};
    ${NavText} {
      opacity: 1;
    }
  }

  &:hover {
    background-color: ${Colors.PRIMARY_DARK};
    ${NavText} {
      opacity: 1;
    }
  }

  ${mediaHelper.md`
    padding: 0 32px;
    font-size: 14px;
  `};
`;

const IconWrapper = styled.div`
  margin-right: 8px;
  height: 26px;
`;

const MenuItemWrapper = styled(MenuItem)`
  display: flex;
  align-items: center;
  text-transform: none;
  color: ${Colors.GRAY_DARKEST};

  &:focus {
    background-color: transparent;
  }

  &:hover:focus {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const NavigationContent = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
`;

const CompanyTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  padding: 0 16px;
  margin-right: 32px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 15px;
  background-color: ${Colors.PRIMARY_BLUE};
  color: ${Colors.SECONDARY_MEDIUM};
`;

const CompanyTagIcon = styled.div`
  width: 22px;
  height: 22px;
  margin-right: 8px;
`;

const ListItem = styled(MenuItem)`
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 24px;
`;

const ListItemLabel = styled(FormControlLabel)`
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
`;

const FormControlWrapper = styled.div`
  width: 100%;
  margin: 0 -24px 16px -24px;
`;

const ListTop = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  border-bottom: 1px solid ${Colors.GRAY_LIGHTER};
`;

const ListBottom = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  background-color: ${Colors.GRAY_LIGHTEST};

  ${ListItem} {
    padding-left: 48px;
  }
`;

const LinkStyled = styled.div`
  display: flex;
  width: 100%;
  text-decoration: none;
  color: ${Colors.GRAY_DARKEST};
`;

const Header = ({
  userData,
  companyDialogList,
  handleOpenCompanyDialog,
  handleOutputCompanyDialog,
  loadingDialog,
  hideMenu,
  hideChangeView,
}) => {
  const [open, setOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(false);
  const [openSubmenuStock, setOpenSubmenuStock] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [companyState, setCompanyState] = useState('luft-1');
  const [isOpenLogoutDialog, setIsOpenLogoutDialog] = useState(false);
  const prevOpen = useRef(open);
  const prevOpenSubmenu = useRef(openSubmenu);
  const prevOpenSubmenuStock = useRef(openSubmenuStock);
  const avatarMenuRef = useRef(null);
  const submenuRef = useRef(null);
  const submenuStockRef = useRef(null);
  const history = useHistory();
  const MainTitle = 'Plataforma Luft Promocional';

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      avatarMenuRef.current.focus();
    }
    prevOpen.current = open;

    if (prevOpenSubmenu.current === true && openSubmenu === false) {
      submenuRef.current.focus();
    }
    prevOpenSubmenu.current = openSubmenu;

    if (prevOpenSubmenuStock.current === true && openSubmenuStock === false) {
      submenuStockRef.current.focus();
    }
    prevOpenSubmenuStock.current = openSubmenuStock;
  }, [
    open,
    openSubmenu,
    openSubmenuStock,
  ]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    setOpenSubmenu(false);
    setOpenSubmenuStock(false);
  };

  const _handleClose = (event) => {
    if (avatarMenuRef.current && avatarMenuRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggleSubmenu = () => {
    setOpenSubmenu((prevOpenSubmenu) => !prevOpenSubmenu);
    setOpen(false);
    setOpenSubmenuStock(false);
  };

  const _handleCloseSubmenu = (event) => {
    if (submenuRef.current && submenuRef.current.contains(event.target)) {
      return;
    }
    setOpenSubmenu(false);
  };

  const handleToggleSubmenuStock = () => {
    setOpenSubmenuStock((prevOpenSubmenuStock) => !prevOpenSubmenuStock);
    setOpen(false);
    setOpenSubmenu(false);
  };

  const _handleCloseSubmenuStock = (event) => {
    if (submenuStockRef.current && submenuStockRef.current.contains(event.target)) {
      return;
    }
    setOpenSubmenuStock(false);
  };

  const _handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const _handleListKeyDownSubmenu = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenSubmenu(false);
    }
  };

  const _handleListKeyDownSubmenuStock = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenSubmenu(false);
    }
  };

  const _handleChange = (event) => {
    setCompanyState(event.target.value);
  };

  const _handleOpenCompanyDialog = () => {
    setIsOpenDialog(true);
    handleOpenCompanyDialog();
  };

  const _goToPage = (e, page) => {
    e.preventDefault();
    history.push(page);
  };

  const _handleCloseCompanyDialog = () => {
    setIsOpenDialog(false);
  };

  const _handleConfirmDialog = () => {
    if (companyState !== `luft-${USER_ROLES.MASTER}`) {
      const companyId = companyState.replace(/company-/g, '');
      handleOutputCompanyDialog(parseInt(companyId, 10));
    } else {
      handleOutputCompanyDialog(null);
    }
    setIsOpenDialog(false);
  };

  const _handleLink = (url) => {
    window.open(url, '_blank');
  };

  const _renderDropdownMenu = () => (
    <DropdownMenuWrapper open={open} anchorEl={avatarMenuRef.current} transition disablePortal>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <Paper>
            <ClickAwayListener onClickAway={_handleClose}>
              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={_handleListKeyDown}>
                {userData.userRole === USER_ROLES.MASTER && !hideChangeView ? (
                  <MenuItemWrapper onClick={_handleOpenCompanyDialog}>
                    <IconWrapper>
                      <Visibility />
                    </IconWrapper>
                    Ver como...
                  </MenuItemWrapper>
                ) : null}
                <MenuItemWrapper>
                  <LinkStyled
                    onClick={() => { _handleLink(StaticRoutes.MANUAL); }}
                    title="Manual"
                  >
                    <IconWrapper>
                      <HelpOutline />
                    </IconWrapper>
                    Ajuda
                  </LinkStyled>
                </MenuItemWrapper>
                <MenuItemWrapper onClick={() => { setIsOpenLogoutDialog(true); }}>
                  <IconWrapper>
                    <ExitToApp />
                  </IconWrapper>
                  Sair
                </MenuItemWrapper>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </DropdownMenuWrapper>
  );

  const _renderSubmenuPairing = () => (
    <SubmenuMenuWrapper
      open={openSubmenu}
      anchorEl={submenuRef.current}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <Paper>
            <ClickAwayListener onClickAway={_handleCloseSubmenu}>
              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={_handleListKeyDownSubmenu}>
                <MenuItemWrapper onClick={(e) => _goToPage(e, RoutePaths.ORDER)}>
                  <IconWrapper>
                    <Assignment />
                  </IconWrapper>
                  Todos os pedidos
                </MenuItemWrapper>
                {userData.userRole === USER_ROLES.MASTER && !userData.companyName && (
                  <MenuItemWrapper onClick={(e) => _goToPage(e, RoutePaths.PAIRING)}>
                    <IconWrapper>
                      <SwapHoriz />
                    </IconWrapper>
                    Pareamento de Pedidos
                  </MenuItemWrapper>
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </SubmenuMenuWrapper>
  );

  const _renderSubmenuOrders = () => (
    <SubmenuMenuWrapper
      open={openSubmenu}
      anchorEl={submenuRef.current}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <Paper>
            <ClickAwayListener onClickAway={_handleCloseSubmenu}>
              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={_handleListKeyDownSubmenu}>
                <MenuItemWrapper onClick={(e) => _goToPage(e, RoutePaths.ORDER)}>
                  <IconWrapper>
                    <LocalShipping />
                  </IconWrapper>
                  Pedidos
                </MenuItemWrapper>
                {/* <MenuItemWrapper onClick={(e) => _goToPage(e, RoutePaths.ORDER)}>
                  <IconWrapper>
                    <TrackChanges />
                  </IconWrapper>
                  Ciclos promocionais
                </MenuItemWrapper> */}
                {userData.userRole === USER_ROLES.COMPANY && (
                  <MenuItemWrapper onClick={(e) => _goToPage(e, RoutePaths.AGENT)}>
                    <IconWrapper>
                      <PersonPinCircle />
                    </IconWrapper>
                    Representantes
                  </MenuItemWrapper>
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </SubmenuMenuWrapper>
  );

  const _renderSubmenuStock = () => (
    <SubmenuMenuWrapper
      open={openSubmenuStock}
      anchorEl={submenuStockRef.current}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <Paper>
            <ClickAwayListener onClickAway={_handleCloseSubmenuStock}>
              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={_handleListKeyDownSubmenuStock}>
                <MenuItemWrapper onClick={(e) => _goToPage(e, RoutePaths.STOCK)}>
                  <IconWrapper>
                    <Home />
                  </IconWrapper>
                  Estoque
                </MenuItemWrapper>
                {(userData.userRole === USER_ROLES.COMPANY) && (
                  <MenuItemWrapper onClick={(e) => _goToPage(e, StockPermissionRoutePaths.PERMISSIONS)}>
                    <IconWrapper>
                      <HowToReg />
                    </IconWrapper>
                    Permissões
                  </MenuItemWrapper>
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </SubmenuMenuWrapper>
  );

  const _renderAvatar = () => {
    const fullName = userData.username.split(' ');
    const initials = fullName.length <= 1 ? fullName[0].charAt(0) : fullName.shift().charAt(0) + fullName.pop().charAt(0);

    return (
      <AvatarWrapper
        ref={avatarMenuRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        active={open}
        title={`${userData.username}: ${userData.userRole !== USER_ROLES.MASTER ? userData.companyName : 'Luft Healthcare'}`}
      >
        <AvatarInfo>
          <UsernameText>{userData.username}</UsernameText>
          <CompanyNameText>
            <>
              {userData.userRole === USER_ROLES.AGENT && `${'Rep. '}`}
              {userData.userRole !== USER_ROLES.MASTER ? userData.companyName : 'Luft Healthcare'}
            </>
          </CompanyNameText>
        </AvatarInfo>
        <AvatarFrame>
          <Avatar>
            {initials.toUpperCase()}
          </Avatar>
        </AvatarFrame>
        {_renderDropdownMenu()}
      </AvatarWrapper>
    );
  };

  const _renderNavigation = () => (
    <NavigationWrapper>
      {userData.companyName && (
        <NavigationItem
          className={history.location.pathname === StockPermissionRoutePaths.PERMISSIONS ? 'active' : history.location.pathname.includes(RoutePaths.STOCK) ? 'active' : ''}
          title="Armazém"
          ref={submenuStockRef}
          aria-controls={openSubmenuStock ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggleSubmenuStock}
        >
          <NavText>
            Armazém
          </NavText>
          {_renderSubmenuStock()}
        </NavigationItem>
      )}
      <NavigationItem
        className={history.location.pathname === RoutePaths.PAIRING ? 'active' : history.location.pathname.includes(AgentRoutePaths.UPDATE) ? 'active' : history.location.pathname === AgentRoutePaths.NEW ? 'active' : history.location.pathname.includes(RoutePaths.AGENT) ? 'active' : history.location.pathname.includes(RoutePaths.ORDER) ? 'active' : ''}
        title="Distribuição"
        ref={submenuRef}
        aria-controls={openSubmenu ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggleSubmenu}
      >
        <NavText>
          Distribuição
        </NavText>
        {userData.userRole === USER_ROLES.MASTER && !userData.companyName ? (
          _renderSubmenuPairing()
        ) : (
          _renderSubmenuOrders()
        )}
      </NavigationItem>
    </NavigationWrapper>
  );

  const _renderCompanyName = () => (
    <CompanyTag>
      <CompanyTagIcon>
        <Visibility />
      </CompanyTagIcon>
      {userData.companyName}
    </CompanyTag>
  );

  return (
    <>
      <HeaderWrapper>
        <Container maxWidth="lg">
          <Grid container>
            <Grid
              item
              lg={2}
              md={2}
              sm={1}
              xs={2}
            >
              <LogoWrapper>
                <Link
                  to={RoutePaths.HOME}
                  title={MainTitle}
                >
                  <LogoImage src={LuftLogo} alt={MainTitle} />
                </Link>
              </LogoWrapper>
            </Grid>
            <Grid
              item
              lg={8}
              md={8}
              sm={9}
              xs={8}
            >
              <NavigationContent>
                {userData.companyName && userData.userRole === USER_ROLES.MASTER && (
                  _renderCompanyName()
                )}
                {!hideMenu && (
                  _renderNavigation()
                )}
              </NavigationContent>
            </Grid>
            <Grid
              item
              lg={2}
              md={2}
              sm={2}
              xs={2}
            >
              {userData && (
                _renderAvatar()
              )}
            </Grid>
          </Grid>
        </Container>
      </HeaderWrapper>

      <DialogModal
        title="Selecione uma empresa"
        size="md"
        confirmButtonText="Confirmar"
        handleCloseDialog={_handleCloseCompanyDialog}
        handleConfirmDialog={_handleConfirmDialog}
        isOpenDialog={isOpenDialog}
        loadingDialog={loadingDialog}
      >
        <Formik
          onSubmit={_handleConfirmDialog}
        >
          {() => (
            <>
              {companyDialogList ? (
                <FormControlWrapper>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="company"
                      name="company"
                      value={companyState}
                      onChange={_handleChange}
                    >
                      <ListTop>
                        <ListItem key={`luft-${USER_ROLES.MASTER}`}>
                          <ListItemLabel
                            value={`luft-${USER_ROLES.MASTER}`}
                            control={<Radio color="primary" />}
                            label="Luft Healthcare"
                          />
                        </ListItem>
                      </ListTop>
                      <ListBottom>
                        {companyDialogList.list.map((option) => (
                          <ListItem key={`company-${option.wmsId}`}>
                            <ListItemLabel
                              value={`company-${option.wmsId}`}
                              control={<Radio color="primary" />}
                              label={option.name}
                            />
                          </ListItem>
                        ))}
                      </ListBottom>
                    </RadioGroup>
                  </FormControl>
                </FormControlWrapper>
              ) : (
                <EmptyState
                  text="Nenhuma empresa para exibir :("
                />
              )}
            </>
          )}
        </Formik>
      </DialogModal>
      <DialogModal
        title="Saindo"
        description="Você deseja realmente sair da plataforma?"
        confirmButtonText="Sim, quero sair"
        cancelButtonText="Voltar"
        handleCloseDialog={() => { setIsOpenLogoutDialog(false); }}
        handleConfirmDialog={() => {
          handleLogout();
        }}
        isOpenDialog={isOpenLogoutDialog}
      />
    </>
  );
};

Header.propTypes = {
  hideMenu: PropTypes.bool,
  userData: PropTypes.object,
  companyDialogList: PropTypes.object,
  loadingDialog: PropTypes.bool,
  handleOpenCompanyDialog: PropTypes.func,
  handleOutputCompanyDialog: PropTypes.func,
  hideChangeView: PropTypes.bool,
};

Header.defaultProps = {
  hideMenu: false,
  userData: {},
  companyDialogList: {},
  loadingDialog: false,
  handleOpenCompanyDialog: () => { },
  handleOutputCompanyDialog: () => { },
  hideChangeView: false,
};

export default Header;
