import { AuthTypes } from './types';

const initialState = {
  sessionToken: null,
  userData: null,
  authorized: false
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
  case AuthTypes.SET_TOKEN:
    return {
      ...state,
      sessionToken: action.token
    };

  case AuthTypes.SET_USER_DATA:
    return {
      ...state,
      userData: action.userData
    };
    
  case AuthTypes.IS_AUTHORIZED:
    return {
      ...state,
      authorized: action.authorized
    };

  case AuthTypes.LOGOUT:
    return {
      ...state,
      userData: null,
      sessionToken: null,
      authorized: false
    };

  default:
    return state;
  }
};