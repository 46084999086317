import React from 'react';
import { PropTypes } from 'prop-types';
import styled, { css as cssHelper, keyframes } from 'styled-components';

import ImgSmall from './img/small.png';
import ImgSmall2x from './img/small@2x.png';
import ImgSmall3x from './img/small@3x.png';

export const SpinnerSize = {
  SMALL: 1,
  MEDIUM: 2,
  BIG: 3,
};

const propTypes = {
  size: PropTypes.number,
};

const defaultProps = {
  size: SpinnerSize.SMALL,
};

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
`;

const AnimatedImg = styled.img`
  animation: ${rotate360} 0.5s linear infinite;

  ${({ size }) =>
    size === SpinnerSize.SMALL &&
    cssHelper`
    width: 16px;
    height: 16px;
  `}
`;

const Spinner = ({ size, ...props }) => (
  <div {...props}>
    <AnimatedImg
      size={size}
      alt='Loading'
      src={ImgSmall}
      srcSet={`${ImgSmall2x} 2x, ${ImgSmall3x} 3x`}
    />
  </div>
);

Spinner.propTypes = propTypes;
Spinner.defaultProps = defaultProps;

export default Spinner;
