import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@material-ui/core';
import { Delete, SaveAlt } from '@material-ui/icons';
import styled from 'styled-components';

import { Colors, USER_ROLES } from '../constants';
import { Button } from '.';
import { mediaHelper } from '../utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  ${mediaHelper.md`
    flex-direction: row;
    justify-content: flex-end;
  `}
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 16px;
  background-color: ${Colors.Alpha(Colors.PRIMARY_BLUE, 0.24)};
  border-radius: 4px;
  width: calc(100% - 24px);
  margin-bottom: 8px;

  ${mediaHelper.md`
    margin-left: 24px;
    width: auto;
    margin-bottom: 0;
    justify-content: center;
  `}
`;

const StyledButton = styled(Button)`
  padding-left: 16px;
  margin-right: auto;
  display: flex;
  margin-bottom: 24px;

  ${mediaHelper.md`
    margin-bottom: 0;
  `}
`;

const StyledIcon = styled(Icon)`
  display: flex;
  font-size: 18px;
  margin-left: 8px;
  margin-right: 8px;
`;

const AppliedFiltersText = styled.div`
  font-weight: 100;
  font-size: 12px;
  color: ${Colors.PRIMARY_BLUE};
  text-transform: uppercase;
`;

const CleanFiltersText = styled.button`
  display: flex;
  align-items: center;
  border: 0;
  background-color: transparent;
  padding: 0;
  font-size: 12px;
  color: ${Colors.PRIMARY_BLUE};
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  margin-left: 16px;
`;

const SearchResultTermText = styled.div`
  font-weight: 100;
  font-size: 12px;
  color: ${Colors.PRIMARY_BLUE};
  text-transform: uppercase;
`;

const FilterIndicator = ({
  userData,
  onExport,
  onClear,
  onClearSearch,
  hideExport,
  appliedFilters = 0,
  searchTerm,
  listCountResults,
  ...props
}) => (
  <>
    {(appliedFilters !== 0 || searchTerm) && (
      <Wrapper {...props}>
        {!hideExport && (
          <>
            {(
              appliedFilters !== 0
              && listCountResults > 0
            ) && (
              userData.userRole !== USER_ROLES.MASTER
              || (userData.userRole === USER_ROLES.MASTER && !!userData.companyName)
            ) && (
              <StyledButton
                color="primary"
                variant="outlined"
                handleClick={onExport}
              >
                Exportar resultados
                <StyledIcon>
                  <SaveAlt fontSize="inherit" />
                </StyledIcon>
              </StyledButton>
            )}
          </>
        )}
        {searchTerm && (
          <Container>
            <SearchResultTermText>
              {`Resultados da busca para "${searchTerm}"`}
            </SearchResultTermText>
            <CleanFiltersText onClick={onClearSearch}>
              Limpar busca
              <StyledIcon>
                <Delete fontSize="inherit" />
              </StyledIcon>
            </CleanFiltersText>
          </Container>
        )}
        {appliedFilters !== 0 && (
          <Container>
            <AppliedFiltersText>
              {appliedFilters}
              {' '}
              {appliedFilters > 1 ? 'Filtros aplicados' : 'Filtro aplicado'}
              &nbsp;
            </AppliedFiltersText>
            <CleanFiltersText onClick={onClear}>
              {appliedFilters > 1 ? 'Limpar filtros' : 'Limpar filtro'}
              <StyledIcon>
                <Delete fontSize="inherit" />
              </StyledIcon>
            </CleanFiltersText>
          </Container>
        )}
      </Wrapper>
    )}
  </>
);

FilterIndicator.propTypes = {
  userData: PropTypes.object,
  appliedFilters: PropTypes.number.isRequired,
  searchTerm: PropTypes.string,
  onClear: PropTypes.func.isRequired,
  onClearSearch: PropTypes.func,
  onExport: PropTypes.func,
};

FilterIndicator.defaultProps = {
  userData: {},
  onExport: () => {},
  onClearSearch: () => {},
  searchTerm: '',
};

export default FilterIndicator;
