import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'formik';
import { format, formatISO, differenceInDays } from 'date-fns';
import NumberFormat from 'react-number-format';
import { TextField, RadioGroup } from 'formik-material-ui';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { setHours } from '../../../../../../utils/set-hours';

import {
  Grid,
  Card,
  Switch,
  Radio,
  Select,
  FormControlLabel,
  FormControl,
  InputLabel,
  Tooltip,
} from '@material-ui/core/';
import { Error } from '@material-ui/icons';
import { Button, showErrorToast } from '../../../../../../components';
import Navbar from '../../../../../../components/Filter/date/NavBar';
import '../../../../../../components/Filter/date/dateStyle.css';

import {
  MONTHS,
  WEEKDAYS_LONG,
  WEEKDAYS_SHORT,
} from '../../../../../../components/Filter/date/ptBR';

import { Colors, Theme } from '../../../../../../constants';
import {
  statusPermission,
  typePermissionTerm,
  permissionRecurrence,
  permissionRecurrenceInterval,
} from '../../../../../../constants/stock/enums';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 0;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border: 1px solid ${Colors.GRAY_LIGHT};
  border-radius: 8px;
  margin-top: 32px;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const LeftContent = styled.div`
  width: 50%;
  padding: 24px;
  border-right: 1px solid ${Colors.GRAY_LIGHT};
`;

const RightContent = styled.div`
  width: 50%;
  padding: 24px;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-top: 1px solid ${Colors.GRAY_LIGHT};
`;

const TitleContent = styled.div`
  color: ${Colors.SECONDARY_MEDIUM};
  font-size: 24px;
  margin-bottom: 16px;

  ${({ disabled }) => disabled && `
    color: ${Colors.GRAY};
  `}
`;

const DayPickerWrapper = styled.div`
  position: relative;
  z-index: 9;
`;

const DayPickerCard = styled(Card)`
  position: absolute;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding: 8px;
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
`;

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 48px;
  
  ${({ disabled }) => disabled && `
    opacity: .5;
  `}
`;

const InfoIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.ORANGE};
  margin-right: 16px;

  ${({ disabled }) => disabled && `
    color: ${Colors.GRAY};
  `}
`;

const InfoLabel = styled.span`
  font-size: 16px;
  color: ${Colors.GRAY_DARKEST};
`;

const InfoValue = styled.span`
  font-size: 14px;
  color: ${Colors.GRAY_DARKER};
`;

const SwitchModeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 100px;
`;

const SwitchLabel = styled.span`
  font-size: 12px;
  color: ${Colors.GRAY_DARKEST};

  ${({ selected }) => selected && `
    color: ${Colors.PRIMARY_BLUE};
    font-weight: bold;
  `}
`;

const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Label = styled.div`
  font-size: 12px;
  color: ${Colors.GRAY_DARKEST};
  font-weight: bold;
`;

const StyledTextField = styled(Field)`
  max-width: 160px;
  margin-left: 16px;
`;

const ActionBlock = styled.div`
  display: flex;
  margin-left: auto;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 300px;
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 24px;
`;

const FieldGroup = styled.div`
  display: flex;
  align-items: flex-end;
`;

const FormControlLabelStyled = styled(FormControlLabel)`
  margin-bottom: 24px;
`;

const DateFieldStyled = styled(Field)`
  min-width: 270px;
`;

const FieldSmallStyled = styled(Field)`
  max-width: 138px;
  margin-right: 16px;
`;

const FieldRowWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const StyledSelectField = styled(FormControl)`
  max-width: 170px;
  min-width: 170px;
`;

const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > button {
    margin-left: 24px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const PermissionForm = ({ ...props }) => {
  const {
    handleSubmit,
    handleCancel,
    isSubmitting,
    setFieldValue,
    handleMaterialAutomatic,
    isAutomaticQuantity,
    materialPermission,
    isNewPermission,
    handlePermissionActive,
    availableQuantity,
    materialId,
    handleQuantityDistribution,
    agentsTotalAuthorized,
    agentsTotalBalance,
    totalAgents,
    values,
    errors,
    isValid,
    hasErrorQuantity,
  } = props;

  const [isPeriodVisible, setIsPeriodVisible] = useState(false);
  const [range, setRange] = useState({ from: null, to: null });
  const [periodValue, setPeriodValue] = useState(null);
  const [recurrenceValue, setRecurrenceValue] = useState(null);
  const [permissionActive, setPermissionActive] = useState(false);
  const [hasInvaldPeriodDate, setHasInvaldPeriodDate] = useState(false);
  const [recurrenceQuantity, setRecurrenceQuantity] = useState(0);
  const [recurrenceIntervalValue, setRecurrenceIntervalValue] = useState();
  const dispatch = useDispatch();
  const modifiers = { start: range.from, end: range.to };
  const regex = new RegExp(/^[0-9_]*$/i);

  useEffect(() => {
    if (materialId) {
      setPermissionActive(true);
      handlePermissionActive(true);
    } else {
      setPermissionActive(materialPermission.status === statusPermission.ACTIVE);
      handlePermissionActive(materialPermission.status === statusPermission.ACTIVE);
    }
  }, [
    materialPermission.status,
  ]);

  useEffect(() => {
    if (materialId) {
      setPeriodValue(typePermissionTerm.INDETERMINATE);
    } else {
      setPeriodValue(materialPermission.typePermissionTerm);
    }
  }, [
    materialPermission.typePermissionTerm,
  ]);

  useEffect(() => {
    if (materialId) {
      setRecurrenceValue(permissionRecurrence.UNIQUE);
    } else {
      setRecurrenceValue(materialPermission.permissionRecurrence);
    }
  }, [
    materialPermission.permissionRecurrence,
  ]);

  useEffect(() => {
    if (hasInvaldPeriodDate) {
      dispatch(showErrorToast('Quantidade da recorrência está maior do que vigência escolhida.'));
    }
  }, [
    hasInvaldPeriodDate,
  ]);

  useEffect(() => {
    if (materialPermission.permissionTermInitialDate && materialPermission.permissionTermEndDate) {
      setRange({ from: new Date(materialPermission.permissionTermInitialDate), to: new Date(materialPermission.permissionTermEndDate) });
    }
    setRecurrenceQuantity(materialPermission.permissionRecurrenceQuantity);
  }, [
    materialPermission,
  ]);

  useEffect(() => {
    if (materialPermission.permissionRecurrenceInterval) {
      setRecurrenceIntervalValue(materialPermission.permissionRecurrenceInterval);
    }
  }, [
    materialPermission.permissionRecurrenceInterval,
  ]);

  const changePermissionActive = () => {
    setPermissionActive(!permissionActive);
    handlePermissionActive(!permissionActive);
  };

  const handleTermChange = (event) => {
    setIsPeriodVisible(false);
    setPeriodValue(parseInt(event.target.value, 10));
    if (parseInt(event.target.value, 10) === typePermissionTerm.INDETERMINATE) {
      setTimeout(() => {
        setFieldValue('validityPeriod', '');
        setFieldValue('permissionTermInitialDate', null);
        setFieldValue('permissionTermEndDate', null);
      }, 100);
    }
  };

  const handleRecurrenceChange = (event) => {
    setRecurrenceValue(parseInt(event.target.value, 10));
    if (parseInt(event.target.value, 10) === permissionRecurrence.UNIQUE) {
      setHasInvaldPeriodDate(false);
    }
  };

  const handleRecurrenceIntervalChange = (event) => {
    const expo = parseInt(event.target.value, 10) === 2 ? 30 : parseInt(event.target.value, 10) === 3 ? 365 : 1;
    setFieldValue('recurrenceInterval', parseInt(event.target.value, 10));
    setTimeout(() => {
      setHasInvaldPeriodDate((parseInt(recurrenceQuantity, 10) * expo) > differenceInDays(range.to, range.from) + 1);
    }, 100);
  };

  const handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }

    const newRange = DateUtils.addDayToRange(day, range);
    setRange(newRange);
  };

  const confirmDateRange = (setFieldValue) => {
    const expo = values.recurrenceInterval === 2 ? 30 : values.recurrenceInterval === 3 ? 365 : permissionRecurrenceInterval.DAYS;
    if (range.from && range.to) {
      setIsPeriodVisible(false);

      setTimeout(() => {
        setHasInvaldPeriodDate((parseInt(recurrenceQuantity, 10) * expo) > differenceInDays(range.to, range.from) + 1);
        setFieldValue('validityPeriod', `${format(range.from, 'dd/MM/yyyy')} a ${format(range.to, 'dd/MM/yyyy')}`);
        setFieldValue('permissionTermInitialDate', formatISO(setHours(range.from, 5)));
        setFieldValue('permissionTermEndDate', formatISO(range.to));
      }, 100);
    }
  };

  const _handleRecurrenceQuantity = (value) => {
    const expo = values.recurrenceInterval === 2 ? 30 : values.recurrenceInterval === 3 ? 365 : permissionRecurrenceInterval.DAYS;
    setRecurrenceQuantity(value);
    setHasInvaldPeriodDate((parseInt(value, 10) * expo) > differenceInDays(range.to, range.from) + 1);
  };

  const changeQuantityDistribution = (value) => {
    handleQuantityDistribution(value);
  };

  return (
    <>
      <Grid
        item
        lg={6}
        md={6}
      >
        <ActionWrapper>
          {((materialPermission && materialPermission.status) || materialId) && (
            <SwitchModeContainer>
              <Label>Permissão</Label>
              <SwitchWrapper>
                <SwitchLabel selected={!permissionActive}>Desativada</SwitchLabel>
                <Switch
                  color="primary"
                  size="small"
                  disabled={!isValid || hasInvaldPeriodDate || (values.agentList.length <= 0 || values.authorizedQuantity <= 0)}
                  checked={permissionActive}
                  onChange={() => { changePermissionActive(); }}
                />
                <SwitchLabel selected={permissionActive}>Ativa</SwitchLabel>
              </SwitchWrapper>
            </SwitchModeContainer>
          )}
          <Button
            size="large"
            color="secondary"
            variant="outlined"
            handleClick={() => { handleCancel(isNewPermission); }}
          >
            {isNewPermission ? 'Cancelar' : 'Excluir'}
          </Button>
          <Button
            size="large"
            color="secondary"
            variant="contained"
            handleClick={handleSubmit}
            disabled={(!isValid && permissionActive) || hasInvaldPeriodDate || ((values.agentList.length <= 0 || values.authorizedQuantity <= 0) && permissionActive)}
          >
            Salvar
          </Button>
        </ActionWrapper>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
      >
        <FormContainer>
          <ColumnWrapper>
            <LeftContent>
              <TitleContent
                disabled={!permissionActive}
              >
                Vigência da permissão
              </TitleContent>
              <FieldGroup>
                <RadioWrapper>
                  <Field
                    component={RadioGroup}
                    name="typePermissionTerm"
                  >
                    <FormControlLabelStyled
                      value={typePermissionTerm.INDETERMINATE}
                      control={<Radio checked={periodValue === typePermissionTerm.INDETERMINATE} disabled={isSubmitting} color="primary" />}
                      label="Indeterminado"
                      disabled={isSubmitting || !permissionActive}
                      onChange={handleTermChange}
                    />
                    <FormControlLabel
                      value={typePermissionTerm.DETERMINED}
                      control={<Radio checked={periodValue === typePermissionTerm.DETERMINED} disabled={isSubmitting} color="primary" />}
                      label="Determinado"
                      disabled={isSubmitting || !permissionActive}
                      onChange={handleTermChange}
                    />
                  </Field>
                </RadioWrapper>
                <DayPickerWrapper>
                  <DateFieldStyled
                    component={TextField}
                    name="validityPeriod"
                    type="text"
                    label="Período de vigência"
                    variant="outlined"
                    onClick={() => { setIsPeriodVisible(true); }}
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled={periodValue === typePermissionTerm.INDETERMINATE || !permissionActive}
                    error={hasInvaldPeriodDate || ('validityPeriod' in errors)}
                    required
                  />
                  {(isPeriodVisible && periodValue === typePermissionTerm.DETERMINED) && (
                    <DayPickerCard>
                      <DayPicker
                        numberOfMonths={2}
                        navbarElement={Navbar}
                        selectedDays={[range.from, { from: range.from, to: range.to }]}
                        modifiers={modifiers}
                        onDayClick={handleDayClick}
                        months={MONTHS}
                        weekdaysLong={WEEKDAYS_LONG}
                        weekdaysShort={WEEKDAYS_SHORT}
                        disabledDays={[
                          {
                            before: new Date(),
                          },
                        ]}
                      />
                      <ButtonsWrapper>
                        <Button
                          theme={Theme}
                          color="secondary"
                          size="medium"
                          handleClick={() => { setIsPeriodVisible(false); }}
                        >
                          Fechar
                        </Button>
                        <Button
                          theme={Theme}
                          color="secondary"
                          size="medium"
                          handleClick={() => { confirmDateRange(setFieldValue); }}
                        >
                          Confirmar
                        </Button>
                      </ButtonsWrapper>
                    </DayPickerCard>
                  )}
                </DayPickerWrapper>
              </FieldGroup>
            </LeftContent>
            <RightContent>
              <TitleContent
                disabled={!permissionActive}
              >
                Recorrência da permissão
              </TitleContent>
              <FieldGroup>
                <RadioWrapper>
                  <Field
                    component={RadioGroup}
                    name="permissionRecurrence"
                  >
                    <FormControlLabelStyled
                      value={permissionRecurrence.UNIQUE}
                      control={<Radio checked={recurrenceValue === permissionRecurrence.UNIQUE} disabled={isSubmitting} color="primary" />}
                      label="Única"
                      disabled={isSubmitting || !permissionActive}
                      onChange={handleRecurrenceChange}
                    />
                    <FormControlLabel
                      value={permissionRecurrence.RECURRENT}
                      control={<Radio checked={recurrenceValue === permissionRecurrence.RECURRENT} disabled={isSubmitting} color="primary" />}
                      label="Recorrente a cada"
                      disabled={isSubmitting || !permissionActive}
                      onChange={handleRecurrenceChange}
                    />
                  </Field>
                </RadioWrapper>
                <FieldRowWrapper>
                  <FieldSmallStyled
                    component={TextField}
                    name="recurrenceQuantity"
                    label="Qtde"
                    variant="outlined"
                    disabled={recurrenceValue === permissionRecurrence.UNIQUE || !permissionActive}
                    error={(hasInvaldPeriodDate || ('recurrenceQuantity' in errors)) && recurrenceValue !== permissionRecurrence.UNIQUE}
                    helperText={errors.recurrenceQuantity ? 'Digite a Qtde.' : ''}
                    onBlur={(e) => { _handleRecurrenceQuantity(e.target.value); }}
                    required
                  />
                  {materialPermission.status ? (
                    <>
                      {recurrenceIntervalValue && (
                        <StyledSelectField variant="outlined">
                          <InputLabel>Intervalo *</InputLabel>
                          <Select
                            label="Intervalo *"
                            defaultValue={recurrenceIntervalValue}
                            disabled={recurrenceValue === permissionRecurrence.UNIQUE || !permissionActive}
                            onChange={handleRecurrenceIntervalChange}
                            native
                            required
                          >
                            <option key="interval-days" value={permissionRecurrenceInterval.DAYS}>Dia(s)</option>
                            <option key="interval-months" value={permissionRecurrenceInterval.MONTHS}>Mês(es)</option>
                            <option key="interval-years" value={permissionRecurrenceInterval.YEARS}>Ano(s)</option>
                          </Select>
                        </StyledSelectField>
                      )}
                    </>
                  ) : (
                    <StyledSelectField variant="outlined">
                      <InputLabel>Intervalo *</InputLabel>
                      <Select
                        label="Intervalo *"
                        defaultValue={1}
                        disabled={recurrenceValue === permissionRecurrence.UNIQUE || !permissionActive}
                        onChange={handleRecurrenceIntervalChange}
                        native
                        required
                      >
                        <option key="interval-days" value={permissionRecurrenceInterval.DAYS}>Dia(s)</option>
                        <option key="interval-months" value={permissionRecurrenceInterval.MONTHS}>Mês(es)</option>
                        <option key="interval-years" value={permissionRecurrenceInterval.YEARS}>Ano(s)</option>
                      </Select>
                    </StyledSelectField>
                  )}
                </FieldRowWrapper>
              </FieldGroup>
            </RightContent>
          </ColumnWrapper>
          {materialPermission && (
            <RowWrapper>
              <TitleContent
                disabled={!permissionActive}
              >
                Comprometimento de estoque
              </TitleContent>
              <InfoRow>
                {materialPermission.quantityAlert && (
                  <InfoIcon
                    disabled={!permissionActive}
                  >
                    <Tooltip
                      title="Quantidade autorizada incompatível com quantidade estocada."
                      placement="top"
                      arrow
                    >
                      <Error />
                    </Tooltip>
                  </InfoIcon>
                )}
                <InfoBlock
                  disabled={!permissionActive}
                >
                  <InfoLabel>
                    <NumberFormat
                      value={materialPermission.totalStock ? materialPermission.totalStock : materialId ? availableQuantity : 0}
                      displayType="text"
                      decimalSeparator=","
                      thousandSeparator={true, '.'}
                    />
                    &nbsp;
                    unidades
                  </InfoLabel>
                  <InfoValue>Estoque disponível</InfoValue>
                </InfoBlock>
                <InfoBlock
                  disabled={!permissionActive}
                >
                  <InfoLabel>
                    <NumberFormat
                      value={totalAgents ? totalAgents : 0}
                      displayType="text"
                      decimalSeparator=","
                      thousandSeparator={true, '.'}
                    />
                  </InfoLabel>
                  <InfoValue>Representantes</InfoValue>
                </InfoBlock>
                <InfoBlock
                  disabled={!permissionActive}
                >
                  <InfoLabel>
                    <NumberFormat
                      value={agentsTotalAuthorized ? agentsTotalAuthorized : 0}
                      displayType="text"
                      decimalSeparator=","
                      thousandSeparator={true, '.'}
                    />
                    &nbsp;
                    unidades
                  </InfoLabel>
                  <InfoValue>Quantidade autorizada</InfoValue>
                </InfoBlock>
                <InfoBlock
                  disabled={!permissionActive}
                >
                  <InfoLabel>
                    <NumberFormat
                      value={agentsTotalBalance}
                      displayType="text"
                      decimalSeparator=","
                      thousandSeparator={true, '.'}
                    />
                    &nbsp;
                    unidades
                  </InfoLabel>
                  <InfoValue>Saldo</InfoValue>
                </InfoBlock>
                <ActionBlock>
                  <SwitchModeContainer>
                    <Label>Qtde por representante</Label>
                    <div>
                      <SwitchLabel selected={!isAutomaticQuantity && permissionActive}>Manual</SwitchLabel>
                      <Switch
                        color="primary"
                        size="small"
                        checked={isAutomaticQuantity}
                        disabled={!permissionActive}
                        onChange={() => { handleMaterialAutomatic(); }}
                      />
                      <SwitchLabel selected={isAutomaticQuantity && permissionActive}>Automático</SwitchLabel>
                    </div>
                  </SwitchModeContainer>
                  <StyledTextField
                    label="Quantidade"
                    name="quantityDistribution"
                    type="text"
                    variant="outlined"
                    size="small"
                    component={TextField}
                    disabled={!isAutomaticQuantity || !permissionActive}
                    error={hasErrorQuantity && (isAutomaticQuantity && permissionActive)}
                    onBlur={(e) => {
                      if (regex.test(e.target.value) && e.target.value !== '') {
                        changeQuantityDistribution(e.target.value, values);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        if (regex.test(e.target.value) && e.target.value !== '') {
                          changeQuantityDistribution(e.target.value, values);
                        }
                      }
                    }}
                    required
                  />
                </ActionBlock>
              </InfoRow>
            </RowWrapper>
          )}
        </FormContainer>
      </Grid>
    </>
  );
};

PermissionForm.propTypes = {
  materialPermission: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};

PermissionForm.defaultProps = {
  materialPermission: {},
  isSubmitting: false,
};

export default PermissionForm;
