import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Container } from '@material-ui/core/';

import {
  Home,
  HowToReg,
  ShoppingCart,
  PersonPinCircle,
} from '@material-ui/icons';

import {
  LoadMask,
  Header,
  Footer,
} from '../../../components';
import { Colors, USER_ROLES } from '../../../constants';
import { RoutePaths, StockPermissionRoutePaths } from '../../../constants/RoutePaths';
import { mediaHelper } from '../../../utils';

const HomeWrapper = styled.div`
  width: 100%;
  background-color: ${Colors.SECONDARY_MEDIUM};

`;

const Content = styled(Container)``;

const HomeButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: calc(100vh - 128px);
  width: 100%;
  padding: 60px 0;
`;

const HomeButtonsRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaHelper.lg`
    flex-direction: row;
  `}
`;

const HomeButton = styled(Link)`
  position: relative;
  display: flex;
  min-width: calc(100% - 32px);
  max-width: calc(100% - 32px);
  max-height: 168px;
  padding: 16px;
  border-radius: 16px;
  color: ${Colors.SECONDARY_MEDIUM};
  background-color: white;
  cursor: pointer;
  overflow: hidden;
  transition: color .6s ease-out, box-shadow .6s ease-out, transform .6s ease-out;
  text-decoration: none;
  outline: none;
  transform: scale(1);
  box-shadow: rgba(0, 0, 0, 0) 0 0 0;
  margin-bottom: 24px;

  svg {
    font-size: 40px;
    transition: color .6s ease-out;
    margin-right: 16px;

    ${mediaHelper.lg`
      font-size: 56px;
      margin-right: 0;
    `}
  }

  &:hover {
    color: ${Colors.PRIMARY_BLUE};
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    transform: scale(1.02);
    &:before {
      transform: translate(0, 0);
      opacity: 1;
    }
    svg {
      color: ${Colors.PRIMARY_BLUE};
    }
  }

  ${mediaHelper.lg`
    min-width: calc(33% - 94px);
    max-width: calc(33% - 94px);
    min-height: 280px;
    max-height: 280px;
    padding: 40px;
    margin-right: 24px;
  `}
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  z-index: 2;

  ${mediaHelper.lg`
    flex-direction: column;
  `}

`;

const TitleText = styled.span`
  font-size: 24px;

  ${mediaHelper.lg`
    font-size: 28px;
  `}

  ${mediaHelper.xl`
    font-size: 30px;
    margin-top: 32px;
  `}
`;

const DescriptionText = styled.span`
  font-size: 16px;
  color: ${Colors.GRAY_DARKER};
  margin-top: 8px;
  transition: color .3s ease-in-out;

  ${mediaHelper.lg`
    font-size: 22px;
  `}

  ${mediaHelper.xl`
    font-size: 24px;
  `}
`;

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const BreakLineStyled = styled.br`
  display: block;
  ${mediaHelper.md`
    display: none;
  `}
  ${mediaHelper.lg`
    display: block;
  `}
`;

const HomeLayout = ({
  userData,
  loading,
  companyDialogList,
  handleOpenCompanyDialog,
  handleOutputCompanyDialog,
  loadingDialog,
}) => (
  <>
    <Header
      hideMenu
      userData={userData}
      companyDialogList={companyDialogList}
      loadingDialog={loadingDialog}
      handleOpenCompanyDialog={handleOpenCompanyDialog}
      handleOutputCompanyDialog={handleOutputCompanyDialog}
    />
    <HomeWrapper>
      <Content>
        <HomeButtonsWrapper>
          <HomeButtonsRow>
            {userData.companyName && (
              <HomeButton
                to={RoutePaths.STOCK}
                title="Controlar Estoque"
              >
                <ButtonWrapper>
                  <Home />
                  <TextBlock>
                    <TitleText>
                      Controlar&nbsp;
                      <strong>
                        Estoque
                      </strong>
                    </TitleText>
                    <DescriptionText>
                      Visualize detalhes e acompanhe o estoque de seus materiais
                    </DescriptionText>
                  </TextBlock>
                </ButtonWrapper>
              </HomeButton>
            )}
            <HomeButton
              to={RoutePaths.ORDER}
              title="Fazer Pedidos"
            >
              <ButtonWrapper>
                <ShoppingCart />
                <TextBlock>
                  <TitleText>
                    Fazer&nbsp;
                    <strong>
                      Pedidos
                    </strong>
                  </TitleText>
                  <DescriptionText>
                    Monte e acompanhe seus pedidos até a chegada nos seus representantes
                  </DescriptionText>
                </TextBlock>
              </ButtonWrapper>
            </HomeButton>
            {userData.userRole === USER_ROLES.COMPANY && (
              <HomeButton
                to={StockPermissionRoutePaths.PERMISSIONS}
                title="Permissões de Estoque"
              >
                <ButtonWrapper>
                  <HowToReg />
                  <TextBlock>
                    <TitleText>
                      Permissões de&nbsp;
                      <BreakLineStyled />
                      <strong>
                        Estoque
                      </strong>
                    </TitleText>
                    <DescriptionText>
                      Configure as permissões do seu estoque por representante
                    </DescriptionText>
                  </TextBlock>
                </ButtonWrapper>
              </HomeButton>
            )}
          </HomeButtonsRow>
          <HomeButtonsRow>
            {userData.userRole === USER_ROLES.COMPANY && (
              <HomeButton
                to={RoutePaths.AGENT}
                title="Gestão de representante"
              >
                <ButtonWrapper>
                  <PersonPinCircle />
                  <TextBlock>
                    <TitleText>
                      Gestão de&nbsp;
                      <BreakLineStyled />
                      <strong>
                        Representantes
                      </strong>
                    </TitleText>
                    <DescriptionText>
                      Gerencie seus representantes de maneira independente
                    </DescriptionText>
                  </TextBlock>
                </ButtonWrapper>
              </HomeButton>
            )}
          </HomeButtonsRow>
        </HomeButtonsWrapper>
      </Content>
    </HomeWrapper>
    <Footer />
    {loading ? <LoadMask /> : null}
  </>
);

HomeLayout.propTypes = {
  userData: PropTypes.object,
  loading: PropTypes.bool,
  companyDialogList: PropTypes.object,
  loadingDialog: PropTypes.bool,
  handleOpenCompanyDialog: PropTypes.func,
  handleOutputCompanyDialog: PropTypes.func,
};

HomeLayout.defaultProps = {
  userData: {},
  loading: false,
  companyDialogList: {},
  loadingDialog: false,
  handleOpenCompanyDialog: () => { },
  handleOutputCompanyDialog: () => { },
};

export default HomeLayout;
