import actionTypes from './actions';

// Consts

export const ToastStyles = {
  DEFAULT: 0,
  SUCCESS: 1,
  DANGER: 2,
};

// Reducer

const initialState = {
  active: false,
  type: ToastStyles.DEFAULT,
  message: '',
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SHOW:
      return {
        active: true,
        type: action.style,
        message: action.message,
      };
    case actionTypes.DISMISS:
      return {
        ...state,
        active: false,
      };
    case actionTypes.CLEAR:
      return {
        ...state,
        active: false,
        message: '',
      };

    default:
      return state;
  }
}

export default reducer;
