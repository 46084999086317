// HTTP custom Error

export class BaseHttpError extends Error {
  constructor(statusCode = 0, message, response) {
    super(message);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, BaseHttpError);
    }

    this.statusCode = statusCode;
    this.response = response;
  }
}

export class ServerError extends BaseHttpError {
  constructor(...params) {
    super(...params);
    this.name = ServerError.getName();
  }

  static getName() {
    return 'ServerError';
  }
}

export class ClientError extends BaseHttpError {
  constructor(...params) {
    super(...params);
    this.name = ClientError.getName();
  }

  static getName() {
    return 'ClientError';
  }
}
