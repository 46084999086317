import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import {
  showErrorToast,
} from '../../components';
import { RoutePaths } from '../../constants/RoutePaths';
import { AuthService } from '../../services';
import { AuthActions } from '../../redux';

import LoginLayout from './login-layout';

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    const { userData, history } = this.props;

    if (userData) {
      history.push(RoutePaths.HOME);
    }
  }

  componentWillUnmount() {
    this.setState = () => { };
  }

  _getValidations = () => Yup.object().shape({
    email: Yup.string().email('Digite seu e-mail corretamente').required('Digite seu e-mail'),
    password: Yup.string().required('Digite sua senha'),
  });

  _login = async (values) => {
    const { dispatch, history } = this.props;

    if (this.state.loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    try {
      const response = await AuthService.login(values);

      dispatch(AuthActions.setToken(response.token));

      const { data } = await AuthService.authorization();

      dispatch(AuthActions.setUserData({
        id: data.id,
        username: data.name,
        companyName: data.companyName,
        userRole: data.role,
        userPermissions: data.userPermissions,
      }));

      this.setState(
        {
          loading: false,
        },
        () => {
          history.push({
            pathname: RoutePaths.HOME,
            state: { fromLogin: true },
          });
        },
      );
    } catch (error) {
      this.setState(
        {
          loading: false,
        },
        () => {
          console.error(error.message);
          dispatch(showErrorToast(error.message));
        },
      );
    }
  };

  render() {
    const { loading } = this.state;
    const { isLogged } = this.props;

    return (
      <LoginLayout
        loading={loading}
        isLogged={isLogged}
        handleValidations={this._getValidations}
        handleLogin={this._login}
      />
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  userData: auth.userData,
  isLogged: auth.sessionToken ? true : false,
});

export default connect(mapStateToProps)(LoginPage);
