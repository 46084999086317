import numeral from 'numeral';

class Mask {
  /**
   * Mask a string with only letters
   * @param {*} text Ex: 89d8g8ffgdf9f575jhj4h6gfhh8
   * @return {string} Ex: dgffgdffjhjhgfhh
   */
  static letters(text) {
    return (text || '').replace(
      /[^a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]/g,
      '',
    );
  }

  /**
   * Mask a string with only letters
   * @param {*} text Ex: abcdefghijklmnopqrstuvxzwy
   * @return {string} Ex: José da Silva
   */
  static fullName(text) {
    return (text || '').replace(/\d/g, '');
  }

  /**
   * Mask a string with only numbers
   * @param {*} text Ex: 89d8g8ffgdf9f575jhj4h6gfhh8
   * @return {string} Ex: 89889575468
   */
  static numbers(text) {
    return (text ? text.toString() : '').replace(/[^0-9]/g, '');
  }

  /**
   * Green card mask
   * @param {string} text Ex: 000000000
   * @return {string} Ex: 000-000-000
   */
  static greenCardNumber(text) {
    const gcMask = [
      /[0-9]/g,
      /[0-9]/g,
      /[0-9]/g,
      '-',
      /[0-9]/g,
      /[0-9]/g,
      /[0-9]/g,
      '-',
      /[0-9]/g,
      /[0-9]/g,
      /[0-9]/g,
    ];

    return Mask.custom(Mask.numbers(text), gcMask);
  }

  /**
   * Hexadecimal mask
   * @param {string} text Ex: 000000
   * @return {string} Ex: 000000
   */
  static hexadecimal(text = '') {
    const gcMask = [
      /[0-9A-F]/g,
      /[0-9A-F]/g,
      /[0-9A-F]/g,
      /[0-9A-F]/g,
      /[0-9A-F]/g,
      /[0-9A-F]/g,
    ];

    return Mask.custom(text.toUpperCase(), gcMask);
  }

  /**
   * Abreviation mask
   * @param {string} text Ex: 000000
   * @return {string} Ex: 000000
   */
  static abreviation(text = '') {
    const gcMask = [/[A-Z]/g, /[A-Z]/g];

    return Mask.custom(text.toUpperCase(), gcMask);
  }

  /**
   * mask a string with only letters and numbers
   * @param {*} text
   * @return {string}
   */
  static alpha(text) {
    return (text || '').replace(
      /[^0-9a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]/g,
      '',
    );
  }

  /**
   * Mask a string based on RG format
   * @param {*} text Ex: 000000000
   * @return {string} Ex: xx.xxx.xxx-x
   */
  static rg(text) {
    const rgMask = [
      /[0-9a-zA-Z]/g,
      /[0-9a-zA-Z]/g,
      '.',
      /[0-9a-zA-Z]/g,
      /[0-9a-zA-Z]/g,
      /[0-9a-zA-Z]/g,
      '.',
      /[0-9a-zA-Z]/g,
      /[0-9a-zA-Z]/g,
      /[0-9a-zA-Z]/g,
      '-',
      /[0-9a-zA-Z]/g,
      /[0-9a-zA-Z]/g,
      /[0-9a-zA-Z]/g,
    ];

    return Mask.custom(Mask.alpha(text), rgMask);
  }

  /**
   * Mask a string based on RNE format
   * @param {*} text Ex: xxxxxxxx
   * @return {string} Ex: xxxxxxx-x
   */
  static rne(text) {
    const rgMask = [
      /[0-9a-zA-Z]/g,
      /[0-9a-zA-Z]/g,
      /[0-9a-zA-Z]/g,
      /[0-9a-zA-Z]/g,
      /[0-9a-zA-Z]/g,
      /[0-9a-zA-Z]/g,
      /[0-9a-zA-Z]/g,
      '-',
      /[0-9a-zA-Z]/g,
    ];

    return Mask.custom(Mask.alpha(text), rgMask);
  }

  /**
   * Mask a string based on DNI format
   * @param {*} text Ex: XXXXXXXXXXX
   * @return {string} Ex: XXXXXXXXXX-X
   */
  static dni(text) {
    const rgMask = [
      /[0-9a-zA-Z]/g,
      /[0-9a-zA-Z]/g,
      /[0-9a-zA-Z]/g,
      /[0-9a-zA-Z]/g,
      /[0-9a-zA-Z]/g,
      /[0-9a-zA-Z]/g,
      /[0-9a-zA-Z]/g,
      /[0-9a-zA-Z]/g,
      /[0-9a-zA-Z]/g,
      /[0-9a-zA-Z]/g,
      '-',
      /[0-9a-zA-Z]/g,
    ];

    return Mask.custom(Mask.alpha(text), rgMask);
  }

  /**
   * Mask a string based on CPF format
   * @param {*} text Ex: 00000000000
   * @return {string} Ex: 000.000.000-00
   */
  static cpf(text) {
    const cpfMask = [
      /[0-9]/g,
      /[0-9]/g,
      /[0-9]/g,
      '.',
      /[0-9]/g,
      /[0-9]/g,
      /[0-9]/g,
      '.',
      /[0-9]/g,
      /[0-9]/g,
      /[0-9]/g,
      '-',
      /[0-9]/g,
      /[0-9]/g,
    ];

    return Mask.custom(Mask.numbers(text), cpfMask);
  }

  /**
   * Default credit card
   * @param {*} text Ex: 0000000000000000
   * @return {string} Ex: 0000 0000 0000 0000
   */
  static creditCard(text, isAmex) {
    let maskPattern = [];

    if (isAmex) {
      maskPattern = [
        /[0-9]/g,
        /[0-9]/g,
        /[0-9]/g,
        /[0-9]/g,
        ' ',
        /[0-9]/g,
        /[0-9]/g,
        /[0-9]/g,
        /[0-9]/g,
        /[0-9]/g,
        /[0-9]/g,
        ' ',
        /[0-9]/g,
        /[0-9]/g,
        /[0-9]/g,
        /[0-9]/g,
        /[0-9]/g,
      ];
    } else {
      maskPattern = [
        /[0-9]/g,
        /[0-9]/g,
        /[0-9]/g,
        /[0-9]/g,
        ' ',
        /[0-9]/g,
        /[0-9]/g,
        /[0-9]/g,
        /[0-9]/g,
        ' ',
        /[0-9]/g,
        /[0-9]/g,
        /[0-9]/g,
        /[0-9]/g,
        ' ',
        /[0-9]/g,
        /[0-9]/g,
        /[0-9]/g,
        /[0-9]/g,
      ];
    }

    return Mask.custom(Mask.numbers(text), maskPattern);
  }

  /**
   * Default credit card
   * @param {*} text Ex: 000000000000000
   * @return {string} Ex: 0000 000000 00000
   */
  static amexCreditCard(text) {
    const cpfMask = [
      /[0-9]/g,
      /[0-9]/g,
      /[0-9]/g,
      /[0-9]/g,
      ' ',
      /[0-9]/g,
      /[0-9]/g,
      /[0-9]/g,
      /[0-9]/g,
      /[0-9]/g,
      /[0-9]/g,
      ' ',
      /[0-9]/g,
      /[0-9]/g,
      /[0-9]/g,
      /[0-9]/g,
      /[0-9]/g,
    ];

    return Mask.custom(Mask.numbers(text), cpfMask);
  }

  /**
   * Mask a string based on CNPJ format
   * @param {*} text Ex: 00000000000000
   * @return {string} Ex: 00.000.000/0000-00
   */
  static cnpj(text) {
    const mask = [
      /[0-9]/,
      /[0-9]/,
      '.',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      '.',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      '/',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      '-',
      /[0-9]/,
      /[0-9]/,
    ];

    return Mask.custom(Mask.numbers(text), mask);
  }

  /**
   * Mask a string based on rfc format (pf or pj)
   * @param {*} text Ex: 00000000000000000
   * @return {string} Ex: 000000000000
   */
  static rfc(text) {
    const mask = [
      /[A-Z0-9]/,
      /[A-Z0-9]/,
      /[A-Z0-9]/,
      /[A-Z0-9]/,
      /[A-Z0-9]/,
      /[A-Z0-9]/,
      /[A-Z0-9]/,
      /[A-Z0-9]/,
      /[A-Z0-9]/,
      /[A-Z0-9]/,
      /[A-Z0-9]/,
      /[A-Z0-9]/,
    ];

    return Mask.custom(Mask.alpha((text || '').toString().toUpperCase()), mask);
  }

  /**
   * Mask a string based on CPF format if it has 11 characters else CNPJ
   * @param {*} text Ex: 00000000000 | 00000000000000
   * @return {string} Ex: 000.000.000-00 | 00.000.000/0000-00
   */
  static cpfCnpj(text) {
    if (text && text.length <= 11) {
      return Mask.cpf(text);
    }

    return Mask.cnpj(text);
  }

  /**
   * Mask a string based on a date format
   * @param {*} text Ex: 00000000
   * @return {string} Ex: 00/00/0000
   */
  static date(text) {
    const mask = [
      /[0-9]/,
      /[0-9]/,
      '/',
      /[0-9]/,
      /[0-9]/,
      '/',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
    ];

    return Mask.custom(Mask.numbers(text), mask);
  }

  /**
   * Mask a string based on a date format
   * @param {*} text Ex: 0000
   * @return {string} Ex: 00/00/
   */
  static dateDayMonth(text) {
    const mask = [/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/];

    return Mask.custom(Mask.numbers(text), mask);
  }

  /**
   * Mask a string based on a cvv format
   * @param {*} text Ex: 000
   * @return {string} Ex: 000
   */
  static cvv(text) {
    const mask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

    return Mask.custom(Mask.numbers(text), mask);
  }

  /**
   * Mask a string based on a Telephone format
   * @param {*} text Ex: 00000000000
   * @return {string} Ex: (00) 00000-0000
   */
  static tel(value, countryCode = null, autoHideDDD = false) {
    const mask = [];
    const text = Mask.numbers(value);

    mask.push(
      ...[
        ...(autoHideDDD && text.length <= 9
          ? []
          : ['(', /[0-9]/, /[0-9]/, ')', ' ']),
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
      ],
    );

    if (text && text.length === 11) {
      mask.push(/[0-9]/);
    }

    mask.push(...['-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]);

    return Mask.custom(text, mask);
  }

  /**
   * Mask a string based on zipCode format
   * @param {*} text Ex: 00000000
   * @return {string} Ex: 00000-000
   */
  static zipCode(text, lang = null) {
    return Mask.cep(text);
  }

  /**
   * Mask a string based on CEP format
   * @param {*} text Ex: 00000000
   * @return {string} Ex: 00000-000
   */
  static cep(text) {
    const mask = [
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      '-',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
    ];

    return Mask.custom(Mask.numbers(text), mask);
  }

  /**
   * Mask a string to a decimal number
   * @param {*} text Ex: 1.00
   * @return {string} Ex: 1.00
   */
  static decimalNumber(text) {
    const decimalDelimiter = numeral.localeData().delimiters.decimal;
    let clearText = (text ? text.toString() : '').replace(
      /\./g,
      decimalDelimiter
    );

    if (decimalDelimiter === clearText) return '';

    if (
      clearText.indexOf(decimalDelimiter) !==
      clearText.lastIndexOf(decimalDelimiter)
    ) {
      return text.substring(0, text.length - 1);
    }

    return (clearText || '').replace(
      new RegExp(`[^0-9\\${decimalDelimiter}]`, 'g'),
      ''
    );
  }

  /**
   * Mask a string to a number with positive or negative sign
   * @param {*} text Ex: +1 || -1
   * @return {string} Ex: +1 || -1
   */
  static numberWithSign(text) {
    return Mask.withSign(text, Mask.numbers);
  }

  /**
   * Mask a string to a decimal number with positive or negative sign
   * @param {*} text Ex: +1.00 || -1.00
   * @return {string} Ex: +1.00 || -1.00
   */
  static decimalNumberWithSign(text) {
    return Mask.withSign(text, Mask.decimalNumber);
  }

  /**
   * Mask a string to a mask with positive or negative sign
   *
   * @static
   * @param {*} text Ex: +1.00 || -1.00
   * @param {*} mask
   * @returns Ex: +1.00 || -1.00
   */
  static withSign(text, mask) {
    if (!text) {
      return '';
    }

    const NOT_SIGN_REGEX = new RegExp(`^[^+-]`);

    if (text.match(NOT_SIGN_REGEX)) {
      return mask(text);
    }

    const signValue = (text || '').charAt(0);
    const absoluteValue = text.substring(1);

    return `${signValue}${mask(absoluteValue)}`;
  }

  /**
   * Mask a string to a decimal number with percent sign
   *
   * @param {*} value Ex: 1.00
   * @return {string} Ex: 1.00%
   */
  static decimalNumberWithPercent(value, hasValueSign = false) {
    const PERCENT_SIGN = '%';
    const decimalValue = hasValueSign
      ? Mask.decimalNumberWithSign(value)
      : Mask.decimalNumber(value);

    if (decimalValue && !decimalValue.includes(PERCENT_SIGN)) {
      return `${decimalValue}${PERCENT_SIGN}`;
    }

    return decimalValue;
  }

  /**
   * Mask a string based on current currency format
   * @param {*} text Ex: 100
   * @return {string} Ex: R$ 100.00
   */
  static currency({ value, lang, currency, style, useGrouping, countryCode}) {
    if (!value && value !== 0) {
      return value;
    }

    const INITIAL_VALUE = '0.00';
    const ITEMS_TO_REMOVE = /[^\d-]/g;

    if (value) {
      if (value.length === 1) {
        value = `${INITIAL_VALUE}${value}`;
      }

      if (typeof value === 'number') {
        value = parseFloat(value).toFixed(2).toString();
      }
      value = value.replace(ITEMS_TO_REMOVE, '');
      value = `${value.slice(0, -2)}.${value.slice(-2)}`;
    } else {
      value = INITIAL_VALUE;
    }

    const formatter = new Intl.NumberFormat(lang, {
      style,
      currency,
      minimumFractionDigits: 2,
      useGrouping,
    });

    const parsed = parseFloat(value);
    const formattedParts = formatter.formatToParts(
      isNaN(parsed) ? parseFloat(INITIAL_VALUE) : parsed,
    );
    const formatted = formattedParts.reduce((acc, cur, index) => {
      if (cur.type === 'currency') return `${cur.value} ${acc}`;
      return `${acc}${cur.value}`;
    }, '');

    return formatted.trim();
  }

  /**
   * mask a string based on a string pattern
   * # = lowercase, uppercase letters and numbers (0-9)
   * 9 = numbers (0-9)
   * 0 = numbers (0-9)
   * a = lowercase letters
   * A = uppercase letters
   * L = lowercase and uppercase letters
   * @param {*} text - Cleaned string
   * @param {*} stringPattern - a string pattern
   * @param {Function} resetFn - The function to remove the mask from the previous value
   * @return {string}
   */
  static pattern(text, stringPattern, resetFn) {
    const patterns = {
      9: '[0-9]',
      0: '[0-9]',
      a: '[a-z]',
      A: '[A-Z]',
      s: '[A-Za-z]',
      S: '[A-Za-z]',
      '*': '[A-Za-z0-9]',
      '#': '[A-Za-z0-9]',
      d: '[^a-zàèìòùáéíóúýâêîôûãñõäëïöüÿçßøåæœ]',
      D: '[^A-ZÀÈÌÒÙÁÉÍÓÚÝÂÊÎÔÛÃÑÕÄËÏÖÜŸÇßØÅÆœ]',
      F:
        '[^a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]',
    };

    const mask = stringPattern.split('').map((letter) => {
      if (Object.keys(patterns).includes(letter)) {
        return new RegExp(patterns[letter], 'g');
      }

      return letter;
    });

    if (typeof resetFn === 'function') {
      return Mask.custom(resetFn(text), mask);
    }

    return Mask.custom(text, mask);
  }

  /**
   * Mask a string based on a regex pattern and strings
   * @param {string} text cleaned string to be masked
   * @param {string[] | RegExp[]} mask string or regex array to replace the text
   * @return {string}
   */
  static custom = (text, mask) => {
    const maskedText = [];
    let lastText = 0;
    let lastMask = 0;

    if (text) {
      while (
        lastText < text.length
        || (text.length > mask.length && lastMask < mask.length)
      ) {
        if (typeof mask[lastMask] === 'string') {
          maskedText.push(mask[lastMask]);

          lastMask += 1;
        } else if (
          typeof text[lastText] === 'string'
          && mask[lastMask] instanceof RegExp
          && mask[lastMask].test(text[lastText])
        ) {
          maskedText.push(text[lastText]);

          lastText += 1;
          lastMask += 1;
        } else {
          lastText += 1;
          lastMask += 1;
        }
      }
    }

    return maskedText.join('');
  };
}

export default Mask;
