import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';

import TextField from '@material-ui/core/TextField';
import {
  Table,
} from '../../../../../../components/Table/Table';
import { Text, EmptyStateWithAction } from '../../../../../../components';
import { Colors } from '../../../../../../constants';
import { columnLabel, columnType } from '../../../../../../constants/stock';
import FloatBatchActionsMenu from '../../../../../../components/DataTable/FloatBatchActionsMenu';
import TouchableText from '../../../../../../components/TouchableText';
import FormValidationContext from '../../../../../../contexts/stock/formValidationContext';

const StyledEmptyStateWithAction = styled(EmptyStateWithAction)`
  padding: 40px 16px;
  border-bottom: 1px solid ${Colors.GRAY_LIGHTER};
  flex-direction: column;
`;

const TableContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 54px;
`;

const TextFieldStyled = styled(TextField)`
  max-width: 160px;
  margin-right: 16px;

  ${({ disabled }) => disabled && `
    color: ${Colors.GRAY};
  `}
`;

const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 4px 0;

  ${({ disabled }) => disabled && `
    opacity: .5;
  `}
`;

const Tag = styled.div`
  border: 1px solid ${Colors.GRAY};
  border-radius: 16px;
  padding: 4px 8px;
  color: ${Colors.GRAY_DARKER};
  font-size: 14px;
  margin: 4px 4px 4px 0;
`;

const TextStyled = styled(Text)`
  ${({ disabled }) => disabled && `
    color: ${Colors.GRAY};
  `}
`;

const NumberFormatStyled = styled(NumberFormat)`
  ${({ disabled }) => disabled && `
    color: ${Colors.GRAY};
  `}
`;

const StyledTouchableText = styled(TouchableText)`
  color: #FFF;
  font-size: 12px;
  margin-left: 24px; 
`;

const AgentTable = ({
  userData,
  data,
  loading,
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
  onChangeItemsPerPage,
  dataOrder,
  pairingActive,
  multiselect,
  slideDataActive,
  handleRowCheck,
  checkOrderNumber,
  onPairOrder,
  onRemovePermission,
  handleQtdChange,
  materialQtyAutomatic,
  handleOpenQuantityDialog,
  disabled,
  onChangeDataOrder,
  checkAllControl,
  hasErrorQuantity,
  handleAgentRemove,
  ...props
}) => {
  const [tableData, setTableData] = useState([]);
  const [indexField, setIndexField] = useState('');
  const [hasErrorField, setHasErrorField] = useContext(FormValidationContext);
  const regex = new RegExp(/^[0-9_]*$/i);

  useEffect(() => {
    setTableData(data);
  }, [
    data,
  ]);

  const _renderBasicTextCell = (value) => (
    <TextStyled
      disabled={disabled}
    >
      {value || '--'}
    </TextStyled>
  );

  const _renderFieldCell = (value, list, index) => (
    <TextFieldStyled
      key={`quantityMaterialByAgent-${list[index].id}`}
      name={`quantityMaterialByAgent-${list[index].id}`}
      label="Quantidade"
      variant="outlined"
      size="small"
      defaultValue={value}
      disabled={materialQtyAutomatic || disabled}
      error={(!hasErrorField || hasErrorQuantity) && index === indexField}
      helperText={!hasErrorField && index === indexField && 'Digite a Qtde.'}
      onChange={(e) => {
        const newData = tableData.map((item, index) => index === indexField ? ({
          ...item,
          [columnType.QUANTITY]: e.target.value,
        }) : item);
        setHasErrorField(tableData.length > 0 && regex.test(e.target.value));
        setTableData(newData);
        setIndexField(index);
      }}
      onBlur={(e) => {
        if (regex.test(e.target.value) && e.target.value !== '') {
          handleQtdChange(parseInt(e.target.value, 10), list[index].userId);
          setHasErrorField(true);
        } else {
          setHasErrorField(false);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          if (regex.test(e.target.value) && e.target.value !== '') {
            handleQtdChange(parseInt(e.target.value, 10), list[index].userId);
          }
        }
      }}
    />
  );

  const _renderNumberCell = (value) => (
    <NumberFormatStyled
      value={value < 0 ? 0 : value}
      displayType="text"
      decimalSeparator=","
      thousandSeparator={true, '.'}
      disabled={disabled}
    />
  );

  const columnConfig = {
    [columnType.AGENT]: {
      order: 1,
      noSorting: true,
    },
    [columnType.CITY]: {
      order: 2,
      noSorting: true,
    },
    [columnType.TAGS]: {
      order: 3,
      noSorting: true,
    },
    [columnType.QUANTITY]: {
      order: 4,
      noSorting: true,
    },
    [columnType.CURRENT_BALANCE]: {
      order: 5,
    },
  };

  const components = {
    [columnType.AGENT]: _renderBasicTextCell,
    [columnType.CITY]: _renderBasicTextCell,
    [columnType.TAGS]: (tags) => (
      <TagWrapper
        disabled={disabled}
      >
        {tags.map((tag) => (
          <Tag key={`${props.name}-${tag.id}`}>{tag.name}</Tag>
        ))}
      </TagWrapper>
    ),
    [columnType.QUANTITY]: _renderFieldCell,
    [columnType.CURRENT_BALANCE]: _renderNumberCell,
  };

  return (
    <TableContainer>
      <Table
        name="agent-table"
        isLoading={loading}
        footerText="Itens por página: "
        multiselect
        data={data}
        handleHeaderTitle={(key) => columnLabel[key]}
        columnConfig={columnConfig}
        components={components}
        paginationProps={{
          currentPage,
          totalItems,
          itemsPerPage,
          onPageChange,
        }}
        onChangeItemsPerPage={onChangeItemsPerPage}
        dataOrder={dataOrder}
        onOrderColumn={onChangeDataOrder}
        renderEmptyState={() => (
          <StyledEmptyStateWithAction
            alignCenter
            emptyStateText="Nenhum representante pra exibir."
          />
        )}
        disabled={disabled}
        renderOutside={(selectedRows, setSelected) => (
          <FloatBatchActionsMenu
            show={!!selectedRows.length}
            selectedLabel={selectedRows.length > 1 ? 'representantes selecionados' : 'representante selecionado'}
            selectedItems={selectedRows}
            onClose={() => {
              setSelected([]);
            }}
          >
            {!materialQtyAutomatic && (
              <StyledTouchableText onClick={() => { handleOpenQuantityDialog(selectedRows); }}>
                Editar quantidade
              </StyledTouchableText>
            )}
            <StyledTouchableText
              onClick={() => {
                handleAgentRemove(selectedRows);
                setSelected([]);
              }}
            >
              Excluir da lista
            </StyledTouchableText>
          </FloatBatchActionsMenu>
        )}
      />
    </TableContainer>
  );
};

AgentTable.propTypes = {
  currentPage: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  onChangeItemsPerPage: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  multiselect: PropTypes.bool,
  handleQtdChange: PropTypes.func,
  onChangeDataOrder: PropTypes.func,
};

AgentTable.defaultProps = {
  loading: false,
  multiselect: false,
  handleQtdChange: () => {},
  onChangeDataOrder: () => {},
};

export default AgentTable;
