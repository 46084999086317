import {
  availabilityType,
} from './filters';

export const DueStatus = {
  VALID: 1,
  WARNING: 2,
  EXPIRED: 3,
};

export const Availability = {
  [availabilityType.HAS_ACTIVE_ORDER]: 1,
  [availabilityType.STOCK_FREE]: 2,
};

export const orderByFields = {
  QUANTITY: 'quantity',
  DUE_DATE: 'dueDate',
  NAME: 'name',
};

export const orderByValues = {
  DESC: 'DESC',
  ASC: 'ASC',
};

export const statusPermission = {
  ACTIVE: 1,
  INACTIVE: 2,
};

export const typePermissionTerm = {
  INDETERMINATE: 1,
  DETERMINED: 2,
};

export const permissionRecurrence = {
  UNIQUE: 1,
  RECURRENT: 2,
};

export const permissionRecurrenceInterval = {
  DAYS: 1,
  MONTHS: 2,
  YEARS: 3,
};

export const typeQuantityDistribution = {
  MANUAL: 1,
  AUTOMATIC: 2,
};
