import React, { useRef } from 'react';
import styled from 'styled-components';

import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import {
  InitialPage,
  Button,
} from '../../../components';

import { Colors } from '../../../constants/Colors';

const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;

  svg {
    margin-left: 8px;
  }
  
  button + button {
    margin-left: 15px;
  }
`;

const TitleContainer = styled.span`
  font-size: 18px;
  color: ${Colors.BLUE_DARKEST};
  display: block;
  white-space: nowrap;
  font-weight: bold;
`;

const SubtitleContainer = styled.span`
  font-size: 13px;
  color: ${Colors.GRAY};
  display: block;
`;

const ForgotPasswordLayout = ({ ...props }) => {
  const {
    handleValidations,
    handleForgotPassword,
    handleBack,
    loading,
    success,
  } = props;

  const submitButton = useRef(null);

  window.addEventListener('keydown', (e) => {
    if (e.key === 'Enter') {
      if (submitButton.current) {
        submitButton.current.click();
      }
    }
  });

  return (
    <InitialPage
      loading={loading}
    >

      <TitleContainer>
        Recuperação de senha
      </TitleContainer>
      <SubtitleContainer>
        { success ? 'Pronto! Enviamos um link de recuperação no seu e-mail. Por meio dele você criará uma nova senha para acessar.'
          : 'Insira seu e-mail abaixo para receber um link de recuperação da sua senha.' }
      </SubtitleContainer>

      { !success ? (
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={handleValidations}
          enableReinitialize
          onSubmit={(values) => handleForgotPassword(values)}
        >
          {({ handleSubmit, isValid }) => (
            <>
              <Field
                component={TextField}
                name="email"
                type="email"
                label="E-mail"
                variant="outlined"
                required
              />

              <WrapperButton>
                <Button
                  size="small"
                  color="secondary"
                  variant="outlined"
                  handleClick={handleBack}
                >
                  Voltar
                </Button>
                <Button
                  size="medium"
                  variant="contained"
                  disabled={!isValid}
                  handleClick={handleSubmit}
                  ref={submitButton}
                >
                  Confirmar
                </Button>
              </WrapperButton>

            </>
          )}
        </Formik>
      ) : (
        <WrapperButton>
          <Button
            size="medium"
            variant="contained"
            handleClick={handleBack}
          >
            Fazer login
          </Button>
        </WrapperButton>
      )}
    </InitialPage>
  );
};

export default ForgotPasswordLayout;
