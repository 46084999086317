import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import OrderListLayout, { updateQueryStringInOrderList } from '../../../shared/order/order-list-layout';
import {
  OrderService,
  ProductService,
  PromotionalCicleService,
} from '../../../../services';
import { showErrorToast, showSuccessToast } from '../../../../components';

class CompanyOrderListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      agentList: {
        loading: false,
        list: [],
      },
      promotionalCicleList: {
        loading: false,
        list: [],
      },
      materialList: {
        loading: false,
        list: [],
      },
      productList: {
        loading: false,
        list: [],
      },
      orderList: {
        loading: false,
        list: [],
        total: 0,
      },
      orderDetailData: {
        loading: false,
        data: {},
        total: 0,
      },
      orderOnApproval: {
        loading: false,
        finished: false,
      },
      orderOnMultipleApproval: {
        loading: false,
        finished: false,
        total: 0,
        approved: 0,
      },
    };
  }

  _requestOrderList = async (data) => {
    const { dispatch, history } = this.props;

    try {
      this.setState(({ orderOnMultipleApproval }) => ({
        orderOnMultipleApproval: {
          ...orderOnMultipleApproval,
          loading: false,
          finished: false,
          total: 0,
          approved: 0,
        },
      }));

      this.setState(({ orderList }) => ({
        loading: true,
        orderList: {
          ...orderList,
          loading: true,
        },
      }));

      updateQueryStringInOrderList(history, data);

      const { data: { list, total } } = await OrderService.fetchOrderList(data);

      this.setState(({ orderList }) => ({
        loading: false,
        orderList: {
          ...orderList,
          loading: false,
          list,
          total,
        },
      }));
    } catch (e) {
      this.setState(({ orderList }) => ({
        loading: false,
        orderList: {
          ...orderList,
          loading: false,
        },
      }));
      dispatch(showErrorToast(e.message));
    }
  }

  _requestOrderDetail = async (id) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ orderDetailData }) => ({
        loading: true,
        orderDetailData: {
          ...orderDetailData,
          loading: true,
          data: {},
        },
      }));

      const { data } = await OrderService.fetchOrderDetail(id);

      this.setState(({ orderDetailData }) => ({
        loading: false,
        orderDetailData: {
          ...orderDetailData,
          loading: false,
          data,
        },
      }));

      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: false,
          finished: false,
        },
      }));
    } catch (e) {
      this.setState(({ orderDetailData }) => ({
        loading: false,
        orderDetailData: {
          ...orderDetailData,
          loading: false,
          data: {},
        },
      }));
      dispatch(showErrorToast(e.message));
    }
  }

  _requestAgentList = async (data) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ agentList }) => ({
        agentList: {
          ...agentList,
          loading: true,
        },
      }));

      const { data: { list, total } } = await OrderService.fetchAgentList(data);

      this.setState(({ agentList }) => ({
        agentList: {
          ...agentList,
          loading: false,
          list,
          total,
        },
      }));
    } catch (e) {
      this.setState(({ agentList }) => ({
        agentList: {
          ...agentList,
          loading: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  _requestPromotionalCicleList = async (data) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ promotionalCicleList }) => ({
        promotionalCicleList: {
          ...promotionalCicleList,
          loading: true,
        },
      }));

      const { data: { list, total } } = await PromotionalCicleService.fetchPromotionalCicleList(data);

      this.setState(({ promotionalCicleList }) => ({
        promotionalCicleList: {
          ...promotionalCicleList,
          loading: false,
          list,
          total,
        },
      }));
    } catch (e) {
      this.setState(({ promotionalCicleList }) => ({
        promotionalCicleList: {
          ...promotionalCicleList,
          loading: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  _requestMaterialList = async (data) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ materialList }) => ({
        materialList: {
          ...materialList,
          loading: true,
        },
      }));

      const { data: { list, total } } = await OrderService.fetchMaterialList(data);

      this.setState(({ materialList }) => ({
        materialList: {
          ...materialList,
          loading: false,
          list,
          total,
        },
      }));
    } catch (e) {
      this.setState(({ materialList }) => ({
        materialList: {
          ...materialList,
          loading: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  _requestProductList = async (data) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ productList }) => ({
        productList: {
          ...productList,
          loading: true,
        },
      }));

      const { data: { list, total } } = await ProductService.fetchProductList(data);

      this.setState(({ productList }) => ({
        productList: {
          ...productList,
          loading: false,
          list,
          total,
        },
      }));
    } catch (e) {
      this.setState(({ productList }) => ({
        productList: {
          ...productList,
          loading: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  _downloadSelectedData = async (orders) => {
    const { dispatch } = this.props;

    try {
      this.setState({
        loading: true,
      });

      await OrderService.downloadSelectedData(
        orders.map((order) => order.data.id),
        'export.csv',
      );

      dispatch(showSuccessToast(`${orders.length} Pedido${orders.length > 1 ? 's exportados' : ' exportado'} com sucesso!`));

      this.setState({
        loading: false,
      });
    } catch (e) {
      this.setState({
        loading: false,
      });

      dispatch(showErrorToast(e.message));
    }
  }

  _downloadFilteredData = async (filters, search) => {
    const { dispatch } = this.props;

    try {
      this.setState({
        loading: true,
      });

      await OrderService.downloadFilteredData(filters, 'export.csv', search);

      dispatch(showSuccessToast('Pedidos exportados com sucesso!'));

      this.setState({
        loading: false,
      });
    } catch (e) {
      this.setState({
        loading: false,
      });

      dispatch(showErrorToast(e.message));
    }
  }

  _requestApproveOrder = async (id) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: true,
          finished: false,
        },
      }));

      await OrderService.approveOrder(id);

      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: false,
          finished: true,
        },
      }));
    } catch (e) {
      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: false,
          finished: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  _requestRefuseOrder = async (id) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: true,
          finished: false,
        },
      }));

      await OrderService.refuseOrder(id);

      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: false,
          finished: true,
        },
      }));
    } catch (e) {
      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: false,
          finished: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  _requestMultipleApproveOrder = async (orders) => {
    const { dispatch } = this.props;
    
    try {
      this.setState(({ orderOnMultipleApproval }) => ({
        orderOnMultipleApproval: {
          ...orderOnMultipleApproval,
          loading: true,
          finished: false,
        },
      }));

      const response = await OrderService.multipleApproveOrder(orders.map((orders) => orders.data.id));
      
      this.setState(({ orderOnMultipleApproval }) => ({
        orderOnMultipleApproval: {
          ...orderOnMultipleApproval,
          loading: false,
          finished: true,
          total: response.data.approved.length + response.data.refused.length,
          approved: response.data.approved.length,
        },
      }));
    } catch (e) {
      this.setState(({ orderOnMultipleApproval }) => ({
        orderOnMultipleApproval: {
          ...orderOnMultipleApproval,
          loading: false,
          finished: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  _requestCancelOrder = async (id) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: true,
          finished: false,
        },
      }));

      await OrderService.cancelOrder(id);

      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: false,
          finished: true,
        },
      }));
    } catch (e) {
      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: false,
          finished: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  render() {
    const {
      userData,
    } = this.props;

    const {
      loading,
      agentList,
      promotionalCicleList,
      materialList,
      productList,
      orderList,
      orderDetailData,
      orderOnApproval,
      orderOnMultipleApproval,
    } = this.state;

    return (
      <OrderListLayout
        title="Pedidos"
        userData={userData}
        loading={loading}
        agentList={agentList}
        promotionalCicleList={promotionalCicleList}
        materialList={materialList}
        productList={productList}
        orderList={orderList}
        orderDetailData={orderDetailData}
        onRequestOrderDetail={this._requestOrderDetail}
        onRequestAgentList={this._requestAgentList}
        onRequestPromotionalCicleList={this._requestPromotionalCicleList}
        onRequestMaterialList={this._requestMaterialList}
        onRequestProductList={this._requestProductList}
        onRequestOrderList={this._requestOrderList}
        onExportFilteredData={this._downloadFilteredData}
        onExportSelectedData={this._downloadSelectedData}
        handleConfirmApproveDialog={this._requestApproveOrder}
        handleConfirmRefuseDialog={this._requestRefuseOrder}
        handleConfirmCancelDialog={this._requestCancelOrder}
        handleConfirmMultipleApproveDialog={this._requestMultipleApproveOrder}
        loadingOrderDialog={orderOnApproval.loading}
        orderOnApprovalFinished={orderOnApproval.finished}
        loadingOrderOnMultipleApprovalDialog={orderOnMultipleApproval.loading}
        orderTotalOnMultipleApproval={orderOnMultipleApproval.total}
        orderOnMultipleApproval={orderOnMultipleApproval.approved}
        orderOnMultipleApprovalFinished={orderOnMultipleApproval.finished}
      />
    );
  }
}

const mapStateToProps = (data) => data;

export default connect(mapStateToProps)(withRouter(CompanyOrderListPage));
