import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '../Icon';
import HeaderCheckbox from '../HeaderCheckbox';
import TableHeader from './TableHeader';
import TableFooter from './TableFooter';
import RowWrapperComponent from './RowWrapperComponent';
import ToggleContent from './ToggleContent';
import VirtualizedTableRows from './VirtualizedTableRows';
import { Colors } from '../../constants';
import Paginate from '../Paginate';
import { mediaHelper } from '../../utils';

const propTypes = {
  name: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  footerText: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  components: PropTypes.any.isRequired,
  showRightArrow: PropTypes.bool,
  rowWrapper: PropTypes.func,
  multiselect: PropTypes.bool,
  columnConfig: PropTypes.shape({
    noHeader: PropTypes.bool,
    width: PropTypes.number,
    centerContent: PropTypes.bool,
    align: PropTypes.string,
    noSorting: PropTypes.bool,
    order: PropTypes.number,
    noPadding: PropTypes.bool,
  }),
  paginationProps: PropTypes.exact(Paginate.propTypes),
  renderOutside: PropTypes.any,
  checkAllControl: PropTypes.bool,
  checkboxesControl: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.object,
    selected: PropTypes.bool.isRequired,
    children: PropTypes.array,
  })),
  toggleConfig: PropTypes.arrayOf(PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    content: PropTypes.element,
  })),
  renderToggleComponent: PropTypes.func,
  onChangeItemsPerPage: PropTypes.func,
  onOrderColumn: PropTypes.func,
  onBatchDelete: PropTypes.func,
  onBatchCancel: PropTypes.func,
  onRowClick: PropTypes.func,
  handleHeaderTitle: PropTypes.func,
  onHeaderCheck: PropTypes.func,
  radiobutton: PropTypes.bool,
  groupFirstColumns: PropTypes.any,
};

const defaultProps = {
  isLoading: false,
  multiselect: false,
  radiobutton: false,
  showRightArrow: true,
  toggleConfig: null,
  columnConfig: {},
  footerText: null,
  rowWrapper: null,
  groupFirstColumns: null,
  paginationProps: {
    itemsPerPage: 10,
    onPageChange: () => { },
  },
  checkboxesControl: null,
  checkAllControl: undefined,
  renderToggleComponent: null,
  renderOutside: () => { },
  onRowClick: () => { },
  onOrderColumn: () => { },
  onBatchDelete: () => { },
  onBatchCancel: () => { },
  onChangeItemsPerPage: () => { },
  handleHeaderTitle: () => { },
  onHeaderCheck: () => { },
};

const FluidContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ThContainer = styled.div`
  background-color: inherit;
  padding-left: 16px;
  
  ${mediaHelper.md`
    padding-left: 0;
  `}
  
  ${({ width }) => width && `
    width: ${width}px;
  `}

  ${({ align }) => align && `
    justify-content: ${align};
  `}

  ${({ multiselect }) => !multiselect && `
    display: flex;
    align-items: center;
    padding: 8px 0px 8px 16px;
    min-height: 56px;
    box-sizing: border-box;
  `}
`;

const Th = styled.th`
  border-spacing: 0;

  ${mediaHelper.md`
    padding-left: 16px;
  `}
`;

export const TdContainer = styled.div`
  font-size: 14px;

  ${({ multiselect }) => !multiselect && `
    display: flex;
    align-items: center;
    padding: 8px 0px 8px 16px;
    min-height: 56px;
    box-sizing: border-box;
  `}

  ${({ align }) => align && `
    text-align: ${align};
  `}

  ${({ noPadding }) => noPadding && `
    padding: 0;
  `}

  ${({ width }) => width && `
    width: ${width}px;
  `}
`;

export const Td = styled.td`
  background-color: white;
  -webkit-border-top-left-radius: 8px;
  -webkit-border-bottom-left-radius: 8px;
  -moz-border-radius-topleft: 8px;
  -moz-border-radius-bottomleft: 8px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  ${mediaHelper.md`
    border-radius: 0px;
  `}
`;

export const Tr = styled.tr`
  border-bottom: 1px solid ${Colors.GRAY_LIGHTER};
  position: static;

  &:last-child {
    border-bottom: none;
    ${mediaHelper.md`
      border-bottom: 1px solid ${Colors.GRAY_LIGHTER};
    `}
  }

  &:first-child {
    & ${Th} {
      border-left: none;
      ${mediaHelper.md`
        border-left: 1px solid ${Colors.GRAY_LIGHTER};
      `}
    }
  }

  ${({ checked }) => checked && `
    & ${Td} {
      background-color: ${Colors.SECONDARY_LIGHT_BLUE};
    }
  `}

  ${({ onClick }) => onClick && `
    cursor: pointer;
  `}

  &:hover {
    & td {
      background-color: ${Colors.GRAY_LIGHTER};
    }
  }
`;

export const GroupedColumnTableContainer = styled.div`
  position: relative;
  width: 140px;
  box-shadow: 0px 2px 1px rgb(0 0 0 / 20%), 0px 1px 1px rgb(0 0 0 / 14%), 0px 1px 3px rgb(0 0 0 / 12%);
  background-color: white;
  -webkit-border-top-left-radius: 8px;
  -webkit-border-bottom-left-radius: 8px;
  -moz-border-radius-topleft: 8px;
  -moz-border-radius-bottomleft: 8px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  
`;

export const GroupedColumnTable = styled.table`
  position: absolute;
  z-index: 2;
  border-spacing: 0;
  border-collapse: collapse;
  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  & ${Th} {
    border-right: 1px solid ${Colors.GRAY_LIGHTER};
    background-color: white;
    -webkit-border-top-left-radius: 8px;
    -webkit-border-bottom-left-radius: 8px;
    -moz-border-radius-topleft: 8px;
    -moz-border-radius-bottomleft: 8px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  & ${Td} {
    border-right: 1px solid ${Colors.GRAY_LIGHTER};

    ${mediaHelper.md`
      border-left: 1px solid ${Colors.GRAY_LIGHTER};
    `}
  }
`;

const GroupedColumnThContainer = styled(ThContainer)`
  display: flex;
  flex-direction: row;
`;

const GroupedColumnTdContainer = styled(TdContainer)`
  display: flex;
  flex-direction: row;
`;

export const TableWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  background-color: white;
  background-color: white;
  -webkit-border-top-right-radius: 8px;
  -webkit-border-bottom-right-radius: 8px;
  -moz-border-radius-topright: 8px;
  -moz-border-radius-bottomright: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  ${mediaHelper.md`
    -webkit-border-top-left-radius: 8px;
    -webkit-border-top-right-radius: 8px;
    -moz-border-radius-topleft: 8px;
    -moz-border-radius-topright: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    -webkit-border-bottom-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    border-bottom-right-radius: 0;
  `}

  & ${Td} {
    border-radius: 0px;
    padding-left: 16px;
  }

`;

const TableContainer = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: white;
  border: 0;
`;

const TdHead = styled.thead`
  border-bottom: 1px solid ${Colors.GRAY_LIGHTER};
`;

const TableCheckboxHeader = styled(HeaderCheckbox)`
  & ${Icon} {
    font-size: 14px;
    color: ${Colors.PRIMARY_BLUE};
  }
`;

const TableCheckboxHeaderPlaceholder = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  margin: 20px 20px 20px 0;
`;

const StyledTableCheckbox = styled(HeaderCheckbox)``;

const IconRowClick = styled(Icon)`
  font-size: 12px;
  color: ${Colors.GRAY};
  margin: 0 10px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 24px 0;
  opacity: .8;
`;

const CHECKBOX_KEY = 'checkbox';

export const Table = ({
  name,
  data,
  components,
  isLoading,
  rowWrapper,
  onOrderColumn,
  multiselect,
  showRightArrow,
  rightArrowDown,
  onRowClick,
  columnConfig,
  paginationProps,
  footerText,
  noFooter,
  itemPerPageLabel,
  dataOrder,
  groupFirstColumns,
  handleHeaderTitle,
  virtualizeRows,
  renderOutside,
  toggleConfig,
  renderToggleComponent,
  checkboxesControl,
  checkAllControl,
  onChangeItemsPerPage,
  onBatchDelete,
  onBatchCancel,
  onRowCheck,
  onHeaderCheck,
  renderEmptyState,
  radiobutton,
  disabled,
  ...props
}) => {
  const [checkAll, setCheckAll] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);

  const _getCheckboxList = () => {
    if (checkboxesControl) {
      return checkboxesControl;
    }

    return checkboxes;
  };

  const _updateCheckboxList = (values) => {
    if (checkboxesControl) {
      return () => { };
    }

    return setCheckboxes(values);
  };

  const _getCheckAll = () => {
    if (checkAllControl) {
      return checkAllControl;
    }

    return checkAll;
  };

  const _updateCheckAll = (values) => {
    if (checkAllControl !== undefined && checkAllControl !== null) {
      return () => { };
    }

    return setCheckAll(values);
  };

  const newColumnConfig = {
    // ...(multiselect ? { [CHECKBOX_KEY]: {} } : {}),
    ...columnConfig,
  };
  const columnConfigKeys = [
    ...(multiselect ? [CHECKBOX_KEY] : []),
    ...Object.entries(newColumnConfig).sort(([keyA, a], [keyB, b]) => {
      if (a.order) {
        if (
          (b.order && a.order > b.order) || (a.order > keyB)
        ) {
          return 1;
        } if (
          (b.order && a.order < b.order) || (a.order < keyB)
        ) {
          return -1;
        }
      } else if (b.order) {
        if (
          (b.order && a.order > b.order) || (a.order > keyB)
        ) {
          return 1;
        } if (
          (b.order && a.order < b.order) || (a.order < keyB)
        ) {
          return -1;
        }
      } else if (keyA > keyB) {
        return 1;
      } else if (keyA < keyB) {
        return -1;
      }

      return 0;
    }).map(([key, value]) => key),
  ];

  const groupedHeaderCells = columnConfigKeys.filter((el, index) => groupFirstColumns && index < groupFirstColumns);
  const notGroupedHeaderCells = columnConfigKeys.filter((el, index) => (!groupFirstColumns || groupFirstColumns) && index >= groupFirstColumns);

  const _fillCheckboxes = (selected) => data.map((item) => ({ data: item, selected }));

  const changeDataOrder = (key, order) => {
    if (key) {
      onOrderColumn(key, order);
      setCheckboxes([]);
    }
  };

  const _hasSelectedCheckbox = (checkboxList) => (
    (checkboxList || []).some((checkbox) => checkbox && checkbox.selected)
  );

  const _hasSelectedAllCheckboxes = (checkboxList) => (
    (checkboxList || []).every((checkbox) => checkbox.selected)
  );

  const _renderHeaderCellContent = (key) => {
    if (key === CHECKBOX_KEY) {
      const isIndeterminate = _hasSelectedCheckbox(_getCheckboxList()) && !_getCheckAll();
      return radiobutton ? (
        <TableCheckboxHeaderPlaceholder />
      ) : (
        <TableCheckboxHeader
          disabled={disabled}
          checked={_getCheckAll()}
          indeterminate={isIndeterminate}
          onChange={() => {
            const checked = isIndeterminate ? _getCheckAll() : !_getCheckAll();

            _updateCheckboxList(_fillCheckboxes(checked));
            _updateCheckAll(checked);

            if (onHeaderCheck) {
              onHeaderCheck(checked);
            }
          }}
        />
      );
    }
    return (!newColumnConfig || !newColumnConfig[key] || !newColumnConfig[key].noHeader) && (
      <TableHeader
        key={key}
        headerKey={key}
        dataOrder={dataOrder}
        noSorting={newColumnConfig && newColumnConfig[key] && newColumnConfig[key].noSorting}
        handleHeaderTitle={handleHeaderTitle}
        onChangeDataOrder={changeDataOrder}
        disabled={disabled}
      />
    );
  };

  const _onCheckboxRowChange = (rowData, rowIndex) => {
    let copy = [];
    if (radiobutton) {
      copy = [_getCheckboxList()];
    } else {
      copy = [..._getCheckboxList()];
    }

    copy[rowIndex] = {
      data: rowData,
      selected: copy[rowIndex] && copy[rowIndex].selected ? !copy[rowIndex].selected : true,
    };
    const selected = copy.filter((item) => item && item.selected);

    _updateCheckboxList(copy);
    _updateCheckAll(data.length === selected.length);

    if (onRowCheck) {
      onRowCheck(rowIndex, copy[rowIndex].selected);
    }
  };

  const _renderRowCellContent = (rowData, key, rowIndex) => {
    let isChecked = _getCheckboxList()[rowIndex] && _getCheckboxList()[rowIndex].selected;

    if (key === CHECKBOX_KEY) {
      let isIndeterminate = false;

      if (toggleConfig && toggleConfig[rowIndex] && toggleConfig[rowIndex].isOpen) {
        isChecked = checkboxesControl[rowIndex].selected;
      }

      if (checkboxesControl && checkboxesControl[rowIndex]) {
        isIndeterminate = checkboxesControl[rowIndex]
          && _hasSelectedCheckbox(checkboxesControl[rowIndex].children)
          && !_hasSelectedAllCheckboxes(checkboxesControl[rowIndex].children);
      }

      return (
        <StyledTableCheckbox
          checked={isChecked}
          disabled={(rowData.availableQuantity <= 0 ? true : false) || disabled || rowData.hasStockPermission}
          indeterminate={isIndeterminate}
          onChange={() => _onCheckboxRowChange(rowData, rowIndex)}
          radiobutton={radiobutton}
        />
      );
    }

    return (
      components[key] && components[key](rowData[key], data, rowIndex, isChecked)
    );
  };

  const _renderToggleContent = (rowIndex, rowData, hideWrapper = false) => {
    if (toggleConfig && toggleConfig[rowIndex] && renderToggleComponent) {
      return (
        <ToggleContent
          index={rowIndex}
          isOpen={toggleConfig && toggleConfig[rowIndex] && toggleConfig[rowIndex].isOpen}
          hideWrapper={hideWrapper}
        >
          {renderToggleComponent(toggleConfig[rowIndex], rowIndex)}
        </ToggleContent>
      );
    }

    return null;
  };

  const _renderVirtualizedRows = () => {
    if (data && data.length) {
      return (
        <VirtualizedTableRows
          name={name}
          data={data}
          getCheckboxList={_getCheckboxList}
          multiselect={multiselect}
          rowWrapper={rowWrapper}
          toggleConfig={toggleConfig}
          notGroupedHeaderCells={notGroupedHeaderCells}
          newColumnConfig={newColumnConfig}
          groupFirstColumns={groupFirstColumns}
          showRightArrow={showRightArrow}
          IconRowClick={IconRowClick}
          renderRowCellContent={_renderRowCellContent}
          renderToggleContent={(rowIndex, rowData) => _renderToggleContent(rowIndex, rowData, true)}
          onRowClick={onRowClick}
        />
      );
    }

    return null;
  };

  return (
    <div {...props}>
      <FluidContainer>
        {
          groupFirstColumns && (
            <GroupedColumnTableContainer>
              <GroupedColumnTable>
                <TdHead>
                  <tr>
                    <Th
                      key={`table-th-${name}-0`}
                      groupFirstColumns={groupFirstColumns}
                      {...(multiselect ? { width: 1 } : {})}
                    >
                      <GroupedColumnThContainer>
                        {
                          groupedHeaderCells.map((el, index) => (
                            _renderHeaderCellContent(el, index)
                          ))
                        }
                      </GroupedColumnThContainer>
                    </Th>
                  </tr>
                </TdHead>
                <tbody>
                  {Object.values(data).map((rowData, rowIndex) => (
                    <RowWrapperComponent
                      Tr={Tr}
                      key={`table-tr-${name}-${rowIndex}`}
                      checked={(_getCheckboxList()[rowIndex] && _getCheckboxList()[rowIndex].selected)}
                      rowIndex={rowIndex}
                      rowWrapper={rowWrapper}
                      rowData={rowData}
                      onRowClick={onRowClick}
                      toggleCheckbox={() => {
                        _onCheckboxRowChange(rowData, rowIndex);
                      }}
                    >
                      <Td
                        key={`table-td-${name}`}
                        groupFirstColumns={groupFirstColumns}
                        {...(multiselect ? { width: 1 } : {})}
                      >
                        <GroupedColumnTdContainer>
                          {groupedHeaderCells.map((el, groupedColumnIndex) => (
                            _renderRowCellContent(rowData, el, rowIndex, groupedColumnIndex)
                          ))}
                        </GroupedColumnTdContainer>
                      </Td>
                    </RowWrapperComponent>
                  ))}
                </tbody>
              </GroupedColumnTable>
            </GroupedColumnTableContainer>
          )
        }
        <TableWrapper>
          <TableContainer>
            <TdHead>
              <tr>
                {notGroupedHeaderCells.map((key, index) => (
                  <Th
                    key={`table-th-${name}-${key}-${index}`}
                    groupFirstColumns={groupFirstColumns}
                    {...(multiselect && key === CHECKBOX_KEY ? { width: 1 } : {})}
                  >
                    <ThContainer
                      align={newColumnConfig && newColumnConfig[key] && newColumnConfig[key].align}
                      width={newColumnConfig && newColumnConfig[key] && newColumnConfig[key].width}
                      multiselect={multiselect}
                    >
                      {_renderHeaderCellContent(key, index)}
                    </ThContainer>
                  </Th>
                ))}
                {showRightArrow && (<Th />)}
              </tr>
            </TdHead>
            <tbody>
              {
                virtualizeRows
                  ? _renderVirtualizedRows()
                  : (
                    Object.values(data).map((rowData, rowIndex) => (
                      <React.Fragment key={rowIndex}>
                        <RowWrapperComponent
                          key={`table-tr-${name}-${rowIndex}`}
                          checked={(_getCheckboxList()[rowIndex] && _getCheckboxList()[rowIndex].selected)}
                          rowIndex={rowIndex}
                          rowWrapper={rowWrapper}
                          rowData={rowData}
                          onRowClick={onRowClick}
                          toggleCheckbox={() => {
                            _onCheckboxRowChange(rowData, rowIndex);
                          }}
                        >
                          {notGroupedHeaderCells.map((key, colIndex) => (
                            <Td
                              key={`table-td-${name}-${key}-${colIndex}`}
                              align={newColumnConfig && newColumnConfig[key] && newColumnConfig[key].align}
                              noPadding={newColumnConfig && newColumnConfig[key] && newColumnConfig[key].noPadding}
                              groupFirstColumns={groupFirstColumns}
                              {...(multiselect && key === CHECKBOX_KEY ? { width: 1 } : {})}
                            >
                              <TdContainer
                                width={newColumnConfig && newColumnConfig[key] && newColumnConfig[key].width}
                                align={newColumnConfig && newColumnConfig[key] && newColumnConfig[key].align}
                                noPadding={newColumnConfig && newColumnConfig[key] && newColumnConfig[key].noPadding}
                                multiselect={multiselect}
                                checkboxCell={key === CHECKBOX_KEY}
                              >
                                {_renderRowCellContent(rowData, key, rowIndex, groupFirstColumns
                                  ? colIndex + groupFirstColumns : colIndex)}
                              </TdContainer>
                            </Td>
                          ))}

                          {showRightArrow
                            && (
                              <Td checked={_getCheckboxList()[rowIndex]}>
                                <IconRowClick name="chevron-right" />
                              </Td>
                            )}

                        </RowWrapperComponent>

                        {_renderToggleContent(rowIndex, rowData)}
                      </React.Fragment>
                    ))
                  )
              }
            </tbody>
          </TableContainer>
          {Object.values(data).length === 0 && !isLoading ? renderEmptyState() : null}
          {isLoading && (
            <LoadingWrapper>
              <CircularProgress />
            </LoadingWrapper>
          )}
          {/* TODO - is the name too generic? */}
          {renderOutside(_getCheckboxList().filter((x) => x && x.selected), (value) => {
            if (value || value.length === 0) {
              _updateCheckAll(false);
            }

            _updateCheckboxList(value || []);
          })}
        </TableWrapper>
      </FluidContainer>
      {noFooter ? null : (
        <TableFooter
          itemsLength={(data || []).length}
          paginationProps={{
            ...paginationProps,
            onPageChange: (...pageProps) => {
              _updateCheckboxList(_fillCheckboxes(false));
              _updateCheckAll(false);
              paginationProps.onPageChange(...pageProps);
            },
          }}
          tableName={name}
          footerText={footerText}
          itemPerPageLabel={itemPerPageLabel}
          onChangeItemsPerPage={(...itemsQtyProps) => {
            _updateCheckboxList(_fillCheckboxes(false));
            _updateCheckAll(false);
            onChangeItemsPerPage(...itemsQtyProps);
          }}
        />
      )}
    </div>
  );
};

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;
