import React from 'react';
import { PropTypes } from 'prop-types';
import styled, { css } from 'styled-components';

import { Text, FontWeight } from './Text';
import TouchableText from './TouchableText';
import { Colors } from '../constants';

const propTypes = {
  emptyStateText: PropTypes.any,
  actionText: PropTypes.string,
  onActionClick: PropTypes.func,
  lineBreak: PropTypes.bool,
};

const defaultProps = {
  emptyStateText: '',
  actionText: '',
  onActionClick: () => { },
  lineBreak: false,
};

const dashedMixin = css`
  padding: 12px;
  border: 1px dashed ${Colors.GRAY};
  border-radius: 8px;
  text-align: center;
`;

const borderSolidMixin = css`
  background-color: white;
  border: 1px solid ${Colors.GRAY_LIGHTER};
  border-radius: 8px;
  padding: 22px;
`;

const alignCenterMixin = css`
  justify-content: center;
  display: flex;
`;

const EmptyStateWithActionContainer = styled.div`
  ${({ dashed }) => dashed && dashedMixin}
  ${({ borderSolid }) => borderSolid && borderSolidMixin}
  ${({ alignCenter }) => alignCenter && alignCenterMixin}
`;

const EmptyStateText = styled(Text)`
  font-size: 16px;
  color: ${Colors.GRAY_DARKER};
  text-align: center;
`;

const StyledTouchableText = styled(TouchableText)`
  margin-left: 8px;
  font-size: 16px;
`;

const EmptyStateWithAction = ({
  emptyStateText,
  renderAction,
  actionText,
  lineBreak,
  onActionClick,
  ...props
}) => (
  <EmptyStateWithActionContainer {...props}>
    <EmptyStateText>
      {emptyStateText}
    </EmptyStateText>
    {lineBreak && <br />}
    {renderAction ? renderAction() : null}
    {actionText && onActionClick && !renderAction && (
      <StyledTouchableText
        weight={FontWeight.Bold}
        underline
        onClick={(e) => {
          e.stopPropagation();
          onActionClick();
        }}
      >
        {actionText}
      </StyledTouchableText>
    )}
  </EmptyStateWithActionContainer>
);

EmptyStateWithAction.propTypes = propTypes;
EmptyStateWithAction.defaultProps = defaultProps;

export default EmptyStateWithAction;
