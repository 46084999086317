import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Close } from '@material-ui/icons';
import {
  Container,
  Grid,
} from '@material-ui/core/';
import { Button } from '../../../../../components';
import {
  Colors,
  USER_ROLES,
  MODULES,
  SUB_MODULES,
  OrderStatusEnum,
  BreakpointValues,
} from '../../../../../constants';
import MaterialSlider from '../materialSlider';
import { mediaHelper, useWindowSize } from '../../../../../utils';
import StatusFlow from '../../../../../components/StatusFlow';

const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.BLUE_LIGHT};
  width: 100%;
  height: 92px;

  ${mediaHelper.lg`
    font-size: 34px;
  `}
`;

const DrawerTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 32px;
  color: ${Colors.SECONDARY_MEDIUM};

  ${mediaHelper.lg`
    font-size: 34px;
    display: block;
  `}
`;

const DrawerActionWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > button {
    margin-left: 24px;
  }
`;

const DrawerContent = styled.div`
  display: flex;
  background: ${Colors.BLUE_DARKER};
  background: -moz-linear-gradient(left, ${Colors.BLUE_DARKER} 0%, ${Colors.BLUE_DARKER} 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, ${Colors.BLUE_DARKER}), color-stop(50%, ${Colors.BLUE_DARKER}), color-stop(50%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,1)));
  background: -webkit-linear-gradient(left, ${Colors.BLUE_DARKER} 0%, ${Colors.BLUE_DARKER} 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
  background: -o-linear-gradient(left, ${Colors.BLUE_DARKER} 0%, ${Colors.BLUE_DARKER} 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
  background: -ms-linear-gradient(left, ${Colors.BLUE_DARKER} 0%, ${Colors.BLUE_DARKER} 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
  background: linear-gradient(to right, ${Colors.BLUE_DARKER} 0%, ${Colors.BLUE_DARKER} 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c6deee', endColorstr='#ffffff', GradientType=1 );
`;

const StyledContainer = styled(Container)`
  padding-left: 0;
  padding-right: 0;

  ${mediaHelper.lg`
    padding-left: 16px;
    padding-right: 16px;
  `}
`;

const StyledGrid = styled(Grid)`
  margin-top: 0 !important;
`;

const StyledGridLeft = styled(Grid)`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.BLUE_DARKER};
`;

const StyledGridRight = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fff;
`;

const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;

  ${mediaHelper.lg`
    padding: 24px 24px 24px 16px;
  `}
`;

const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const LabelText = styled.span`
  font-size: 12px;
  color: ${Colors.GRAY_DARKEST};
  margin-bottom: 8px;
`;

const ValueText = styled.span`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
`;

const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;  

  ${mediaHelper.lg`
    flex-direction: row;
    align-items: center;
    padding-left: 8px;
  `}
`;

const ActionHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;

  & button {
    margin-bottom: 16px;
  }

  ${mediaHelper.lg`
    flex-direction: row;

    & button {
      margin-bottom: 0;
    }
  `}
`;

const ActionRight = styled.div`
  ${mediaHelper.lg`
    margin-left: 16px;
  `}
`;

const RowTitleText = styled.strong`
  display: flex;
  color: ${Colors.SECONDARY_MEDIUM};
  font-size: 20px;
  margin-bottom: 16px;
`;

const StyledButton = styled(Button)`
  max-width: 165px;
`;

const StyledIcon = styled(Close)`
  margin-left: 8px;
`;

const StyledStatusFlow = styled(StatusFlow)`
  padding-left: 8px;
`;

const PromoCicleName = styled.span`
  font-size: 17px;
  color: ${Colors.SECONDARY_MEDIUM};
  margin-left: 8px;
`;

const OrderDetail = ({ ...props }) => {
  const {
    userData,
    orderNumber,
    orderData,
    orderDetailData,
    onCloseDrawer,
    onExportSelectedData,
    openApproveOrderDialog,
    openRefuseOrderDialog,
    openCancelOrderDialog,
    handleOpenUrls,
  } = props;

  const currentWindowSize = useWindowSize();

  const { data } = orderDetailData;

  return (
    <>
      <DrawerHeader>
        <Container maxWidth="lg">
          <Grid container justify="space-between" spacing={3}>
            <Grid
              item
              lg={7}
              md={7}
            >
              <DrawerTitle>
                Pedido
                {' '}
                {orderData.length > 0 && (
                  <>
                    {orderNumber === null ? orderData[0].data.id : orderNumber}
                    <PromoCicleName>
                      {orderData[0].data.promotionalCicle === '' ? 'Avulso' : orderData[0].data.promotionalCicle}
                    </PromoCicleName>
                  </>
                )}
              </DrawerTitle>
            </Grid>
            <Grid
              item
              lg={5}
              md={5}
            >
              <DrawerActionWrapper>
                <Button
                  size="large"
                  color="secondary"
                  handleClick={onCloseDrawer}
                >
                  {currentWindowSize.width >= BreakpointValues.md ? 'Fechar' : ''}
                  <StyledIcon
                    fontSize="small"
                  />
                </Button>
              </DrawerActionWrapper>
            </Grid>
          </Grid>
        </Container>
      </DrawerHeader>
      <DrawerContent>
        <StyledContainer>
          <StyledGrid container>
            <StyledGridLeft
              item
              lg={3}
              md={3}
              xs={12}
            >
              <BoxContent>
                {data.receiver ? (
                  <>
                    <InfoContent>
                      <LabelText>
                        Destinatário
                      </LabelText>
                      <ValueText>
                        {data.receiver.name ? data.receiver.name : 'Não definido'}
                      </ValueText>
                    </InfoContent>
                    {data.receiver.address && (
                      <InfoContent>
                        <LabelText>
                          Endereço de entrega
                        </LabelText>
                        <ValueText>
                          {data.receiver.address.street}
                          {', '}
                          {data.receiver.address.addressNumber}
                          {' - '}
                          {data.receiver.address.neighborhood}
                          {' - '}
                          {data.receiver.address.city}
                          {'- CEP '}
                          {data.receiver.address.cep}
                        </ValueText>
                      </InfoContent>
                    )}
                    {data.createdBy && (
                      <InfoContent>
                        <LabelText>
                          Criado por
                        </LabelText>
                        <ValueText>
                          {data.createdBy}
                        </ValueText>
                      </InfoContent>
                    )}
                    {data.approvedBy && (
                      <InfoContent>
                        <LabelText>
                          Aprovado por
                        </LabelText>
                        <ValueText>
                          {data.approvedBy}
                        </ValueText>
                      </InfoContent>
                    )}
                    {data.refusedBy && (
                      <InfoContent>
                        <LabelText>
                          Recusado por
                        </LabelText>
                        <ValueText>
                          {data.refusedBy}
                        </ValueText>
                      </InfoContent>
                    )}
                    {data.canceledBy && (
                      <InfoContent>
                        <LabelText>
                          Cancelado por
                        </LabelText>
                        <ValueText>
                          {data.canceledBy}
                        </ValueText>
                      </InfoContent>
                    )}
                    {userData.userRole === USER_ROLES.COMPANY || (userData.userRole === USER_ROLES.MASTER && userData.companyName) ? (
                      <StyledButton
                        size="small"
                        color="primary"
                        variant="contained"
                        handleClick={() => { onExportSelectedData(orderData); }}
                      >
                        Exportar pedido
                      </StyledButton>
                    ) : null}
                  </>
                ) : (
                  <InfoContent>
                    <LabelText>
                      Destinatário
                    </LabelText>
                    <ValueText>
                      Nenhum destinatário para exibir :(
                    </ValueText>
                  </InfoContent>
                )}
              </BoxContent>
            </StyledGridLeft>
            <StyledGridRight
              item
              lg={9}
              md={9}
              xs={12}
            >
              <BoxContent>
                <SectionHeader>
                  <RowTitleText>
                    Status do pedido
                  </RowTitleText>
                  {data && data.statusList && (
                    <ActionHeader>
                      {(userData.userPermissions && userData.userPermissions[SUB_MODULES[MODULES.DISTRIBUTIONS].APPROVAL_ORDERS][4]) && (
                        <>
                          {data.canRefuse && (
                            <Button
                              size="small"
                              color="secondary"
                              variant="outlined"
                              handleClick={() => { openRefuseOrderDialog(orderData[0].data.id, true); }}
                            >
                              Recusar
                            </Button>
                          )}
                          {data.canApprove && (
                            <ActionRight>
                              <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                handleClick={() => { openApproveOrderDialog(orderData[0].data.id, true); }}
                              >
                                Aprovar
                              </Button>
                            </ActionRight>
                          )}
                        </>
                      )}
                      {(((orderData[0].data.status === OrderStatusEnum.ORDER_PENDING_APPROVAL || orderData[0].data.status === OrderStatusEnum.BILLING_ORDER) && userData.userRole === USER_ROLES.COMPANY) || (orderData[0].data.status === OrderStatusEnum.ORDER_PENDING_APPROVAL && userData.userRole === USER_ROLES.AGENT)) && (
                        <ActionRight>
                          <Button
                            size="small"
                            color="secondary"
                            variant="contained"
                            handleClick={() => { openCancelOrderDialog(orderData[0].data.id, true); }}
                          >
                            Cancelar pedido
                          </Button>
                        </ActionRight>
                      )}
                    </ActionHeader>
                  )}
                </SectionHeader>
                <StyledStatusFlow
                  statusList={data ? data.statusList : []}
                />
                <SectionHeader>
                  <RowTitleText>
                    Materiais enviados
                  </RowTitleText>
                  {data.nfeList && data.nfeList.length > 0 && (
                    <Button
                      size="small"
                      color="secondary"
                      variant="outlined"
                      handleClick={() => handleOpenUrls(data.nfeList)}
                    >
                      Notas fiscais
                    </Button>
                  )}
                </SectionHeader>
                <MaterialSlider
                  sliderData={data ? data.materialList : []}
                />
              </BoxContent>
            </StyledGridRight>
          </StyledGrid>
        </StyledContainer>
      </DrawerContent>
    </>
  );
};

OrderDetail.propTypes = {
  orderNumber: PropTypes.string,
  orderData: PropTypes.array,
  orderDetailData: PropTypes.object,
  onCloseDrawer: PropTypes.func,
  onExportSelectedData: PropTypes.func,
  openApproveOrderDialog: PropTypes.func,
  openRefuseOrderDialog: PropTypes.func,
  openCancelOrderDialog: PropTypes.func,
  handleOpenUrls: PropTypes.func,
};

OrderDetail.defaultProps = {
  orderNumber: '',
  orderData: [],
  orderDetailData: {},
  onCloseDrawer: () => { },
  onExportSelectedData: () => { },
  openApproveOrderDialog: () => {},
  openRefuseOrderDialog: () => {},
  openCancelOrderDialog: () => {},
  handleOpenUrls: () => {},
};

export default OrderDetail;
