export const regionTypes = {
  NORTH: 1,
  SOUTH: 2,
  SOUTHEAST: 3,
  MIDWEST: 4,
  NORTH_EAST: 5,
};

export const agentDetailTabs = {
  ORDERS: 0,
  MATERIAL_PERMISSIONS: 1,
};
