import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import {
  showErrorToast,
} from '../../components';
import { RoutePaths } from '../../constants/RoutePaths';
import { UserService, HttpService } from '../../services';
import ResetPasswordLayout from './reset-password-layout';

class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      success: false,
      isValid: true,
      token: '',
    };
  }
  
  _validTokenResetPassword = async (token) => {
    try {
      await UserService.verifyTokenResetPassword(token);
      
      this.setState(
        {
          loading: false,
          token: token,
        },
      );
    } catch (error) {
      this.setState(
        {
          loading: false,
          isValid: false,
        },
      );
    }
  }
    
  componentDidMount() {
    const { location } = this.props;

    const queryObject = HttpService.unserializeQueryString(location.search);
    
    this._validTokenResetPassword(queryObject.token);
  }

  _getValidationsPassword = () => Yup.object().shape({
    password: Yup.string().required('Digite sua senha'),
    passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'As senhas devem corresponder'),
  });

  _getValidationsEmail = () => Yup.object().shape({
    email: Yup.string().email('Digite seu e-mail corretamente').required('Digite seu e-mail'),
  });

  _handleBack = () => {
    const { history } = this.props;

    history.push(RoutePaths.HOME);
  }

  _forgotPassword = async (values) => {
    const { dispatch } = this.props;

    try {
      this.setState({
        loading: true,
      });

      await UserService.forgotPassword(values);

      this.setState(
        {
          loading: false,
          success: true,
        },
      );
    } catch (error) {
      this.setState(
        {
          loading: false,
        },
        () => {
          console.error(error.message);
          dispatch(showErrorToast(error.message));
        },
      );
    }
  };

  _resetPassword = async (values) => {
    const { dispatch, history } = this.props;

    try {
      this.setState({
        loading: true,
      });

      await UserService.resetPassword(values, this.state.token);

      this.setState(
        {
          loading: false,
        },
      );

      history.push(RoutePaths.HOME);
    } catch (error) {
      this.setState(
        {
          loading: false,
        },
        () => {
          console.error(error.message);
          dispatch(showErrorToast(error.message));
        },
      );
    }
  };

  render() {
    const { loading, isValid, success } = this.state;

    return (
      <ResetPasswordLayout
        loading={loading}
        isValid={isValid}
        success={success}
        handleValidationsPassword={this._getValidationsPassword}
        handleValidationsEmail={this._getValidationsEmail}
        handleResetPassword={this._resetPassword}
        handleForgotPassword={this._forgotPassword}
        handleBack={this._handleBack}
      />
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(ResetPasswordPage);
