import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { Edit } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Grid,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Radio,
} from '@material-ui/core';
import {
  Page,
  Button,
  LoadMask,
  DialogModal,
} from '../../../../components';
import {
  Colors,
} from '../../../../constants';
import { RoutePaths } from '../../../../constants/RoutePaths';
import { Mask } from '../../../../utils';
import { stateOptionList, typeTag } from './constants';

const PageActionButton = styled(Button).attrs(({ variant }) => ({
  size: 'large',
  color: 'secondary',
  variant: variant || 'contained',
}))`
  margin-left: 24px;
`;

const SectionWrapper = styled.div`
  display: flex;
  margin-bottom: 48px;
`;

const SectionTitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 270px;
  margin-right: 24px;
`;

const SectionTitleLabel = styled.div`
  width: 240px;
  font-size: 24px;
  text-align: right;
  color: ${Colors.SECONDARY_MEDIUM};
`;

const CardSection = styled.div`
  flex: 1;
  display: flex;
  background-color: #FFF;
  border-radius: 8px;
  border: 1px solid ${Colors.GRAY_LIGHT};
  box-sizing: border-box;

  ${({ flexColumn }) => flexColumn && `
    flex-direction: column;
  `}
`;

const CardContent = styled.div`
  width: 100%;
  padding: 24px;

  ${({ block }) => block && `
    flex: 1;
  `}

  ${({ blueBg }) => blueBg && `
    background-color: ${Colors.Alpha(Colors.PRIMARY_BLUE, 0.04)};
  `}
`;

const StyledField = styled(Field)`
  width: 100%;
`;

const Divider = styled.div`
  width: 100%;
  height: 24px;
`;

const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const FormControlStyled = styled(FormControl)`
  width: 100%;
`;

const EditStyled = styled(Edit)`
  margin-right: 8px;
  color: ${Colors.PRIMARY_BLUE};
`;

const MenuItemStyled = styled(MenuItem)`
  padding: 8px 16px 8px 4px;

  &:focus, &:hover {
    background: white;
  }
  &:hover {
    background: ${Colors.GRAY_LIGHTER};
  }
`;

const MenuItemFixedStyled = styled(MenuItem)`
  padding: 8px 16px 8px 4px;
  background: white;
  &:focus {
    background: white;
  }
  &:hover {
    background: ${Colors.GRAY_LIGHTER};
  }
`;

const StickyMenuItem = styled.div`
  background: white;
  width: 100%;
  height: 49px;
  
  & > li {
    background: white;
    border-bottom: 1px solid ${Colors.GRAY_LIGHT};
    position: fixed;
    z-index: 99;
    padding: 16px 16px 16px 16px;
    width: 280px;
    margin-top: -8px;
  }
`;

const SelectStyled = styled(Select)`
  & > div > span {
    display: none;
  }
`;

const DialogContent = styled.div`
  width: 600px;
  display: flex;
  align-items: center;
  padding: 16px 0;
`;

const LabelStyled = styled.div`
  width: 100%;
  font-size: 16px;
  color: ${Colors.SECONDARY_MEDIUM};
  font-weight: bold;
  margin-bottom: 15px;
`;

const WrapperField = styled.div`
  display: flex;
  flex-direction: column;
`;

const TagWrapper = styled.div`
  display: flex;
  margin-left: 16px;
`;

const TagPreview = styled.div`
  display: flex;
  font-size: 16px;
  color: ${Colors.GRAY_DARKEST};
  margin-left: 48px;
  font-weight: bold;
`;

const Tag = styled.div`
  border: 1px solid ${Colors.PRIMARY_BLUE};
  border-radius: 16px;
  padding: 4px 8px;
  color: ${Colors.PRIMARY_BLUE};
  font-size: 14px;
  margin-right: 4px;
  white-space: nowrap;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const FormAgentLayout = ({
  userData,
  title,
  loading,
  requestDialog,
  handleValidations,
  tagBusinessUnityList,
  tagLineList,
  tagGroupSectorList,
  tagSectorList,
  handleAgentSubmit,
  isEditing,
  agentDetailData,
  handleNewTag,
  newTag,
  handleZipCodeInfo,
  zipCodeInfo,
}) => {
  const [businessUnityField, setBusinessUnityField] = useState('');
  const [lineField, setLineField] = useState('');
  const [groupSectorField, setGroupSectorField] = useState('');
  const [sectorField, setSectorField] = useState('');
  const [isOpenTagModal, setIsOpenTagModal] = useState(false);
  const [dataBusinessUnityList, setDataBusinessUnityList] = useState([]);
  const [dataLineList, setDataLineList] = useState([]);
  const [dataGroupSectorList, setDataGroupSectorList] = useState([]);
  const [dataSectorList, setDataSectorList] = useState([]);
  const [typeOfTag, setTypeOfTag] = useState('');
  const [typeOfTagText, setTypeOfTagText] = useState('');
  const [typeOfTagLabel, setTypeOfTagLabel] = useState('');
  const [agentTags, setAgentTags] = useState([]);
  const [stateField, setStateField] = useState('');
  const [isOpenAgentDialog, setIsOpenAgentDialog] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (tagBusinessUnityList.list.length) {
      setDataBusinessUnityList(tagBusinessUnityList.list);
    }
    if (tagLineList.list.length) {
      setDataLineList(tagLineList.list);
    }
    if (tagGroupSectorList.list.length) {
      setDataGroupSectorList(tagGroupSectorList.list);
    }
    if (tagSectorList.list.length) {
      setDataSectorList(tagSectorList.list);
    }
    if (agentDetailData.data) {
      const agentIdTags = agentDetailData.data ? agentDetailData.data.tags.map((item) => item.id) : [];
      setAgentTags(agentIdTags);

      if (tagBusinessUnityList.list.length) {
        const foundBusinessUnity = tagBusinessUnityList.list.filter((item) => agentIdTags.indexOf(item.id) !== -1);
        if (Object.values(foundBusinessUnity).length) {
          setBusinessUnityField(foundBusinessUnity[0].id);
        }
      }
      if (tagLineList.list.length) {
        const foundLine = tagLineList.list.filter((item) => agentIdTags.indexOf(item.id) !== -1);
        if (Object.values(foundLine).length) {
          setLineField(foundLine[0].id);
        }
      }
      if (tagGroupSectorList.list.length) {
        const foundGroupSector = tagGroupSectorList.list.filter((item) => agentIdTags.indexOf(item.id) !== -1);
        if (Object.values(foundGroupSector).length) {
          setGroupSectorField(foundGroupSector[0].id);
        }
      }
      if (tagSectorList.list.length) {
        const foundSector = tagSectorList.list.filter((item) => agentIdTags.indexOf(item.id) !== -1);
        if (Object.values(foundSector).length) {
          setSectorField(foundSector[0].id);
        }
      }
      setStateField(agentDetailData.data.uf);
    }
  }, [
    agentDetailData,
    tagBusinessUnityList.list,
    tagLineList.list,
    tagGroupSectorList.list,
    tagSectorList.list,
  ]);

  useEffect(() => {
    if (newTag.id) {
      if (typeOfTag === typeTag.BU.type) {
        updateTags(newTag.id, typeTag.BU.type);
        setDataBusinessUnityList((dataBusinessUnityList) => [{ id: newTag.id, name: `${newTag.name}` }, ...dataBusinessUnityList]);
        setBusinessUnityField(newTag.id);
      }
      if (typeOfTag === typeTag.L.type) {
        updateTags(newTag.id, typeTag.L.type);
        setDataLineList((dataLineList) => [{ id: newTag.id, name: `${newTag.name}` }, ...dataLineList]);
        setLineField(newTag.id);
      }
      if (typeOfTag === typeTag.GS.type) {
        updateTags(newTag.id, typeTag.GS.type);
        setDataGroupSectorList((dataGroupSectorList) => [{ id: newTag.id, name: `${newTag.name}` }, ...dataGroupSectorList]);
        setGroupSectorField(newTag.id);
      }
      if (typeOfTag === typeTag.S.type) {
        updateTags(newTag.id, typeTag.S.type);
        setDataSectorList((dataSectorList) => [{ id: newTag.id, name: `${newTag.name}` }, ...dataSectorList]);
        setSectorField(newTag.id);
      }
      setIsOpenTagModal(false);
    }
  }, [
    newTag.id,
  ]);

  const initialValues = {
    name: agentDetailData.data && agentDetailData.data.name ? agentDetailData.data.name : '',
    email: agentDetailData.data && agentDetailData.data.email ? agentDetailData.data.email : '',
    phone1: agentDetailData.data && agentDetailData.data.phone1 ? Mask.tel(agentDetailData.data.phone1, '', true) : '',
    phone2: agentDetailData.data && agentDetailData.data.phone2 ? Mask.tel(agentDetailData.data.phone2, '', true) : '',
    cep: agentDetailData.data && agentDetailData.data.cep ? Mask.cep(agentDetailData.data.cep, '', true) : '',
    street: agentDetailData.data && agentDetailData.data.street ? agentDetailData.data.street : '',
    number: agentDetailData.data && agentDetailData.data.number ? agentDetailData.data.number : '',
    neighborhood: agentDetailData.data && agentDetailData.data.neighborhood ? agentDetailData.data.neighborhood : '',
    addressDetail: agentDetailData.data && agentDetailData.data.addressDetail ? agentDetailData.data.addressDetail : '',
    city: agentDetailData.data && agentDetailData.data.city ? agentDetailData.data.city : '',
    uf: agentDetailData.data && agentDetailData.data.uf ? agentDetailData.data.uf : '',
    tags: agentDetailData.data && agentDetailData.data.tags.map((item) => item.id) ? agentDetailData.data.tags.map((item) => item.id) : '',
    newTag: '',
  };

  const useStyles = makeStyles({
    paper: {
      minWidth: '280px !important',
      maxHeight: 200,
    },
  }, { name: 'MuiPopover' });

  const classes = useStyles();

  const handleBusinessUnityChange = (tagId) => {
    updateTags(tagId, typeTag.BU.type);
    setBusinessUnityField(tagId);
  };

  const handleLineChange = (tagId) => {
    setLineField(tagId);
    updateTags(tagId, typeTag.L.type);
  };

  const handleSectorGroupChange = (tagId) => {
    setGroupSectorField(tagId);
    updateTags(tagId, typeTag.GS.type);
  };

  const handleSectorChange = (tagId) => {
    setSectorField(tagId);
    updateTags(tagId, typeTag.S.type);
  };

  const setPhoneMask = (event, fieldName, setFieldValue) => {
    setFieldValue(fieldName, Mask.tel(event.target.value, null, true));
  };

  const setCepMask = (event, setFieldValue) => {
    setFieldValue('cep', Mask.cep(event.target.value, null, true));
    if (event.target.value.length === 9) {
      handleZipCodeInfo(event.target.value.replace('-', ''));
    }
  };

  const setCepData = (event, setFieldValue, validateForm) => {
    if (zipCodeInfo && Object.values(zipCodeInfo).length && zipCodeInfo.cep === event.target.value) {
      setFieldValue('street', zipCodeInfo.street ? zipCodeInfo.street : '-');
      setFieldValue('neighborhood', zipCodeInfo.neighborhood ? zipCodeInfo.neighborhood : '-');
      setFieldValue('addressDetail', zipCodeInfo.addressDetail ? zipCodeInfo.addressDetail : '');
      setFieldValue('city', zipCodeInfo.city ? zipCodeInfo.city : '-');
      setFieldValue('uf', zipCodeInfo.uf ? zipCodeInfo.uf.toUpperCase() : '-');
      setStateField(zipCodeInfo.uf);
      setTimeout(() => {
        validateForm();
      }, 500);
    } else {
      setFieldValue('cep', '');
      setFieldValue('street', '');
      setFieldValue('neighborhood', '');
      setFieldValue('addressDetail', '');
      setFieldValue('city', '');
      setFieldValue('uf', '');
      setStateField('');
    }
  };

  const handleStateChange = (value, setFieldValue) => {
    setStateField(value);
    setFieldValue('uf', value);
  };

  const _onOpenAgentDialog = () => {
    setIsOpenAgentDialog(true);
  };

  const _onCloseAgentDialog = () => {
    setIsOpenAgentDialog(false);
  };

  const updateTags = (tagId, type) => {
    const newAgentTags = [...agentTags];
    if (type === typeTag.BU.type && newAgentTags.includes(businessUnityField)) {
      newAgentTags.splice(newAgentTags.indexOf(businessUnityField), 1);
    }
    if (type === typeTag.L.type && newAgentTags.includes(lineField)) {
      newAgentTags.splice(newAgentTags.indexOf(lineField), 1);
    }
    if (type === typeTag.GS.type && newAgentTags.includes(groupSectorField)) {
      newAgentTags.splice(newAgentTags.indexOf(groupSectorField), 1);
    }
    if (type === typeTag.S.type && newAgentTags.includes(sectorField)) {
      newAgentTags.splice(newAgentTags.indexOf(sectorField), 1);
    }
    setAgentTags([tagId, ...newAgentTags]);
  };

  const _handleAgentSubmit = (values) => {
    const filteredValues = values;
    delete filteredValues.newTag;
    if (agentDetailData.data) {
      handleAgentSubmit(filteredValues, agentTags, agentDetailData.data.id);
    } else {
      handleAgentSubmit(filteredValues, agentTags);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={handleValidations}
      enableReinitialize
      className={classes.root}
    >
      {({
        isValid,
        touched,
        values,
        errors,
        setFieldValue,
        validateForm,
      }) => (
        <>
          {console.log(errors, isValid)}
          <Page
            userData={userData}
            title={title}
            smallTitle
            originPath={RoutePaths.AGENT}
            originPathName="Representantes"
            renderRight={() => (
              <>
                <PageActionButton
                  variant="outlined"
                  type="button"
                  onClick={() => { history.push(RoutePaths.AGENT); }}
                >
                  Cancelar
                </PageActionButton>
                <PageActionButton
                  type="submit"
                  onClick={_onOpenAgentDialog}
                  disabled={(!isEditing && Object.values(touched).length === 0) || !isValid}
                >
                  Salvar
                </PageActionButton>
              </>
            )}
          >
            <Divider />
            <SectionWrapper>
              <SectionTitleContainer>
                <SectionTitleLabel>Dados pessoais</SectionTitleLabel>
              </SectionTitleContainer>
              <CardSection>
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      xs={12}
                    >
                      <StyledField
                        name="name"
                        type="text"
                        label="Nome*"
                        variant="outlined"
                        component={TextField}
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      lg={3}
                      md={3}
                      xs={3}
                    >
                      <StyledField
                        name="phone1"
                        type="tel"
                        label="Telefone*"
                        variant="outlined"
                        component={TextField}
                        onKeyUp={(event) => { setPhoneMask(event, 'phone1', setFieldValue); }}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={3}
                      md={3}
                      xs={3}
                    >
                      <StyledField
                        name="phone2"
                        type="text"
                        label="Telefone 2 (opcional)"
                        variant="outlined"
                        component={TextField}
                        onKeyUp={(event) => { setPhoneMask(event, 'phone2', setFieldValue); }}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      xs={6}
                    >
                      <StyledField
                        name="email"
                        type="text"
                        label="E-mail*"
                        variant="outlined"
                        component={TextField}
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                  <Divider />
                  <Divider />
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      lg={3}
                      md={3}
                      xs={3}
                    >
                      <StyledField
                        name="cep"
                        type="text"
                        label="CEP*"
                        variant="outlined"
                        component={TextField}
                        onKeyUp={(event) => { setCepMask(event, setFieldValue); }}
                        onBlur={(event) => { setCepData(event, setFieldValue, validateForm); }}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={9}
                      md={9}
                      xs={9}
                    >
                      <StyledField
                        name="street"
                        type="text"
                        label="Endereço*"
                        variant="outlined"
                        component={TextField}
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      lg={3}
                      md={3}
                      xs={3}
                    >
                      <StyledField
                        name="number"
                        type="text"
                        label="Número*"
                        variant="outlined"
                        component={TextField}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      md={4}
                      xs={4}
                    >
                      <StyledField
                        name="addressDetail"
                        type="text"
                        label="Complemento (opcional)"
                        variant="outlined"
                        component={TextField}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={5}
                      md={5}
                      xs={5}
                    >
                      <StyledField
                        name="neighborhood"
                        type="text"
                        label="Bairro*"
                        variant="outlined"
                        component={TextField}
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      lg={5}
                      md={5}
                      xs={5}
                    >
                      <StyledField
                        name="city"
                        type="text"
                        label="Cidade*"
                        variant="outlined"
                        component={TextField}
                        disabled
                      />
                    </Grid>
                    <Grid
                      item
                      lg={2}
                      md={2}
                      xs={2}
                    >
                      <FormControlStyled variant="outlined">
                        <InputLabel id="stateField">Estado*</InputLabel>
                        <SelectStyled
                          labelId="stateField"
                          id="stateField"
                          value={stateField}
                          label="Estado*"
                          disabled
                        >
                          {stateOptionList.map((item) => (
                            <MenuItemStyled
                              key={`line-${item.name}`}
                              value={item.name}
                              onClick={() => { handleStateChange(item.name, setFieldValue); }}
                            >
                              <Radio
                                color="primary"
                                checked={stateField === item.name}
                              />
                              {item.name}
                            </MenuItemStyled>
                          ))}
                        </SelectStyled>
                      </FormControlStyled>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardSection>
            </SectionWrapper>
            <SectionWrapper>
              <SectionTitleContainer>
                <SectionTitleLabel>Categorização do representante (tags)</SectionTitleLabel>
              </SectionTitleContainer>
              <CardSection>
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      lg={4}
                      md={4}
                      xs={4}
                    >
                      <FormControlStyled variant="outlined">
                        <InputLabel id="businessUnityField">Categoria 1:</InputLabel>
                        <SelectStyled
                          labelId="businessUnityField"
                          id="businessUnityField"
                          value={businessUnityField}
                          label="Categoria 1:"
                        >
                          <StickyMenuItem>
                            <MenuItemFixedStyled
                              onClick={() => {
                                setIsOpenTagModal(true);
                                setFieldValue('newTag', '');
                                setTypeOfTag(typeTag.BU.type);
                                setTypeOfTagText(1);
                                setTypeOfTagLabel(typeTag.BU.label);
                              }}
                            >
                              <EditStyled
                                fontSize="small"
                              />
                              Nova tag C1
                            </MenuItemFixedStyled>
                          </StickyMenuItem>
                          {dataBusinessUnityList.map((item) => (
                            <MenuItemStyled
                              key={`business-${item.id}`}
                              value={item.id}
                              onClick={() => { handleBusinessUnityChange(item.id); }}
                            >
                              <Radio
                                color="primary"
                                checked={businessUnityField === item.id}
                              />
                              {item.name}
                            </MenuItemStyled>
                          ))}
                        </SelectStyled>
                      </FormControlStyled>
                    </Grid>
                    <Grid
                      item
                      lg={2}
                      md={2}
                      xs={2}
                    >
                      <FormControlStyled variant="outlined">
                        <InputLabel id="lineField">Categoria 2:</InputLabel>
                        <SelectStyled
                          labelId="lineField"
                          id="lineField"
                          value={lineField}
                          label="Categoria 2:"
                        >
                          <StickyMenuItem>
                            <MenuItemFixedStyled
                              onClick={() => {
                                setIsOpenTagModal(true);
                                setFieldValue('newTag', '');
                                setTypeOfTag(typeTag.L.type);
                                setTypeOfTagText(2);
                                setTypeOfTagLabel(typeTag.L.label);
                              }}
                            >
                              <EditStyled
                                fontSize="small"
                              />
                              Nova tag C2
                            </MenuItemFixedStyled>
                          </StickyMenuItem>
                          {dataLineList.map((item) => (
                            <MenuItemStyled
                              key={`line-${item.id}`}
                              value={item.id}
                              onClick={() => { handleLineChange(item.id); }}
                            >
                              <Radio
                                color="primary"
                                checked={lineField === item.id}
                              />
                              {item.name}
                            </MenuItemStyled>
                          ))}
                        </SelectStyled>
                      </FormControlStyled>
                    </Grid>
                    <Grid
                      item
                      lg={3}
                      md={3}
                      xs={3}
                    >
                      <FormControlStyled variant="outlined">
                        <InputLabel id="groupSectorField">Categoria 3:</InputLabel>
                        <SelectStyled
                          labelId="groupSectorField"
                          id="groupSectorField"
                          value={groupSectorField}
                          label="Categoria 3:"
                        >
                          <StickyMenuItem>
                            <MenuItemFixedStyled
                              onClick={() => {
                                setIsOpenTagModal(true);
                                setFieldValue('newTag', '');
                                setTypeOfTag(typeTag.GS.type);
                                setTypeOfTagText(3);
                                setTypeOfTagLabel(typeTag.GS.label);
                              }}
                            >
                              <EditStyled
                                fontSize="small"
                              />
                              Nova tag C3
                            </MenuItemFixedStyled>
                          </StickyMenuItem>
                          {dataGroupSectorList.map((item) => (
                            <MenuItemStyled
                              key={`sector-group-${item.id}`}
                              value={item.id}
                              onClick={() => { handleSectorGroupChange(item.id); }}
                            >
                              <Radio
                                color="primary"
                                checked={groupSectorField === item.id}
                              />
                              {item.name}
                            </MenuItemStyled>
                          ))}
                        </SelectStyled>
                      </FormControlStyled>
                    </Grid>
                    <Grid
                      item
                      lg={3}
                      md={3}
                      xs={3}
                    >
                      <FormControlStyled variant="outlined">
                        <InputLabel id="sectorField">Categoria 4:</InputLabel>
                        <SelectStyled
                          labelId="sectorField"
                          id="sectorField"
                          value={sectorField}
                          label="Categoria 4:"
                        >
                          <StickyMenuItem>
                            <MenuItemFixedStyled
                              onClick={() => {
                                setIsOpenTagModal(true);
                                setFieldValue('newTag', '');
                                setTypeOfTag(typeTag.S.type);
                                setTypeOfTagText(4);
                                setTypeOfTagLabel(typeTag.S.label);
                              }}
                            >
                              <EditStyled
                                fontSize="small"
                              />
                              Nova tag C4
                            </MenuItemFixedStyled>
                          </StickyMenuItem>
                          {dataSectorList.map((item) => (
                            <MenuItemStyled
                              key={`sector-${item.id}`}
                              value={item.id}
                              onClick={() => { handleSectorChange(item.id); }}
                            >
                              <Radio
                                color="primary"
                                checked={sectorField === item.id}
                              />
                              {item.name}
                            </MenuItemStyled>
                          ))}
                        </SelectStyled>
                      </FormControlStyled>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardSection>
            </SectionWrapper>
            <ActionWrapper>
              <PageActionButton
                variant="outlined"
                type="button"
                onClick={() => { history.push(RoutePaths.AGENT); }}
              >
                Cancelar
              </PageActionButton>
              <PageActionButton
                type="submit"
                onClick={_onOpenAgentDialog}
                disabled={(!isEditing && Object.values(touched).length === 0) || !isValid}
              >
                Salvar
              </PageActionButton>
            </ActionWrapper>
            {loading || agentDetailData.loading || tagBusinessUnityList.loading || tagLineList.loading || tagGroupSectorList.loading || tagSectorList.loading ? <LoadMask /> : null}
            <DialogModal
              title="Salvar"
              size="xs"
              description={(
                <span>
                  Tem certeza que deseja salvar as informações do representante?
                  {agentDetailData.data && agentDetailData.data.name && (
                    <>
                      <BoldText>
                        {' '}
                        {agentDetailData.data.name}
                      </BoldText>
                    </>
                  )}
                </span>
              )}
              cancelButtonText="Cancelar"
              confirmButtonText="Salvar"
              handleCloseDialog={_onCloseAgentDialog}
              handleConfirmDialog={() => { _handleAgentSubmit(values); }}
              isOpenDialog={isOpenAgentDialog}
              loadingDialog={requestDialog.loading}
            />
            <DialogModal
              title="Criar nova tag"
              confirmButtonText="Confirmar"
              handleCloseDialog={() => { setIsOpenTagModal(false); }}
              handleConfirmDialog={() => {
                handleNewTag(`${values.newTag}`, typeOfTagText);
              }}
              enableConfirm={values.newTag === '' ? false : true}
              isOpenDialog={isOpenTagModal}
              size="md"
            >
              <DialogContent>
                <WrapperField>
                  <LabelStyled>
                    Nova tag:
                  </LabelStyled>
                  <StyledField
                    name="newTag"
                    type="text"
                    component={TextField}
                  />
                </WrapperField>
                <TagPreview>
                  Prévia
                </TagPreview>
                <TagWrapper>
                  <Tag>{values.newTag ? `${values.newTag}` : 'Exemplo'}</Tag>
                </TagWrapper>
              </DialogContent>
            </DialogModal>
          </Page>
        </>
      )}
    </Formik>
  );
};

FormAgentLayout.propTypes = {
  userData: PropTypes.object,
  loading: PropTypes.bool,
  requestDialog: PropTypes.object,
  title: PropTypes.string,
  handleAgentSubmit: PropTypes.func,
  isEditing: PropTypes.bool,
  agentDetailData: PropTypes.object,
  handleNewTag: PropTypes.func,
  newTag: PropTypes.object,
  handleZipCodeInfo: PropTypes.func,
  zipCodeInfo: PropTypes.object,
};

FormAgentLayout.defaultProps = {
  userData: {},
  loading: false,
  requestDialog: {},
  title: '',
  handleAgentSubmit: () => { },
  isEditing: false,
  agentDetailData: {},
  handleNewTag: () => { },
  newTag: {
    id: undefined,
    name: undefined,
  },
  handleZipCodeInfo: () => {},
  zipCodeInfo: {
    loading: false,
  },
};

export default FormAgentLayout;
