import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import isEqual from 'lodash.isequal';

import { useHistory, useLocation } from 'react-router-dom';
import { formatISO, parseISO } from 'date-fns';
import {
  Page,
  Button,
  LoadMask,
  DrawerWindow,
  DialogModal,
} from '../../../../components';
import OrdersTable from './ordersTable';
import FilterIndicator from '../../../../components/FilterIndicator';
import { ITEMS_PER_PAGE } from '../../../../constants/Paginate';
import { fieldNames, filtersInitialState, luftFieldNames } from '../../../../constants/order';
import { OrderStatusEnum } from '../../../../constants';
import Filters from './filters';
import OrderDetail from './orderDetail';
import { OrderRoutePaths, RoutePaths } from '../../../../constants/RoutePaths';
import { USER_ROLES } from '../../../../constants/UsersRoles';
import { HttpService } from '../../../../services';
import { useDidUpdateEffect } from '../../../../utils/hooks/did-update-effect';
import { getNumberOfAppliedFilters } from '../../../../utils';

const orderByInitialState = {
  field: undefined,
  orderType: undefined,
};
const searchInitialState = {
  type: undefined,
  term: '',
};

export const updateQueryStringInOrderList = (history, {
  filters, search, orderBy, skip, take,
}) => {
  history.push(`?${HttpService.serializeQueryString({
    companyList: Object.keys(filters.companyList)
      .map((key) => filters.companyList[key] ? key : null),
    createdAtFrom: filters.createdAt.from
      ? formatISO(filters.createdAt.from, { representation: 'date' }) : null,
    createdAtTo: filters.createdAt.to
      ? formatISO(filters.createdAt.to, { representation: 'date' }) : null,
    deliveryDateFrom: filters.deliveryDate.from
      ? formatISO(filters.deliveryDate.from, { representation: 'date' }) : null,
    deliveryDateTo: filters.deliveryDate.to
      ? formatISO(filters.deliveryDate.to, { representation: 'date' }) : null,
    agentList: Object.keys(filters.agentList)
      .map((key) => filters.agentList[key] ? key : null),
    statusList: Object.keys(filters.statusList)
      .map((key) => filters.statusList[key] ? key : null),
    promotionalCicleList: Object.keys(filters.promotionalCicleList)
      .map((key) => filters.promotionalCicleList[key] ? key : null),
    materialList: Object.keys(filters.materialList)
      .map((key) => filters.materialList[key] ? key : null),
    productList: Object.keys(filters.productList)
      .map((key) => filters.productList[key] ? key : null),
    searchTerm: search.term,
    take,
    skip,
    ...(orderBy.field && orderBy.orderType ? {
      orderByField: orderBy.field,
      orderByType: orderBy.orderType,
    } : {}),
  })}`);
};

const OrderListLayout = ({
  userData,
  title,
  loading,
  agentList,
  promotionalCicleList,
  materialList,
  productList,
  orderList,
  companyList,
  orderDetailData,
  onRequestOrderDetail,
  onRequestAgentList,
  onRequestPromotionalCicleList,
  onRequestMaterialList,
  onRequestProductList,
  onRequestOrderList,
  onRequestCompanyList,
  onExportFilteredData,
  onExportSelectedData,
  companyDialogList,
  handleOpenCompanyDialog,
  handleOutputCompanyDialog,
  loadingDialog,
  handleConfirmApproveDialog,
  handleConfirmRefuseDialog,
  handleConfirmCancelDialog,
  handleConfirmMultipleApproveDialog,
  loadingOrderDialog,
  orderOnApprovalFinished,
  loadingOrderOnMultipleApprovalDialog,
  orderTotalOnMultipleApproval,
  orderOnMultipleApproval,
  orderOnMultipleApprovalFinished,
}) => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE);
  const [appliedFilters, setAppliedFilters] = useState(filtersInitialState);
  const [countAppliedFilters, setCountAppliedFilters] = useState(0);
  const [openDrawer, setDrawerState] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [orderNumber, setOrderNumber] = useState('');
  const [orderBy, setOrderBy] = useState(orderByInitialState);
  const [search, setSearch] = useState(searchInitialState);
  const [isApproveDialog, setIsApproveDialog] = useState({ id: null, open: false });
  const [isRefuseDialog, setIsRefuseDialog] = useState({ id: null, open: false });
  const [isCancelDialog, setIsCancelDialog] = useState({ id: null, open: false });
  const [isMultipleApproval, setIsMultipleApproval] = useState({ rejectedOrders: null, orders: null, open: false });
  const { search: locationSearch } = useLocation();
  const location = useLocation();

  const _buildOrderRequestFromQueryString = () => {
    const queryObject = HttpService.unserializeQueryString(locationSearch);
    const skip = queryObject.skip || 0;
    const take = queryObject.take || ITEMS_PER_PAGE;

    if (locationSearch === '') {
      return ({
        filters: {
          ...filtersInitialState,
        },
        take,
        skip,
        orderBy: {
          ...orderByInitialState,
        },
        search: {
          ...searchInitialState,
        },
      });
    }

    return ({
      filters: {
        companyList: HttpService.unserializeQueryStringCheckboxObject(
          queryObject.companyList, null, { ...filtersInitialState.companyList }),
        createdAt: {
          from: queryObject.createdAtFrom ? parseISO(queryObject.createdAtFrom) : null,
          to: queryObject.createdAtTo ? parseISO(queryObject.createdAtTo) : null,
        },
        deliveryDate: {
          from: queryObject.deliveryDateFrom ? parseISO(queryObject.deliveryDateFrom) : null,
          to: queryObject.deliveryDateTo ? parseISO(queryObject.deliveryDateTo) : null,
        },
        agentList: HttpService.unserializeQueryStringCheckboxObject(
          queryObject.agentList, null, { ...filtersInitialState.agentList }),
        statusList: HttpService.unserializeQueryStringCheckboxObject(
          queryObject.statusList, Number, { ...filtersInitialState.statusList }),
        promotionalCicleList: HttpService.unserializeQueryStringCheckboxObject(
          queryObject.promotionalCicleList, null, { ...filtersInitialState.promotionalCicleList }),
        materialList: HttpService.unserializeQueryStringCheckboxObject(
          queryObject.materialList, null, { ...filtersInitialState.materialList }),
        productList: HttpService.unserializeQueryStringCheckboxObject(
          queryObject.productList, null, { ...filtersInitialState.productList }),
      },
      take,
      skip,
      orderBy: {
        field: queryObject.orderByField,
        orderType: Number(queryObject.orderByType),
      },
      search: {
        term: queryObject.searchTerm,
        type: queryObject.searchType,
      },
    });
  };

  useEffect(() => {
    const {
      filters,
      orderBy,
      search,
    } = _buildOrderRequestFromQueryString();

    if (location.state && location.state.materialId && location.state.statusList) {
      filtersInitialState[fieldNames.MATERIAL] = {};
      filters[fieldNames.MATERIAL] = location.state.materialId;
      filters[fieldNames.STATUS] = location.state.statusList;
    }
    if (location.state && location.state.agentId) {
      filtersInitialState[fieldNames.AGENT] = {};
      filters[fieldNames.AGENT] = location.state.agentId;
    }
    setAppliedFilters(filters);
    setOrderBy(orderBy);
    setSearch(search);
  }, []);

  useDidUpdateEffect(() => {
    const requestParams = {
      filters: appliedFilters,
      take: itemsPerPage,
      skip: (currentPage - 1) * itemsPerPage,
      orderBy,
      search,
    };

    setCountAppliedFilters(getNumberOfAppliedFilters(filtersInitialState, appliedFilters));
    onRequestOrderList(requestParams);
  }, [
    currentPage,
    itemsPerPage,
    appliedFilters,
    orderBy,
    search,
  ]);

  const _onPageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const _onChangeItemsPerPage = (event, newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const _onFiltersChange = ({ orderBy, ...newFilters }) => {
    setSearch(searchInitialState);
    setAppliedFilters(newFilters);
    setCurrentPage(1);
  };

  const _onClearFilter = (handleReset) => {
    if (handleReset) handleReset();
    setAppliedFilters({ ...filtersInitialState });
    setCurrentPage(1);
  };

  const _onChangeDataOrder = (field, orderType) => {
    setOrderBy({
      field,
      orderType,
    });
  };

  const _onSearch = (value) => {
    setAppliedFilters({
      ...filtersInitialState,
    });
    setSearch(value);
    setCurrentPage(1);
  };

  const onMultipleApproveSelectedData = (orders) => {
    const rejectedOrders = [];

    orders.forEach((order) => {
      if (order.data.status !== OrderStatusEnum.ORDER_PENDING_APPROVAL) {
        rejectedOrders.push(order.data.id);
      }
    });

    if (rejectedOrders.length > 0) {
      setIsMultipleApproval({
        rejectedOrders,
        orders,
        open: true,
      });
    } else {
      setIsMultipleApproval({
        rejectedOrders: null,
        orders,
        open: true,
      });
      handleConfirmMultipleApproveDialog(orders);
    }
  };

  const _onClearSearch = (handleReset) => {
    if (handleReset) handleReset();
    setSearch(searchInitialState);
    _onClearFilter();
  };

  const _onRequestOrderDetail = (data) => {
    setDrawerState(true);
    onRequestOrderDetail(data.id);
    setOrderData([{ data }]);
    setOrderNumber(data.orderNumber);
  };

  const _onCloseDrawer = () => {
    setDrawerState(false);
  };

  const _onCloseDialog = () => {
    setIsApproveDialog({ id: null, open: false });
    setIsRefuseDialog({ id: null, open: false });
    setIsCancelDialog({ id: null, open: false });
    setIsMultipleApproval({ rejectedOrders: null, orders: null, open: false });
    if (orderOnApprovalFinished || orderOnMultipleApprovalFinished) {
      setDrawerState(false);
      setTimeout(() => {
        onRequestOrderList({
          filters: appliedFilters,
          take: itemsPerPage,
          skip: (currentPage - 1) * itemsPerPage,
          orderBy,
          search,
        });
      }, 300);
    }
  };

  const _onOpenUrls = (nfes) => {
    nfes.map((item) => item.url ? window.open(item.url) : null);
  };

  return (
    <Page
      userData={userData}
      title={title}
      renderRight={() => (
        <>
          {(userData.userRole === USER_ROLES.COMPANY || userData.userRole === USER_ROLES.AGENT) && (
            <Button
              size="large"
              color="secondary"
              variant="contained"
              disabled
              onClick={() => {
                history.push(`${RoutePaths.ORDER}${OrderRoutePaths.NEW}`);
              }}
            >
              Novo pedido
            </Button>
          )}
        </>
      )}
      companyDialogList={companyDialogList}
      loadingDialog={loadingDialog}
      handleOpenCompanyDialog={handleOpenCompanyDialog}
      handleOutputCompanyDialog={(companyId) => {
        handleOutputCompanyDialog(companyId, () => {
          _onClearSearch();
        });
      }}
      openDrawer={openDrawer}
    >
      <Formik
        initialValues={{
          [luftFieldNames.COMPANY]: appliedFilters[luftFieldNames.COMPANY],
          [fieldNames.CREATE_DATE]: appliedFilters[fieldNames.CREATE_DATE],
          [fieldNames.DELIVERY_DATE]: appliedFilters[fieldNames.DELIVERY_DATE],
          [fieldNames.AGENT]: appliedFilters[fieldNames.AGENT],
          [fieldNames.STATUS]: appliedFilters[fieldNames.STATUS],
          [fieldNames.PROMOTIONAL_CICLE]: appliedFilters[fieldNames.PROMOTIONAL_CICLE],
          [fieldNames.MATERIAL]: appliedFilters[fieldNames.MATERIAL],
          [fieldNames.PRODUCT]: appliedFilters[fieldNames.PRODUCT],
        }}
        onSubmit={_onFiltersChange}
        enableReinitialize
      >
        {({ handleReset }) => (
          <>
            <Filters
              userData={userData}
              agentList={agentList}
              promotionalCicleList={promotionalCicleList}
              materialList={materialList}
              productList={productList}
              companyList={companyList}
              onRequestAgentList={onRequestAgentList}
              onRequestPromotionalCicleList={onRequestPromotionalCicleList}
              onRequestMaterialList={onRequestMaterialList}
              onRequestProductList={onRequestProductList}
              onRequestCompanyList={onRequestCompanyList}
              onSearch={_onSearch}
              listCountResults={orderList.list.length}
              appliedFilters={appliedFilters}
            />
            {(!isEqual(filtersInitialState, appliedFilters) || search.term) && (
              <FilterIndicator
                userData={userData}
                onExport={() => onExportFilteredData(appliedFilters, search)}
                onClear={() => _onClearFilter(handleReset)}
                onClearSearch={() => _onClearSearch(handleReset)}
                appliedFilters={countAppliedFilters}
                searchTerm={search.term}
                hideExport={!orderList.list.length}
                listCountResults={orderList.list.length}
              />
            )}
            <OrdersTable
              userData={userData}
              data={orderList.list}
              loading={orderList.loading}
              currentPage={currentPage}
              totalItems={orderList.total}
              itemsPerPage={itemsPerPage}
              onPageChange={_onPageChange}
              onChangeItemsPerPage={_onChangeItemsPerPage}
              onExportSelectedData={onExportSelectedData}
              onApproveSelectedData={onMultipleApproveSelectedData}
              onChangeDataOrder={_onChangeDataOrder}
              dataOrder={orderBy}
              onRequestOrderDetail={_onRequestOrderDetail}
              multiselect={userData.userRole !== USER_ROLES.MASTER || (userData.userRole === USER_ROLES.MASTER && !!userData.companyName)}
            />
          </>
        )}
      </Formik>
      <DrawerWindow
        openDrawer={orderDetailData.data.receiver !== undefined && !orderDetailData.loading && openDrawer}
        handleCloseDrawer={_onCloseDrawer}
        hideBackdrop
      >
        <OrderDetail
          userData={userData}
          orderNumber={orderNumber}
          orderData={orderData}
          orderDetailData={orderDetailData}
          onCloseDrawer={_onCloseDrawer}
          onExportSelectedData={onExportSelectedData}
          openApproveOrderDialog={(id, open) => setIsApproveDialog({ id, open })}
          openRefuseOrderDialog={(id, open) => setIsRefuseDialog({ id, open })}
          openCancelOrderDialog={(id, open) => setIsCancelDialog({ id, open })}
          handleOpenUrls={_onOpenUrls}
        />
      </DrawerWindow>

      <DialogModal
        title={orderOnApprovalFinished ? 'Pedido aprovado' : 'Aprovar pedido'}
        description={orderOnApprovalFinished ? '' : 'Tem certeza que deseja aprovar este pedido?'}
        cancelButtonText={orderOnApprovalFinished ? 'Fechar' : 'Voltar'}
        hideConfirmButton={orderOnApprovalFinished}
        confirmButtonText="Aprovar pedido"
        handleCloseDialog={_onCloseDialog}
        loadingDialog={loadingOrderDialog}
        handleConfirmDialog={() => { handleConfirmApproveDialog(isApproveDialog.id); }}
        isOpenDialog={isApproveDialog.open}
      />

      <DialogModal
        title={orderOnApprovalFinished ? 'Pedido recusado' : 'Recusar pedido'}
        description={orderOnApprovalFinished ? '' : 'Tem certeza que deseja recusar este pedido?'}
        cancelButtonText={orderOnApprovalFinished ? 'Fechar' : 'Voltar'}
        hideConfirmButton={orderOnApprovalFinished}
        confirmButtonText="Recusar pedido"
        handleCloseDialog={_onCloseDialog}
        loadingDialog={loadingOrderDialog}
        handleConfirmDialog={() => { handleConfirmRefuseDialog(isRefuseDialog.id); }}
        isOpenDialog={isRefuseDialog.open}
      />

      <DialogModal
        title={isMultipleApproval.rejectedOrders && !orderOnMultipleApprovalFinished ? 'Aprovar pedidos' : 'Pedidos aprovados'}
        description={
          isMultipleApproval.rejectedOrders && !orderOnMultipleApprovalFinished
            ? `Os seguintes pedidos não poderão ser aprovados porque não estão com aprovação pendente: ${isMultipleApproval.rejectedOrders.join(', ')}`
            : `Pronto, ${orderOnMultipleApproval} de ${orderTotalOnMultipleApproval} pedidos selecionados foram aprovados.`
        }
        cancelButtonText={isMultipleApproval.rejectedOrders && !orderOnMultipleApprovalFinished ? 'Cancelar' : 'Fechar'}
        hideConfirmButton={!isMultipleApproval.rejectedOrders || orderOnMultipleApprovalFinished}
        confirmButtonText="Prosseguir assim mesmo"
        handleCloseDialog={_onCloseDialog}
        loadingDialog={loadingOrderOnMultipleApprovalDialog}
        handleConfirmDialog={() => { handleConfirmMultipleApproveDialog(isMultipleApproval.orders); }}
        isOpenDialog={isMultipleApproval.open}
      />

      <DialogModal
        title={orderOnApprovalFinished ? 'Pedido cancelado' : 'Cancelar pedido'}
        description={orderOnApprovalFinished ? '' : 'Tem certeza que deseja cancelar este pedido?'}
        cancelButtonText={orderOnApprovalFinished ? 'Fechar' : 'Voltar'}
        hideConfirmButton={orderOnApprovalFinished}
        confirmButtonText="Cancelar pedido"
        handleCloseDialog={_onCloseDialog}
        loadingDialog={loadingOrderDialog}
        handleConfirmDialog={() => { handleConfirmCancelDialog(isCancelDialog.id); }}
        isOpenDialog={isCancelDialog.open}
      />

      {loading ? <LoadMask /> : null}
    </Page>
  );
};

OrderListLayout.propTypes = {
  userData: PropTypes.object,
  loading: PropTypes.bool,
  title: PropTypes.string,
  agentList: PropTypes.shape({
    list: PropTypes.any,
    loading: PropTypes.any,
    total: PropTypes.any,
  }),
  materialList: PropTypes.shape({
    list: PropTypes.any,
    loading: PropTypes.any,
    total: PropTypes.any,
  }).isRequired,
  orderList: PropTypes.shape({
    list: PropTypes.any,
    loading: PropTypes.any,
    total: PropTypes.any,
  }).isRequired,
  productList: PropTypes.shape({
    list: PropTypes.any,
    loading: PropTypes.any,
    total: PropTypes.any,
  }).isRequired,
  promotionalCicleList: PropTypes.shape({
    list: PropTypes.any,
    loading: PropTypes.any,
    total: PropTypes.any,
  }).isRequired,
  companyList: PropTypes.shape({
    list: PropTypes.any,
    loading: PropTypes.any,
    total: PropTypes.any,
  }),
  onExportFilteredData: PropTypes.func.isRequired,
  onExportSelectedData: PropTypes.func.isRequired,
  onRequestAgentList: PropTypes.func,
  onRequestMaterialList: PropTypes.func.isRequired,
  onRequestOrderList: PropTypes.func.isRequired,
  onRequestProductList: PropTypes.func.isRequired,
  onRequestPromotionalCicleList: PropTypes.func.isRequired,
  onRequestCompanyList: PropTypes.func,
  companyDialogList: PropTypes.object,
  loadingDialog: PropTypes.bool,
  handleOpenCompanyDialog: PropTypes.func,
  handleOutputCompanyDialog: PropTypes.func,
  loadingOrderDialog: PropTypes.bool,
  orderOnApprovalFinished: PropTypes.bool,
};

OrderListLayout.defaultProps = {
  userData: {},
  loading: false,
  title: '',
  onRequestCompanyList: () => { },
  agentList: {
    list: [],
    loading: false,
    total: 0,
  },
  companyList: {
    list: [],
    loading: false,
    total: 0,
  },
  loadingOrderDialog: false,
  orderOnApprovalFinished: false,
  companyDialogList: {},
  loadingDialog: false,
  handleOpenCompanyDialog: () => { },
  handleOutputCompanyDialog: () => { },
  onRequestAgentList: () => { },
};

export default OrderListLayout;
