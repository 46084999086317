// Action types
import actionTypes from './types';
import { AuthActions } from '../../auth';
import { getSessionToken } from '../../../utils';

export const hydrate = (data) => ({
  type: actionTypes.HYDRATE,
  data,
});

export const hydrateApplication = (urlParams = {}) => async (dispatch) => {
  const hydrateData = {
    lang: urlParams.lang,
  };
  const sessionToken = getSessionToken();

  dispatch(hydrate(hydrateData));
  dispatch(AuthActions.hydrateUserDataStorage());
  if (sessionToken) dispatch(AuthActions.setToken(sessionToken));
};
