import React from 'react';
import { Redirect } from 'react-router-dom';

import {
  PrivateRoute,
} from '../../components';
import HomePage from '../home';
import PairingPage from '../pairing';
import StockPage from '../shared/stock/material-list';
import LuftOrderListPage from './order/order-list';
import MaterialDetailPage from '../shared/stock/stock-detail';
import { RoutePaths } from '../../constants/RoutePaths';
import { USER_ROLES } from '../../constants/UsersRoles';

const LuftRoutes = ({
  userData,
}) => (
  [
    <PrivateRoute
      key={`route-${RoutePaths.HOME}`}
      exact
      path={RoutePaths.HOME}
      render={() => (
        <HomePage
          userData={userData}
        />
      )}
    />,
    (userData || {}).companyName && (
      <PrivateRoute
        key={`route-${RoutePaths.STOCK}`}
        exact
        path={RoutePaths.STOCK}
        render={() => (
          <StockPage
            userData={userData}
          />
        )}
      />
    ),
    (userData || {}).companyName && (
      <PrivateRoute
        key={`route-${RoutePaths.STOCK}/:id`}
        exact
        path={`${RoutePaths.STOCK}/:id`}
        render={(props) => (
          <MaterialDetailPage
            userData={userData}
            {...props}
          />
        )}
      />
    ),
    <PrivateRoute
      key={`route-${RoutePaths.ORDER}`}
      exact
      path={RoutePaths.ORDER}
      render={() => (
        <LuftOrderListPage
          userData={userData}
        />
      )}
    />,
    userData.userRole === USER_ROLES.MASTER && !userData.companyName && (
      <PrivateRoute
        key={`route-${RoutePaths.PAIRING}`}
        exact
        path={RoutePaths.PAIRING}
        render={() => (
          <PairingPage
            userData={userData}
          />
        )}
      />
    ),
    <Redirect
      key={`route-${RoutePaths.HOME}`}
      to={RoutePaths.HOME}
    />,
  ]
);

export default LuftRoutes;
