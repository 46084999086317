import React from 'react';
import { Redirect } from 'react-router-dom';

import {
  PrivateRoute,
} from '../../components';
import HomePage from '../home';
import AgentNewOrderPage from './order/new-order';
import AgentOrderListPage from './order/order-list';
import StockPage from '../shared/stock/material-list';
import MaterialDetailPage from '../shared/stock/stock-detail';

import {
  RoutePaths,
  OrderRoutePaths,
} from '../../constants/RoutePaths';

const AgentRoutes = ({ userData }) => (
  [
    <PrivateRoute
      key={`route-${RoutePaths.HOME}`}
      exact
      path={RoutePaths.HOME}
      render={() => (
        <HomePage
          userData={userData}
        />
      )}
    />,
    <PrivateRoute
      key={`route-${RoutePaths.STOCK}`}
      exact
      path={RoutePaths.STOCK}
      render={() => (
        <StockPage
          userData={userData}
        />
      )}
    />,
    <PrivateRoute
      key={`route-${RoutePaths.STOCK}/:id`}
      exact
      path={`${RoutePaths.STOCK}/:id`}
      render={(props) => (
        <MaterialDetailPage
          userData={userData}
          {...props}
        />
      )}
    />,
    <PrivateRoute
      key={`route-${RoutePaths.ORDER}`}
      exact
      path={RoutePaths.ORDER}
      render={() => (
        <AgentOrderListPage
          userData={userData}
        />
      )}
    />,
    <PrivateRoute
      key={`route-${RoutePaths.ORDER}${OrderRoutePaths.NEW}`}
      exact
      path={`${RoutePaths.ORDER}${OrderRoutePaths.NEW}`}
      render={(props) => (
        <AgentNewOrderPage
          userData={userData}
          {...props}
        />
      )}
    />,
    <Redirect
      key={`route-${RoutePaths.HOME}`}
      to={RoutePaths.HOME}
    />,
  ]
);

export default AgentRoutes;
