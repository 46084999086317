import { createMuiTheme } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import { PaletteColors } from './PaletteColors';

export const Theme = createMuiTheme({
  palette: PaletteColors,
  shape: {
    borderRadius: 4,
  },
}, ptBR);
