export const typeTag = {
  BU: {
    type: 0,
    text: 'BU: ',
    label: 'Unidade de negócio',
  },
  L: {
    type: 1,
    text: 'L: ',
    label: 'Linha',
  },
  GS: {
    type: 2,
    text: 'GS: ',
    label: 'Grupo de setores',
  },
  S: {
    type: 3,
    text: 'S: ',
    label: 'Setor',
  },
};

export const stateOptionList = [
  {
    name: 'AC',
  },
  {
    name: 'AL',
  },
  {
    name: 'AP',
  },
  {
    name: 'AM',
  },
  {
    name: 'BA',
  },
  {
    name: 'CE',
  },
  {
    name: 'DF',
  },
  {
    name: 'ES',
  },
  {
    name: 'GO',
  },
  {
    name: 'MA',
  },
  {
    name: 'MT',
  },
  {
    name: 'MS',
  },
  {
    name: 'MG',
  },
  {
    name: 'PA',
  },
  {
    name: 'PB',
  },
  {
    name: 'PR',
  },
  {
    name: 'PE',
  },
  {
    name: 'PI',
  },
  {
    name: 'RJ',
  },
  {
    name: 'RN',
  },
  {
    name: 'RS',
  },
  {
    name: 'RO',
  },
  {
    name: 'RR',
  },
  {
    name: 'SC',
  },
  {
    name: 'SP',
  },
  {
    name: 'SE',
  },
  {
    name: 'TO',
  },
];
