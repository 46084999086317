import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from '@material-ui/core';

import Button from './Button';
import { Theme, Colors } from '../constants';
import { mediaHelper } from '../utils';

const LeftActions = styled.div`
  flex: 1;
  display: flex;
`;

const StyledDialogTitle = styled(DialogTitle)`
  ${mediaHelper.md`
    min-width: 272px;
  `}
`;

const DialogContentStyled = styled(DialogContent)`
  ${({ border }) => border && `
    border-top: 1px solid ${Colors.GRAY_LIGHT};
  `}
`;

const DialogModal = ({
  title,
  description,
  size,
  children,
  confirmButtonText,
  cancelButtonText,
  isOpenDialog,
  loadingDialog,
  fullScreen,
  handleCloseDialog,
  handleConfirmDialog,
  renderLeftActions,
  hideConfirmButton,
  hideCancelButton,
  showCloseButton,
  handleOnlyClose,
  closeButtonText,
  enableConfirm,
  hasBorder,
}) => {
  const handleKeyDown = (event) => {
    if (event.keyCode === 27) {
      if (handleOnlyClose) {
        handleOnlyClose();
        return;
      }
      if (handleCloseDialog) {
        handleCloseDialog();
      }
    }
  };

  return (
    <Dialog
      open={isOpenDialog}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={size}
      fullScreen={fullScreen}
      onKeyDown={(event) => { handleKeyDown(event); }}
    >
      {title ? (
        <StyledDialogTitle id="alert-dialog-title">{title}</StyledDialogTitle>
      ) : null}
      <DialogContentStyled
        border={hasBorder ? 1 : 0}
      >
        {description ? (
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        ) : (
          children
        )}
      </DialogContentStyled>
      {loadingDialog && (
        <LinearProgress color="secondary" />
      )}
      <DialogActions>
        {renderLeftActions ? (
          <LeftActions>
            {renderLeftActions()}
          </LeftActions>
        ) : null}
        {showCloseButton && (
          <Button
            handleClick={handleOnlyClose}
            color="secondary"
            theme={Theme}
          >
            {closeButtonText}
          </Button>
        )}
        {!hideCancelButton && (
          <Button
            handleClick={handleCloseDialog}
            color="secondary"
            theme={Theme}
          >
            {cancelButtonText}
          </Button>
        )}
        {!hideConfirmButton && (
          <Button
            handleClick={handleConfirmDialog}
            color="secondary"
            autoFocus
            theme={Theme}
            disabled={!enableConfirm}
          >
            {confirmButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

DialogModal.propTypes = {
  title: PropTypes.string,
  size: PropTypes.string,
  description: PropTypes.node,
  children: PropTypes.node,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  isOpenDialog: PropTypes.bool,
  loadingDialog: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
  handleConfirmDialog: PropTypes.func,
  renderLeftActions: PropTypes.func,
  hideConfirmButton: PropTypes.bool,
  hideCancelButton: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  closeButtonText: PropTypes.string,
  enableConfirm: PropTypes.bool,
  hasBorder: PropTypes.bool,
  fullScreen: PropTypes.bool,
};

DialogModal.defaultProps = {
  title: null,
  size: 'xs',
  description: undefined,
  children: undefined,
  confirmButtonText: '',
  cancelButtonText: 'Cancelar',
  isOpenDialog: false,
  loadingDialog: false,
  handleCloseDialog: () => { },
  handleConfirmDialog: () => { },
  renderLeftActions: null,
  hideConfirmButton: false,
  hideCancelButton: false,
  showCloseButton: false,
  closeButtonText: 'Fechar',
  enableConfirm: true,
  hasBorder: false,
  fullScreen: false,
};

export default DialogModal;
