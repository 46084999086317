import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { Paper } from '@material-ui/core';

import { Button } from '../../../../../../components';
import { Colors } from '../../../../../../constants';
import { RoutePaths, StockPermissionRoutePaths } from '../../../../../../constants/RoutePaths';
import { mediaHelper } from '../../../../../../utils/media-helper';

const Subtitle = styled.p`
  width: 100%;
  font-size: 24px;
  margin-top: 32px;
  color: ${Colors.SECONDARY_MEDIUM};
`;

const StockInfoWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StockInfoContent = styled(Paper)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 8px;
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 16px;
  height: 64px;
  background-color: ${({ reserved, available }) => reserved ? Colors.GRAY_LIGHTER
    : available ? Colors.BLUE_DARKER
      : 'white'};
  border-bottom: 1px solid ${Colors.GRAY_LIGHT};

  ${mediaHelper.xl`
    padding: 0 32px;
  `}

`;

const WarehouseTotal = styled.div`
  font-size: 22px;
  color: ${Colors.PRIMARY_BLUE};
`;

const WarehouseText = styled.div`
  min-width: 170px;
  font-size: 14px;
  color: ${Colors.SECONDARY_MEDIUM};
  font-weight: bold;
  text-transform: uppercase;
`;

const ReservedTotal = styled.div`
  font-size: 16px;
  color: ${Colors.GRAY_DARKER};
  text-transform: uppercase;
`;

const ReservedText = styled.div`
  min-width: 170px;
  font-size: 12px;
  color: ${Colors.GRAY};
  font-weight: bold;
  text-transform: uppercase;
`;

const AvailableTotal = styled.div`
  font-size: 22px;
  color: ${Colors.PRIMARY_BLUE};
  text-transform: uppercase;
`;

const AvailableText = styled.div`
  min-width: 170px;
  font-size: 14px;
  color: ${Colors.SECONDARY_MEDIUM};
  font-weight: bold;
  text-transform: uppercase;
`;

const ButtonStyled = styled(Button)`
  margin-left: auto;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  ${mediaHelper.lg`
    width: auto;
    flex-direction: row;
    align-items: center;
  `}
`;

const StockInfo = ({ ...props }) => {
  const {
    stockData,
    isAgentView,
  } = props;
  const history = useHistory();

  return (
    <StockInfoWrapper>
      <Subtitle>
        Estoque
      </Subtitle>
      <StockInfoContent>
        <InfoWrapper>
          <TextWrapper>
            <WarehouseText>
              No armazém
            </WarehouseText>
            <WarehouseTotal>
              <NumberFormat
                value={stockData.totalQuantity}
                displayType="text"
                decimalSeparator=","
                thousandSeparator={true, '.'}
              />
            </WarehouseTotal>
          </TextWrapper>
        </InfoWrapper>
        <InfoWrapper reserved>
          <TextWrapper>
            <ReservedText>
              {isAgentView ? 'Encomendado' : 'Reservado'}
            </ReservedText>
            <ReservedTotal>
              <NumberFormat
                value={stockData.reservedQuantity}
                displayType="text"
                decimalSeparator=","
                thousandSeparator={true, '.'}
              />
            </ReservedTotal>
          </TextWrapper>
          <ButtonStyled
            size="small"
            variant="contained"
            disabled={stockData.reservedQuantity <= 0}
            onClick={() => {
              history.push(RoutePaths.ORDER, {
                materialId: { [stockData.materialId]: true },
                statusList: {
                  1: true,
                  3: true,
                  4: true,
                  5: true,
                  9: true,
                  10: true,
                  11: true,
                  12: true,
                },
              });
            }}
          >
            Pedidos
          </ButtonStyled>
        </InfoWrapper>
        {!isAgentView && (
          <>
            <InfoWrapper reserved>
              <TextWrapper>
                <ReservedText>
                  Expirado ou
                  <br />
                  truncado/vencido
                </ReservedText>
                <ReservedTotal>
                  <NumberFormat
                    value={stockData.expiredBatchQuantity}
                    displayType="text"
                    decimalSeparator=","
                    thousandSeparator={true, '.'}
                  />
                </ReservedTotal>
              </TextWrapper>
            </InfoWrapper>
          </>
        )}
        <InfoWrapper available>
          <TextWrapper>
            <AvailableText>
              Disponível
            </AvailableText>
            <AvailableTotal>
              <NumberFormat
                value={stockData.availableQuantity}
                displayType="text"
                decimalSeparator=","
                thousandSeparator={true, '.'}
              />
            </AvailableTotal>
          </TextWrapper>
          {!isAgentView && (
            <ButtonStyled
              size="small"
              variant="contained"
              disabled={stockData.stockPermissionId === 0}
              onClick={() => { history.push(`${StockPermissionRoutePaths.PERMISSIONS}/${stockData.stockPermissionId}`); }}
            >
              Permissões
            </ButtonStyled>
          )}
        </InfoWrapper>

      </StockInfoContent>
    </StockInfoWrapper>
  );
};

StockInfo.propTypes = {
  stockData: PropTypes.object,
  isAgentView: PropTypes.bool,
};

StockInfo.defaultProps = {
  stockData: {},
  isAgentView: false,
};

export default StockInfo;
