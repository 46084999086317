import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik } from 'formik';
import isEqual from 'lodash.isequal';

import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Container,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core/';
import {
  Header,
  Footer,
  LoadMask,
  DrawerWindow,
  DialogModal,
  EmptyStateWithAction,
  Button,
} from '../../../components';
import { Colors } from '../../../constants/Colors';
import FilterIndicator from '../../../components/FilterIndicator';
import { ITEMS_PER_PAGE } from '../../../constants/Paginate';
import { filtersInitialState } from '../../../constants/order';
import OrdersTable from '../../shared/order/order-list-layout/ordersTable';
import Filters from '../../shared/order/order-list-layout/filters';
import OrderDetail from '../../shared/order/order-list-layout/orderDetail';
import OrdersWmsSlider from './ordersWmsSlider';
import {
  MAX_SLIDES_NUMBER,
  COMPANY_ID_ACTIVE,
} from '../../../constants/PairingInitialValues';
import { orderBySuggestionType } from '../../../constants/order/filters';

const PairingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 78vh;
  background-color: ${Colors.GRAY_LIGHEST_LIGHT};
`;

const Content = styled(Container)`
  padding-bottom: 24px;
`;

const TitleText = styled.span`
  font-size: 38px;
  color: ${Colors.SECONDARY_MEDIUM};
`;

const PairingHeader = styled.div`
  background-color: ${Colors.BLUE_LIGHT};
  padding-top: 32px;
  margin-bottom: 24px;
`;

const StyledContent = styled(Content)`
  position: relative;
  min-height: 344px;
  padding-top: 8px;
  overflow: hidden;
`;

const PairingHeaderRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
`;

const StyledFormControl = styled(FormControl)`
  min-width: 270px;
`;

const StyledEmptyStateWithAction = styled(EmptyStateWithAction)`
  padding: 80px 16px 0 16px;
  border-bottom: 1px solid ${Colors.GRAY_LIGHTER};
  flex-direction: column;
`;

const StyledEmptyActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;

const CleanSearch = styled.a`
  font-size: 14px;
  color: ${Colors.PRIMARY_BLUE};
  text-decoration: underline;
  margin-top: 4px;
  cursor: pointer;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 9;
  background-color: rgba(231,240,245, .5);
`;

const PairingLayout = ({
  title,
  userData,
  loading,
  wmsOrderlist,
  searchWmsOrderlist,
  handleValidations,
  agentList,
  promotionalCicleList,
  materialList,
  productList,
  orderList,
  companyList,
  orderDetailData,
  onRequestOrderDetail,
  onRequestAgentList,
  onRequestPromotionalCicleList,
  onRequestMaterialList,
  onRequestProductList,
  onRequestOrderList,
  onRequestCompanyList,
  onExportFilteredData,
  onExportSelectedData,
  companyDialogList,
  handleOpenCompanyDialog,
  handleOutputCompanyDialog,
  loadingDialog,
  handleConfirmApproveDialog,
  handleConfirmRefuseDialog,
  handleConfirmCancelDialog,
  loadingOrderDialog,
  orderOnApprovalFinished,
  onRequestWmsOrderlist,
  onRequestSearchWmsOrderlist,
  onPairOrder,
  pairOrder,
}) => {
  const [companyState, setCompanyState] = useState(COMPANY_ID_ACTIVE);
  const [changedCompany, setChangedCompany] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [skipSlides, setSkipSlides] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE);
  const [appliedFilters, setAppliedFilters] = useState(filtersInitialState);
  const [countAppliedFilters, setCountAppliedFilters] = useState(0);
  const [openDrawer, setDrawerState] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [orderNumber, setOrderNumber] = useState('');
  const [orderBy, setOrderBy] = useState({
    field: undefined,
    orderType: undefined,
  });
  const [search, setSearch] = useState({
    type: undefined,
    term: '',
  });
  const [searchWms, setSearchWms] = useState('');
  const [isApproveDialog, setIsApproveDialog] = useState({ id: null, open: false });
  const [isRefuseDialog, setIsRefuseDialog] = useState({ id: null, open: false });
  const [isCancelDialog, setIsCancelDialog] = useState({ id: null, open: false });
  const [slideDataActive, setSlideDataActive] = useState({});
  const [checkOrderNumber, setCheckOrderNumber] = useState(null);
  const [zipCode, setZipCode] = useState(null);

  useEffect(() => {
    onRequestOrderList({
      filters: appliedFilters,
      take: itemsPerPage,
      skip: (currentPage - 1) * itemsPerPage,
      orderBy,
      search,
      companyId: companyState,
      zipCode,
    });
  }, [
    currentPage,
    itemsPerPage,
    appliedFilters,
    orderBy,
    search,
  ]);

  useEffect(() => {
    onRequestWmsOrderlist({
      take: MAX_SLIDES_NUMBER,
      skip: skipSlides,
      companyId: companyState,
      changedCompany,
    });
    setChangedCompany(false);
  }, [
    companyState,
    skipSlides,
  ]);

  const changeCompany = (event) => {
    setCompanyState(event.target.value);
    setChangedCompany(true);
    setSearchWms('');
    setZipCode(null);
  };

  const _onPageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const _onChangeItemsPerPage = (event, newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const _onFiltersChange = (newFilters) => {
    if (!isEqual(filtersInitialState, newFilters)) {
      setAppliedFilters(newFilters);
      setCurrentPage(1);

      const newCountAppliedFilters = Object.entries(filtersInitialState).reduce((count, [key, value]) => isEqual(value, newFilters[key]) ? count : count + 1, 0);
      setCountAppliedFilters(newCountAppliedFilters);
    }
  };

  const _onClearFilter = (handleReset) => {
    handleReset();
    setAppliedFilters(filtersInitialState);
    setCountAppliedFilters(0);
    setZipCode(null);
  };

  const _onChangeDataOrder = (field, orderType) => {
    setOrderBy({
      field,
      orderType,
    });
  };

  const _onChangeDataOrderSuggestion = (field) => {
    if (field === orderBySuggestionType.SUGGESTION) {
      setZipCode(slideDataActive.cep);
    } else {
      setZipCode(null);
    }
    setOrderBy({
      field,
      orderType: 'DESC',
    });
  };

  const _onSearch = (value) => setSearch(value);

  const _onSearchWms = (value) => {
    if (value.search.length >= 4) {
      setSearchWms(value.search);
      setSkipSlides(0);
      onRequestSearchWmsOrderlist({
        take: 1,
        skip: 0,
        companyId: companyState,
        searchWms: value.search,
      });
    }
  };

  const _onClearSearch = (handleReset) => {
    handleReset();
    setAppliedFilters(filtersInitialState);
    setSearch({
      type: undefined,
      term: '',
    });
  };

  const _onRequestOrderDetail = (data) => {
    setDrawerState(true);
    onRequestOrderDetail(data.id);
    setOrderData([{ data }]);
    setOrderNumber(data.orderNumber);
  };

  const _onCloseDrawer = () => {
    setDrawerState(false);
  };

  const _onCloseDialog = () => {
    setIsApproveDialog({ id: null, open: false });
    setIsRefuseDialog({ id: null, open: false });
    setIsCancelDialog({ id: null, open: false });
    if (orderOnApprovalFinished) {
      setDrawerState(false);
      setTimeout(() => {
        onRequestOrderList({
          filters: appliedFilters,
          take: itemsPerPage,
          skip: (currentPage - 1) * itemsPerPage,
          orderBy,
          search,
        });
      }, 300);
    }
  };

  const _onOpenUrls = (nfes) => {
    nfes.map((item) => item.url ? window.open(item.url) : null);
  };

  const handleCurrentSlide = (currentSlide) => {
    if (currentSlide === (skipSlides + (MAX_SLIDES_NUMBER - 10))) {
      setSkipSlides(skipSlides + MAX_SLIDES_NUMBER);
    }
  };

  return (
    <>
      <Header
        userData={userData}
        companyDialogList={companyDialogList}
        handleOpenCompanyDialog={handleOpenCompanyDialog}
        handleOutputCompanyDialog={handleOutputCompanyDialog}
        loadingDialog={loadingDialog}
      />
      <PairingWrapper>
        <PairingHeader>
          <StyledContent>
            <PairingHeaderRow>
              <TitleText>
                {title}
              </TitleText>
              {companyDialogList.list.length > 0 && (
                <StyledFormControl variant="outlined">
                  <InputLabel>Empresa</InputLabel>
                  <Select
                    native
                    defaultValue={companyState}
                    onChange={changeCompany}
                    label="Empresa"
                  >
                    {companyDialogList.list.map((item) => (
                      <option
                        key={item.wmsId}
                        value={parseInt(item.wmsId, 10)}
                      >
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </StyledFormControl>
              )}
            </PairingHeaderRow>
            {!searchWms && wmsOrderlist.list && wmsOrderlist.list.length <= 0 && (
              <>
                {!wmsOrderlist.loading && (
                  <StyledEmptyStateWithAction
                    alignCenter
                    emptyStateText="Nenhum pedido pra exibir :("
                    renderAction={() => (
                      <StyledEmptyActionsContainer>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            onRequestWmsOrderlist({
                              take: MAX_SLIDES_NUMBER,
                              skip: skipSlides,
                              companyId: companyState,
                            });
                          }}
                        >
                          Atualizar
                        </Button>
                      </StyledEmptyActionsContainer>
                    )}
                  />
                )}
              </>
            )}
            {searchWms && searchWmsOrderlist.list && searchWmsOrderlist.list.length <= 0 && (
              <>
                {!searchWmsOrderlist.loading && (
                  <StyledEmptyStateWithAction
                    alignCenter
                    emptyStateText="Nenhum pedido pra exibir :("
                    renderAction={() => (
                      <StyledEmptyActionsContainer>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setSearchWms('');
                          }}
                        >
                          Atualizar
                        </Button>
                      </StyledEmptyActionsContainer>
                    )}
                  />
                )}
              </>
            )}
            {(wmsOrderlist.list && wmsOrderlist.list.length > 0) || (searchWms && searchWmsOrderlist.list && searchWmsOrderlist.list.length > 0) ? (
              <>
                <OrdersWmsSlider
                  sliderData={wmsOrderlist.list ? wmsOrderlist.list : []}
                  sliderDataSearch={searchWmsOrderlist.list ? searchWmsOrderlist.list : []}
                  totalItems={searchWms ? 1 : wmsOrderlist.list.length}
                  handleValidations={handleValidations}
                  handleSearchWms={_onSearchWms}
                  handleCurrentSlide={handleCurrentSlide}
                  handleCurrentData={(data) => setSlideDataActive(data)}
                  searchWms={searchWms}
                  companyState={companyState}
                />
                {searchWms && searchWmsOrderlist.list.length > 0 && (
                  <CleanSearch
                    onClick={() => {
                      setSearchWms('');
                    }}
                  >
                    Limpar busca
                  </CleanSearch>
                )}
              </>
            ) : (
              null
            )}
            {(wmsOrderlist.loading && wmsOrderlist.list.length <= 0) || searchWmsOrderlist.loading ? (
              <LoadingWrapper>
                <CircularProgress />
              </LoadingWrapper>
            ) : (
              null
            )}
          </StyledContent>
        </PairingHeader>
        <Content>
          <Formik
            initialValues={filtersInitialState}
            onSubmit={_onFiltersChange}
          >
            {({ handleReset, values }) => (
              <>
                <Filters
                  userData={userData}
                  agentList={agentList}
                  promotionalCicleList={promotionalCicleList}
                  materialList={materialList}
                  productList={productList}
                  companyList={companyList}
                  onRequestAgentList={onRequestAgentList}
                  onRequestPromotionalCicleList={onRequestPromotionalCicleList}
                  onRequestMaterialList={onRequestMaterialList}
                  onRequestProductList={onRequestProductList}
                  onRequestCompanyList={onRequestCompanyList}
                  onSearch={_onSearch}
                  listCountResults={orderList.list.length}
                  values={values}
                  onChangeDataOrderSuggestion={_onChangeDataOrderSuggestion}
                  pairingActive
                />
                {(!isEqual(filtersInitialState, appliedFilters) || search.term) && (
                  <FilterIndicator
                    userData={userData}
                    onExport={() => onExportFilteredData(appliedFilters, search)}
                    onClear={() => _onClearFilter(handleReset)}
                    onClearSearch={() => _onClearSearch(handleReset)}
                    appliedFilters={countAppliedFilters}
                    searchTerm={search.term}
                    hideExport={!orderList.list.length}
                    listCountResults={orderList.list.length}
                  />
                )}
                <OrdersTable
                  userData={userData}
                  data={orderList.list}
                  loading={orderList.loading}
                  currentPage={currentPage}
                  totalItems={orderList.total}
                  itemsPerPage={itemsPerPage}
                  onPageChange={_onPageChange}
                  onChangeItemsPerPage={_onChangeItemsPerPage}
                  onExportSelectedData={onExportSelectedData}
                  onPairOrder={onPairOrder}
                  onChangeDataOrder={_onChangeDataOrder}
                  dataOrder={orderBy}
                  onRequestOrderDetail={_onRequestOrderDetail}
                  handleRowCheck={(orderId) => setCheckOrderNumber(orderId)}
                  slideDataActive={
                    (slideDataActive || {}).orderNumber ? slideDataActive : wmsOrderlist.list.length > 0 ? {
                      orderNumber: wmsOrderlist.list[0].orderNumber,
                      cep: wmsOrderlist.list[0].cep,
                    } : {}
                  }
                  checkOrderNumber={checkOrderNumber}
                  pairingActive
                  multiselect
                  radiobutton
                />
              </>
            )}
          </Formik>
          <DrawerWindow
            openDrawer={orderDetailData.data.receiver !== 'undefined' && !orderDetailData.loading && openDrawer}
            handleCloseDrawer={_onCloseDrawer}
            hideBackdrop
          >
            <OrderDetail
              userData={userData}
              orderNumber={orderNumber}
              orderData={orderData}
              orderDetailData={orderDetailData}
              onCloseDrawer={_onCloseDrawer}
              onExportSelectedData={onExportSelectedData}
              openApproveOrderDialog={(id, open) => setIsApproveDialog({ id, open })}
              openRefuseOrderDialog={(id, open) => setIsRefuseDialog({ id, open })}
              openCancelOrderDialog={(id, open) => setIsCancelDialog({ id, open })}
              handleOpenUrls={_onOpenUrls}
            />
          </DrawerWindow>
          <DialogModal
            title={orderOnApprovalFinished ? 'Pedido aprovado' : 'Aprovar pedido'}
            description={orderOnApprovalFinished ? '' : 'Tem certeza que deseja aprovar este pedido?'}
            cancelButtonText={orderOnApprovalFinished ? 'Fechar' : 'Voltar'}
            hideConfirmButton={orderOnApprovalFinished}
            confirmButtonText="Aprovar pedido"
            handleCloseDialog={_onCloseDialog}
            loadingDialog={loadingOrderDialog}
            handleConfirmDialog={() => { handleConfirmApproveDialog(isApproveDialog.id); }}
            isOpenDialog={isApproveDialog.open}
          />
          <DialogModal
            title={orderOnApprovalFinished ? 'Pedido recusado' : 'Recusar pedido'}
            description={orderOnApprovalFinished ? '' : 'Tem certeza que deseja recusar este pedido?'}
            cancelButtonText={orderOnApprovalFinished ? 'Fechar' : 'Voltar'}
            hideConfirmButton={orderOnApprovalFinished}
            confirmButtonText="Recusar pedido"
            handleCloseDialog={_onCloseDialog}
            loadingDialog={loadingOrderDialog}
            handleConfirmDialog={() => { handleConfirmRefuseDialog(isRefuseDialog.id); }}
            isOpenDialog={isRefuseDialog.open}
          />
          <DialogModal
            title={orderOnApprovalFinished ? 'Pedido cancelado' : 'Cancelar pedido'}
            description={orderOnApprovalFinished ? '' : 'Tem certeza que deseja cancelar este pedido?'}
            cancelButtonText={orderOnApprovalFinished ? 'Fechar' : 'Voltar'}
            hideConfirmButton={orderOnApprovalFinished}
            confirmButtonText="Cancelar pedido"
            handleCloseDialog={_onCloseDialog}
            loadingDialog={loadingOrderDialog}
            handleConfirmDialog={() => { handleConfirmCancelDialog(isCancelDialog.id); }}
            isOpenDialog={isCancelDialog.open}
          />
          {loading || pairOrder.loading ? <LoadMask /> : null}
        </Content>

      </PairingWrapper>
      <Footer />
    </>
  );
};

PairingLayout.propTypes = {
  title: PropTypes.string,
  userData: PropTypes.object,
  loading: PropTypes.bool,
  agentList: PropTypes.shape({
    list: PropTypes.any,
    loading: PropTypes.any,
    total: PropTypes.any,
  }).isRequired,
  materialList: PropTypes.shape({
    list: PropTypes.any,
    loading: PropTypes.any,
    total: PropTypes.any,
  }).isRequired,
  orderList: PropTypes.shape({
    list: PropTypes.any,
    loading: PropTypes.any,
    total: PropTypes.any,
  }).isRequired,
  productList: PropTypes.shape({
    list: PropTypes.any,
    loading: PropTypes.any,
    total: PropTypes.any,
  }).isRequired,
  promotionalCicleList: PropTypes.shape({
    list: PropTypes.any,
    loading: PropTypes.any,
    total: PropTypes.any,
  }).isRequired,
  companyList: PropTypes.shape({
    list: PropTypes.any,
    loading: PropTypes.any,
    total: PropTypes.any,
  }),
  companyDialogList: PropTypes.object,
  loadingDialog: PropTypes.bool,
  onExportFilteredData: PropTypes.func.isRequired,
  onExportSelectedData: PropTypes.func.isRequired,
  onRequestAgentList: PropTypes.func.isRequired,
  onRequestMaterialList: PropTypes.func.isRequired,
  onRequestOrderList: PropTypes.func.isRequired,
  onRequestProductList: PropTypes.func.isRequired,
  onRequestPromotionalCicleList: PropTypes.func.isRequired,
  handleOpenCompanyDialog: PropTypes.func,
  handleOutputCompanyDialog: PropTypes.func,
  onRequestCompanyList: PropTypes.func,
  loadingOrderDialog: PropTypes.bool,
  orderOnApprovalFinished: PropTypes.bool,
  wmsOrderlist: PropTypes.object,
  searchWmsOrderlist: PropTypes.object,
  onPairOrder: PropTypes.func,
  pairOrder: PropTypes.object,
};

PairingLayout.defaultProps = {
  userData: {},
  title: '',
  loading: false,
  onRequestCompanyList: () => { },
  companyList: {
    list: [],
    loading: false,
    total: 0,
  },
  companyDialogList: {},
  loadingDialog: false,
  handleOpenCompanyDialog: () => { },
  handleOutputCompanyDialog: () => { },
  loadingOrderDialog: false,
  orderOnApprovalFinished: false,
  wmsOrderlist: {},
  searchWmsOrderlist: {},
  onPairOrder: () => {},
  pairOrder: {},
};

export default PairingLayout;
