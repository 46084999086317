import React, { useState, useRef, useEffect } from 'react';

import {
  centerCrop,
  makeAspectCrop,
} from 'react-image-crop';

import {
  Add,
  Remove,
  RotateLeft,
  RotateRight,
  Flip,
} from '@material-ui/icons';

import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from '../../utils/hooks/useDebounceEffect';
import 'react-image-crop/dist/ReactCrop.css';
import { Button } from '..';
import {
  ReactCropStyled, Wrapper, ActionsWrapper, WrapperControls,
} from './styles';

function centerAspectCrop(
  mediaWidth,
  mediaHeight,
  aspect,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
        height: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

export function CropImage({
  imgToCrop, crop, currentImageName, setCrop, updateWithCroppedImage, handleCropTool,
}) {
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const blobUrlRef = useRef('');
  const [imgSrc, setImgSrc] = useState('');
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState([1, 1]);
  const [rotate, setRotate] = useState(0);
  const aspect = undefined;
  const [errorMessage, setErrorMessage] = useState(null);
  const [imgWidth, setImgWidth] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);
  const [x, y] = scale;

  function handleCancelCrop() {
    handleCropTool(false);
    setErrorMessage(null);
  }

  function handleRotateImage(direction, degrees) {
    if (direction === 'left') {
      return setRotate(rotate - degrees);
    }

    return setRotate(rotate + degrees);
  }

  function handleZoomImage(zoom) {
    const scaleIncrement = 0.1;
    let scaleX = Math.abs(x);
    let scaleY = Math.abs(y);

    if (zoom === 'zoomIn') {
      scaleX += scaleIncrement;
      scaleY += scaleIncrement;
    } else if (zoom === 'zoomOut') {
      scaleX -= scaleIncrement;
      scaleY -= scaleIncrement;
    }

    const newX = x >= 0 ? scaleX : -scaleX;
    const newY = y >= 0 ? scaleY : -scaleY;

    setScale([newX, newY]);
  }

  function handleFlipImage(direction) {
    const scaleX = direction === 'y' ? x : -x;
    const scaleY = direction === 'x' ? y : -y;

    setScale([scaleX, scaleY]);
  }

  function onImageLoad() {
    const { naturalWidth, naturalHeight } = imgRef.current;
    setImgWidth(naturalWidth);
    setImgHeight(naturalHeight);
    setCrop(centerAspectCrop(naturalWidth, naturalHeight, 290 / 180));
  }

  async function handleCropComplete() {
    try {
      if (!previewCanvasRef.current) {
        throw new Error('Selecione a área da imagem para recortar.');
      }

      const blob = await new Promise((resolve) => previewCanvasRef.current.toBlob(resolve, 'image/jpeg', 0.6));
      if (!blob) {
        throw new Error('Failed to create blob');
      }

      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);

      const croppedImageResponse = await fetch(blobUrlRef.current);
      const croppedImageBlob = await croppedImageResponse.blob();

      const newFilesToPreview = new File([croppedImageBlob], `${currentImageName}`, { type: 'image/jpeg', lastModified: Date.now() });
      updateWithCroppedImage(newFilesToPreview);
      handleCropTool(false);
    } catch (error) {
      setErrorMessage(error.message);
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width
        && completedCrop?.height
        && imgRef.current
        && previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        );
      }
    },
    100,
    [completedCrop, scale, rotate],
  );

  useEffect(() => {
    setCrop(undefined);

    if (imgToCrop instanceof File) {
      const imageUrl = URL.createObjectURL(imgToCrop);
      setImgSrc(imageUrl);
    }
  }, [imgToCrop]);

  return (
    <>
      <Wrapper>
        <WrapperControls>
          <div className="img-control__scale">
            <button aria-label="Ampliar" type="button" onClick={() => handleZoomImage('zoomIn')}><Add /></button>
            <button type="button" aria-label="Dominuir" onClick={() => handleZoomImage('zoomOut')}><Remove /></button>
          </div>
          <div className="img-control__rotate">
            <button type="button" aria-label="Rotacionar para a esquerda" onClick={() => handleRotateImage('left', 90)}><RotateLeft /></button>
            <button type="button" aria-label="Rotacionar para a direita" onClick={() => handleRotateImage('right', 90)}><RotateRight /></button>
          </div>
          <div className="img-control__flip">
            <button type="button" aria-label="Inverter na vertical" onClick={() => handleFlipImage('y')}><Flip style={{ transform: 'rotate(90deg)', fontSize: '1rem' }} /></button>
            <button type="button" aria-label="Inverter na horizontal" onClick={() => handleFlipImage('x')}><Flip style={{ fontSize: '1rem' }} /></button>
          </div>
        </WrapperControls>
        <ReactCropStyled
          crop={crop}
          imgWidth={imgWidth}
          imgHeight={imgHeight}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
          keepSelection
        >
          <img
            ref={imgRef}
            alt="Crop me"
            src={imgSrc}
            onLoad={() => onImageLoad()}
            style={{ transform: `scaleX(${x}) scaleY(${y}) rotate(${rotate}deg)` }}
          />
        </ReactCropStyled>
        {errorMessage && (
          <div className="error">
            <p className="error__message">
              {errorMessage}
            </p>
          </div>
        )}
      </Wrapper>
      <ActionsWrapper>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          handleClick={() => {
            handleCancelCrop();
          }}
        >
          Desfazer
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          handleClick={handleCropComplete}
        >
          Confirmar
        </Button>
        {!!completedCrop && (
          <div style={{ display: 'none' }}>
            <canvas
              ref={previewCanvasRef}
            />
          </div>
        )}
      </ActionsWrapper>
    </>
  );
}
