export const BreakpointValues = {
  xs: 360,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const Breakpoints = {
  xs: `${BreakpointValues.xs}px`,
  sm: `${BreakpointValues.sm}px`,
  md: `${BreakpointValues.md}px`,
  lg: `${BreakpointValues.lg}px`,
  xl: `${BreakpointValues.xl}px`,
};
