import { regionTypes } from './enums';
import { checkboxDefaultInitialState } from '../../components/Filter/checkbox';

/**
 * AGENTS
 */
export const fieldAgentNames = {
  TAG: 'tagsId',
  TAG_NAME: 'tagName',
  CITY: 'city',
  REGION: 'region',
  ORDER_BY: 'orderBy',
  SEARCH: 'name',
};

export const regionLabel = {
  [regionTypes.NORTH]: 'Norte',
  [regionTypes.SOUTH]: 'Sul',
  [regionTypes.SOUTHEAST]: 'Sudeste',
  [regionTypes.MIDWEST]: 'Centro-oeste',
  [regionTypes.NORTH_EAST]: 'Nordeste',
};

export const regionOptionList = [
  {
    value: regionTypes.NORTH,
    label: regionLabel[regionTypes.NORTH],
  },
  {
    value: regionTypes.NORTH_EAST,
    label: regionLabel[regionTypes.NORTH_EAST],
  },
  {
    value: regionTypes.MIDWEST,
    label: regionLabel[regionTypes.MIDWEST],
  },
  {
    value: regionTypes.SOUTHEAST,
    label: regionLabel[regionTypes.SOUTHEAST],
  },
  {
    value: regionTypes.SOUTH,
    label: regionLabel[regionTypes.SOUTH],
  },
];

/**
 * ORDER BY
 */
export const orderByType = {
  ALPHABETICAL: 'alphabeticalOrder',
  GREATER_QUANTITY: 'moreQuantityOrder',
  LESS_QUANTITY: 'lessQuantityOrder',
};

export const orderByTypeLabel = {
  [orderByType.ALPHABETICAL]: 'Ordem alfabética',
  [orderByType.GREATER_QUANTITY]: 'Maior quantidade',
  [orderByType.LESS_QUANTITY]: 'Menor quantidade',
};

export const orderByOptionList = [
  {
    value: orderByType.ALPHABETICAL,
    label: orderByTypeLabel[orderByType.ALPHABETICAL],
  },
  {
    value: orderByType.GREATER_QUANTITY,
    label: orderByTypeLabel[orderByType.GREATER_QUANTITY],
  },
  {
    value: orderByType.LESS_QUANTITY,
    label: orderByTypeLabel[orderByType.LESS_QUANTITY],
  },
];

export const OrderTypes = {
  ASC: 1,
  DESC: 2,
};

export const OrderTypeName = {
  [OrderTypes.ASC]: 'ascending',
  [OrderTypes.DESC]: 'descending',
};

export const filtersAgentInitialState = {
  [fieldAgentNames.TAG]: {},
  [fieldAgentNames.CITY]: null,
  [fieldAgentNames.REGION]: checkboxDefaultInitialState(regionOptionList),
  [fieldAgentNames.ORDER_BY]: {},
  [fieldAgentNames.SEARCH]: {},
};
