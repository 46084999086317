import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik } from 'formik';
import isEqual from 'lodash.isequal';
import { useHistory, useLocation } from 'react-router-dom';

import {
  LoadMask,
  Page,
  Button,
} from '../../../../../components';
import AgentManagementTable from './agentManagementTable';
import { fieldAgentNames, filtersAgentInitialState } from '../../../../../constants/agent';
import FilterIndicator from '../../../../../components/FilterIndicator';
import Filters from './filters';
import { ITEMS_PER_PAGE } from '../../../../../constants/Paginate';
import { HttpService } from '../../../../../services';
import { useDidUpdateEffect } from '../../../../../utils/hooks/did-update-effect';
import { getNumberOfAppliedFilters } from '../../../../../utils';
import { RoutePaths, AgentRoutePaths } from '../../../../../constants/RoutePaths';

const PageActionButton = styled(Button).attrs(({ variant }) => ({
  size: 'large',
  color: 'secondary',
  variant: variant || 'contained',
}))`
  margin-left: 24px;
`;

const orderByInitialState = {
  field: undefined,
  orderType: undefined,
};

const searchInitialState = {
  type: undefined,
  term: '',
};

export const updateQueryStringInAgentList = (history, {
  filters, search, skip, take,
}) => {
  history.push(`?${HttpService.serializeQueryString({
    region: Object.keys(filters.region)
      .map((key) => filters.region[key] ? key : null),
    tagsId: Object.keys(filters.tagsId)
      .map((key) => filters.tagsId[key] ? key : null),
    city: filters.city,
    searchTerm: search.term,
    take,
    skip,
  })}`);
};

const AgentManagementLayout = ({
  userData,
  title,
  loadingDialog,
  onRequestAgentList,
  onRequestTagList,
  agentList,
  tagList,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE);
  const [countAppliedFilters, setCountAppliedFilters] = useState(0);
  const [appliedFilters, setAppliedFilters] = useState(filtersAgentInitialState);
  const [orderBy, setOrderBy] = useState(orderByInitialState);
  const [search, setSearch] = useState(searchInitialState);
  const { search: locationSearch } = useLocation();
  const history = useHistory();

  const _buildOrderRequestFromQueryString = () => {
    const queryObject = HttpService.unserializeQueryString(locationSearch);
    const skip = queryObject.skip || 0;
    const take = queryObject.take || ITEMS_PER_PAGE;

    if (locationSearch === '') {
      return ({
        filters: {
          ...filtersAgentInitialState,
        },
        take,
        skip,
        orderBy: {
          ...orderByInitialState,
        },
        search: {
          ...searchInitialState,
        },
      });
    }

    return ({
      filters: {
        region: HttpService.unserializeQueryStringCheckboxObject(queryObject.region, null, { ...filtersAgentInitialState.region }),
        tagsId: HttpService.unserializeQueryStringCheckboxObject(queryObject.tagsId, null, { ...filtersAgentInitialState.tagsId }),
        city: queryObject.city ? queryObject.city : null,
      },
      take,
      skip,
      orderBy: {
        field: queryObject.orderByField,
        orderType: Number(queryObject.orderByType),
      },
      search: {
        term: queryObject.searchTerm,
        type: queryObject.searchType,
      },
    });
  };

  useEffect(() => {
    const {
      filters,
      orderBy,
      search,
    } = _buildOrderRequestFromQueryString();
    setAppliedFilters(filters);
    setOrderBy(orderBy);
    setSearch(search);
  }, []);

  useDidUpdateEffect(() => {
    const requestParams = {
      filters: appliedFilters,
      take: itemsPerPage,
      skip: (currentPage - 1) * itemsPerPage,
      orderBy,
      search,
    };
    setCountAppliedFilters(getNumberOfAppliedFilters(filtersAgentInitialState, appliedFilters));
    onRequestAgentList(requestParams);
  }, [
    currentPage,
    itemsPerPage,
    appliedFilters,
    orderBy,
    search,
  ]);

  const _onPageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const _onChangeItemsPerPage = (event, newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const _onChangeDataOrder = (field, orderType) => {
    setOrderBy({
      field,
      orderType,
    });
  };

  const _onFiltersChange = ({ ...newFilters }) => {
    setSearch(searchInitialState);
    setAppliedFilters(newFilters);
    setCurrentPage(1);
  };

  const _onSearch = (value) => {
    setAppliedFilters({
      ...filtersAgentInitialState,
    });
    setSearch(value);
    setCurrentPage(1);
  };

  const _onCitySearch = (search, setFieldValue, submitForm) => {
    setFieldValue('city', search.term);
    submitForm();
  };

  const _onClearSearch = (handleReset) => {
    if (handleReset) handleReset();
    setSearch(searchInitialState);
    _onClearFilter();
  };

  const _onClearFilter = (handleReset) => {
    if (handleReset) handleReset();
    setAppliedFilters({ ...filtersAgentInitialState });
    setCurrentPage(1);
  };

  return (
    <>
      <Page
        userData={userData}
        title={title}
        loadingDialog={loadingDialog}
        renderRight={() => (
          <>
            <PageActionButton
              onClick={() => { history.push(`${AgentRoutePaths.NEW}`); }}
            >
              Novo representante
            </PageActionButton>
          </>
        )}
      >
        <Formik
          initialValues={{
            [fieldAgentNames.TAG]: appliedFilters[fieldAgentNames.TAG],
            [fieldAgentNames.CITY]: appliedFilters[fieldAgentNames.CITY],
            [fieldAgentNames.REGION]: appliedFilters[fieldAgentNames.REGION],
            [fieldAgentNames.SEARCH]: appliedFilters[fieldAgentNames.SEARCH],
          }}
          onSubmit={_onFiltersChange}
          enableReinitialize
        >
          {({
            handleReset,
            setFieldValue,
            submitForm,
            values,
          }) => (
            <>
              <Filters
                values={values}
                tagList={tagList}
                onRequestTagList={onRequestTagList}
                onSearch={_onSearch}
                onCitySearch={(search) => { _onCitySearch(search, setFieldValue, submitForm); }}
                listCountResults={tagList.list.length}
                appliedFilters={appliedFilters}
              />
              {(!isEqual(filtersAgentInitialState, appliedFilters) || search.term) && (
                <FilterIndicator
                  userData={userData}
                  onClear={() => _onClearFilter(handleReset)}
                  onClearSearch={() => _onClearSearch(handleReset)}
                  appliedFilters={countAppliedFilters}
                  searchTerm={search.term}
                  listCountResults={agentList.list.length}
                  hideExport
                />
              )}
              <AgentManagementTable
                userData={userData}
                data={agentList.list ? agentList.list : []}
                loading={false}
                currentPage={currentPage}
                totalItems={agentList.total ? agentList.total : 0}
                itemsPerPage={itemsPerPage}
                onPageChange={_onPageChange}
                onChangeItemsPerPage={_onChangeItemsPerPage}
                onChangeDataOrder={_onChangeDataOrder}
                dataOrder={orderBy}
              />
            </>
          )}
        </Formik>
      </Page>
      {agentList.loading ? <LoadMask /> : null}
    </>
  );
};

AgentManagementLayout.propTypes = {
  userData: PropTypes.object,
  title: PropTypes.string,
  loading: PropTypes.bool,
  companyDialogList: PropTypes.object,
  loadingDialog: PropTypes.bool,
  handleOpenCompanyDialog: PropTypes.func,
  handleOutputCompanyDialog: PropTypes.func,
};

AgentManagementLayout.defaultProps = {
  userData: {},
  title: '',
  loading: false,
  companyDialogList: {},
  loadingDialog: false,
  handleOpenCompanyDialog: () => { },
  handleOutputCompanyDialog: () => { },
};

export default AgentManagementLayout;
