import { NotificationTypes } from './types';
// reducer

const initialState = {
  unreadNotificationCount: 0,
  notificationList: []
};

export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
  case NotificationTypes.UPDATE_NOTIFICATION_LIST: {
    const newNotificationList = [
      ...state.notificationList,
      ...action.data.list.filter(notification => !state.notificationList.some(el => el.id === notification.id))
    ];
    const firstRead = newNotificationList.find(el => el.isRead);
    const firstUnread = newNotificationList.find(el => !el.isRead);

    return {
      ...state,
      unreadNotificationCount: action.data.unreadNotificationCount || 0,
      notificationList: newNotificationList.map(el => ({
        ...el,
        firstUnread: firstUnread && el.id === firstUnread.id,
        firstRead: firstRead && el.id === firstRead.id
      }))
    };
  }
  case NotificationTypes.MARK_NOTIFICATION_AS_READ: {
    const isNotificationUnread = state.notificationList.some(el => !el.isRead && el.id === action.id);
    const newNotificationList = state.notificationList.map(el => ({
      ...el, 
      ...(el.id === action.id ? { isRead: true } : {})
    })).sort((a, b) => a.isRead - b.isRead === 0 ? b.createdAt.localeCompare(a.createdAt) : a.isRead - b.isRead);
    const firstRead = newNotificationList.find(el => el.isRead);
    const firstUnread = newNotificationList.find(el => !el.isRead);
      
    return {
      ...state,
      unreadNotificationCount: isNotificationUnread ? (state.unreadNotificationCount - 1 > 0 ? state.unreadNotificationCount - 1 : 0) : state.unreadNotificationCount,
      notificationList: newNotificationList.map(el => ({
        ...el,
        firstUnread: firstUnread && el.id === firstUnread.id,
        firstRead: firstRead && el.id === firstRead.id
      }))
    };
  }
  default:
    return state;
  }
}
