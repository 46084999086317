import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';

import {
  Colors,
} from '../../../../../../constants';

const SpecificationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 48px;
`;

const SpecificationsTableContainer = styled.div`
  border: 1px solid ${Colors.GRAY_LIGHT};
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
`;

const Subtitle = styled.p`
  width: 100%;
  font-size: 24px;
  margin-top: 32px;
  color: ${Colors.SECONDARY_MEDIUM};
`;

const Row = styled(TableRow)`
  &:last-child td,
  &:last-child th {
    border-bottom: none;
  }
`;

const RowNameText = styled.div`
  color: ${Colors.PRIMARY_BLUE};
  font-weight: bold;
`;

const Specifications = ({ ...props }) => {
  const {
    specificationsData,
    isAgentView,
  } = props;

  function createData(name, values) {
    return { name, values };
  }

  const packingQuantity = createData('Quantidade por embalagem', <NumberFormat
    value={specificationsData.packingQuantity ? specificationsData.packingQuantity : 0}
    displayType="text"
    decimalSeparator=","
    thousandSeparator={true, '.'}
  />);

  const dataTable = [
    createData('Tipo de material', specificationsData.materialType),
    createData('Peso unitário', specificationsData.weight),
    createData('Dimensões', `${specificationsData.width} x ${specificationsData.length} x ${specificationsData.height} cm (L x C x A)`),
    createData('Criticidade de validade', specificationsData.criticalTime),
    createData('Aviso de criticidade', specificationsData.warningTime),
  ];

  if (isAgentView) {
    dataTable.splice(-2, 2);
  }

  if (specificationsData.packingQuantity) {
    dataTable.splice(3, 0, packingQuantity);
  }

  return (
    <SpecificationsWrapper>

      <Subtitle>
        Especificações
      </Subtitle>

      <SpecificationsTableContainer>
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody>
              {dataTable.map((row) => (
                <Row key={row.name}>
                  <TableCell component="th" scope="row">
                    <RowNameText>
                      {row.name}
                    </RowNameText>
                  </TableCell>
                  <TableCell align="left">{row.values}</TableCell>
                </Row>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </SpecificationsTableContainer>

    </SpecificationsWrapper>
  );
};

Specifications.propTypes = {
  specificationsData: PropTypes.object,
};

Specifications.defaultProps = {
  specificationsData: {},
};

export default Specifications;
