import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import isEqual from 'lodash.isequal';

import Dropdown from '../../Dropdown';
import Button from '../../Button';
import FilterSearchForm from './searchForm';

import {
  searchType,
} from '../../../constants/stock/filters';
import { BreakpointValues } from '../../../constants';
import { useWindowSize } from '../../../utils';

const propTypes = {
  name: PropTypes.string.isRequired,
  typeList: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  placeholder: PropTypes.any,
  handleOutput: PropTypes.func.isRequired,
  value: PropTypes.exact({
    type: PropTypes.any,
    term: PropTypes.string,
  }),
  minChar: PropTypes.number,
  placement: PropTypes.string,
};
const defaultProps = {
  typeList: [],
  placeholder: '',
  value: {
    type: searchType.NAME,
    term: '',
  },
  minChar: 4,
  placement: 'bottom-start',
};

const FilterSearch = ({
  typeList,
  placeholder,
  name,
  handleOutput,
  value,
  submitOnChange,
  onChange,
  minChar,
  placement,
}) => {
  const [type, setType] = useState(value.type);
  const [term, setTerm] = useState(value.term);
  const [isSearch, setIsSearch] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const currentWindowSize = useWindowSize();

  useEffect(() => {
    if (!isEqual({
      type,
      term,
    }, value)) {
      setType(value.type);
      setTerm(value.term);
    }
  }, [value]);

  const handleChangeTypeRadio = (event) => {
    setType(event.target.value);
  };

  const handleChangeTerm = (event) => {
    setTerm(event.target.value);
  };

  const handleSearchSubmit = (e) => {
    const newValue = {
      type,
      term,
    };

    if (submitOnChange) {
      handleOutput(newValue);
    } else {
      onChange(newValue, e);
    }
  };

  const handleIsSearch = () => {
    setIsSearch(!isSearch);
    setType(value.type);
    setTerm(value.term);
    setSearchOpen(!searchOpen);
  };

  const handleIsSearchClose = () => {
    setIsSearch(false);
    setSearchOpen(false);
    setType(value.type);
    setTerm(value.term);
  };

  return (
    <Dropdown
      size={currentWindowSize.width <= BreakpointValues.md ? '280px' : '360px'}
      toggleIsSearch={handleIsSearchClose}
      searchOpen={searchOpen}
      placement={placement}
      customLabel={(
        <Button
          color="primary"
          variant="contained"
          handleClick={handleIsSearch}
        >
          {isSearch ? (
            'Cancelar'
          ) : (
            <>
              Buscar &nbsp;
              <SearchIcon fontSize="small" />
            </>
          )}
        </Button>
      )}
    >
      <FilterSearchForm
        typeList={typeList}
        placeholder={placeholder}
        name={name}
        value={{
          type,
          term,
        }}
        handleOutput={() => { handleSearchSubmit(); handleIsSearch(); }}
        onChangeType={handleChangeTypeRadio}
        onChangeTerm={handleChangeTerm}
        minChar={minChar}
      />
    </Dropdown>
  );
};

FilterSearch.propTypes = propTypes;
FilterSearch.defaultProps = defaultProps;

export default FilterSearch;
