import React, { useRef } from 'react';
import styled, { css } from 'styled-components';

import {
  LoadMask,
} from './index';

import { Colors } from '../constants/Colors';

import LuftLogo from '../images/luft-logo.svg';
import { mediaHelper } from '../utils';

const Container = styled.div`
  height: 100%;
  background: ${Colors.SECONDARY_MEDIUM};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const FormWrapper = styled.div`
  flex-direction: column;
  padding: 0 24px 0 24px;
  background: white;
  display: flex;
  border-radius: 16px;
  margin: 0px 24px;

  ${mediaHelper.md`
    width: 660px;
    flex-direction: row;
    margin: 0px;
  `}
`;

const FormContainer = styled.div`
  height: 224px;
  max-height: 224px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  ${mediaHelper.md`
    padding: 32px 32px;
    width: 50%;
    border-left: 1px solid ${Colors.GRAY_LIGHT};
  `}
`;

const ImageComponent = styled.img`
  width: 202px;
  margin-bottom: 34px;

  ${mediaHelper.md`
    width: 290px;
    margin-bottom: 80px;
  `}
`;

const TitleLoginWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
  flex-direction: column;
  pointer-events: none;

  ${mediaHelper.md`
    margin-top: 54px;
    align-items: flex-end;
    margin-right: 32px;
  `}
`;

const TitleLogin = styled.span`
  font-size: 24px;
  color: ${Colors.PRIMARY_BLUE};
  display: block;
  
  white-space: nowrap;

  ${({ bold }) => bold
  && css`
      font-weight: bold;
  `}

  ${mediaHelper.md`
    font-size: 34px;
    min-width: 300px;
    text-align: right;
  `}
`;

const InitialPage = ({ children, ...props }) => {
  const {
    loading,
  } = props;

  const submitButton = useRef(null);

  window.addEventListener('keydown', (e) => {
    if (e.key === 'Enter') {
      if (submitButton.current) {
        submitButton.current.click();
      }
    }
  });

  return (
    <Container>

      <ImageComponent src={LuftLogo} alt="Plataforma Luft Promocional" />

      <FormWrapper>

        <TitleLoginWrapper
          ref={submitButton}
          onClick={() => {
            if (children.props.formData.email
                && children.props.formData.password
                && Object.values(children.props.formErrors).length === 0) {
              children.props.onSubmit(children.props.formData);
            }
          }}
        >
          <TitleLogin>
            Plataforma
          </TitleLogin>

          <TitleLogin bold>
            Luft Promocional
          </TitleLogin>
        </TitleLoginWrapper>

        <FormContainer>
          {children}
        </FormContainer>

      </FormWrapper>

      {loading ? <LoadMask /> : null}
    </Container>
  );
};

export default InitialPage;
