import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Container,
  Grid,
} from '@material-ui/core/';
import {
  PageDetail,
  LoadMask,
  DialogModal,
} from '../../../../../components';

import Specifications from './specifications';
import Batches from './batches';
import StockInfo from './stock-info';
import Gallery from './gallery';
import { RoutePaths } from '../../../../../constants/RoutePaths';
import { USER_ROLES } from '../../../../../constants';
import { mediaHelper } from '../../../../../utils';

const GridContainerStyled = styled(Grid)`
  flex-direction: ${({ isAgentView }) => isAgentView ? 'column' : 'column-reverse'};

  ${mediaHelper.lg`
    flex-direction: row;
  `}
`;

const MaterialDetailLayout = ({ ...props }) => {
  const {
    userData,
    requestPageConfig,
    materialData,
    stockData,
    originStockSearch,
    handleValidations,
    handleToggleDrawer,
    handleOpenDialog,
    handleCloseDialog,
    handleConfirmDialog,
    isOpenDialog,
    loadingDialog,
    handleDeleteImage,
    newMaterialImages,
  } = props;

  const isAgentView = userData && userData.userRole === USER_ROLES.AGENT;

  return (
    <>
      <PageDetail
        title={materialData.name ? materialData.name : 'Sem título'}
        userData={userData}
        materialData={materialData}
        materialId={materialData.id ? materialData.id : null}
        materialCode={materialData.code ? materialData.code : 'Nenhum'}
        availableQuantity={stockData.availableQuantity ? stockData.availableQuantity : 0}
        description={materialData.description ? materialData.description : 'Sem descrição'}
        originStockSearch={originStockSearch}
        handleValidations={handleValidations}
        handleOpenDialog={handleOpenDialog}
        handleToggleDrawer={handleToggleDrawer}
        sliderData={materialData.photos != null ? materialData.photos : []}
        handleDeleteImage={handleDeleteImage}
        newMaterialImages={newMaterialImages}
        originPath={RoutePaths.STOCK}
        originPathName="Estoque"
      >
        <Container maxWidth="lg">
          <GridContainerStyled isAgentView={isAgentView} container spacing={3}>
            <Grid
              item
              lg={5}
              md={5}
              xs={12}
            >
              {isAgentView && (
                <StockInfo
                  isAgentView={isAgentView}
                  stockData={{
                    materialId: materialData.id ? materialData.id : null,
                    stockPermissionId: stockData.stockPermissionId ? stockData.stockPermissionId : 0,
                    availableQuantity: stockData.availableQuantity ? stockData.availableQuantity : 0,
                    totalQuantity: stockData.totalQuantity ? stockData.totalQuantity : 0,
                    reservedQuantity: stockData.reservedQuantity ? stockData.reservedQuantity : 0,
                    expiredBatchQuantity: stockData.expiredBatchQuantity ? stockData.expiredBatchQuantity : 0,
                  }}
                />
              )}
              <Specifications
                isAgentView={isAgentView}
                specificationsData={{
                  height: materialData.height ? materialData.height : 0,
                  width: materialData.width ? materialData.width : 0,
                  length: materialData.length ? materialData.length : 0,
                  weight: materialData.weight ? materialData.weight : 0,
                  materialType: materialData.materialType ? materialData.materialType : 'Sem tipo',
                  packingQuantity: materialData.packingQuantity,
                  warningTime: materialData.warningTime ? `${materialData.warningTime} meses` : 'Sem data',
                  criticalTime: materialData.criticalTime ? `${materialData.criticalTime} meses` : 'Sem data',
                }}
              />

              {!isAgentView && (
                <>
                  {materialData.photos != null && (
                    <Gallery
                      imagesData={materialData.photos[0] != null ? materialData.photos : []}
                      materialName={materialData.name ? materialData.name : ''}
                    />
                  )}
                </>
              )}
            </Grid>
            <Grid
              item
              lg={2}
              md={2}
              xs={12}
            />
            <Grid
              item
              lg={5}
              md={5}
              xs={12}
            >
              {!isAgentView ? (
                <>
                  <StockInfo
                    stockData={{
                      materialId: materialData.id ? materialData.id : null,
                      stockPermissionId: stockData.stockPermissionId ? stockData.stockPermissionId : 0,
                      availableQuantity: stockData.availableQuantity ? stockData.availableQuantity : 0,
                      totalQuantity: stockData.totalQuantity ? stockData.totalQuantity : 0,
                      reservedQuantity: stockData.reservedQuantity ? stockData.reservedQuantity : 0,
                      expiredBatchQuantity: stockData.expiredBatchQuantity ? stockData.expiredBatchQuantity : 0,
                    }}
                  />
                  {requestPageConfig.data.batchs && (
                    <Batches
                      batchesData={requestPageConfig.data.batchs}
                    />
                  )}
                </>
              ) : (
                <>
                  {materialData.photos != null && (
                    <Gallery
                      imagesData={materialData.photos[0] != null ? materialData.photos : []}
                      materialName={materialData.name ? materialData.name : ''}
                    />
                  )}
                </>
              )}
            </Grid>
          </GridContainerStyled>
        </Container>
        {requestPageConfig.loading || newMaterialImages.loading ? <LoadMask /> : null}

        <DialogModal
          title="Salvar alterações"
          description="Tem certeza que deseja salvar as alterações neste material?"
          confirmButtonText="Salvar"
          handleCloseDialog={handleCloseDialog}
          handleConfirmDialog={handleConfirmDialog}
          isOpenDialog={isOpenDialog}
          loadingDialog={loadingDialog}
        />

      </PageDetail>
    </>
  );
};

MaterialDetailLayout.propTypes = {
  userData: PropTypes.object,
  requestPageConfig: PropTypes.object.isRequired,
  materialData: PropTypes.object,
  stockData: PropTypes.object.isRequired,
  handleValidations: PropTypes.func,
  handleToggleDrawer: PropTypes.func,
  handleOpenDialog: PropTypes.func,
  handleCloseDialog: PropTypes.func,
  handleConfirmDialog: PropTypes.func,
  isOpenDialog: PropTypes.bool,
  loadingDialog: PropTypes.bool,
  handleDeleteImage: PropTypes.func,
  newMaterialImages: PropTypes.object,
};

MaterialDetailLayout.defaultProps = {
  userData: {},
  materialData: {},
  handleValidations: () => { },
  handleToggleDrawer: () => { },
  handleOpenDialog: () => { },
  handleCloseDialog: () => { },
  handleConfirmDialog: () => { },
  isOpenDialog: false,
  loadingDialog: false,
  handleDeleteImage: () => { },
  newMaterialImages: {},
};

export default MaterialDetailLayout;
