import React from 'react';
import PropTypes from 'prop-types';
import { Search } from '@material-ui/icons';
import styled from 'styled-components';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import FloatBatchActionsMenu from '../../../../../components/DataTable/FloatBatchActionsMenu';
import {
  Table,
  Tr,
  ThContainer,
  TdContainer,
} from '../../../../../components/Table/Table';
import { Button, Text, EmptyStateWithAction } from '../../../../../components';
import {
  Colors,
  USER_ROLES,
  OrderStatusText,
  BreakpointValues,
} from '../../../../../constants';
import { columnLabel, columnType } from '../../../../../constants/order';
import TouchableText from '../../../../../components/TouchableText';
import IconCompany from '../../../../../images/icon-company.svg';
import IconLuft from '../../../../../images/icon-luft.svg';
import IconAgent from '../../../../../images/icon-agent.svg';
import { RoutePaths, OrderRoutePaths } from '../../../../../constants/RoutePaths';
import { mediaHelper, useWindowSize } from '../../../../../utils';

const AuthorWrapper = styled.div`
  display: flex;
`;

const PersonContainer = styled.div`
  margin-right: 6px;
`;

const AuthorName = styled.div`
  margin-top: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  width: 85px;
`;

const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const StyledTouchableText = styled(TouchableText)`
  color: #FFF;
  font-size: 12px;
  margin-left: 24px;
`;

const StyledEmptyStateWithAction = styled(EmptyStateWithAction)`
  padding: 40px 16px;
  border-bottom: 1px solid ${Colors.GRAY_LIGHTER};
  flex-direction: column;
`;

const StyledEmptyActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  flex-direction: column;

  ${mediaHelper.lg`
    flex-direction: row;
  `}

  button {
    margin-bottom: 8px;
  }
`;

const ButtonDivider = styled.div`
  width: 16px;
  height: 1px;
`;

const RowWrapper = styled(Tr)`
  &:hover {
    background-color: ${Colors.Alpha(Colors.GRAY_LIGHT, 0.3)};
  }

  ${({ checked }) => checked && `
    background-color: ${Colors.Alpha(Colors.PRIMARY_BLUE, 0.05)};
  `}
`;

const SearchStyled = styled(Search)`
  color: ${Colors.SECONDARY_DARK};
`;

const StyledTable = styled(Table)`  
  & ${ThContainer} {
    height: 56px;
    display: flex;
    align-items: center;
    background-color: transparent;
    ${({ haspadding }) => haspadding && `
      padding-right: 32px;
    `};
  }

  & ${TdContainer} {
    height: 56px;
    display: flex;
    align-items: center;
    ${({ haspadding }) => haspadding && `
      padding-right: 32px;
    `};
  }
`;

const OrdersTable = ({
  userData,
  data,
  loading,
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
  onChangeItemsPerPage,
  onExportSelectedData,
  onApproveSelectedData,
  onChangeDataOrder,
  dataOrder,
  onRequestOrderDetail,
  pairingActive,
  multiselect,
  radiobutton,
  slideDataActive,
  handleRowCheck,
  checkOrderNumber,
  onPairOrder,
  ...props
}) => {
  const history = useHistory();
  const currentWindowSize = useWindowSize();

  const _renderBasicTextCell = (value) => (
    <Text key={`key-${value}`}>
      {value || '--'}
    </Text>
  );

  const _renderNumberLinesTextCell = (value) => (
    <Text numberOfLines={2}>
      {value || '--'}
    </Text>
  );

  const _renderCicleTextCell = (value) => (
    <Text>
      {value || 'Pedido avulso'}
    </Text>
  );

  const _renderStatusTextCell = (status) => (
    <Text>
      {OrderStatusText[status] || 'Sem status'}
    </Text>
  );

  const columnConfig = {
    [columnType.ORDER_NUMBER]: {
      order: 1,
      width: 120,
    },
    [columnType.WMS_ORDER_NUMBER]: {
      order: 2,
      width: 120,
    },
    [columnType.CREATE_DATE]: {
      order: 3,
      width: 100,
    },
    [columnType.DELIVERY]: {
      order: 4,
      width: 100,
    },
    [columnType.AGENT]: {
      order: 5,
      noSorting: true,
      width: 120,
    },
    [columnType.PROMOTIONAL_CICLE]: {
      order: 6,
      noSorting: true,
      width: 120,
    },
    [columnType.AUTHOR]: {
      order: 7,
      noSorting: true,
      width: 100,
    },
    [columnType.STATUS]: {
      order: 8,
      width: 160,
    },
    [columnType.ACTION]: {
      order: 9,
      noHeader: true,
      noSorting: true,
      width: 40,
    },
  };

  const columnAgentsConfig = {
    [columnType.ORDER_NUMBER]: {
      order: 1,
      width: 80,
    },
    [columnType.WMS_ORDER_NUMBER]: {
      order: 2,
      width: 80,
    },
    [columnType.CREATE_DATE]: {
      order: 3,
      width: 150,
    },
    [columnType.DELIVERY]: {
      order: 4,
      width: 100,
    },
    [columnType.MATERIAL]: {
      order: 5,
      noSorting: true,
      width: 240,
    },
    [columnType.AUTHOR]: {
      order: 6,
      noSorting: true,
      width: 100,
    },
    [columnType.STATUS]: {
      order: 7,
      width: 180,
    },
    [columnType.ACTION]: {
      order: 8,
      noHeader: true,
      noSorting: true,
      width: 100,
    },
  };

  const components = {
    [columnType.ORDER_NUMBER]: _renderBasicTextCell,
    [columnType.WMS_ORDER_NUMBER]: _renderBasicTextCell,
    [columnType.CREATE_DATE]: (value) => _renderBasicTextCell(value && format(new Date(value), 'dd/MM/yyyy')),
    [columnType.DELIVERY]: (value) => _renderBasicTextCell(value && format(new Date(value), 'dd/MM/yyyy')),
    [columnType.AGENT]: (agent) => _renderBasicTextCell(agent?.name),
    [columnType.PROMOTIONAL_CICLE]: _renderCicleTextCell,
    [columnType.AUTHOR]: (requester) => (
      <AuthorWrapper>
        <PersonContainer>
          {requester?.role === USER_ROLES.MASTER && (
            <img src={IconLuft} alt={requester?.name} />
          )}
          {requester?.role === USER_ROLES.COMPANY && (
            <img src={IconCompany} alt={requester?.name} />
          )}
          {requester?.role === USER_ROLES.AGENT && (
            <img src={IconAgent} alt={requester?.name} />
          )}
        </PersonContainer>
        <AuthorName>
          {requester?.name}
        </AuthorName>
      </AuthorWrapper>
    ),
    [columnType.STATUS]: _renderStatusTextCell,
    [columnType.ACTION]: () => (
      <ActionsContainer>
        <SearchStyled />
      </ActionsContainer>
    ),
  };

  const componentsAgents = {
    [columnType.ORDER_NUMBER]: _renderBasicTextCell,
    [columnType.WMS_ORDER_NUMBER]: _renderBasicTextCell,
    [columnType.CREATE_DATE]: (value) => _renderBasicTextCell(value && format(new Date(value), 'dd/MM/yyyy')),
    [columnType.DELIVERY]: (value) => _renderBasicTextCell(value && format(new Date(value), 'dd/MM/yyyy')),
    [columnType.MATERIAL]: (value) => {
      const materials = value.map((material, index) => (`${material.materialName} (${material.quantity})${value.length > index + 1 ? ', ' : ''}`));
      return _renderNumberLinesTextCell(materials);
    },
    [columnType.AUTHOR]: (requester) => (
      <AuthorWrapper>
        <PersonContainer>
          {requester.role === USER_ROLES.MASTER && (
            <img src={IconLuft} alt={requester.name} />
          )}
          {requester.role === USER_ROLES.COMPANY && (
            <img src={IconCompany} alt={requester.name} />
          )}
          {requester.role === USER_ROLES.AGENT && (
            <img src={IconAgent} alt={requester.name} />
          )}
        </PersonContainer>
        <AuthorName>
          {requester.name}
        </AuthorName>
      </AuthorWrapper>
    ),
    [columnType.STATUS]: _renderStatusTextCell,
    [columnType.ACTION]: () => (
      <ActionsContainer>
        <SearchStyled />
      </ActionsContainer>
    ),
  };

  const handleOnRequestOrderDetail = async (data) => {
    onRequestOrderDetail(data);
  };

  return (
    <>
      <StyledTable
        name="orders-table"
        isLoading={loading}
        footerText="Pedidos por página: "
        multiselect={userData.userRole === USER_ROLES.AGENT ? null : multiselect}
        radiobutton={userData.userRole === USER_ROLES.AGENT ? null : radiobutton}
        data={data}
        handleHeaderTitle={(key) => columnLabel[key]}
        columnConfig={userData.userRole === USER_ROLES.AGENT ? columnAgentsConfig : columnConfig}
        components={userData.userRole === USER_ROLES.AGENT ? componentsAgents : components}
        paginationProps={{
          currentPage,
          totalItems,
          itemsPerPage,
          onPageChange,
        }}
        onChangeItemsPerPage={onChangeItemsPerPage}
        dataOrder={dataOrder}
        onOrderColumn={onChangeDataOrder}
        rowWrapper={() => ({ RowWrapper, rowWrapperProps: {} })}
        onRowClick={(data) => { handleOnRequestOrderDetail(data); }}
        onRowCheck={(index) => { handleRowCheck(data[index].id); }}
        haspadding={userData.userRole === USER_ROLES.AGENT ? 1 : 0}
        groupFirstColumns={currentWindowSize.width > BreakpointValues.sm ? null : userData.userRole === USER_ROLES.AGENT ? 1 : 2}
        renderEmptyState={() => (
          <StyledEmptyStateWithAction
            alignCenter
            emptyStateText="Nenhum pedido pra exibir :("
            renderAction={() => (
              <StyledEmptyActionsContainer>
                <Button
                  variant="outlined"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Atualizar
                </Button>
                <ButtonDivider />
                <Button
                  variant="contained"
                  onClick={() => { history.push(`${RoutePaths.ORDER}${OrderRoutePaths.NEW}`); }}
                >
                  Fazer pedido
                </Button>
              </StyledEmptyActionsContainer>
            )}
          />
        )}
        renderOutside={(selectedRows, setSelected) => pairingActive ? (
          <FloatBatchActionsMenu
            show={!!selectedRows.length}
            selectedLabel={slideDataActive.orderNumber ? `WMS ${slideDataActive.orderNumber} e ${checkOrderNumber}` : 'Nenhum pedido WMS para parear'}
            selectedItems={selectedRows}
            pairingActive
            onClose={() => {
              setSelected([]);
            }}
          >
            {slideDataActive.orderNumber && (
              <StyledTouchableText onClick={() => {
                onPairOrder({
                  orderNumber: slideDataActive.orderNumber,
                  orderId: checkOrderNumber,
                });
                setSelected([]);
              }}
              >
                Parear pedido
              </StyledTouchableText>
            )}
          </FloatBatchActionsMenu>
        ) : (
          <FloatBatchActionsMenu
            show={!!selectedRows.length}
            selectedLabel={selectedRows.length > 1 ? 'pedidos selecionados' : 'pedido selecionado'}
            selectedItems={selectedRows}
            onClose={() => {
              setSelected([]);
            }}
          >
            <StyledTouchableText onClick={() => {
              onApproveSelectedData(selectedRows);
              setSelected([]);
            }}
            >
              Aprovar pedidos
            </StyledTouchableText>
            <StyledTouchableText onClick={() => {
              onExportSelectedData(selectedRows);
              setSelected([]);
            }}
            >
              Exportar pedidos
            </StyledTouchableText>
          </FloatBatchActionsMenu>
        )}
      />
    </>
  );
};

OrdersTable.propTypes = {
  currentPage: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  onChangeItemsPerPage: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onApproveSelectedData: PropTypes.func.isRequired,
  onExportSelectedData: PropTypes.func.isRequired,
  onChangeDataOrder: PropTypes.func,
  onRequestOrderDetail: PropTypes.func.isRequired,
  pairingActive: PropTypes.bool,
  multiselect: PropTypes.bool,
  radiobutton: PropTypes.bool,
  slideDataActive: PropTypes.object,
  handleRowCheck: PropTypes.func,
  checkOrderNumber: PropTypes.number,
};

OrdersTable.defaultProps = {
  loading: false,
  onChangeDataOrder: undefined,
  pairingActive: false,
  multiselect: false,
  radiobutton: false,
  slideDataActive: {},
  handleRowCheck: () => {},
  checkOrderNumber: null,
};

export default OrdersTable;
