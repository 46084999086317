import { ChangeViewService } from '../services';
import { LOCAL_STORAGE_COMPANY_LIST } from './local-storage';

const fetchCompanyList = async () => {
  const companyDialogList = {
    list: [],
  };
  try {
    const response = await ChangeViewService.fetchCompanyDialogList();
    companyDialogList.list = response.data.list;
  } catch (error) {
    console.error(error.message);
  }
  return companyDialogList;
};

export const getCompanyDialogList = async () => {
  const savedValue = JSON.parse(localStorage.getItem(LOCAL_STORAGE_COMPANY_LIST));
  if (savedValue && savedValue.list.length) return savedValue;
  const response = await fetchCompanyList();
  localStorage.setItem(LOCAL_STORAGE_COMPANY_LIST, JSON.stringify(response));
  return response;
};
