export const OrderStatusEnum = {
  ORDER_PENDING_APPROVAL: 1, // Pedido em aprovação
  // ORDER_NOT_APPROVED: 2, // Pedido não aprovado
  BILLING_ORDER: 3, // Pedido em faturamento
  PREPARING_ORDER: 4, // Pedido em preparação
  ORDER_SENT_TO_TRANSPORT: 5, // Pedido enviado para transporte
  /* SCHEDULING_ORDER: 6, // Pedido em agendamento
  TRY_SCHEDULLING_ORDER: 7, // Tentativa de agendamento
  ORDER_NOT_SCHEDULED: 8, */ // Pedido não agendado
  ORDER_IN_TRANSPORT: 9, // Pedido em transporte
  DELIVERY_ATTEMPT: 10, // Tentativa de entrega
  ORDER_DELIVERED: 11, // Pedido entregue
  ORDER_NOT_DELIVERED: 12, // Pedido não entregue
  ORDER_CANCELED: 13, // Pedido cancelado
  ORDER_REFUSED: 14, // Pedido recusado
};

export const OrderStatusText = {
  [OrderStatusEnum.ORDER_PENDING_APPROVAL]: 'Pedido em aprovação',
  // [OrderStatusEnum.ORDER_NOT_APPROVED]: 'Pedido não aprovado',
  [OrderStatusEnum.BILLING_ORDER]: 'Pedido em faturamento',
  [OrderStatusEnum.PREPARING_ORDER]: 'Pedido em preparação',
  [OrderStatusEnum.ORDER_SENT_TO_TRANSPORT]: 'Pedido enviado para transporte',
  /* [OrderStatusEnum.SCHEDULING_ORDER]: 'Pedido em agendamento',
  [OrderStatusEnum.TRY_SCHEDULLING_ORDER]: 'Tentativa de agendamento',
  [OrderStatusEnum.ORDER_NOT_SCHEDULED]: 'Pedido não agendado', */
  [OrderStatusEnum.ORDER_IN_TRANSPORT]: 'Pedido em transporte',
  [OrderStatusEnum.DELIVERY_ATTEMPT]: 'Tentativa de entrega',
  [OrderStatusEnum.ORDER_DELIVERED]: 'Pedido entregue',
  [OrderStatusEnum.ORDER_NOT_DELIVERED]: 'Pedido não entregue',
  [OrderStatusEnum.ORDER_CANCELED]: 'Pedido cancelado',
  [OrderStatusEnum.ORDER_REFUSED]: 'Pedido recusado',
};
