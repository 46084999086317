import { HttpService } from './http';
import { API_BASE_URL } from '../constants/API';

const Endpoint = {
  fetchTags: `${API_BASE_URL}/tags`,
  fetchTagList: `${API_BASE_URL}/tags/list`,
};

export class TagService {
  static getDefaultRequest = () => ({
    skip: 0,
    take: 100,
    filters: {},
  });

  static transformCheckFilter = (checkFilter, parseFunc) => Object.entries(checkFilter || {}).reduce((final, [key, value]) => {
    if (value) {
      return [
        ...final,
        parseFunc ? parseFunc(key) : key,
      ];
    }

    return final;
  }, []);

  static transformFiltersToRequest = (filters) => {
    const filtersRequest = {};

    filtersRequest.standard = [];

    if (filters.term) {
      filtersRequest.standard.push({
        attribute: 'name',
        value: filters.term,
      });
    }

    if (filters.type) {
      filtersRequest.type = filters.type;
    }
    return filtersRequest;
  };

  static async fetchTagList(data) {
    const request = TagService.getDefaultRequest();
    request.filters = TagService.transformFiltersToRequest(data);

    const response = await HttpService.post(Endpoint.fetchTagList, request);

    return response;
  }

  static async requestCreateTag(data) {
    const response = await HttpService.post(Endpoint.fetchTags, data);
    return response;
  }
}

export default TagService;
