import React from 'react';
import ReactDOM from 'react-dom';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import SimpleReactLightbox from 'simple-react-lightbox';
import TagManager from 'react-gtm-module';
import store from './redux/store';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Theme } from './constants';

const userData = JSON.parse(window.localStorage.getItem('luft-promo/UserData'));
const tagManagerArgs = {
  // Reminder - use the bellow gtmId only for prod release
  // gtmId: 'GTM-MJT7CPN',
  gtmId: '',
  dataLayer: {
    userId: (userData || {}).id,
  },
};
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <ThemeProvider theme={Theme}>
      <StylesProvider injectFirst>
        <SimpleReactLightbox>
          <App />
        </SimpleReactLightbox>
      </StylesProvider>
    </ThemeProvider>
    {/* </React.StrictMode> */}
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
