import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'react-slick';
import { SRLWrapper } from 'simple-react-lightbox';

import 'slick-carousel/slick/slick.css';
import './gallerySlider.css';

import {
  PrevArrow,
  NextArrow,
} from './Arrows';

const SliderWrapper = styled(Slider)`
  width: 100%;
  margin-bottom: 48px;
`;

const SlideItem = styled.div`
  position: relative;
  width: 100%;
  height: 315px;
  cursor: pointer;
`;

const ImageHidden = styled.img`
  opacity: 0;
  width: 100%;
  height: 100%;
`;

const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 315px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: #F3F4F4;
`;

const Gallery = ({ ...props }) => {
  const [nextArrowActive, setNextArrowActive] = useState(true);
  const [prevArrowActive, setPrevArrowActive] = useState(false);

  const {
    imagesData,
    materialName,
  } = props;

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow nextArrowActive={nextArrowActive} />,
    prevArrow: <PrevArrow prevArrowActive={prevArrowActive} />,
    afterChange: (current) => {
      if (current === 0) {
        setPrevArrowActive(false);
      } else {
        setPrevArrowActive(true);
      }
      if (current >= (imagesData.length - sliderSettings.slidesToShow)) {
        setNextArrowActive(false);
      } else {
        setNextArrowActive(true);
      }
    },
  };

  const lightboxSettings = {
    thumbnails: {
      showThumbnails: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
    },
  };

  return (
    <div id="materialGallery">
      {imagesData.length > 0 && (
        <SRLWrapper
          options={lightboxSettings}
        >
          <SliderWrapper
            {...sliderSettings}
          >
            {imagesData.map((item) => (
              <SlideItem
                key={item}
              >
                <ImageWrapper
                  style={{ backgroundImage: `url(${item.url})` }}
                >
                  <ImageHidden
                    src={item.url}
                    alt={materialName}
                  />
                </ImageWrapper>
              </SlideItem>
            ))}
          </SliderWrapper>
        </SRLWrapper>
      )}
    </div>
  );
};

Gallery.propTypes = {
  imagesData: PropTypes.array.isRequired,
  materialName: PropTypes.string.isRequired,
};

export default Gallery;
