import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Container,
  Breadcrumbs,
  Link,
} from '@material-ui/core/';

import {
  TitlePage,
  Header,
  Footer,
} from '.';

import { Colors, BreakpointValues } from '../constants';
import { mediaHelper, useWindowSize } from '../utils';

const PageWrapper = styled.div`
  background-color: ${({ isDetailPage }) => isDetailPage ? 'white' : Colors.GRAY_LIGHEST_LIGHT};
  padding-bottom: ${({ openDrawer }) => openDrawer ? '430px' : '0px'};
`;

const Content = styled(Container)`
  margin-top: 14px;
  min-height: 95vh;
  padding-bottom: 15px;

  ${mediaHelper.md`
    margin-top: 24px;
    padding-bottom: 60px;
    min-height: 85vh;
  `}
`;

const SubHeaderWrapper = styled.div`
  padding-bottom: 32px;

  ${mediaHelper.md`
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-bottom: 0px;
    margin-bottom: 34px;
  `}

  ${mediaHelper.lg`
    margin-bottom: 0;

  `}
`;

const LeftContent = styled.div`
  flex: 1;
`;

const PageBreadcrumbs = styled(Breadcrumbs)`
  display: flex;
  margin-top: 48px !important;
  color: ${Colors.GRAY_DARKER};

  li, a {
      color: ${Colors.GRAY_DARKER};
  }
`;

const PageStep = styled.span`
  color: ${Colors.GRAY_DARKEST};
`;

const Page = ({
  title,
  smallTitle,
  userData,
  companyDialogList,
  handleOpenCompanyDialog,
  handleOutputCompanyDialog,
  loadingDialog,
  children,
  renderRight,
  openDrawer,
  originPath,
  originPathName,
}) => {
  const currentWindowSize = useWindowSize();

  useEffect(() => {
    if (currentWindowSize.width <= BreakpointValues.lg && openDrawer) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [openDrawer]);

  return (
    <PageWrapper
      openDrawer={openDrawer}
    >
      <Header
        userData={userData}
        companyDialogList={companyDialogList}
        handleOpenCompanyDialog={handleOpenCompanyDialog}
        handleOutputCompanyDialog={handleOutputCompanyDialog}
        loadingDialog={loadingDialog}
      />
      <Content maxWidth="lg">
        {originPath && (
          <PageBreadcrumbs aria-label="breadcrumb">
            <Link href={`${originPath}`}>
              {originPathName}
            </Link>
            <PageStep>
              {title ? title : '-'}
            </PageStep>
          </PageBreadcrumbs>
        )}
        <SubHeaderWrapper>
          <LeftContent>
            <TitlePage
              title={title}
              smallTitle={smallTitle}
            />
          </LeftContent>
          {renderRight && renderRight()}
        </SubHeaderWrapper>
        {children}
      </Content>
      <Footer />
    </PageWrapper>
  );
};

Page.propTypes = {
  title: PropTypes.string,
  smallTitle: PropTypes.bool,
  userData: PropTypes.object,
  companyDialogList: PropTypes.object,
  loadingDialog: PropTypes.bool,
  handleOpenCompanyDialog: PropTypes.func,
  handleOutputCompanyDialog: PropTypes.func,
  children: PropTypes.node,
  openDrawer: PropTypes.bool,
  originPath: PropTypes.string,
  originPathName: PropTypes.string,
};

Page.defaultProps = {
  title: '',
  smallTitle: false,
  userData: {},
  companyDialogList: {},
  loadingDialog: false,
  handleOpenCompanyDialog: () => { },
  handleOutputCompanyDialog: () => { },
  children: [],
  openDrawer: false,
  originPath: '',
  originPathName: '',
};

export default Page;
