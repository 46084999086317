import { HttpService } from './http';
import { API_BASE_URL } from '../constants/API';

const Endpoint = {
  fetchCompanyList: `${API_BASE_URL}/companies`,
};

export class CompanyService {
  /**
   * @param {{ term: string }} data
   */
  static async fetchCompanyList(data) {
    const response = await HttpService.get(Endpoint.fetchCompanyList, {
      name: data.term,
    });

    return response;
  }
}

export default CompanyService;
