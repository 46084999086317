import React from 'react';
import styled from 'styled-components';

import {
  FilterCheckbox,
  FilterSearch,
  FilterWrapper,
  FilterCheckboxSearch,
} from '../../../../../../components/Filter';
import {
  fieldPermissionsNames,
  stockTypeOptionList,
  searchTypeList,
  searchType,
  agentTypeOptionList,
  stockStatusOptionList,
  stockAlertOptionList,
  agentType,
} from '../../../../../../constants/stock';
import FastFieldWrapper from '../../../../../../components/form/FastFieldWrapper';

const StyledFilterWrapper = styled(FilterWrapper)`
  transition: height ease-out 0.3s;
`;

const SingleFilterContainer = styled.div`
  margin-left: 24px;
`;

const Filters = ({
  handleReset,
  agentList,
  onRequestAgentList,
  onSearch,
  appliedFilters,
}) => {
  const defaultDataListMap = ({
    id,
    name,
  }) => ({
    label: name,
    value: id,
  });
  const renderFilters = () => (
    <>
      <SingleFilterContainer>
        <FastFieldWrapper
          name={fieldPermissionsNames.MATERIAL_TYPE}
          label="Tipo"
          component={FilterCheckbox}
          submitOnChange
          optionList={stockTypeOptionList}
          handleReset={handleReset}
          appliedFilters={appliedFilters}
          fieldNames={fieldPermissionsNames}
        />
      </SingleFilterContainer>
      <SingleFilterContainer>
        <FastFieldWrapper
          name={fieldPermissionsNames.AGENT}
          label="Representante"
          placeholder={(selectedType) => selectedType.type === agentType.NAME ? 'Nome do representante' : 'Tag do representante'}
          component={FilterCheckboxSearch}
          submitOnChange
          optionList={agentList.list}
          optionMap={defaultDataListMap}
          searchTypeList={agentTypeOptionList}
          loading={agentList.loading}
          onSearch={onRequestAgentList}
          appliedFilters={appliedFilters}
          fieldNames={fieldPermissionsNames}
        />
      </SingleFilterContainer>
      <SingleFilterContainer>
        <FastFieldWrapper
          name={fieldPermissionsNames.ALERT}
          label="Alerta"
          component={FilterCheckbox}
          submitOnChange
          optionList={stockAlertOptionList}
          handleReset={handleReset}
          appliedFilters={appliedFilters}
          fieldNames={fieldPermissionsNames}
        />
      </SingleFilterContainer>
      <SingleFilterContainer>
        <FastFieldWrapper
          name={fieldPermissionsNames.STATUS}
          label="Status"
          component={FilterCheckbox}
          submitOnChange
          optionList={stockStatusOptionList}
          handleReset={handleReset}
          appliedFilters={appliedFilters}
          fieldNames={fieldPermissionsNames}
        />
      </SingleFilterContainer>
    </>
  );

  return (
    <StyledFilterWrapper
      renderLeft={() => (
        <>
          {renderFilters()}
        </>
      )}
      renderRight={() => (
        <>
          <FilterSearch
            name={fieldPermissionsNames.SEARCH}
            placeholder={(selectedType) => selectedType.type === searchType.NAME ? 'Nome do material' : 'Cód. do material'}
            typeList={searchTypeList}
            component={FilterSearch}
            submitOnChange
            handleOutput={onSearch}
            placement="bottom-end"
          />
        </>
      )}
    />
  );
};

export default Filters;
