import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { Close } from '@material-ui/icons';
import { Container, Icon } from '@material-ui/core';

import { Colors } from '../../constants';
import TouchableText from '../TouchableText';

const fadeIn = keyframes`
  from {
    opacity: 0;
    bottom: -74px;
  }

  to {
    opacity: 1;
    bottom: 60px;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    bottom: 60px;
  }

  to {
    opacity: 0;
    bottom: -74px;
  }
`;

const FloatContainer = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 10;
`;

const WidthContainer = styled(Container)`
  margin-top: 24px;
  padding-bottom: 60px;
  margin: 0 auto;
  position: relative;
  pointer-events: none;
`;

export const BoxContainer = styled.div`
  width: 635px;
  height: initial;
  background-color: ${Colors.PRIMARY_BLUE};
  border-radius: 8px;
  padding: 16px 24px;
  position: absolute;
  right: 0;
  pointer-events: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 60px;
  opacity: 0;
  font-weight: normal;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);

  ${({ show }) => show && `
    bottom: 60px;
    opacity: 1;
  `}

  animation: ${({ show }) => show ? fadeIn : fadeOut} 0.5s;
`;

const CancelActionContainer = styled(TouchableText)`
  display: flex;
  align-items: center;
  margin-left: 24px;
  cursor: pointer;
  color: ${Colors.GRAY_LIGHT};
  font-size: 12px;
`;

const StyledIcon = styled(Icon)`
  color: white;
`;

const CloseIcon = styled(StyledIcon)`
  display: block;
  font-size: 18px;
  height: 18px;
  margin-left: 6px;
  margin-top: 0.5px;
`;

const SelectedText = styled.span`
  font-size: 14px;
  color: white;
  margin-left: 8px;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: #FFF;
`;

const SelectedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FloatBatchActionsMenu = ({
  show,
  selectedLabel,
  selectedItems,
  onClose,
  children,
  pairingActive,
}) => {
  const [shouldShow, setShouldShow] = useState(show);
  const setTimeoutRef = useRef(null);

  useEffect(() => {
    clearTimeout(setTimeoutRef.current);

    if (show) {
      setShouldShow(true);
    } else {
      requestAnimationFrame(() => {
        setTimeoutRef.current = setTimeout(() => {
          setShouldShow(false);
        }, 500);
      });
    }
  }, [show]);

  return shouldShow && (
    <FloatContainer>
      <WidthContainer maxWidth="lg">
        <BoxContainer show={show}>
          <SelectedContainer>
            {pairingActive ? (
              <SelectedText>
                {selectedLabel}
              </SelectedText>
            ) : (
              <SelectedText>
                {`${(selectedItems || []).length} ${selectedLabel}`}
              </SelectedText>
            )}
          </SelectedContainer>
          <ActionsContainer>
            {children}
            <CancelActionContainer onClick={onClose}>
              Cancelar
              {' '}
              <CloseIcon name="close">
                <Close fontSize="inherit" />
              </CloseIcon>
            </CancelActionContainer>
          </ActionsContainer>
        </BoxContainer>
      </WidthContainer>
    </FloatContainer>
  );
};

FloatBatchActionsMenu.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.any,
  selectedItems: PropTypes.array,
  selectedLabel: PropTypes.string,
  onClose: PropTypes.func,
  pairingActive: PropTypes.bool,
};

FloatBatchActionsMenu.defaultProps = {
  show: false,
  children: (<></>),
  selectedItems: [],
  selectedLabel: '',
  onClose: () => {},
  pairingActive: false,
};

export default FloatBatchActionsMenu;
