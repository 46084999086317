// Action types
import actionTypes from './types';
import { ToastStyles } from '../reducer';


// Actions creators
function showToast(message, style) {
  return {
    type: actionTypes.SHOW,
    message,
    style,
  };
}

export function showSuccessToast(message) {
  return showToast(message, ToastStyles.SUCCESS);
}

export function showErrorToast(message) {
  return showToast(message, ToastStyles.DANGER);
}

export function dismissToast() {
  return { type: actionTypes.DISMISS };
}

export function clearToast() {
  return { type: actionTypes.CLEAR };
}
