import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Grid,
} from '@material-ui/core/';
import { Colors } from '../constants';

import { mediaHelper } from '../utils';

const propTypes = {
  title: PropTypes.string.isRequired,
  right: PropTypes.node,
};

const defaultProps = {
  right: undefined,
};

const TitleText = styled.h1`
  color: ${Colors.SECONDARY_MEDIUM};
  font-weight: normal;
  margin: 16px 0px;

  ${mediaHelper.md`
    margin: 32px 0px;
  `}

  font-size: ${({ smallTitle }) => smallTitle ? '24px' : '24px'};
  ${mediaHelper.lg`
    font-size: ${({ smallTitle }) => smallTitle ? '34px' : '48px'};
  `};
`;

const RightWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;

const TitleWrapper = styled.div`
  overflow: hidden;
`;

const TitlePage = ({
  title,
  smallTitle,
  right,
}) => (
  <TitleWrapper>
    <Grid container spacing={3}>
      <Grid
        item
        lg={8}
        md={8}
        xs={12}
      >
        <TitleText
          smallTitle={smallTitle}
        >
          {title}
        </TitleText>
      </Grid>
      {right && (
        <Grid
          item
          lg={4}
          md={4}
          xs={12}
        >
          <RightWrapper>
            {right}
          </RightWrapper>
        </Grid>
      )}
    </Grid>
  </TitleWrapper>
);

TitlePage.propTypes = propTypes;
TitlePage.defaultProps = defaultProps;

export default TitlePage;
