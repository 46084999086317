import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Container,
} from '@material-ui/core/';

import { Colors } from '../../constants';
import { mediaHelper } from '../../utils';

const propTypes = {
  renderLeft: PropTypes.func,
  renderRight: PropTypes.func,
};
const defaultProps = {
  renderLeft: () => null,
  renderRight: () => null,
};

export const DEFAULT_FILTERS_WRAPPER_HEIGHT = 48;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  background-color: white;
  margin-bottom: 24px;
  border-radius: 8px;
  z-index: 99;

  ${mediaHelper.md`
    height: 48px;
  `};
`;

const StyledContainer = styled(Container)`
  && {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 8px 0;

    ${mediaHelper.xs`
      align-items: flex-start;
      flex-direction: row;
    `};

    ${mediaHelper.md`
      padding: 0;
    `};
  }
`;

const FilterLeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-direction: column-reverse;

  ${mediaHelper.md`
    width: auto;
    flex-direction: row;
  `};
`;

const FilterRightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px 8px 8px;

  ${mediaHelper.lg`
    padding: 8px;
    margin-right: 24px;
  `}
`;

const FilterTitle = styled.span`
  font-size: 16px;
  color: ${Colors.PRIMARY_BLUE};
  font-weight: bold;
  margin-left: 0;

  ${mediaHelper.md`
    margin-left: 16px;
  `}
`;

const TitleContainer = styled.div`
  height: 32px;
  display: none;
  align-items: center;

  ${mediaHelper.md`
    display: flex;
    margin-left: 8px;
  `};
`;

const Stratch = styled.div`
  flex: 1;
  padding: 0;
  position: relative;
  top: 12px;

  ${mediaHelper.md`
    padding: 8px;
    top: auto;
  `};
`;

const FilterWrapper = ({
  renderLeft,
  renderRight,
  ...props
}) => (
  <Wrapper {...props}>
    <StyledContainer maxWidth="lg">
      <Stratch>
        <FilterLeftWrapper>
          <TitleContainer>
            <FilterTitle>Filtrar</FilterTitle>
          </TitleContainer>
          {renderLeft()}
        </FilterLeftWrapper>
      </Stratch>
      <FilterRightWrapper>
        {renderRight()}
      </FilterRightWrapper>
    </StyledContainer>
  </Wrapper>
);

FilterWrapper.propTypes = propTypes;
FilterWrapper.defaultProps = defaultProps;

export default FilterWrapper;
