import React, { useState } from 'react';
import styled from 'styled-components';

import { Text } from '../../../../../../components';
import ModalTableCheckboxSearch from '../../../../../../components/ModalTableCheckboxSearch';
import { agentType, agentTypeOptionList } from '../../../../../../constants/order';
import { agentTableKey, agentTableHeader } from './constants';
import { Colors } from '../../../../../../constants/Colors';

const AddressWrapper = styled.div`
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 16px;
`;

const AgentWrapper = styled.div`
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 16px;
`;

export const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 4px 0;
`;

export const Tag = styled.div`
  border: 1px solid ${Colors.GRAY};
  border-radius: 16px;
  padding: 4px 8px;
  color: ${Colors.GRAY_DARKER};
  font-size: 14px;
  margin: 4px 4px 4px 0;
`;

const AgentsModalTable = ({
  handleRefresh,
  ...props
}) => {
  const [isRefresh, setIsRefresh] = useState(false);
  const columnConfig = {
    [agentTableKey.AGENT]: {
      order: 1,
    },
    [agentTableKey.CITY]: {
      order: 2,
    },
    [agentTableKey.TAG]: {
      order: 3,
      width: 350,
    },
  };

  const components = {
    [agentTableKey.AGENT]: (fieldData, data, rowIndex) => {
      const rowData = data[rowIndex];
      return (
        <AgentWrapper>
          {rowData.name}
        </AgentWrapper>
      );
    },
    [agentTableKey.CITY]: (fieldData, data, rowIndex) => {
      const rowData = data[rowIndex];
      return (
        <AddressWrapper>
          <Text>
            {`${rowData.city} - ${rowData.uf}`}
          </Text>
        </AddressWrapper>
      );
    },
    [agentTableKey.TAG]: (tags) => (
      <TagWrapper>
        {tags.map((tag) => (
          <Tag key={`${props.name}-${tag.id}`}>{tag.name}</Tag>
        ))}
      </TagWrapper>
    ),
  };

  return (
    <ModalTableCheckboxSearch
      title="Selecione um representante"
      placeholder={(option) => agentType.NAME === option.type ? 'Nome do representante' : 'Tag do representante'}
      tableColumnConfig={columnConfig}
      tableComponents={components}
      searchTypeList={agentTypeOptionList}
      tableHandleHeaderTitle={(key) => agentTableHeader[key]}
      handleRefresh={() => {
        handleRefresh();
        setIsRefresh(true);
      }}
      isRefresh={isRefresh}
      disableConfirm
      {...props}
    />
  );
};

export default AgentsModalTable;
