import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { default as MaterialButton } from '@material-ui/core/Button';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeButton } from '../constants';

const propTypes = {
  theme: PropTypes.object,
  size: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
  children: PropTypes.node,
};

const defaultProps = {
  theme: ThemeButton,
  size: 'small',
  color: 'primary',
  variant: 'text',
  disabled: false,
  handleClick: () => {},
  children: [],
};

const StyledButton = styled(MaterialButton)`
  white-space: nowrap;
`;

const Button = ({
  theme,
  size,
  color,
  variant,
  disabled,
  handleClick,
  children,
  ...props
}) => (
  <MuiThemeProvider theme={theme}>
    <StyledButton
      size={size}
      color={color}
      variant={variant}
      disabled={disabled}
      onClick={handleClick}
      {...props}
    >
      {children}
    </StyledButton>
  </MuiThemeProvider>
);

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
