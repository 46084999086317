export const Alpha = (color = '#000000', alphaNumber = 0) => {
  if (
    typeof color === 'string'
    && color.length === 7
    && typeof alphaNumber === 'number'
    && (alphaNumber >= 0 || alphaNumber <= 1)
  ) {
    const hexArr = [color.substr(1, 2), color.substr(3, 2), color.substr(5, 2)];
    const alphaArr = [
      parseInt(hexArr[0], 16),
      parseInt(hexArr[1], 16),
      parseInt(hexArr[2], 16),
    ];

    return `rgba(${alphaArr.join(',')}, ${alphaNumber})`;
  }

  return 'transparent';
};

const DANGER = '#F44336';

export const Colors = {
  PRIMARY_BLUE: '#1077B9',
  PRIMARY_DARK: '#161F3B',
  SECONDARY_MEDIUM: '#263465',
  SECONDARY_DARK: '#0B5583',
  SUCCESS: '#1EB44B',
  SUCCESS_LIGHTER: '#B1C750',
  DANGER,
  DANGER_LOW_OPACITY: Alpha(DANGER, 0.1),
  GRAY_DARKEST: '#606060',
  GRAY_DARKER: '#909090',
  GRAY_DARK_LIGHT: '#C4C4C4',
  GRAY: '#c0c0c0',
  GRAY_LIGHEST_LIGHT: '#FAFAFA',
  GRAY_LIGHT: '#e0e0e0',
  GRAY_LIGHTER: '#f0f0f0',
  GRAY_LIGHTEST: '#FBFBFB',
  GRAY_CHART_LABEL: '#C0C7CE',
  ORANGE: '#F9A61E',
  ORANGE_DARKER: '#D88000',
  GREEN_DARKER: '#B1C750',
  BLUE_LIGTHER: '#FAFAFF',
  BLUE_LIGHT: '#E7F0F5',
  BLUE_DARKER: '#C6DEEE',
  BLUE_DARKEST: '#01518A',
  BLUE_TURNKEY: '#4DB7E9',
  BLUE_VIVID: '#229DEC',
  YELLOW_LIGHT: '#FFF6E5',
  WHITE: '#FFF',
  Alpha,
};
