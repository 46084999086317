import { css } from 'styled-components';

import { Breakpoints } from '../constants';

export const createMediaTemplate = (breakpoint) => (...args) => css`
  @media (min-width: ${breakpoint}) {
    ${css(...args)}
  }
`;

// Iterate through the sizes and create a media template
export const mediaHelper = Object.keys(Breakpoints).reduce((acc, label) => {
  acc[label] = createMediaTemplate(Breakpoints[label]);
  return acc;
}, {});
