import React, { useState } from 'react';
import styled from 'styled-components';

import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { useHistory } from 'react-router-dom';
import { ArrowForward } from '@material-ui/icons';
import TouchableText from '../../../components/TouchableText';
import { RoutePaths } from '../../../constants/RoutePaths';

import {
  InitialPage,
  Button,
} from '../../../components';

import { Colors } from '../../../constants/Colors';

const WrapperButton = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    margin-left: 8px;
  }
`;

const TextActionContainer = styled(TouchableText)`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${Colors.BLUE_DARKEST};
  font-size: 12px;
  font-weight: bold;
  margin-right: 10px;
  white-space: nowrap;
`;

const LoginLayout = ({ ...props }) => {
  const {
    handleValidations,
    handleLogin,
    loading,
  } = props;

  const history = useHistory();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [formErrors, setFormErrors] = useState({});

  const onResetPassword = () => {
    history.push(`${RoutePaths.FORGOT_PASSWORD}`);
  };

  return (
    <InitialPage
      loading={loading}
    >

      <Formik
        initialValues={{
          email: '',
          password: '',
          // email: '',
          // password: '',
        }}
        validationSchema={handleValidations}
        enableReinitialize
        onSubmit={(values) => handleLogin(values)}
        formData={formData}
        formErrors={formErrors}
      >
        {({
          handleSubmit,
          isValid,
          values,
          errors,
        }) => (
          <>
            <Field
              component={TextField}
              name="email"
              type="email"
              label="Usuário"
              variant="outlined"
              required
              onKeyUp={() => {
                setFormData({
                  ...formData,
                  email: values.email,
                });
                setFormErrors(errors);
              }}
            />
            <Field
              component={TextField}
              type="password"
              label="Senha"
              name="password"
              variant="outlined"
              required
              onKeyUp={() => {
                setFormData({
                  ...formData,
                  password: values.password,
                });
                setFormErrors(errors);
              }}
            />

            <WrapperButton>
              <TextActionContainer onClick={onResetPassword}>
                ESQUECI MINHA SENHA
              </TextActionContainer>
              <Button
                size="medium"
                variant="contained"
                disabled={!isValid}
                onClick={handleSubmit}
              >
                Entrar
                {' '}
                <ArrowForward fontSize="small" />
              </Button>
            </WrapperButton>

          </>
        )}
      </Formik>

    </InitialPage>
  );
};

export default LoginLayout;
