import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import {
  OrderService,
  AuthService,
  ChangeViewService,
  PromotionalCicleService,
  AgentService,
  CompanyService,
  ProductService,
} from '../../services';
import {
  showErrorToast,
  showSuccessToast,
} from '../../components';
import { AuthActions } from '../../redux';
import PairingLayout from './pairing-layout';
import { getCompanyDialogList } from '../../utils';

class PairingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      companyList: {
        loading: false,
        list: [],
      },
      agentList: {
        loading: false,
        list: [],
      },
      promotionalCicleList: {
        loading: false,
        list: [],
      },
      materialList: {
        loading: false,
        list: [],
      },
      productList: {
        loading: false,
        list: [],
      },
      orderList: {
        loading: false,
        list: [],
        total: 0,
      },
      companyDialogList: {
        loading: false,
        list: [],
      },
      orderDetailData: {
        loading: false,
        data: {},
        total: 0,
      },
      orderOnApproval: {
        loading: false,
        finished: false,
      },
      wmsOrderlist: {
        loading: false,
        list: [],
        total: 0,
      },
      searchWmsOrderlist: {
        loading: false,
        list: [],
      },
      pairOrder: {
        loading: false,
      },
    };
  }

  componentDidMount() {
    this._requestCompanyDialogList();
  }

  _requestOrderList = async (data) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ orderList }) => ({
        loading: true,
        orderList: {
          ...orderList,
          loading: true,
        },
      }));

      const { data: { list, total } } = await OrderService.fetchOrderToPairList(data);

      this.setState(({ orderList }) => ({
        loading: false,
        orderList: {
          ...orderList,
          loading: false,
          list,
          total,
        },
      }));
    } catch (e) {
      this.setState(({ orderList }) => ({
        loading: false,
        orderList: {
          ...orderList,
          loading: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  _requestCompanyList = async (data) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ companyList }) => ({
        companyList: {
          ...companyList,
          loading: true,
        },
      }));

      const { data: { list, total } } = await CompanyService.fetchCompanyList(data);

      this.setState(({ companyList }) => ({
        companyList: {
          ...companyList,
          loading: false,
          list,
          total,
        },
      }));
    } catch (e) {
      this.setState(({ companyList }) => ({
        companyList: {
          ...companyList,
          loading: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  _requestAgentList = async (data) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ agentList }) => ({
        agentList: {
          ...agentList,
          loading: true,
        },
      }));

      const { data: { list, total } } = await AgentService.fetchAgentList(data);

      this.setState(({ agentList }) => ({
        agentList: {
          ...agentList,
          loading: false,
          list,
          total,
        },
      }));
    } catch (e) {
      this.setState(({ agentList }) => ({
        agentList: {
          ...agentList,
          loading: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  _requestPromotionalCicleList = async (data) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ promotionalCicleList }) => ({
        promotionalCicleList: {
          ...promotionalCicleList,
          loading: true,
        },
      }));

      const { data: { list, total } } = await PromotionalCicleService.fetchPromotionalCicleList(data);

      this.setState(({ promotionalCicleList }) => ({
        promotionalCicleList: {
          ...promotionalCicleList,
          loading: false,
          list,
          total,
        },
      }));
    } catch (e) {
      this.setState(({ promotionalCicleList }) => ({
        promotionalCicleList: {
          ...promotionalCicleList,
          loading: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  _requestMaterialList = async (data) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ materialList }) => ({
        materialList: {
          ...materialList,
          loading: true,
        },
      }));

      const { data: { list, total } } = await OrderService.fetchMaterialList(data);

      this.setState(({ materialList }) => ({
        materialList: {
          ...materialList,
          loading: false,
          list,
          total,
        },
      }));
    } catch (e) {
      this.setState(({ materialList }) => ({
        materialList: {
          ...materialList,
          loading: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  _requestProductList = async (data) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ productList }) => ({
        productList: {
          ...productList,
          loading: true,
        },
      }));

      const { data: { list, total } } = await ProductService.fetchProductList(data);

      this.setState(({ productList }) => ({
        productList: {
          ...productList,
          loading: false,
          list,
          total,
        },
      }));
    } catch (e) {
      this.setState(({ productList }) => ({
        productList: {
          ...productList,
          loading: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  _downloadSelectedData = async (orders) => {
    const { dispatch } = this.props;

    try {
      this.setState({
        loading: true,
      });

      await OrderService.downloadSelectedData(
        orders.map((order) => order.data.id),
        'export.csv',
      );

      dispatch(showSuccessToast(`${orders.length} Pedido${orders.length > 1 ? 's exportados' : ' exportado'} com sucesso!`));

      this.setState({
        loading: false,
      });
    } catch (e) {
      this.setState({
        loading: false,
      });

      dispatch(showErrorToast(e.message));
    }
  }

  _downloadFilteredData = async (filters, search) => {
    const { dispatch } = this.props;

    try {
      this.setState({
        loading: true,
      });

      await OrderService.downloadFilteredData(filters, 'export.csv', search);

      dispatch(showSuccessToast('Pedidos exportados com sucesso!'));

      this.setState({
        loading: false,
      });
    } catch (e) {
      this.setState({
        loading: false,
      });

      dispatch(showErrorToast(e.message));
    }
  }

  _handleChangeCompanyView = async (companyId) => {
    const { dispatch } = this.props;

    try {
      this.setState((prevState) => ({
        orderList: {
          ...prevState.orderList,
          loading: true,
          list: [],
          total: 0,
        },
      }));

      const response = await ChangeViewService.changeCompanyView(companyId);

      dispatch(AuthActions.setToken(response.token));

      const { data } = await AuthService.authorization();

      if (companyId !== null) {
        await dispatch(AuthActions.setUserData({
          id: data.id,
          username: data.name,
          companyName: data.companyName,
          userRole: data.role,
          userPermissions: data.userPermissions,
        }));
      } else {
        await dispatch(AuthActions.setUserData({
          username: data.name,
          userRole: data.role,
          userPermissions: data.userPermissions,
        }));
      }
      document.location.reload();
    } catch (error) {
      console.error(error.message);
      dispatch(showErrorToast(error.message));
    }
  };

  _requestCompanyDialogList = async () => {
    this.setState((prevState) => ({
      companyDialogList: {
        ...prevState.companyDialogList,
        loading: true,
      },
    }));
    const response = await getCompanyDialogList();
    this.setState((prevState) => ({
      companyDialogList: {
        ...prevState.companyDialogList,
        loading: false,
        list: response.list,
      },
    }));
  };

  _requestOrderDetail = async (id) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ orderDetailData }) => ({
        loading: true,
        orderDetailData: {
          ...orderDetailData,
          loading: true,
          data: {},
        },
      }));

      const { data } = await OrderService.fetchOrderDetail(id);

      this.setState(({ orderDetailData }) => ({
        loading: false,
        orderDetailData: {
          ...orderDetailData,
          loading: false,
          data,
        },
      }));
      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: false,
          finished: false,
        },
      }));
    } catch (e) {
      this.setState(({ orderDetailData }) => ({
        loading: false,
        orderDetailData: {
          ...orderDetailData,
          loading: false,
          data: {},
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  _requestApproveOrder = async (id) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: true,
          finished: false,
        },
      }));

      await OrderService.approveOrder(id);

      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: false,
          finished: true,
        },
      }));
    } catch (e) {
      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: false,
          finished: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  _requestRefuseOrder = async (id) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: true,
          finished: false,
        },
      }));

      await OrderService.refuseOrder(id);

      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: false,
          finished: true,
        },
      }));
    } catch (e) {
      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: false,
          finished: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  _requestCancelOrder = async (id) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: true,
          finished: false,
        },
      }));

      await OrderService.cancelOrder(id);

      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: false,
          finished: true,
        },
      }));
    } catch (e) {
      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: false,
          finished: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  _requestWmsOrderlist = async (data) => {
    const { dispatch } = this.props;
    try {
      if (data.changedCompany) {
        this.setState((prevState) => ({
          wmsOrderlist: {
            ...prevState.wmsOrderlist,
            list: [],
            loading: true,
          },
        }));

        const { data: { list, total } } = await OrderService.fetchWmsOrderList(data);

        this.setState((prevState) => ({
          wmsOrderlist: {
            ...prevState.wmsOrderlist,
            loading: false,
            list,
            total,
          },
        }));
      } else {
        this.setState((prevState) => ({
          wmsOrderlist: {
            ...prevState.wmsOrderlist,
            loading: true,
          },
        }));

        const { data: { list, total } } = await OrderService.fetchWmsOrderList(data);

        this.setState((prevState) => ({
          wmsOrderlist: {
            ...prevState.wmsOrderlist,
            loading: false,
            list: [...prevState.wmsOrderlist.list, ...list],
            total,
          },
        }));
      }
    } catch (error) {
      this.setState((prevState) => ({
        wmsOrderlist: {
          ...prevState.wmsOrderlist,
          loading: false,
        },
      }), () => {
        console.error(error.message);
        dispatch(showErrorToast(error.message));
      });
    }
  }

  _requestSearchWmsOrderlist = async (data) => {
    const { dispatch } = this.props;

    try {
      this.setState((prevState) => ({
        searchWmsOrderlist: {
          ...prevState.searchWmsOrderlist,
          loading: true,
          list: [],
        },
      }));

      const response = await OrderService.fetchWmsOrderList(data);

      this.setState((prevState) => ({
        searchWmsOrderlist: {
          ...prevState.searchWmsOrderlist,
          loading: false,
          list: response.data.list,
        },
      }));
    } catch (error) {
      this.setState((prevState) => ({
        searchWmsOrderlist: {
          ...prevState.searchWmsOrderlist,
          loading: false,
        },
      }), () => {
        console.error(error.message);
        dispatch(showErrorToast(error.message));
      });
    }
  }

  _getValidations = () => Yup.object().shape({
    search: Yup.string().min(4, 'Digite no mínimo 4 caracteres'),
  });

  _requestPairOrder = async (data) => {
    const { dispatch } = this.props;
    const { wmsOrderlist, orderList } = this.state;

    try {
      this.setState(({ pairOrder }) => ({
        pairOrder: {
          ...pairOrder,
          loading: true,
        },
      }));

      await OrderService.pairOrder(data);

      this.setState(({ pairOrder }) => ({
        pairOrder: {
          ...pairOrder,
          loading: false,
        },
      }));

      const newWmsOrderlist = wmsOrderlist.list.filter((order) => order.orderNumber !== data.orderNumber);
      const newOrderList = orderList.list.filter((order) => order.id !== data.orderId);

      this.setState(({ wmsOrderlist }) => ({
        wmsOrderlist: {
          ...wmsOrderlist,
          list: newWmsOrderlist,
          total: parseInt(wmsOrderlist.total - 1, 10),
        },
      }));

      this.setState(({ orderList }) => ({
        orderList: {
          ...orderList,
          list: newOrderList,
          total: parseInt(orderList.total - 1, 10),
        },
      }));

      dispatch(showSuccessToast(`Pedidos WMS ${data.orderNumber} e ${data.orderId} pareados com sucesso!`));
    } catch (e) {
      this.setState(({ pairOrder }) => ({
        pairOrder: {
          ...pairOrder,
          loading: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  render() {
    const {
      userData,
    } = this.props;

    const {
      loading,
      agentList,
      promotionalCicleList,
      materialList,
      productList,
      orderList,
      companyList,
      companyDialogList,
      orderDetailData,
      orderOnApproval,
      wmsOrderlist,
      searchWmsOrderlist,
      pairOrder,
    } = this.state;

    return (
      <PairingLayout
        title="Pareamento de pedidos WMS"
        userData={userData}
        wmsOrderlist={wmsOrderlist}
        searchWmsOrderlist={searchWmsOrderlist}
        handleValidations={this._getValidations}
        loading={loading}
        agentList={agentList}
        promotionalCicleList={promotionalCicleList}
        materialList={materialList}
        productList={productList}
        orderList={orderList}
        companyList={companyList}
        orderDetailData={orderDetailData}
        onRequestOrderDetail={this._requestOrderDetail}
        onRequestAgentList={this._requestAgentList}
        onRequestPromotionalCicleList={this._requestPromotionalCicleList}
        onRequestMaterialList={this._requestMaterialList}
        onRequestProductList={this._requestProductList}
        onRequestOrderList={this._requestOrderList}
        onExportFilteredData={this._downloadFilteredData}
        onExportSelectedData={this._downloadSelectedData}
        onRequestCompanyList={this._requestCompanyList}
        companyDialogList={companyDialogList}
        loadingDialog={companyDialogList.loading}
        handleOpenCompanyDialog={this._requestCompanyDialogList}
        handleOutputCompanyDialog={this._handleChangeCompanyView}
        handleConfirmApproveDialog={this._requestApproveOrder}
        handleConfirmRefuseDialog={this._requestRefuseOrder}
        handleConfirmCancelDialog={this._requestCancelOrder}
        loadingOrderDialog={orderOnApproval.loading}
        orderOnApprovalFinished={orderOnApproval.finished}
        onRequestWmsOrderlist={this._requestWmsOrderlist}
        onRequestSearchWmsOrderlist={this._requestSearchWmsOrderlist}
        pairOrder={pairOrder}
        onPairOrder={this._requestPairOrder}
      />
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  userData: auth.userData,
  isLogged: auth.sessionToken ? true : false,
});

export default connect(mapStateToProps)(PairingPage);
