import MaterialService from './MaterialService';
import {
  fieldPermissionsNames,
  fieldNames,
  orderByType,
} from '../constants/stock/filters';
import {
  Availability,
  orderByFields,
  orderByValues,
} from '../constants/stock/enums';
import { OrderTypes } from '../constants';
import { dateObjToService } from '../components/Filter/date';

import { HttpService } from './http';
import { API_BASE_URL } from '../constants/API';
import { OrderService } from './OrderService';

const Endpoint = {
  removeStockPermissionStock: `${API_BASE_URL}/stockpermission`,
  removeAllStockPermissionAgent: `${API_BASE_URL}/stockpermission/user`,
  stockPermissionList: `${API_BASE_URL}/stockpermission/list`,
  stockPermissionByAgentList: `${API_BASE_URL}/stockpermission/list/agent`,
  stockPermissionDetail: `${API_BASE_URL}/stockpermission`,
  stockPosition: `${API_BASE_URL}/batches/stockPosition`,
};

export class StockService {
  static getDefaultRequest = () => ({
    skip: 0,
    take: 0,
    filters: {
      standard: [],
      reserved: undefined,
    },
    orderBy: {
      attribute: 'name',
      value: 'ASC',
    },
  });

  static transformCheckFilter = (checkFilter, parseFunc) => Object.entries(checkFilter || {}).reduce((final, [key, value]) => {
    if (value) {
      return [
        ...final,
        parseFunc ? parseFunc(key) : key,
      ];
    }

    return final;
  }, []);

  static transformFiltersToRequest = (filters, search) => {
    const filtersRequest = {};

    if (!Object.values(filters[fieldPermissionsNames.AGENT]).every((item) => item === false)) {
      filtersRequest[fieldPermissionsNames.AGENT] = StockService.transformCheckFilter(filters[fieldPermissionsNames.AGENT]);
    }

    if (!Object.values(filters[fieldPermissionsNames.ALERT]).every((item) => item === false)) {
      const activeTypes = Object.keys(filters[fieldPermissionsNames.ALERT]);
      filtersRequest[fieldPermissionsNames.ALERT] = {
        [activeTypes]: true,
      };
    }

    filtersRequest.standard = [];

    if (search && search.term && search.term.length) {
      filtersRequest.standard.push({
        attribute: fieldPermissionsNames.SEARCH,
        value: search.term,
      });
    }

    if (!Object.values(filters[fieldPermissionsNames.MATERIAL_TYPE]).every((item) => item === false)) {
      const activeTypes = Object.keys(filters[fieldPermissionsNames.MATERIAL_TYPE]).filter((i) => filters[fieldPermissionsNames.MATERIAL_TYPE][i]);
      filtersRequest.standard.push({
        attribute: fieldPermissionsNames.MATERIAL_TYPE,
        value: activeTypes,
      });
    }

    if (!Object.values(filters[fieldPermissionsNames.STATUS]).every((item) => item === false)) {
      const activeTypes = Object.keys(filters[fieldPermissionsNames.STATUS]).filter((i) => filters[fieldPermissionsNames.STATUS][i]);
      filtersRequest.standard.push({
        attribute: fieldPermissionsNames.STATUS,
        value: activeTypes,
      });
    }
    return filtersRequest;
  }

  static async requestMaterialList(data) {
    const request = StockService.getDefaultRequest();

    request.skip = data.skip;
    request.take = data.take;

    request.orderBy = {
      attribute:
        data.orderBy.field === orderByType.GREATER_QUANTITY
          || data.orderBy.field === orderByType.LESS_QUANTITY ? orderByFields.QUANTITY
          : data.orderBy.field === orderByType.VALIDITY ? orderByFields.DUE_DATE : orderByFields.NAME,
      value:
        data.orderBy.field === orderByType.GREATER_QUANTITY ? orderByValues.DESC
          : data.orderBy.field === orderByType.LESS_QUANTITY ? orderByValues.ASC
            : data.orderBy.field === orderByType.VALIDITY ? orderByValues.DESC : orderByValues.ASC,
    };

    if (data.filters[fieldNames.DUE_DATE].from || data.filters[fieldNames.DUE_DATE].to) {
      request.filters = {
        ...request.filters,
        dueDateRange: dateObjToService(data.filters[fieldNames.DUE_DATE]),
      };
    }

    if (data.filters[fieldNames.AVAILABILITY]) {
      request.filters.reserved = Availability[data.filters[fieldNames.AVAILABILITY]];
    }

    request.filters.standard = [];

    if (data.search.term && data.search.term.length) {
      request.filters.standard.push({
        attribute: data.search.type,
        value: data.search.term,
      });
    }

    if (!Object.values(data.filters.materialType).every((item) => item === false)) {
      const activeTypes = Object.keys(data.filters.materialType).filter((i) => data.filters.materialType[i]);
      request.filters.standard.push({
        attribute: fieldNames.TYPE,
        value: activeTypes,
      });
    }

    return MaterialService.fetchMaterialList(request);
  }

  /**
   * @param {Object} request
   * @param {boolean} request.skip - Skip the results
   * @param {boolean} request.take - Take a certain number of results
   * @param {Object} request.filters
   * @param {{ attribute: string, value: string | number | boolean }[]} request.filters.standard
   * @param {{ attribute: string, name: string }} request.orderBy
   */
  static async fetchStockPermissionList(data) {
    const request = StockService.getDefaultRequest();

    request.skip = data.skip;
    request.take = data.take;
    request.filters = StockService.transformFiltersToRequest(data.filters, data.search);
    if (data.orderBy && data.orderBy.orderType) {
      request.orderBy = {
        attribute: data.orderBy.field,
        value: data.orderBy.orderType === OrderTypes.ASC ? 'ASC' : 'DESC',
      };
    }

    if (!data.orderBy.orderType) {
      request.orderBy = {
        attribute: 'id',
        value: 'DESC',
      };
    }
    const response = await HttpService.post(Endpoint.stockPermissionList, request);
    return response;
  }

  static async fetchDownloadStockPosition(data) {
    const { skip, take, fileName } = data;

    const response = await OrderService.downloadFile({
      endpoint: Endpoint.stockPosition,
      data: { skip, take },
      fileName,
    });

    return response;
  }

  static async fetchStockPermissionByAgentList(data) {
    const response = await HttpService.post(Endpoint.stockPermissionByAgentList, data);
    return response;
  }

  /**
   * @param {string | number} id
   */
  static async fetchStockPermissionDetail(id) {
    const response = await HttpService.get(`${Endpoint.stockPermissionDetail}/${id}`);
    return response;
  }

  /**
   * @param {string | number} id
   */
  static async requestRemovePermissionStock(id) {
    const response = await HttpService.delete(`${Endpoint.removeStockPermissionStock}/${id}`);
    return response;
  }

  static async requestRemoveAllStockPermissionAgent(id) {
    const response = await HttpService.delete(`${Endpoint.removeAllStockPermissionAgent}/${id}?allDelete=true`);
    return response;
  }

  static async requestRemoveStockPermissionAgent(id, stockPermissionId) {
    const response = await HttpService.delete(`${Endpoint.removeAllStockPermissionAgent}/${id}?allDelete=false&stockPermissionId=${stockPermissionId}`);
    return response;
  }

  static async requestEditStockPermission(data) {
    await HttpService.patchWithEmptyFields(`${Endpoint.stockPermissionDetail}/${data.id}`, data);
  }

  static async requestNewStockPermission(data) {
    const response = await HttpService.post(`${Endpoint.stockPermissionDetail}`, data);
    return response;
  }
}
