import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  Container,
  Grid,
  Tabs,
  Tab,
} from '@material-ui/core/';
import {
  PageDetail,
  LoadMask,
  Button,
  DialogModal,
  DrawerWindow,
} from '../../../../../components';
import { ITEMS_PER_PAGE } from '../../../../../constants/Paginate';
import { RoutePaths } from '../../../../../constants/RoutePaths';
import OrderAgentTable from './orderAgentTable';
import StockPermissionAgentTable from './materialPermissionAgentTable';
import { agentDetailTabs } from '../../../../../constants/agent';
import { Colors } from '../../../../../constants';
import { useDidUpdateEffect } from '../../../../../utils/hooks/did-update-effect';
import OrderDetail from '../../../../shared/order/order-list-layout/orderDetail';

const StyledTabs = styled(Tabs)`
  width: 100%;
  margin-top: -60px;

  button {
    min-width: 50%;
    width: 50%;
  }
`;

const TabContent = styled.div`
  display: flex;
  margin-top: 8px;
`;

const TabPanel = styled.div`
  width: 100%;
  margin-bottom: 48px;
`;

const TabHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 96px;
`;

const TabTitle = styled.span`
  font-size: 24px;
  color: ${Colors.SECONDARY_DARK};
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const AgentDetailLayout = ({ ...props }) => {
  const {
    userData,
    agentDetailData,
    orderList,
    stockPermissionList,
    onRequestStockPermissionList,
    loading,
    requestDialog,
    handleRemoveMaterialPermissionDialog,
    handleRemoveAgent,
    orderDetailData,
    onRequestOrderDetail,
    onExportSelectedData,
    orderOnApprovalFinished,
    loadingOrderDialog,
    handleConfirmApproveDialog,
    handleConfirmRefuseDialog,
    handleConfirmCancelDialog,
    handleRemoveAllStockPermissionAgent,
    newfiltersInitialState,
    onRequestOrderList,
    loadingExport,
  } = props;
  const history = useHistory();
  const [tabActive, setTabActive] = useState(agentDetailTabs.ORDERS);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE);
  const [isFirstLoadStockPermission, setIsFirstLoadStockPermission] = useState(true);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenAgentDialog, setIsOpenAgentDialog] = useState(false);
  const [isOpenAllStockPermissionAgentDialog, setIsOpenAllStockPermissionAgentDialog] = useState(false);
  const [stockPermissionId, setStockPermissionId] = useState(undefined);
  const [openDrawer, setDrawerState] = useState(false);
  const [isApproveDialog, setIsApproveDialog] = useState({ id: null, open: false });
  const [isRefuseDialog, setIsRefuseDialog] = useState({ id: null, open: false });
  const [isCancelDialog, setIsCancelDialog] = useState({ id: null, open: false });
  const [orderData, setOrderData] = useState([]);
  const [orderNumber, setOrderNumber] = useState('');

  useDidUpdateEffect(() => {
    const requestParams = {
      take: itemsPerPage,
      skip: (currentPage - 1) * itemsPerPage,
      userId: agentDetailData.id,
    };
    onRequestStockPermissionList(requestParams);
  }, [
    currentPage,
    itemsPerPage,
  ]);

  useEffect(() => {
    if (!requestDialog.isOpenDialog) {
      setIsOpenDialog(false);
      setIsOpenAgentDialog(false);
      setIsOpenAllStockPermissionAgentDialog(false);
    }
  }, [
    requestDialog,
  ]);

  const handleChange = (event, tab) => {
    setTabActive(tab);
    if (tab === agentDetailTabs.MATERIAL_PERMISSIONS && isFirstLoadStockPermission) {
      onRequestStockPermissionList({
        skip: 0,
        take: ITEMS_PER_PAGE,
        userId: agentDetailData.id,
      });
      setIsFirstLoadStockPermission(false);
    }
  };

  const _onPageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const _onChangeItemsPerPage = (event, newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const _onRemoveMaterialPermissionDialog = (id) => {
    setIsOpenDialog(true);
    setStockPermissionId(id);
  };

  const _handleCloseDialog = () => {
    setIsOpenDialog(false);
  };

  const _handleCloseAgentDialog = () => {
    setIsOpenAgentDialog(false);
  };

  const _handleCloseAllStockPermissionAgentDialog = () => {
    setIsOpenAllStockPermissionAgentDialog(false);
  };

  const _handleConfirmStockPermissionDialog = () => {
    handleRemoveMaterialPermissionDialog(stockPermissionId, agentDetailData.id);
  };

  const _handleConfirmRemoveAllStockPermissionDialog = () => {
    handleRemoveAllStockPermissionAgent(agentDetailData.id);
  };

  const _onRemoveAgentDialog = () => {
    setIsOpenAgentDialog(true);
  };

  const _onRemoveeAllStockPermissionDialog = () => {
    setIsOpenAllStockPermissionAgentDialog(true);
  };

  const _handleConfirmAgentDialog = () => {
    handleRemoveAgent(agentDetailData.id);
  };

  const _onRequestOrderDetail = (data) => {
    setDrawerState(true);
    onRequestOrderDetail(data.id);
    setOrderData([{ data }]);
    setOrderNumber(data.orderNumber);
  };

  const _onCloseDrawer = () => {
    setDrawerState(false);
  };

  const _onOpenUrls = (nfes) => {
    nfes.map((item) => item.url ? window.open(item.url) : null);
  };

  const _onCloseDialog = () => {
    setIsApproveDialog({ id: null, open: false });
    setIsRefuseDialog({ id: null, open: false });
    setIsCancelDialog({ id: null, open: false });
    if (orderOnApprovalFinished) {
      setDrawerState(false);
      setTimeout(() => {
        onRequestOrderList({
          filters: newfiltersInitialState,
          take: ITEMS_PER_PAGE,
          skip: 0,
          orderBy: {
            attribute: 'id',
            value: 'DESC',
          },
        });
      }, 300);
    }
  };

  return (
    <>
      <PageDetail
        title={agentDetailData.name ? agentDetailData.name : 'Sem nome'}
        userData={userData}
        originPath={RoutePaths.AGENT}
        originPathName="Representantes"
        agentDetailData={agentDetailData}
        onRemoveAgentDialog={_onRemoveAgentDialog}
        isAgentDetail
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid
              item
              lg={12}
              md={12}
            >
              <StyledTabs
                indicatorColor="primary"
                textColor="primary"
                value={tabActive}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="Últimos pedidos" />
                <Tab label="Materiais autorizados" />
              </StyledTabs>
              <TabContent>
                <TabPanel
                  role="tabpanel"
                  hidden={tabActive !== agentDetailTabs.ORDERS}
                  id={`simple-tabpanel-${agentDetailTabs.ORDERS}`}
                  aria-labelledby={`simple-tab-${agentDetailTabs.ORDERS}`}
                >
                  <TabHead>
                    <TabTitle>
                      Últimos pedidos do representante
                    </TabTitle>
                    <Button
                      size="large"
                      color="secondary"
                      variant="outlined"
                      handleClick={() => { history.push(RoutePaths.ORDER, { agentId: { [agentDetailData.id]: true } }); }}
                    >
                      Ver todos
                    </Button>
                  </TabHead>
                  <OrderAgentTable
                    userData={userData}
                    data={orderList.list}
                    loading={orderList.loading}
                    totalItems={orderList.total}
                    onRequestOrderDetail={_onRequestOrderDetail}
                  />
                </TabPanel>
                <TabPanel
                  role="tabpanel"
                  hidden={tabActive !== agentDetailTabs.MATERIAL_PERMISSIONS}
                  id={`simple-tabpanel-${agentDetailTabs.MATERIAL_PERMISSIONS}`}
                  aria-labelledby={`simple-tab-${agentDetailTabs.MATERIAL_PERMISSIONS}`}
                >
                  <TabHead>
                    <TabTitle>
                      Permissões de estoque do representante
                    </TabTitle>
                    <Button
                      size="large"
                      color="secondary"
                      variant="outlined"
                      handleClick={() => { _onRemoveeAllStockPermissionDialog(); }}
                    >
                      Excluir todas
                    </Button>
                  </TabHead>
                  <StockPermissionAgentTable
                    userData={userData}
                    data={stockPermissionList.list}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    onPageChange={_onPageChange}
                    onChangeItemsPerPage={_onChangeItemsPerPage}
                    loading={stockPermissionList.loading}
                    totalItems={stockPermissionList.total}
                    onRemoveMaterialPermissionDialog={_onRemoveMaterialPermissionDialog}
                  />
                </TabPanel>
              </TabContent>
            </Grid>
          </Grid>
        </Container>
      </PageDetail>
      <DialogModal
        title="Excluir permissão"
        size="md"
        description={(
          <span>
            Tem certeza que deseja excluir a permissão?
            <br />
            <BoldText> (Essa ação não poderá ser desfeita)</BoldText>
            .
          </span>
        )}
        confirmButtonText="Excluir permissão"
        handleCloseDialog={_handleCloseDialog}
        handleConfirmDialog={_handleConfirmStockPermissionDialog}
        isOpenDialog={isOpenDialog}
        loadingDialog={requestDialog.loading}
      />
      <DialogModal
        title="Excluir permissões"
        size="md"
        description={`O representante ${agentDetailData.name} será removido de todas as permissões de estoque concedidas anteriormente. Tem certeza que deseja prosseguir?`}
        confirmButtonText="Prosseguir"
        handleCloseDialog={_handleCloseAllStockPermissionAgentDialog}
        handleConfirmDialog={_handleConfirmRemoveAllStockPermissionDialog}
        isOpenDialog={isOpenAllStockPermissionAgentDialog}
        loadingDialog={requestDialog.loading}
      />
      <DialogModal
        title="Remover representante"
        size="md"
        description={(
          <span>
            Tem certeza que deseja remover o representante
            <br />
            <BoldText>{agentDetailData.name}</BoldText>
            ?
            <BoldText> (Essa ação não poderá ser desfeita).</BoldText>
          </span>
        )}
        confirmButtonText="Remover"
        cancelButtonText="Cancelar"
        handleCloseDialog={_handleCloseAgentDialog}
        handleConfirmDialog={_handleConfirmAgentDialog}
        isOpenDialog={isOpenAgentDialog}
        loadingDialog={requestDialog.loading}
      />
      <DrawerWindow
        openDrawer={orderDetailData.data.receiver !== undefined && !orderDetailData.loading && openDrawer}
        handleCloseDrawer={_onCloseDrawer}
        hideBackdrop
      >
        <OrderDetail
          userData={userData}
          orderNumber={orderNumber}
          orderData={orderData}
          orderDetailData={orderDetailData}
          onCloseDrawer={_onCloseDrawer}
          onExportSelectedData={onExportSelectedData}
          openApproveOrderDialog={(id, open) => setIsApproveDialog({ id, open })}
          openRefuseOrderDialog={(id, open) => setIsRefuseDialog({ id, open })}
          openCancelOrderDialog={(id, open) => setIsCancelDialog({ id, open })}
          handleOpenUrls={_onOpenUrls}
        />
      </DrawerWindow>

      <DialogModal
        title={orderOnApprovalFinished ? 'Pedido aprovado' : 'Aprovar pedido'}
        description={orderOnApprovalFinished ? '' : 'Tem certeza que deseja aprovar este pedido?'}
        cancelButtonText={orderOnApprovalFinished ? 'Fechar' : 'Voltar'}
        hideConfirmButton={orderOnApprovalFinished}
        confirmButtonText="Aprovar pedido"
        handleCloseDialog={_onCloseDialog}
        loadingDialog={loadingOrderDialog}
        handleConfirmDialog={() => { handleConfirmApproveDialog(isApproveDialog.id); }}
        isOpenDialog={isApproveDialog.open}
      />

      <DialogModal
        title={orderOnApprovalFinished ? 'Pedido recusado' : 'Recusar pedido'}
        description={orderOnApprovalFinished ? '' : 'Tem certeza que deseja recusar este pedido?'}
        cancelButtonText={orderOnApprovalFinished ? 'Fechar' : 'Voltar'}
        hideConfirmButton={orderOnApprovalFinished}
        confirmButtonText="Recusar pedido"
        handleCloseDialog={_onCloseDialog}
        loadingDialog={loadingOrderDialog}
        handleConfirmDialog={() => { handleConfirmRefuseDialog(isRefuseDialog.id); }}
        isOpenDialog={isRefuseDialog.open}
      />

      <DialogModal
        title={orderOnApprovalFinished ? 'Pedido cancelado' : 'Cancelar pedido'}
        description={orderOnApprovalFinished ? '' : 'Tem certeza que deseja cancelar este pedido?'}
        cancelButtonText={orderOnApprovalFinished ? 'Fechar' : 'Voltar'}
        hideConfirmButton={orderOnApprovalFinished}
        confirmButtonText="Cancelar pedido"
        handleCloseDialog={_onCloseDialog}
        loadingDialog={loadingOrderDialog}
        handleConfirmDialog={() => { handleConfirmCancelDialog(isCancelDialog.id); }}
        isOpenDialog={isCancelDialog.open}
      />
      {loading || orderDetailData.loading || orderList.loading || loadingExport ? <LoadMask /> : null}
    </>
  );
};

AgentDetailLayout.propTypes = {
  userData: PropTypes.object,
  agentDetailData: PropTypes.object,
  orderList: PropTypes.object,
  stockPermissionList: PropTypes.object,
  loading: PropTypes.bool,
  handleRemoveMaterialPermissionDialog: PropTypes.func,
  orderOnApprovalFinished: PropTypes.bool,
  loadingOrderDialog: PropTypes.bool,
};

AgentDetailLayout.defaultProps = {
  userData: {},
  agentDetailData: {},
  orderList: {
    loading: false,
    list: [],
    total: 0,
  },
  stockPermissionList: {
    loading: false,
    list: [],
    total: 0,
  },
  loading: false,
  handleRemoveMaterialPermissionDialog: () => {},
  orderOnApprovalFinished: false,
  loadingOrderDialog: false,
};

export default AgentDetailLayout;
