import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Search } from '@material-ui/icons';
import { Table, ThContainer } from '../../../../../../components/Table/Table';
import { Button, Text, EmptyStateWithAction } from '../../../../../../components';
import { Colors } from '../../../../../../constants';
import { columnLabel, columnType } from '../../../../../../constants/agent';
import { RoutePaths } from '../../../../../../constants/RoutePaths';
import { mediaHelper } from '../../../../../../utils';

const StyledEmptyStateWithAction = styled(EmptyStateWithAction)`
  padding: 40px 16px;
  border-bottom: 1px solid ${Colors.GRAY_LIGHTER};
  flex-direction: column;
`;

const StyledEmptyActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  flex-direction: column;

  ${mediaHelper.lg`
    flex-direction: row;
  `}

  button {
    margin-bottom: 8px;
  }
`;

const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: ${Colors.PRIMARY_BLUE};
`;

const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 4px 0;
`;

const Tag = styled.div`
  border: 1px solid ${Colors.GRAY};
  border-radius: 16px;
  padding: 4px 8px;
  color: ${Colors.GRAY_DARKER};
  font-size: 14px;
  margin: 4px 4px 4px 0;
`;

const AddressWrapper = styled.div`
  padding-right: 16px;
`;

const SearchStyled = styled(Search)`
  color: ${Colors.SECONDARY_DARK};
`;

const StyledText = styled(Text)`
  width: 100%;
  text-align: center;
`;

const TableStyled = styled(Table)`
  & ${ThContainer} {
    padding-left: 16px;
  }
`;

const AgentManagementTable = ({
  userData,
  data,
  loading,
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
  onChangeItemsPerPage,
  onChangeDataOrder,
  dataOrder,
  multiselect,
  radiobutton,
  ...props
}) => {
  const history = useHistory();

  const _renderBasicTextCell = (value) => (
    <Text>
      {value || '--'}
    </Text>
  );

  const _renderBasicCenterTextCell = (value) => (
    <StyledText>
      {value || '--'}
    </StyledText>
  );

  const columnConfig = {
    [columnType.AGENT]: {
      order: 1,
    },
    [columnType.CITY]: {
      order: 2,
    },
    [columnType.TAGS]: {
      order: 3,
      noSorting: true,
    },
    [columnType.TOTAL_ORDERS]: {
      order: 4,
      align: 'center',
    },
    [columnType.ACTION]: {
      order: 5,
      noHeader: true,
      noSorting: true,
    },
  };

  const components = {
    [columnType.AGENT]: _renderBasicTextCell,
    [columnType.CITY]: (fieldData, data, rowIndex) => {
      const rowData = data[rowIndex];
      return (
        <AddressWrapper>
          <Text>
            {`${rowData.city} - ${rowData.uf}`}
          </Text>
        </AddressWrapper>
      );
    },
    [columnType.TAGS]: (tags) => (
      <TagWrapper>
        {tags.map((tag) => (
          <Tag key={`${props.name}-${tag.id}`}>{tag.name}</Tag>
        ))}
      </TagWrapper>
    ),
    [columnType.TOTAL_ORDERS]: _renderBasicCenterTextCell,
    [columnType.ACTION]: () => (
      <ActionsContainer>
        <SearchStyled />
      </ActionsContainer>
    ),
  };
  return (
    <>
      <TableStyled
        name="agent-table"
        isLoading={loading}
        footerText="Itens por página: "
        multiselect={multiselect}
        radiobutton={radiobutton}
        data={data}
        handleHeaderTitle={(key) => columnLabel[key]}
        columnConfig={columnConfig}
        components={components}
        paginationProps={{
          currentPage,
          totalItems,
          itemsPerPage,
          onPageChange,
        }}
        onChangeItemsPerPage={onChangeItemsPerPage}
        dataOrder={dataOrder}
        onOrderColumn={onChangeDataOrder}
        onRowClick={(data) => { history.push(`${RoutePaths.AGENT}/${data.id}`); }}
        renderEmptyState={() => (
          <StyledEmptyStateWithAction
            alignCenter
            emptyStateText="Nenhum representante pra exibir :("
            renderAction={() => (
              <StyledEmptyActionsContainer>
                <Button
                  variant="outlined"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Atualizar
                </Button>
              </StyledEmptyActionsContainer>
            )}
          />
        )}
      />
    </>
  );
};

AgentManagementTable.propTypes = {
  currentPage: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  onChangeItemsPerPage: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onChangeDataOrder: PropTypes.func,
  multiselect: PropTypes.bool,
  radiobutton: PropTypes.bool,
  handleRowCheck: PropTypes.func,
};

AgentManagementTable.defaultProps = {
  loading: false,
  onChangeDataOrder: undefined,
  multiselect: false,
  radiobutton: false,
  handleRowCheck: () => {},
};

export default AgentManagementTable;
