import React from 'react';
import styled from 'styled-components';

import { Text, FontWeight } from './Text';
import Icon from './Icon';
import { Colors } from '../constants';

const TouchableTextContainer = styled(Text)`
  font-size: 14px;
  color: ${Colors.PRIMARY_BLUE};
  cursor: pointer;
  text-decoration: ${({ underline }) => underline ? 'underline' : 'none'};
  &:hover {
    opacity: 0.7;
  }
`;

const LeftIcon = styled(Icon)`
  margin-right: 8px;
`;

const TouchableText = ({
  leftIcon,
  children,
  ...props
}) => (
  <TouchableTextContainer
    {...props}
    weight={FontWeight.Bold}
  >
    {leftIcon && <LeftIcon name={leftIcon} />}
    {children}
  </TouchableTextContainer>
);

export default styled(TouchableText)``;
