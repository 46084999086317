import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { Colors } from '../constants';
import { Text, FontWeight } from './Text';

const propTypes = {
  underline: PropTypes.bool,
  onClick: PropTypes.func,
};

const defaultProps = {
  underline: true,
  onClick: () => {},
};

export const LinkCSS = css`
  font-size: 14px;
  color: ${Colors.PRIMARY_BLUE};
  text-decoration: underline;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const LinkContainer = styled.a`
  ${LinkCSS}
  color: ${({ primaryBlue }) =>
    primaryBlue ? Colors.PRIMARY_BLUE : Colors.SECONDARY_DARKER};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
`;

const Link = ({ children, underline, onClick, ...props }) => (
  <LinkContainer
    target='_blank'
    underline={underline}
    onClick={(e) => {
      e.stopPropagation();
      if (onClick) onClick(e);
    }}
    {...props}
  >
    <Text weight={FontWeight.Bold}>{children}</Text>
  </LinkContainer>
);

Link.propTypes = propTypes;
Link.defaultProps = defaultProps;

export default styled(Link)``;
