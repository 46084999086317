import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';

import FilterSearchForm from './Filter/search/searchForm';
import ModalTable from './ModalTable';

const ModalTableCheckboxSearch = ({
  name,
  placeholder,
  value,
  searchTypeList = [],
  onSearch,
  isRefresh,
  ...props
}) => {
  const typeInitialState = searchTypeList && searchTypeList.length && searchTypeList[0].value;
  const [type, setType] = useState(typeInitialState);
  const [term, setTerm] = useState('');
  const [skip, setSkip] = useState(0);
  const [lastValue, setLastValue] = useState(value);
  const [isSearch, setIsSearch] = useState(false);

  useEffect(() => {
    if (!isEqual(lastValue, value)) {
      setType(typeInitialState);
      setTerm('');
      setLastValue(value);
    }
    setIsSearch(false);
  }, [
    value,
  ]);

  useEffect(() => {
    setType(typeInitialState);
    setTerm('');
    setLastValue('');
    setIsSearch(false);
  }, [
    isRefresh,
  ]);

  const handleChangeTypeRadio = (event) => {
    setType(event.target.value);
  };

  const handleChangeTerm = (event) => {
    setTerm(event.target.value);
  };

  const handleSearchSubmit = () => {
    setSkip(0);

    onSearch({
      type,
      term,
      skip: 0,
    });

    setIsSearch(true);
  };

  return (
    <ModalTable
      name={name}
      value={value}
      isSearch={isSearch}
      loadNextData={{
        type,
        term,
        skip,
      }}
      renderContentAbove={() => (
        <FilterSearchForm
          typeList={searchTypeList}
          placeholder={placeholder}
          name={name}
          value={{
            type,
            term,
          }}
          handleOutput={handleSearchSubmit}
          onChangeType={handleChangeTypeRadio}
          onChangeTerm={handleChangeTerm}
        />
      )}
      {...props}
    />
  );
};

ModalTableCheckboxSearch.propTypes = {
  name: PropTypes.any.isRequired,
  placeholder: PropTypes.any,
  searchTypeList: PropTypes.array,
  value: PropTypes.array,
  onSearch: PropTypes.func,
  isRefresh: PropTypes.bool,
  ...ModalTable.propTypes,
};

ModalTableCheckboxSearch.defaultProps = {
  placeholder: '',
  searchTypeList: [],
  value: [],
  onSearch: () => {},
  isRefresh: false,
};

export default ModalTableCheckboxSearch;
