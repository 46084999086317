export const columnType = {
  MATERIAL_NAME: 'name',
  AVAILABLE_QUANTITY: 'availableStock',
  AGENTS: 'totalAgents',
  AUTHORIZED_QUANTITY: 'authorizedQuantity',
  ALERT_QUANTITY: 'quantityAlert',
  STATUS: 'status',
  EDIT: 'edit',
  REMOVE: 'remove',
  AGENT: 'agentName',
  USERID: 'userId',
  CITY: 'city',
  TAGS: 'tags',
  QUANTITY: 'authorizedQuantity',
  CURRENT_BALANCE: 'currentBalance',
};

export const columnLabel = {
  [columnType.MATERIAL_NAME]: 'Material',
  [columnType.AVAILABLE_QUANTITY]: 'Estoque disponível',
  [columnType.AGENTS]: 'Representantes',
  [columnType.AUTHORIZED_QUANTITY]: 'Qtde Autorizada',
  [columnType.ALERT_QUANTITY]: 'Alerta Qtde',
  [columnType.STATUS]: 'Status',
  [columnType.EDIT]: '',
  [columnType.REMOVE]: '',
  [columnType.AGENT]: 'Representante',
  [columnType.CITY]: 'Cidade',
  [columnType.TAGS]: 'Tags',
  [columnType.QUANTITY]: 'Quantidade',
  [columnType.CURRENT_BALANCE]: 'Saldo total',
};
