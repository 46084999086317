import { HttpService } from './http';
import { API_BASE_URL } from '../constants/API';

const Endpoint = {
  fetchProductList: `${API_BASE_URL}/products`,
};

export class ProductService {
  /**
   * @param {{ term: string }} data
   */
  static async fetchProductList(data) {
    const response = await HttpService.get(Endpoint.fetchProductList, {
      productName: data.term,
    });

    return response;
  }
}

export default ProductService;
