import React from 'react';
import { Redirect } from 'react-router-dom';

import {
  PrivateRoute,
} from '../../components';
import HomePage from '../home';
import CompanyNewOrderPage from './order/new-order';
import CompanyOrderListPage from './order/order-list';
import StockPage from '../shared/stock/material-list';
import MaterialDetailPage from '../shared/stock/stock-detail';
import StockPermissionsPage from '../shared/stock/stock-permissions';
import PermissionDetailPage from '../shared/stock/stock-permission-detail';
import AgentManagementPage from './agent/agent-management';
import AgentDetailPage from './agent/agent-management-detail';
import NewAgentPage from './agent/new-agent';
import EditAgentPage from './agent/edit-agent';

import {
  RoutePaths,
  StockPermissionRoutePaths,
  OrderRoutePaths,
  AgentRoutePaths,
} from '../../constants/RoutePaths';

const CompanyRoutes = ({ userData }) => (
  [
    <PrivateRoute
      key={`route-${RoutePaths.HOME}`}
      exact
      path={RoutePaths.HOME}
      render={() => (
        <HomePage
          userData={userData}
        />
      )}
    />,
    <PrivateRoute
      key={`route-${RoutePaths.STOCK}`}
      exact
      path={RoutePaths.STOCK}
      render={() => (
        <StockPage
          userData={userData}
        />
      )}
    />,
    <PrivateRoute
      key={`route-${RoutePaths.STOCK}/:id`}
      exact
      path={`${RoutePaths.STOCK}/:id`}
      render={(props) => (
        <MaterialDetailPage
          userData={userData}
          {...props}
        />
      )}
    />,
    <PrivateRoute
      key={`route-${RoutePaths.ORDER}`}
      exact
      path={RoutePaths.ORDER}
      render={() => (
        <CompanyOrderListPage
          userData={userData}
        />
      )}
    />,
    <PrivateRoute
      key={`route-${RoutePaths.ORDER}${OrderRoutePaths.NEW}`}
      exact
      path={`${RoutePaths.ORDER}${OrderRoutePaths.NEW}`}
      render={(props) => (
        <CompanyNewOrderPage
          userData={userData}
          {...props}
        />
      )}
    />,
    <PrivateRoute
      key={`route-${StockPermissionRoutePaths.PERMISSIONS}`}
      exact
      path={StockPermissionRoutePaths.PERMISSIONS}
      render={(props) => (
        <StockPermissionsPage
          userData={userData}
          {...props}
        />
      )}
    />,
    <PrivateRoute
      key={`route-${StockPermissionRoutePaths.PERMISSIONS}-detail`}
      exact
      path={`${StockPermissionRoutePaths.PERMISSIONS}/:id`}
      render={(props) => (
        <PermissionDetailPage
          userData={userData}
          {...props}
        />
      )}
    />,
    <PrivateRoute
      key={`route-${StockPermissionRoutePaths.NEW}`}
      exact
      path={`${StockPermissionRoutePaths.NEW}`}
      render={(props) => (
        <PermissionDetailPage
          userData={userData}
          {...props}
        />
      )}
    />,
    <PrivateRoute
      key={`route-${RoutePaths.AGENT}`}
      exact
      path={`${RoutePaths.AGENT}`}
      render={(props) => (
        <AgentManagementPage
          userData={userData}
          {...props}
        />
      )}
    />,
    <PrivateRoute
      key={`route-${RoutePaths.AGENT}/:id`}
      exact
      path={`${RoutePaths.AGENT}/:id`}
      render={(props) => (
        <AgentDetailPage
          userData={userData}
          {...props}
        />
      )}
    />,
    <PrivateRoute
      key={`route-${AgentRoutePaths.NEW}`}
      exact
      path={`${AgentRoutePaths.NEW}`}
      render={(props) => (
        <NewAgentPage
          userData={userData}
          {...props}
        />
      )}
    />,
    <PrivateRoute
      key={`route-${AgentRoutePaths.UPDATE}/:id`}
      exact
      path={`${AgentRoutePaths.UPDATE}/:id`}
      render={(props) => (
        <EditAgentPage
          userData={userData}
          isEditing
          {...props}
        />
      )}
    />,
    <Redirect
      key={`route-${RoutePaths.HOME}`}
      to={RoutePaths.HOME}
    />,
  ]
);

export default CompanyRoutes;
