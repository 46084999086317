import { HttpService } from './http';
import { API_BASE_URL } from '../constants/API';

const Endpoint = {
  fetchPromotionalCicleList: `${API_BASE_URL}/promotionalCicles`,
};

export class PromotionalCicleService {
  /**
   * @param {{ term: string }} data
   */
  static async fetchPromotionalCicleList(data) {
    const response = await HttpService.get(Endpoint.fetchPromotionalCicleList, {
      promotionalCicleName: data.term,
    });

    return response;
  }
}

export default PromotionalCicleService;
