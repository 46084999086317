import React from 'react';
import styled, { css } from 'styled-components';
import { ArrowDownward, ArrowUpward, SwapVert } from '@material-ui/icons';

import { Colors } from '../../constants';
import { OrderTypes } from '../../constants/OrderTypes';
import { Text, FontWeight } from '../Text';

const ThContent = styled.div`
  display: flex;
  align-items: center;

  & ${Text} {
    font-size: 14px;
    font-weight: 700;
    color: ${Colors.SECONDARY_DARK};

    ${({ disabled }) => disabled && `
      color: ${Colors.GRAY};
    `}

    ${({ ordered }) => ordered && `
      color: ${Colors.SECONDARY_MEDIUM};
    `}

    &:hover {
      color: ${Colors.PRIMARY_BLUE};
    }
  }
`;

const HeaderTextButton = styled.button`
  flex: 1;
  padding: 0;
  height: auto;
  text-transform: none;
  text-align: left;
  white-space: normal;
  border: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;

  ${({ noSorting }) => noSorting && css`
    &:hover {
      cursor: default;

      & ${Text} {
        color: ${Colors.SECONDARY_DARK};
      }
    }

    & ${Text} {
      text-transform: none;
    }
  `}
`;

const ArrowIconContainer = styled.div`
  margin-left: 8px;
`;

const ArrowIcon = styled.div`
  padding: 0;
  border-radius: 0;
  overflow: hidden;
  position: relative;
  color: ${Colors.GRAY_LIGHT};
  width: 20px;
  height: 28px;

  & > svg {
    font-size: 20px;
    position: absolute;
    color: ${Colors.GRAY};
    left: -2px;
    top: 4px;

    ${({ active }) => active && `
      color: ${Colors.PRIMARY_BLUE};
    `}
  }
`;

const TableHeader = ({
  dataOrder,
  headerKey,
  onChangeDataOrder,
  noSorting,
  noPadding,
  handleHeaderTitle,
  disabled,
  ...props
}) => (
  <ThContent
    ordered={dataOrder && dataOrder.field === headerKey}
    disabled={disabled}
    {...props}
  >
    <HeaderTextButton
      noSorting={noSorting}
      onClick={noSorting ? null : () => {
        if (dataOrder && dataOrder.field === headerKey && dataOrder.orderType === OrderTypes.ASC) {
          onChangeDataOrder(headerKey, OrderTypes.DESC);
        } else if (dataOrder && dataOrder.field === headerKey && dataOrder.orderType === OrderTypes.DESC) {
          onChangeDataOrder(headerKey, undefined);
        } else {
          onChangeDataOrder(headerKey, OrderTypes.ASC);
        }
      }}
    >
      <Text weight={FontWeight.Bold}>{handleHeaderTitle(headerKey)}</Text>
      {!noSorting && dataOrder && (
        <ArrowIconContainer>
          {(dataOrder.field !== headerKey || (dataOrder.field === headerKey && !dataOrder.orderType)) && (
            <ArrowIcon>
              <SwapVert />
            </ArrowIcon>
          )}
          {dataOrder.field === headerKey && dataOrder.orderType === OrderTypes.DESC && (
            <ArrowIcon active>
              <ArrowUpward />
            </ArrowIcon>
          )}
          {dataOrder.field === headerKey && dataOrder.orderType === OrderTypes.ASC && (
            <ArrowIcon active>
              <ArrowDownward />
            </ArrowIcon>
          )}
        </ArrowIconContainer>
      )}
    </HeaderTextButton>
  </ThContent>
);

export default styled(TableHeader)``;
