import React, { Component } from 'react';
import { connect } from 'react-redux';

import HomeLayout from './home-layout';
import {
  showErrorToast,
} from '../../components';
import {
  AuthService,
  ChangeViewService,
} from '../../services';
import { AuthActions } from '../../redux';
import { getCompanyDialogList } from '../../utils';

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      requestPageConfig: {
        loading: false,
      },
      companyDialogList: {
        loading: false,
        list: [],
      },
    };
  }

  _requestCompanyDialogList = async () => {
    this.setState((prevState) => ({
      companyDialogList: {
        ...prevState.companyDialogList,
        loading: true,
      },
    }));
    const response = await getCompanyDialogList();
    this.setState((prevState) => ({
      companyDialogList: {
        ...prevState.companyDialogList,
        loading: false,
        list: response.list,
      },
    }));
  };

  _handleChangeCompanyView = async (companyId) => {
    const { dispatch } = this.props;

    try {
      this.setState((prevState) => ({
        requestPageConfig: {
          ...prevState.requestPageConfig,
          loading: true,
        },
      }));

      const response = await ChangeViewService.changeCompanyView(companyId);

      dispatch(AuthActions.setToken(response.token));

      const { data } = await AuthService.authorization();

      if (companyId !== null) {
        await dispatch(AuthActions.setUserData({
          id: data.id,
          username: data.name,
          companyName: data.companyName,
          userRole: data.role,
          userPermissions: data.userPermissions,
        }));
      } else {
        await dispatch(AuthActions.setUserData({
          username: data.name,
          userRole: data.role,
          userPermissions: data.userPermissions,
        }));
      }
      this.setState((prevState) => ({
        requestPageConfig: {
          ...prevState.requestPageConfig,
          loading: false,
        },
      }));
    } catch (error) {
      console.error(error.message);
      dispatch(showErrorToast(error.message));
    }
  };

  render() {
    const {
      requestPageConfig,
      companyDialogList,
    } = this.state;
    const {
      userData,
    } = this.props;
    return (
      <HomeLayout
        userData={userData}
        loading={requestPageConfig.loading}
        companyDialogList={companyDialogList}
        loadingDialog={companyDialogList.loading}
        handleOpenCompanyDialog={this._requestCompanyDialogList}
        handleOutputCompanyDialog={this._handleChangeCompanyView}
      />
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  userData: auth.userData,
  isLogged: auth.sessionToken ? true : false,
});

export default connect(mapStateToProps)(HomePage);
