import PropTypes from 'prop-types';
import React from 'react';
import { IconButton } from '@material-ui/core';
import styled from 'styled-components';

import { Delete } from '@material-ui/icons';
import { Colors } from '../../../../constants';

const Container = styled.div`
  display: flex;
  padding: 18px 24px;
  height: 56px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
`;

const ValueText = styled.div`
  font-size: 14px;
  color: ${Colors.GRAY_DARKEST};
`;

const Title = styled.div`
  font-size: 16px;
  color: ${Colors.GRAY_DARKEST};
  font-weight: bold;
  margin-bottom: 4px;
`;

const ActionButtonContainer = styled.div`
  padding-top: 4px;
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 4px 0;
`;

const Tag = styled.div`
  border: 1px solid ${Colors.GRAY};
  border-radius: 16px;
  padding: 4px 8px;
  color: ${Colors.GRAY_DARKER};
  font-size: 14px;
  margin: 4px 4px 4px 0;
`;

function SelectedAgentRow({
  title,
  city,
  uf,
  tags,
  handleRemove,
  ...props
}) {
  return (
    <Container {...props}>
      <TitleWrapper>
        <Title>{title}</Title>
        <ValueText>
          {city || '--'}
          {' - '}
          {uf || '--'}
        </ValueText>
      </TitleWrapper>
      {tags.length > 0 && (
        <TagsWrapper>
          {tags.map((item) => (
            <Tag
              key={`${item.name}-${item.id}`}
            >
              {item.name}
            </Tag>
          ))}
        </TagsWrapper>
      )}
      <ActionButtonContainer>
        {handleRemove ? (
          <IconButton
            color="primary"
            aria-label="Remover representante"
            onClick={handleRemove}
          >
            <Delete />
          </IconButton>
        ) : null}
      </ActionButtonContainer>
    </Container>
  );
}

SelectedAgentRow.propTypes = {
  handleRemove: PropTypes.func,
  title: PropTypes.string,
  city: PropTypes.string,
  uf: PropTypes.string,
  tags: PropTypes.array,
};

SelectedAgentRow.defaultProps = {
  handleRemove: null,
  title: '',
  city: '',
  uf: '',
  tags: [],
};

export default SelectedAgentRow;
