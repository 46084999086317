export const USER_ROLES = {
  MASTER: 1,
  COMPANY: 2,
  AGENT: 3,
};

export const MODULES = {
  USERS: 1,
  COMPANIES: 2,
  WAREHOUSE: 3,
  DISTRIBUTIONS: 4,
};

export const SUB_MODULES = {
  [MODULES.USERS]: {
    ACCOUNTS: 1,
    MANAGE_COMPANY_GROUP: 2,
    AGENT: 3,
    TAG: 4,
  },
  [MODULES.COMPANIES]: {
    COMPANY: 1,
  },
  [MODULES.WAREHOUSE]: {
    MATERIAL: 1,
    PROMOTIONAL_CICLE: 2,
    MATCH: 3,
  },
  [MODULES.DISTRIBUTIONS]: {
    ORDER: 1,
    BATCH: 2,
    PRODUCT: 3,
    APPROVAL_ORDERS: 4,
  },
};

export const PERMISSIONS = {
  CREATE: 1,
  READ: 2,
  EDIT: 3,
  DELETE: 4,
};
