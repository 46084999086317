export const columnType = {
  ORDER_NUMBER: 'id',
  WMS_ORDER_NUMBER: 'orderNumber',
  CREATE_DATE: 'createdAt',
  DELIVERY: 'deliveryDate',
  MATERIAL: 'ordersMaterials',
  AGENT: 'agent',
  PROMOTIONAL_CICLE: 'promotionalCicle',
  AUTHOR: 'requester',
  STATUS: 'status',
  ACTION: 'action',
};

export const columnLabel = {
  [columnType.ORDER_NUMBER]: 'Ref. Pedido',
  [columnType.WMS_ORDER_NUMBER]: 'Nº do Pedido',
  [columnType.CREATE_DATE]: 'Criado em',
  [columnType.DELIVERY]: 'Entrega',
  [columnType.MATERIAL]: 'Material',
  [columnType.AGENT]: 'Representante',
  [columnType.PROMOTIONAL_CICLE]: 'Ciclo Promocional',
  [columnType.AUTHOR]: 'Criado por',
  [columnType.STATUS]: 'Status',
};
