import { checkboxDefaultInitialState } from '../../components/Filter/checkbox';
import { OrderStatusEnum, OrderStatusText } from '../OrderStatus';

export const companyFieldNames = {};

export const luftFieldNames = {
  COMPANY: 'companyList',
};

export const fieldNames = {
  CREATE_DATE: 'createdAt',
  DELIVERY_DATE: 'deliveryDate',
  AGENT: 'agentList',
  STATUS: 'statusList',
  PROMOTIONAL_CICLE: 'promotionalCicleList',
  MATERIAL: 'materialList',
  PRODUCT: 'productList',
  ORDER_BY: 'orderBy',
  SEARCH: 'search',
  ORDER_BY_SUGGESTION: 'orderBySuggestion',
};

export const orderStatusOptionList = [
  {
    value: OrderStatusEnum.ORDER_PENDING_APPROVAL,
    label: OrderStatusText[OrderStatusEnum.ORDER_PENDING_APPROVAL],
  },
  /* {
    value: OrderStatusEnum.ORDER_NOT_APPROVED,
    label: OrderStatusText[OrderStatusEnum.ORDER_NOT_APPROVED],
  }, */
  {
    value: OrderStatusEnum.BILLING_ORDER,
    label: OrderStatusText[OrderStatusEnum.BILLING_ORDER],
  },
  {
    value: OrderStatusEnum.PREPARING_ORDER,
    label: OrderStatusText[OrderStatusEnum.PREPARING_ORDER],
  },
  {
    value: OrderStatusEnum.ORDER_SENT_TO_TRANSPORT,
    label: OrderStatusText[OrderStatusEnum.ORDER_SENT_TO_TRANSPORT],
  },
  /* {
    value: OrderStatusEnum.SCHEDULING_ORDER,
    label: OrderStatusText[OrderStatusEnum.SCHEDULING_ORDER],
  },
  {
    value: OrderStatusEnum.TRY_SCHEDULLING_ORDER,
    label: OrderStatusText[OrderStatusEnum.TRY_SCHEDULLING_ORDER],
  },
  {
    value: OrderStatusEnum.ORDER_NOT_SCHEDULED,
    label: OrderStatusText[OrderStatusEnum.ORDER_NOT_SCHEDULED],
  }, */
  {
    value: OrderStatusEnum.ORDER_IN_TRANSPORT,
    label: OrderStatusText[OrderStatusEnum.ORDER_IN_TRANSPORT],
  },
  {
    value: OrderStatusEnum.DELIVERY_ATTEMPT,
    label: OrderStatusText[OrderStatusEnum.DELIVERY_ATTEMPT],
  },
  {
    value: OrderStatusEnum.ORDER_DELIVERED,
    label: OrderStatusText[OrderStatusEnum.ORDER_DELIVERED],
  },
  {
    value: OrderStatusEnum.ORDER_NOT_DELIVERED,
    label: OrderStatusText[OrderStatusEnum.ORDER_NOT_DELIVERED],
  },
  {
    value: OrderStatusEnum.ORDER_CANCELED,
    label: OrderStatusText[OrderStatusEnum.ORDER_CANCELED],
  },
  {
    value: OrderStatusEnum.ORDER_REFUSED,
    label: OrderStatusText[OrderStatusEnum.ORDER_REFUSED],
  },
];

/**
 * REPRESENTATIVE TYPES
 */
export const agentType = {
  NAME: 'name',
  TAG: 'tagName',
};

export const agentTypeLabel = {
  [agentType.NAME]: 'Nome',
  [agentType.TAG]: 'Tag',
};

export const agentTypeOptionList = [
  {
    value: agentType.NAME,
    label: agentTypeLabel[agentType.NAME],
  },
  {
    value: agentType.TAG,
    label: agentTypeLabel[agentType.TAG],
  },
];

/**
 * MATERIAL TYPES
 */
export const materialType = {
  NAME: 'name',
  CODE: 'code',
};

export const materialTypeLabel = {
  [materialType.NAME]: 'Nome',
  [materialType.CODE]: 'Código',
};

export const materialTypeOptionList = [
  {
    value: materialType.NAME,
    label: materialTypeLabel[materialType.NAME],
  },
  {
    value: materialType.CODE,
    label: materialTypeLabel[materialType.CODE],
  },
];

/**
 * ORDER BY SUGGESTION
 */
export const orderBySuggestionType = {
  SUGGESTION: 'suggestion',
  ORDER_NUMBER: 'orderNumber',
  CREATED_AT: 'createdAt',
};

export const orderBySuggestion = {
  [orderBySuggestionType.SUGGESTION]: 'Sugestão de pareamento',
  [orderBySuggestionType.ORDER_NUMBER]: 'Nº do pedido',
  [orderBySuggestionType.CREATED_AT]: 'Data de criação',
};

export const orderBySuggestionList = [
  {
    value: orderBySuggestionType.SUGGESTION,
    label: orderBySuggestion[orderBySuggestionType.SUGGESTION],
  },
  {
    value: orderBySuggestionType.ORDER_NUMBER,
    label: orderBySuggestion[orderBySuggestionType.ORDER_NUMBER],
  },
  {
    value: orderBySuggestionType.CREATED_AT,
    label: orderBySuggestion[orderBySuggestionType.CREATED_AT],
  },
];

export const filtersInitialState = {
  [luftFieldNames.COMPANY]: {},
  [fieldNames.CREATE_DATE]: {
    from: null,
    to: null,
  },
  [fieldNames.DELIVERY_DATE]: {
    from: null,
    to: null,
  },
  [fieldNames.AGENT]: {},
  [fieldNames.STATUS]: checkboxDefaultInitialState(orderStatusOptionList),
  [fieldNames.PROMOTIONAL_CICLE]: {},
  [fieldNames.MATERIAL]: {},
  [fieldNames.PRODUCT]: {},
  [fieldNames.ORDER_BY_SUGGESTION]: {},
};
