// import { HttpService, ClientError, HttpStatusCode } from './http';

import { HttpService } from './http';
import { API_BASE_URL } from '../constants/API';

export class AuthService {
  static async authorization() {
    const response = await HttpService.get(`${API_BASE_URL}/sessions`);
    return response;
  }

  static async login(data) {
    const response = await HttpService.post(`${API_BASE_URL}/sessions`,
      {
        email: data.email,
        password: data.password,
      },
    );
    return response;
  }
}
