import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isEqual from 'lodash.isequal';
import { useLocation } from 'react-router-dom';
import {
  formatISO,
  parseISO,
} from 'date-fns';

import {
  Grid,
} from '@material-ui/core/';

import { Formik } from 'formik';
import {
  Page,
  LoadMask,
  Paginate,
  EmptyStateWithAction,
  Button,
  showSuccessToast,
  showErrorToast,
} from '../../../../../components';
import { Colors } from '../../../../../constants';
import MaterialItem from './materialItem';
import Filters from './filters';

import {
  searchType,
  filtersInitialState,
  fieldNames,
  orderByType,
} from '../../../../../constants/stock/filters';
import FilterIndicator from '../../../../../components/FilterIndicator';
import { ITEMS_PER_PAGE } from '../../../../../constants/Paginate';
import { useDidUpdateEffect } from '../../../../../utils/hooks/did-update-effect';
import { HttpService, StockService } from '../../../../../services';
import { getNumberOfAppliedFilters } from '../../../../../utils';
import Spinner from '../../../../../components/Spinner';

const SpinnerContainer = styled.div`
  display: flex;
  padding: 0 56px;
  align-items: center;
  justify-content: center;
`;

const StyledEmptyStateWithAction = styled(EmptyStateWithAction)`
  padding: 40px 16px;
  border-bottom: 1px solid ${Colors.GRAY_LIGHTER};
  flex-direction: column;
`;

const StyledEmptyActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;

const orderByInitialState = {
  field: orderByType.ALPHABETICAL,
};
const searchInitialState = {
  type: searchType.NAME,
  term: '',
};

export const updateQueryStringInStockUrl = (history, {
  filters, search, orderBy, skip, take,
}) => {
  history.push(`?${HttpService.serializeQueryString({
    dueFrom: filters.dueDateRange.from
      ? formatISO(filters.dueDateRange.from, { representation: 'date' }) : null,
    dueTo: filters.dueDateRange.to
      ? formatISO(filters.dueDateRange.to, { representation: 'date' }) : null,
    materialType: Object.keys(filters.materialType)
      .map((key) => filters.materialType[key] ? key : null),
    availability: filters.availability,
    searchTerm: search.term,
    searchType: search.term ? search.type : null,
    take,
    skip,
    orderBy: orderBy.field,
  })}`);
};

const StockLayout = ({
  userData,
  title,
  onRequestMaterialList,
  onRequestMaterialBatches,
  materialList,
  companyDialogList,
  handleOpenCompanyDialog,
  handleOutputCompanyDialog,
  loadingDialog,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE);
  const [appliedFilters, setAppliedFilters] = useState(filtersInitialState);
  const [countAppliedFilters, setCountAppliedFilters] = useState(0);
  const [orderBy, setOrderBy] = useState(orderByInitialState);
  const [search, setSearch] = useState(searchInitialState);
  const { search: locationSearch } = useLocation();

  const _buildMaterialRequestFromQueryString = () => {
    const queryObject = HttpService.unserializeQueryString(locationSearch);
    const skip = queryObject.skip || 0;
    const take = queryObject.take || ITEMS_PER_PAGE;

    if (locationSearch === '') {
      return ({
        filters: {
          ...filtersInitialState,
        },
        take,
        skip,
        orderBy: {
          ...orderByInitialState,
        },
        search: {
          ...searchInitialState,
        },
      });
    }

    return ({
      filters: {
        dueDateRange: {
          from: queryObject.dueFrom ? parseISO(queryObject.dueFrom) : null,
          to: queryObject.dueTo ? parseISO(queryObject.dueTo) : null,
        },
        materialType: HttpService.unserializeQueryStringCheckboxObject(queryObject.materialType, Number, filtersInitialState.materialType),
        availability: queryObject.availability,
      },
      take,
      skip,
      orderBy: {
        field: queryObject.orderBy,
      },
      search: {
        term: queryObject.searchTerm,
        type: queryObject.searchType,
      },
    });
  };

  useEffect(() => {
    const {
      filters,
      orderBy,
      search,
    } = _buildMaterialRequestFromQueryString();
    setAppliedFilters(filters);
    setOrderBy(orderBy);
    setSearch(search);
  }, []);

  useDidUpdateEffect(() => {
    const requestParams = {
      filters: appliedFilters,
      take: itemsPerPage,
      skip: (currentPage - 1) * itemsPerPage,
      orderBy,
      search,
    };

    setCountAppliedFilters(getNumberOfAppliedFilters(filtersInitialState, appliedFilters));
    onRequestMaterialList(requestParams);
  }, [
    currentPage,
    itemsPerPage,
    appliedFilters,
    orderBy,
    search,
  ]);

  const _onPageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const _onChangeItemsPerPage = (event, newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const _onFiltersChange = ({ orderBy, ...newFilters }) => {
    setAppliedFilters(newFilters);
    setCurrentPage(1);
  };

  const _onClearFilter = (handleReset) => {
    if (handleReset) handleReset();
    setAppliedFilters({ ...filtersInitialState });
    setCurrentPage(1);
  };

  const _onChangeDataOrder = (field) => {
    setOrderBy({
      field,
    });
  };

  const _downloadStockPosition = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    const queryObject = HttpService.unserializeQueryString(locationSearch);
    const skip = queryObject.skip || 0;
    const take = queryObject.take || ITEMS_PER_PAGE;

    try {
      await StockService.fetchDownloadStockPosition({ skip, take, fileName: 'export.xlsx' });

      showSuccessToast('Relatório exportado com sucesso');
    } catch (e) {
      showErrorToast(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const _onClearSearch = (handleReset) => {
    if (handleReset) handleReset();
    setSearch({
      type: searchType.NAME,
      term: '',
    });
    _onClearFilter();
  };

  const _onSearch = ({
    value,
    handleSubmit,
  }) => {
    setAppliedFilters({
      ...filtersInitialState,
    });
    setSearch(value);
    handleSubmit();
    setCurrentPage(1);
  };

  return (
    <Page
      title={title}
      userData={userData}
      companyDialogList={companyDialogList}
      loadingDialog={loadingDialog}
      renderRight={() => (
        <Button
          size="large"
          color="secondary"
          variant="contained"
          disabled={isLoading}
          onClick={_downloadStockPosition}
        >
          {isLoading ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : 'Baixar relatório'}
        </Button>
      )}
      handleOpenCompanyDialog={handleOpenCompanyDialog}
      handleOutputCompanyDialog={(companyId) => {
        handleOutputCompanyDialog(companyId, () => {
          _onClearSearch();
        });
      }}
    >
      <>
        <Formik
          initialValues={{
            [fieldNames.AVAILABILITY]: appliedFilters[fieldNames.AVAILABILITY],
            [fieldNames.DUE_DATE]: appliedFilters[fieldNames.DUE_DATE],
            [fieldNames.TYPE]: appliedFilters[fieldNames.TYPE],
            [fieldNames.ORDER_BY]: orderBy.field,
          }}
          onSubmit={_onFiltersChange}
          enableReinitialize
        >
          {({
            handleReset, values, setValues, handleSubmit,
          }) => (
            <>
              <Filters
                userData={userData}
                values={values}
                onSearch={(value) => _onSearch({
                  value,
                  setValues,
                  handleSubmit,
                })}
                onChangeDataOrder={_onChangeDataOrder}
                listCountResults={materialList.list.length}
                appliedFilters={appliedFilters}
              />
              {(!isEqual(filtersInitialState, appliedFilters) || search.term) && (
                <FilterIndicator
                  onClear={() => _onClearFilter(handleReset)}
                  onClearSearch={() => _onClearSearch(handleReset)}
                  appliedFilters={countAppliedFilters}
                  searchTerm={search.term}
                  hideExport
                />
              )}
            </>
          )}
        </Formik>
        {materialList.list.length > 0 ? (
          <>
            <Grid container spacing={3}>
              {materialList.list.map((obj) => (
                <Grid
                  key={obj.id}
                  item
                  lg={3}
                  md={4}
                  sm={6}
                  xs={12}
                >
                  <MaterialItem
                    userData={userData}
                    item={obj}
                    enableSearchBatches={materialList.enableSearchBatches}
                    onRequestMaterialBatches={onRequestMaterialBatches}
                  />
                </Grid>
              ))}
            </Grid>
            {materialList.total > itemsPerPage && (
              <Paginate
                currentPage={currentPage}
                totalItems={materialList.total}
                itemsPerPage={itemsPerPage}
                onPageChange={_onPageChange}
                onChangeItemsPerPage={_onChangeItemsPerPage}
              />
            )}
          </>
        ) : (
          <>
            {!materialList.loading && (
              <StyledEmptyStateWithAction
                alignCenter
                emptyStateText={search.term && materialList.list.length <= 0 ? 'Nenhum material foi encontrado. Que tal tentar buscar de outra forma?' : 'Nenhum material pra exibir :('}
                renderAction={() => (
                  <StyledEmptyActionsContainer>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      Atualizar
                    </Button>
                  </StyledEmptyActionsContainer>
                )}
              />
            )}
          </>
        )}
      </>
      {materialList.loading && <LoadMask />}
    </Page>
  );
};

StockLayout.propTypes = {
  title: PropTypes.string.isRequired,
  userData: PropTypes.object,
  onRequestMaterialList: PropTypes.func,
  onRequestMaterialBatches: PropTypes.func,
  materialList: PropTypes.object,
  companyDialogList: PropTypes.object,
  loadingDialog: PropTypes.bool,
  handleOpenCompanyDialog: PropTypes.func,
  handleOutputCompanyDialog: PropTypes.func,
};

StockLayout.defaultProps = {
  userData: {},
  onRequestMaterialList: () => { },
  onRequestMaterialBatches: () => { },
  materialList: {},
  companyDialogList: {},
  loadingDialog: false,
  handleOpenCompanyDialog: () => { },
  handleOutputCompanyDialog: () => { },
};

export default StockLayout;
