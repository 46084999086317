import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import styled, { css } from 'styled-components';
import { format } from 'date-fns';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';

import ErrorIcon from '@material-ui/icons/Error';
import CancelIcon from '@material-ui/icons/Cancel';


import {
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';

import {
  Colors,
} from '../../../../../../constants';
import { DueStatus } from '../../../../../../constants/stock/enums';

const SpecificationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 32px;
`;

const SpecificationsTableContainer = styled.div`
  border: 1px solid ${Colors.GRAY_LIGHT};
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
`;

const Subtitle = styled.p`
  width: 100%;
  font-size: 20px;
  margin-top: 32px;
  color: ${Colors.SECONDARY_MEDIUM};
  font-weight: bold;
`;

const RowNameText = styled.div`
  color: ${Colors.PRIMARY_BLUE};
  font-weight: bold;
`;

const IconPlaceholder = styled.div`
  width: 16px;
  height: 16px;
  display: inline-block;
`;

const ScrollContainer = styled.div`
  width: 100%;
  max-height: 320px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const Row = styled(TableRow)`

  ${({ duestatus }) => duestatus === DueStatus.EXPIRED
    ? css`
          svg {
            color: ${Colors.DANGER};
          }
          background-color: ${Colors.DANGER_LOW_OPACITY};
        `
    : duestatus === DueStatus.WARNING
      ? css`
          svg {
            color: ${Colors.ORANGE};
          }
        `
      : ''}

  &:last-child td {
    border-bottom: none;
  }
`;

const IconCell = styled(TableCell)`
  width: 16px;
  padding: 8px 16px 8px 0 !important;

  & > svg {
    position: relative;
    font-size: 16px;
    top: 2px;
    cursor: pointer;
  }
`;

const Cell = styled(TableCell)`
  padding: 8px 8px 8px 16px !important;
`;

const Batches = ({ ...props }) => {
  const {
    batchesData,
  } = props;

  const themeTooltip = createMuiTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: '12px',
        },
      },
    },
  });

  return (
    <>
      <SpecificationsWrapper>

        <Subtitle>
          Lotes armazenados
        </Subtitle>

        <SpecificationsTableContainer>

          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <Cell style={{ minWidth: '48px' }}>
                    <RowNameText>
                      Lote
                    </RowNameText>
                  </Cell>
                  <Cell align="center">
                    <RowNameText>
                      Quantidade
                    </RowNameText>
                  </Cell>
                  <Cell align="right">
                    <RowNameText>
                      Validade
                    </RowNameText>
                  </Cell>
                  <IconCell align="left" />
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <ScrollContainer>
            <TableContainer>
              <Table aria-label="simple table">
                {batchesData.length > 0 ? (
                  <TableBody>
                    {batchesData.map((item) => (
                      <Row
                        key={item.id}
                        duestatus={item.dueStatus}
                      >
                        <Cell>
                          {item.id ? item.id : '-'}
                        </Cell>
                        <Cell>
                          <NumberFormat
                            value={item.availableQuantity ? item.availableQuantity : 0}
                            displayType="text"
                            decimalSeparator=","
                            thousandSeparator={true, '.'}
                          />
                        </Cell>
                        <Cell align="right">
                          {item.dueDate ? format(new Date(item.dueDate), 'dd/MM/yyyy') : 'Sem validade'}
                        </Cell>
                        <MuiThemeProvider theme={themeTooltip}>
                          <IconCell align="left">
                            {item.dueStatus === DueStatus.WARNING ? <Tooltip placement="top" title={item.state}><ErrorIcon /></Tooltip>
                              : item.dueStatus === DueStatus.EXPIRED ? <Tooltip placement="top" title={item.state}><CancelIcon /></Tooltip> : <IconPlaceholder />}
                          </IconCell>
                        </MuiThemeProvider>
                      </Row>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <Row>
                      <Cell align="left">
                        Nenhum lote cadastrado.
                      </Cell>
                    </Row>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </ScrollContainer>

        </SpecificationsTableContainer>

      </SpecificationsWrapper>

    </>
  );
};

Batches.propTypes = {
  batchesData: PropTypes.array.isRequired,
};

export default Batches;
