import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DayPicker, { DateUtils } from 'react-day-picker';
import { formatISO, setHours } from 'date-fns';
import isEqual from 'lodash.isequal';

import { Colors, BreakpointValues } from '../../../constants';
import Dropdown from '../../Dropdown';

import 'react-day-picker/lib/style.css';
import './dateStyle.css';
import Navbar from './NavBar';
import { useWindowSize } from '../../../utils';

// force folder name change

import {
  MONTHS,
  WEEKDAYS_LONG,
  WEEKDAYS_SHORT,
} from './ptBR';

const propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string,
  handleOutput: PropTypes.func.isRequired,
  value: PropTypes.any,
};

const defaultProps = {
  title: 'Selecione uma data ou período',
  value: {
    from: undefined,
    to: undefined,
  },
};

const DatepickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LabelWrapper = styled.div`
  border-bottom: 1px #E0E0E0 solid;
  width: 100%;
  display: flex;
  height: 46px;
  align-items: center;
`;

const DatepickerLabel = styled.span`
  padding: 0 16px 8px 24px;
  font-size: 16px;
  font-weight: bold;
  color: ${Colors.SECONDARY_MEDIUM};
`;

export const dateObjToService = ({ from, to }) => ({
  startDate: formatISO(setHours(new Date(from), 0)),
  endDate: formatISO(setHours(new Date(to), 23)),
});

const FilterDate = ({
  label,
  title,
  handleOutput,
  value,
  appliedFilters,
  fieldNames,
  name,
}) => {
  const [range, setRange] = useState(value);
  const modifiers = { start: range.from, end: range.to };
  const currentWindowSize = useWindowSize();

  useEffect(() => {
    if (!isEqual(range, value)) {
      setRange(value);
    }
  }, [value]);

  const handleDayClick = (day) => {
    const newRange = DateUtils.addDayToRange(day, range);
    setRange(newRange);
    if (range.from !== null && range.to !== null) {
      setRange({ from: day, to: null });
    }
  };

  return (
    <Dropdown
      labelText={label}
      hasConfirmButtons
      handleConfirm={() => {
        handleOutput(range);
      }}
      handleDismiss={() => {
        setRange(value);
      }}
      placement={currentWindowSize.width <= BreakpointValues.md ? 'bottom' : 'bottom-start'}
      appliedFilters={appliedFilters}
      fieldNames={fieldNames}
      name={name}
    >
      <DatepickerWrapper>
        <LabelWrapper>
          <DatepickerLabel>
            {title}
          </DatepickerLabel>
        </LabelWrapper>
        <DayPicker
          className="Birthdays"
          numberOfMonths={currentWindowSize.width >= BreakpointValues.md ? 2 : 1}
          navbarElement={Navbar}
          selectedDays={[range.from, { from: range.from, to: range.to }]}
          modifiers={modifiers}
          onDayClick={handleDayClick}
          months={MONTHS}
          weekdaysLong={WEEKDAYS_LONG}
          weekdaysShort={WEEKDAYS_SHORT}
        />
      </DatepickerWrapper>
    </Dropdown>
  );
};

FilterDate.propTypes = propTypes;
FilterDate.defaultProps = defaultProps;

export default FilterDate;
