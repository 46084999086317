import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import cloneDeep from 'lodash.clonedeep';
import styled from 'styled-components';

import {
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import { Tooltip, TextField } from '@material-ui/core';
import { RemoveCircleOutline } from '@material-ui/icons';
import { Text } from '../../../../components';
import ModalTable from '../../../../components/ModalTable';
import { Tag, TagWrapper } from './AgentsModalTable';
import { agentTableHeader, agentTableKey } from './constants';
import SelectedMaterialRow from './SelectedMaterialRow';
import { Colors, MaterialQtdMode } from '../../../../constants';

const StyledTextField = styled(TextField)`
  width: 130px;
  margin: 12px 0;
`;

const StyledSelectedMaterialRow = styled(SelectedMaterialRow)`
  margin-top: -10px;
`;

const DeactivatedContainer = styled.div`
  display: flex;
  align-items: center;
  height: 80px;

  & svg {
    fill: ${Colors.PRIMARY_BLUE};
    margin-right: 6px;
  }
`;

const StyledText = styled(Text)`
  width: 120px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ManageMaterialModalTable = ({
  materialIndex,
  value,
  open,
  name,
  getMaterialAmountQty,
  onConfirm,
  showManageMaterialModal,
  ...props
}) => {
  const themeTooltip = createMuiTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: '12px',
        },
      },
    },
  });

  if (
    materialIndex === null
    || materialIndex === undefined
    || !value
    || !value[materialIndex]
    || !value[materialIndex].agentList
  ) {
    return null;
  }

  const { setFieldValue } = useFormikContext();
  const [focusField, setFocusField] = useState('');
  const [material, setMaterial] = useState(value[materialIndex]);

  useEffect(() => {
    setMaterial(value[materialIndex]);
  }, [value, open]);

  const _handleChangeMode = (value, checked) => {
    const copy = cloneDeep(material);
    copy.mode = checked
      ? MaterialQtdMode.AUTOMATIC
      : MaterialQtdMode.MANUAL;

    if (checked) {
      copy.agentList = copy.agentList.map((agent) => ({
        ...agent,
        qty: agent.active ? copy.qty : 0,
      }));
    }

    setMaterial(copy);
  };

  const _handleMaterialQtyChange = (e) => {
    const qty = parseInt(e.target.value, 10);
    const copy = cloneDeep(material);
    copy.mode = MaterialQtdMode.AUTOMATIC;

    copy.qty = qty;
    copy.agentList = copy.agentList.map((agent) => ({
      ...agent,
      qty: agent.active ? qty : 0,
    }));

    setMaterial(copy);
  };

  const _handleManualMaterialQtyChange = (e, rowIndex) => {
    const copy = cloneDeep(material);
    copy.mode = MaterialQtdMode.MANUAL;
    copy.agentList[rowIndex].qty = copy.agentList[rowIndex].active ? parseInt(e.target.value, 10) : 0;
    setMaterial(copy);
  };

  const _renderBasicTextCell = (value) => (
    <StyledText>
      {value || 'Nenhum'}
    </StyledText>
  );

  const columnConfig = {
    [agentTableKey.AGENT]: {
      order: 1,
    },
    [agentTableKey.TAG]: {
      order: 2,
      width: 350,
    },
    [agentTableKey.QUANTITY]: {
      order: 3,
    },
  };

  const components = {
    [agentTableKey.AGENT]: _renderBasicTextCell,
    [agentTableKey.TAG]: (tags) => (
      <TagWrapper>
        {tags.map((tag) => (
          <Tag key={`${props.name}-${tag.id}`}>{tag.name}</Tag>
        ))}
      </TagWrapper>
    ),
    [agentTableKey.QUANTITY]: (qty, data, rowIndex, checked) => checked ? (
      <MuiThemeProvider theme={themeTooltip}>
        <Tooltip
          placement="left"
          title={qty === 0 ? 'Não é permitido enviar a quantidade zerada. Desative o representante caso não queria enviar o material.' : 'Estoque disponível não cobre a quantidade configurada. Por favor, revise as quantidades.'}
          arrow
          open={data[rowIndex].id === focusField && (qty === 0 || data.reduce((sum, agent) => sum + agent.qty, 0) > material.availableQuantity) ? true : false}
        >
          <StyledTextField
            label="Quantidade"
            value={qty || 0}
            variant="outlined"
            onChange={(e) => {
              const copy = cloneDeep(material);
              copy.mode = MaterialQtdMode.MANUAL;
              copy.agentList[rowIndex].qty = parseInt(e.target.value, 10);
              setMaterial(copy);
            }}
            onBlur={(e) => {
              _handleManualMaterialQtyChange(e, rowIndex, data[rowIndex].id);
              setFocusField('');
            }}
            onFocus={() => {
              setFocusField(data[rowIndex].id);
            }}
            error={(data[rowIndex].id === focusField && (data.reduce((sum, agent) => sum + agent.qty, 0) > material.availableQuantity || data[rowIndex].qty === 0)) ? true : false}
          />
        </Tooltip>
      </MuiThemeProvider>
    ) : (
      <DeactivatedContainer>
        <RemoveCircleOutline />
        <span>DESATIVADO</span>
      </DeactivatedContainer>
    ),
  };

  return (
    <ModalTable
      width={810}
      name={name}
      open={open}
      value={value[materialIndex].agentList.filter((agent) => agent.active)}
      defaultCheckAll={material.agentList.every((agent) => agent.active)}
      showManageMaterialModal={showManageMaterialModal}
      renderContentAbove={() => (
        <StyledSelectedMaterialRow
          title={value[materialIndex].name}
          canEdit={material.mode === MaterialQtdMode.AUTOMATIC}
          availableCount={value[materialIndex].availableQuantity}
          qtdMode={material.mode}
          qty={material.qty}
          total={getMaterialAmountQty(material)}
          handleModeChange={_handleChangeMode}
          handleQtdChange={_handleMaterialQtyChange}
        />
      )}
      tableColumnConfig={columnConfig}
      tableComponents={components}
      tableHandleHeaderTitle={(key) => agentTableHeader[key]}
      tableData={material.agentList}
      handleRowCheck={(dataIndex, checked) => {
        const copy = cloneDeep(material);
        copy.agentList[dataIndex].active = checked;
        if (!copy.agentList[dataIndex].active) {
          copy.agentList[dataIndex].qty = 0;
        }
        setMaterial(copy);
      }}
      handleHeaderCheck={(checked) => {
        const copy = cloneDeep(material);
        copy.agentList = copy.agentList.map((agent) => ({
          ...agent,
          active: checked,
          qty: !checked ? 0 : agent.qty,
        }));

        setMaterial(copy);
      }}
      {...props}
      handleOutput={(name) => {
        const copy = cloneDeep(value);
        copy[materialIndex] = material;

        setFieldValue(name, copy);
      }}
      onConfirm={() => {
        onConfirm(material);
      }}
      material={material}
      disableConfirm
      forceDisable
    />
  );
};

export default ManageMaterialModalTable;
