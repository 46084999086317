import { HttpService } from './http';
import { API_BASE_URL } from '../constants/API';
import { fieldAgentNames, OrderTypes } from '../constants/agent';

const Endpoint = {
  fetchAgentList: `${API_BASE_URL}/agents/list`,
  fetchAgentDetail: `${API_BASE_URL}/agents`,
};

export class AgentService {
  static getDefaultRequest = () => ({
    skip: 0,
    take: 20,
    filters: {},
  });

  static transformCheckFilter = (checkFilter, parseFunc) => Object.entries(checkFilter || {}).reduce((final, [key, value]) => {
    if (value) {
      return [
        ...final,
        parseFunc ? parseFunc(key) : key,
      ];
    }

    return final;
  }, []);

  static transformFiltersToRequest = (filters, search) => {
    const filtersRequest = {};

    if (filters) {
      filtersRequest.standard = filters.standard ? filters.standard : [];
    }

    if (filters && filters[fieldAgentNames.TAG]) {
      filtersRequest[fieldAgentNames.TAG] = AgentService.transformCheckFilter(filters[fieldAgentNames.TAG]);
    }

    if (filters && filters[fieldAgentNames.TAG_NAME]) {
      filtersRequest[fieldAgentNames.TAG_NAME] = filters[fieldAgentNames.TAG_NAME];
    }

    if (filters && filters[fieldAgentNames.REGION]) {
      if (Object.keys(filters[fieldAgentNames.REGION]).filter((key) => filters[fieldAgentNames.REGION][key] === true).length) {
        filtersRequest.standard.push({
          attribute: fieldAgentNames.REGION,
          value: Object.keys(filters[fieldAgentNames.REGION]).filter((key) => filters[fieldAgentNames.REGION][key] === true),
        });
      }
    }

    if (filters && filters[fieldAgentNames.CITY]) {
      filtersRequest.standard.push({
        attribute: fieldAgentNames.CITY,
        value: filters[fieldAgentNames.CITY],
      });
    }

    if (search && search.term && search.term.length) {
      filtersRequest.standard.push({
        attribute: fieldAgentNames.SEARCH,
        value: search.term,
      });
    }
    return filtersRequest;
  };

  static async fetchAgentList(data) {
    const request = AgentService.getDefaultRequest();
    request.skip = data.skip;
    request.take = data.take;
    request.filters = AgentService.transformFiltersToRequest(data.filters, data.search);

    if (data.orderBy) {
      if (data.orderBy && data.orderBy.orderType) {
        request.orderBy = {
          attribute: data.orderBy.field,
          value: data.orderBy.orderType === OrderTypes.ASC ? 'ASC' : 'DESC',
        };
      }
      if (!data.orderBy.orderType) {
        request.orderBy = {
          attribute: 'name',
          value: 'DESC',
        };
      }
    }

    const response = await HttpService.post(Endpoint.fetchAgentList, request);
    return response;
  }

  static async fetchAgentDetail(id) {
    const response = await HttpService.get(`${Endpoint.fetchAgentDetail}/${id}`);
    return response;
  }

  static async requestRemoveAgent(id) {
    const response = await HttpService.delete(`${Endpoint.fetchAgentDetail}/${id}`);
    return response;
  }

  static async requestNewAgent(data, tags) {
    const request = data;
    request.tags = tags;
    const response = await HttpService.post(`${Endpoint.fetchAgentDetail}`, request);
    return response;
  }

  static async requestUpdateAgent(data, tags, agentId) {
    const request = data;
    request.id = agentId;
    request.tags = tags;
    const response = await HttpService.patch(`${Endpoint.fetchAgentDetail}`, request);
    return response;
  }
}

export default AgentService;
