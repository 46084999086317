import { createMuiTheme } from "@material-ui/core/styles";
import { ptBR } from '@material-ui/core/locale';
import { Colors } from './Colors';

export const ThemeDatepicker = createMuiTheme({
	palette: {
		primary: {
			main: Colors.SECONDARY_MEDIUM
		}
    },
    overrides: {
        MuiPickersDay: {
            day: {
                margin: '2px'
            },
            dayDisabled: {
                opacity: 0.8
            }
        }
    }
}, ptBR);