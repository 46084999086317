import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

const propTypes = {
  onPreviousClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
};
const defaultProps = {
};

const MonthControl = css`
  position: absolute;
  border: none;
  background-color: transparent;
  top: 14px;
  cursor: pointer;
`;

const MonthControlButtonLeft = styled.button`
  ${MonthControl};
  left: 16px;
`;

const MonthControlButtonRight = styled.button`
  ${MonthControl};
  right: 16px;
`;

const Navbar = ({
  onPreviousClick,
  onNextClick,
}) => (
  <div>
    <MonthControlButtonLeft onClick={() => onPreviousClick()}>
      <ChevronLeft />
    </MonthControlButtonLeft>
    <MonthControlButtonRight onClick={() => onNextClick()}>
      <ChevronRight />
    </MonthControlButtonRight>
  </div>
);

Navbar.propTypes = propTypes;
Navbar.defaultProps = defaultProps;

export default Navbar;
