import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SingleStatus from './single-status';
import EmptyStateWithAction from '../EmptyStateWithAction';
import { Colors } from '../../constants';
import { mediaHelper } from '../../utils';

const Container = styled.div`
  display: flex;
  margin-top: 16px;
  margin-bottom: 32px;
  flex: 1;
  flex-direction: column;

  ${mediaHelper.lg`
    flex-direction: row;
    align-items: flex-start;
  `}
`;

const StyledEmptyStateWithAction = styled(EmptyStateWithAction)`
  width: 100%;
  padding: 40px 16px;
  border-bottom: 1px solid ${Colors.GRAY_LIGHTER};
  flex-direction: column;
`;

function StatusFlow({
  statusList,
}) {
  return (
    <Container>
      {statusList && statusList.length > 0 ? (
        <>
          {statusList.map((item, index) => (
            <SingleStatus
              key={`${item.title}-${index}`}
              data={item}
              type={item.status}
              first={index === 0}
              last={index === statusList.length - 1}
            />
          ))}
        </>
      ) : (
        <StyledEmptyStateWithAction
          alignCenter
          emptyStateText="Nenhum status pra exibir :("
        />
      )}
    </Container>
  );
}

StatusFlow.propTypes = {
  statusList: PropTypes.array,
};

StatusFlow.defaultProps = {
  statusList: [],
};

export default StatusFlow;
