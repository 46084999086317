import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  showErrorToast,
} from '../../../../components';
import StockLayout, { updateQueryStringInStockUrl } from './stock-layout';

import {
  AuthService,
  ChangeViewService,
  MaterialService,
  StockService,
} from '../../../../services';
import { AuthActions } from '../../../../redux';
import { getCompanyDialogList } from '../../../../utils';

class StockPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      materialList: {
        loading: false,
        enableSearchBatches: true,
        list: [],
        total: 0,
      },
      companyDialogList: {
        loading: false,
        list: [],
      },
    };
  }

  _requestMaterialList = async (data) => {
    const { dispatch, history } = this.props;

    try {
      this.setState((prevState) => ({
        materialList: {
          ...prevState.materialList,
          loading: true,
        },
      }));

      updateQueryStringInStockUrl(history, data);

      const response = await StockService.requestMaterialList(data);

      this.setState((prevState) => ({
        materialList: {
          ...prevState.materialList,
          loading: false,
          list: response.data.list,
          total: response.data.total,
        },
      }));
    } catch (error) {
      this.setState((prevState) => ({
        materialList: {
          ...prevState.materialList,
          loading: false,
        },
      }), () => {
        console.error(error.message);
        dispatch(showErrorToast(error.message));
      });
    }
  }

  _requestMaterialBatches = async (id, takeItems, fetchAllow) => {
    const { dispatch } = this.props;
    const materialId = this.state.materialList.list.findIndex((element) => element.id === id);
    const newMaterialList = [...this.state.materialList.list];

    if (fetchAllow) {
      try {
        this.setState((prevState) => ({
          materialList: {
            ...prevState.materialList,
            enableSearchBatches: false,
          },
        }));
        if (newMaterialList[materialId].batches) {
          newMaterialList[materialId] = {
            ...newMaterialList[materialId],
            batches: {
              ...newMaterialList[materialId].batches,
              loading: true,
            },
          };
        } else {
          newMaterialList[materialId] = {
            ...newMaterialList[materialId],
            batches: {
              loading: true,
              list: [],
            },
          };
        }

        this.setState((prevState) => ({
          materialList: {
            ...prevState.materialList,
            list: newMaterialList,
          },
        }));

        const response = await MaterialService.requestMaterialBatches(
          {
            id,
            skip: takeItems,
            take: 4,
          },
        );

        if (newMaterialList[materialId].batches.list.length > 0) {
          const newBatches = newMaterialList[materialId].batches.list.concat(response.data.batches);

          newMaterialList[materialId] = {
            ...newMaterialList[materialId],
            batches: {
              loading: false,
              list: newBatches,
              total: response.data.total,
            },
          };
        } else {
          newMaterialList[materialId] = {
            ...newMaterialList[materialId],
            batches: {
              loading: false,
              list: response.data.batches,
              total: response.data.total,
            },
          };
        }

        this.setState((prevState) => ({
          materialList: {
            ...prevState.materialList,
            enableSearchBatches: true,
            list: newMaterialList,
          },
        }));
      } catch (error) {
        newMaterialList[materialId] = {
          ...newMaterialList[materialId],
          batches: {
            loading: false,
            list: [],
          },
        };
        this.setState((prevState) => ({
          materialList: {
            ...prevState.materialList,
            list: newMaterialList,
          },
        }), () => {
          console.error(error.message);
          dispatch(showErrorToast(error.message));
        });
      }
    }
  }

  _handleChangeCompanyView = async (companyId, onCompanyChange) => {
    const { dispatch } = this.props;

    try {
      this.setState((prevState) => ({
        materialList: {
          ...prevState.materialList,
          loading: true,
          list: [],
          total: 0,
        },
      }));

      const response = await ChangeViewService.changeCompanyView(companyId);

      dispatch(AuthActions.setToken(response.token));

      const { data } = await AuthService.authorization();

      await dispatch(AuthActions.setUserData({
        id: data.id,
        username: data.name,
        userRole: data.role,
        ...(companyId !== null ? { companyName: data.companyName } : {}),
        userPermissions: data.userPermissions,
      }));

      if (onCompanyChange) onCompanyChange();
    } catch (error) {
      console.error(error.message);
      dispatch(showErrorToast(error.message));
    }
  };

  _requestCompanyDialogList = async () => {
    this.setState((prevState) => ({
      companyDialogList: {
        ...prevState.companyDialogList,
        loading: true,
      },
    }));
    const response = await getCompanyDialogList();
    this.setState((prevState) => ({
      companyDialogList: {
        ...prevState.companyDialogList,
        loading: false,
        list: response.list,
      },
    }));
  };

  render() {
    const {
      userData,
    } = this.props;
    const {
      materialList,
      companyDialogList,
    } = this.state;

    return (
      <StockLayout
        title="Estoque"
        userData={userData}
        materialList={materialList}
        onRequestMaterialList={this._requestMaterialList}
        onRequestMaterialBatches={this._requestMaterialBatches}
        companyDialogList={companyDialogList}
        loadingDialog={companyDialogList.loading}
        handleOpenCompanyDialog={this._requestCompanyDialogList}
        handleOutputCompanyDialog={this._handleChangeCompanyView}
      />
    );
  }
}

const mapStateToProps = (data) => data;

export default connect(mapStateToProps)(withRouter(StockPage));
