import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useReactRouter from 'use-react-router';

import { AuthService } from '../services';
import { AuthActions } from '../redux';
import { showErrorToast } from './Toast';

const PrivateRoute = ({ ...props }) => {
  const dispatch = useDispatch();
  const { location } = useReactRouter();
  const { fromLogin } = location.state || {};
  const [loading, setLoading] = useState(!fromLogin);

  useEffect(() => {
    if (fromLogin) return;
    initRoute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initRoute = async () => {
    await _authorize();
  };

  const _authorize = async () => {
    try {
      const { data } = await AuthService.authorization();

      await dispatch(AuthActions.setUserData({
        id: data.id,
        username: data.name,
        companyName: data.companyName,
        userRole: data.role,
        userPermissions: data.userPermissions,
      }));

      await dispatch(AuthActions.isAuthorized(true));

      setLoading(false);
    } catch (error) {
      console.error(error.message);
      setLoading(false);
      dispatch(showErrorToast(error.message));
    }
  };

  if (loading) {
    return null;
  }

  return <Route {...props} />;
};

export default PrivateRoute;
