import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Paginate from '../Paginate';

const propTypes = {
  tableName: PropTypes.string.isRequired,
  onChangeItemsPerPage: PropTypes.func.isRequired,
  paginationProps: PropTypes.exact(Paginate.propTypes),
};

const defaultProps = {
  paginationProps: {
    itemsPerPage: 10,
    onPageChange: () => {},
  },
};

const TableFooter = ({
  tableName,
  itemsLength,
  onChangeItemsPerPage,
  footerText,
  itemPerPageLabel,
  paginationProps,
  ...props
}) => (
  <Paginate
    currentPage={paginationProps.currentPage}
    totalItems={paginationProps.totalItems}
    itemsPerPage={paginationProps.itemsPerPage}
    onPageChange={paginationProps.onPageChange}
    onChangeItemsPerPage={onChangeItemsPerPage}
    labelItemsPerPage={footerText}
    {...props}
  />
);

TableFooter.propTypes = propTypes;
TableFooter.defaultProps = defaultProps;

export default styled(TableFooter)``;
