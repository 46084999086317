
import actionTypes from './actions';

// reducer

const initialState = {
  overlayCount: 0
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.INCREMENT_COUNT:
    return {
      ...state,
      overlayCount: state.overlayCount + 1
    };

  case actionTypes.DECREMENT_COUNT:
    return {
      ...state,
      overlayCount: state.overlayCount - 1 >= 0 ? state.overlayCount - 1 : 0
    };

  default:
    return state;
  }
}

export default reducer;
