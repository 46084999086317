import React from 'react';
import styled, { css } from 'styled-components';
import {
  Cancel,
  CheckCircle,
  Place,
  RadioButtonUnchecked,
} from '@material-ui/icons';

import { Colors } from '../../constants';
import DottedBgH from '../../images/dotted-bg-h.png';
import DottedBgV from '../../images/dotted-bg-v.png';
import { mediaHelper } from '../../utils';

export const StepStatusValues = {
  PENDING: 1, // AGUARDANDO
  INLINE: 2, // NA FILA
  DONE: 3, // COMPLETO
  FAILED: 4, // FALHOU
  FIRST_TRY: 5, // PRIMEIRA TENTATIVA
  SECOND_TRY: 6, // SEGUNDA TENTATIVA
  CANCELED: 7, // CANCELADO
  REFUSED: 8, // RECUSADO
};

const Container = styled.div`
  position: relative;
  min-width: 12.5%;
  max-width: 12.5%;
  display: flex;
  align-items: center;

  ${mediaHelper.lg`
    flex-direction: column;
  `}
`;

const ContainerStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 48px;
  white-space: nowrap;

  ${mediaHelper.lg`
    flex-direction: column;
    margin-left: 0px;
    white-space: normal;
    align-items: center;
  `}
`;

const IconContainer = styled.div`
  color: ${Colors.BLUE_VIVID};
  font-size: 20px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CancelIconContainer = styled.div`
  color: ${Colors.SECONDARY_MEDIUM};
`;

const PlaceIconContainer = styled.div`
  color: ${Colors.SECONDARY_MEDIUM};

  & > svg {
    position: relative;
    top: 2px;
    
    ${mediaHelper.lg`
      top: -14px;
    `}
  }

  &:after {
    content: '.';
    width: 8px;
    height: 8px;
    top: 54px;
    left: 8px;
    background-color: ${Colors.SECONDARY_MEDIUM};
    position: absolute;
    border-radius: 50%;
    text-indent: -9999px;
    z-index: 2;
    
    ${mediaHelper.lg`
      top: 24px;
      left: 50%;
      margin-left: -4px;
      margin-top: -4px;
    `}
  }
`;

const StatusName = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: ${Colors.SECONDARY_MEDIUM};
  line-height: 14px;
  margin-bottom: 2px;

  ${mediaHelper.lg`
    text-align: center;
    padding: 0 10px;
  `}
`;

const SubtitleText = styled.span`
  color: ${Colors.GRAY_DARKER};
  font-size: 10px;
  line-height: 14px;

  ${({ uppercase }) => uppercase && `
    text-transform: uppercase;
  `}
`;

const ConnectionLine = css`
  position: absolute;
  width: 2px;
  height: calc(50% - 13px);
  background-color: ${Colors.BLUE_VIVID};
  margin-top: -1px;

  ${mediaHelper.lg`
    width: calc(50% - 12px);
    height: 2px;

    ${({ dotted }) => dotted && `
      background-color: transparent;
      background-image: url(${DottedBgH});
    `}
  `}

  ${({ dotted }) => dotted && `
    background-color: transparent;
    background-image: url(${DottedBgV});
  `}
`;

const LeftConnectionLine = styled.div`
  ${ConnectionLine};
  bottom: -1px;
  ${({ dotted }) => !dotted && `
    border-radius: 1px 0 0 1px;
  `}

  ${mediaHelper.lg`
    right: -1px;
    bottom: auto;

    ${({ dotted }) => !dotted && `
      border-radius: 0 1px 1px 0;
    `}
  `}
`;

const RightConnectionLine = styled.div`
  ${ConnectionLine};
  top: -1px;
  ${({ dotted }) => !dotted && `
    border-radius: 0 1px 1px 0;
  `}

  ${mediaHelper.lg`
    left: -1px;
    top: auto;

    ${({ dotted }) => !dotted && `
      border-radius: 1px 0 0 1px;
    `}
  `}
`;

function SingleStatus({
  data,
  type,
  first,
  last,
}) {
  return (
    <Container>
      <IconContainer>
        {!first && (
          <RightConnectionLine dotted={type === StepStatusValues.PENDING} />
        )}
        {(!type || type === StepStatusValues.DONE) && (
          <CheckCircle />
        )}
        {type === StepStatusValues.INLINE && (
          <PlaceIconContainer>
            <Place />
          </PlaceIconContainer>
        )}
        {type === StepStatusValues.PENDING && (
          <RadioButtonUnchecked />
        )}
        {(type === StepStatusValues.CANCELED || type === StepStatusValues.FAILED || type === StepStatusValues.REFUSED) && (
          <CancelIconContainer>
            <Cancel />
          </CancelIconContainer>
        )}
        {!last && (
          <LeftConnectionLine dotted={type === StepStatusValues.INLINE || type === StepStatusValues.PENDING} />
        )}
      </IconContainer>
      <ContainerStatus>
        <StatusName>
          {data.title}
        </StatusName>
        <SubtitleText uppercase>
          {type === StepStatusValues.INLINE ? 'Em andamento' : ''}
          {type === StepStatusValues.PENDING ? 'Pendente' : ''}
        </SubtitleText>
        {data.date && (
          <SubtitleText>
            {data.date}
          </SubtitleText>
        )}
      </ContainerStatus>
    </Container>
  );
}

export default SingleStatus;
