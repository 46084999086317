import styled, { css } from 'styled-components';
import ReactCrop from 'react-image-crop';

export const ReactCropStyled = styled(ReactCrop)`
  ${({ imgWidth, imgHeight }) => css`

  position: relative;
    img {
      object-fit: contain;
      ${imgWidth <= imgHeight && 'height: 250px;'};
      }
  `}
`;

export const Wrapper = styled.div`
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #E0E0E0;
  background-color: #F3F4F4;
  width: 395px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  position: relative;

  .error {
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;

    .error__message {
      line-height: 1;
      padding: 0;
      margin: 0;
      font-size: 13px;
      text-align: center;
      color: red;
    }
  }
`;

export const ActionsWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const WrapperControls = styled.div`
  position: absolute;
  right: -8px;
  bottom: 50%;
  transform: translate(100%, 50%);
  display: flex;
  flex-direction: column;
  gap: 8px;

  div {
    width: 24px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 4px;

    button {
      appearance: none;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 100%;
      background-color: #1077B9;
      color: #FFF;
      cursor: pointer;

      :hover {
        background-color: #0B5394;
      }


    }
  }
`;
