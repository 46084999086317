// Action types
import { NotificationTypes } from './types';

export class NotificationActions {
  static updateNotificationList = (data, skip) => {
    return {
      type: NotificationTypes.UPDATE_NOTIFICATION_LIST,
      data,
      skip
    };
  };

  static markNotificationAsRead = (id) => {
    return {
      type: NotificationTypes.MARK_NOTIFICATION_AS_READ,
      id
    };
  };
}
