import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'react-slick';
import NumberFormat from 'react-number-format';
import 'slick-carousel/slick/slick.css';
import './materialSlider.css';

import { Paper } from '@material-ui/core';
import {
  PrevArrow,
  NextArrow,
} from './Arrows';
import {
  Button,
  EmptyStateWithAction,
} from '../../../../../components';
import { Colors, BreakpointValues } from '../../../../../constants';
import { RoutePaths } from '../../../../../constants/RoutePaths';
import { useWindowSize, mediaHelper } from '../../../../../utils';

const SliderWrapper = styled(Slider)`
  margin: 16px 0;
  min-height: 140px;
`;

const SlideItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 48px);
  max-width: calc(100% - 48px);
  height: 140px;

  ${mediaHelper.lg`
    width: auto;
    max-width: 180px;
  `}
`;

const SlideContent = styled(Paper)`
  padding: 16px;
  margin: 8px;
  text-align: center;
  border-radius: 8px;
  width: 100%;
  
  ${mediaHelper.lg`
    width: 130px;
  `}
`;

const MaterialTitle = styled.span`
  max-width: 130px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  color: ${Colors.PRIMARY_BLUE};
`;

const QuantityItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0 16px 0;
`;

const QuantityLabel = styled.strong`
  font-size: 13px;
  color: ${Colors.GRAY_DARKEST};
`;

const QuantityValue = styled.span`
  font-size: 14px;
  color: ${Colors.GRAY_DARKER};
`;

const StyledButton = styled(Button)`
  width: 100%;
  font-size: 12px;
`;

const StyledEmptyStateWithAction = styled(EmptyStateWithAction)`
  padding: 40px 16px;
  border-bottom: 1px solid ${Colors.GRAY_LIGHTER};
  flex-direction: column;
`;

const MaterialSlider = ({ ...props }) => {
  const {
    sliderData,
  } = props;

  const history = useHistory();
  const [nextArrowActive, setNextArrowActive] = useState(true);
  const [prevArrowActive, setPrevArrowActive] = useState(false);
  const currentWindowSize = useWindowSize();

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: currentWindowSize.width >= BreakpointValues.md ? 5 : 1,
    slidesToScroll: currentWindowSize.width >= BreakpointValues.md ? 5 : 1,
    nextArrow: <NextArrow nextArrowActive={nextArrowActive} />,
    prevArrow: <PrevArrow prevArrowActive={prevArrowActive} />,
    afterChange: (current) => {
      if (current === 0) {
        setPrevArrowActive(false);
      } else {
        setPrevArrowActive(true);
      }
      if (current >= (sliderData.length - sliderSettings.slidesToShow)) {
        setNextArrowActive(false);
      } else {
        setNextArrowActive(true);
      }
    },
  };

  const _onGoToMaterialDetail = (id) => {
    history.push(`${RoutePaths.STOCK}/${id}`);
  };

  return (
    <div id="materialList">
      {sliderData && sliderData.length > 0 ? (
        <SliderWrapper {...sliderSettings}>
          {sliderData.map((item) => (
            <SlideItem
              key={item.id}
            >
              <SlideContent
                elevation={3}
              >
                <MaterialTitle>
                  {item.name}
                </MaterialTitle>
                <QuantityItem>
                  <QuantityLabel>
                    Quantidade
                  </QuantityLabel>
                  <QuantityValue>
                    <NumberFormat
                      value={item.quantity}
                      displayType="text"
                      decimalSeparator=","
                      thousandSeparator={true, '.'}
                    />
                  </QuantityValue>
                </QuantityItem>
                <StyledButton
                  size="small"
                  color="secondary"
                  variant="outlined"
                  handleClick={() => { _onGoToMaterialDetail(item.id); }}
                >
                  Ver no estoque
                </StyledButton>
              </SlideContent>
            </SlideItem>
          ))}
        </SliderWrapper>
      ) : (
        <StyledEmptyStateWithAction
          alignCenter
          emptyStateText="Nenhum material pra exibir :("
        />
      )}
    </div>
  );
};

MaterialSlider.propTypes = {
  sliderData: PropTypes.array,
};

MaterialSlider.defaultProps = {
  sliderData: [],
};

export default MaterialSlider;
