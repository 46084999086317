import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  AgentService,
  StockService,
  OrderService,
} from '../../../../services';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../components';
import {
  filtersInitialState,
} from '../../../../constants/order/filters';
import { ITEMS_PER_PAGE } from '../../../../constants/Paginate';
import AgentDetailLayout from './agent-management-layout';
import { filtersPermissionsInitialState } from '../../../../constants/stock';

class AgentDetailPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingExport: false,
      agentDetail: {
        loading: false,
        data: {},
      },
      orderList: {
        loading: false,
        list: [],
        total: 0,
      },
      stockPermissionList: {
        loading: false,
        list: [],
        total: 0,
      },
      requestDialog: {
        loading: false,
        isOpenDialog: false,
      },
      orderDetailData: {
        loading: false,
        data: {},
        total: 0,
      },
      orderOnApproval: {
        loading: false,
        finished: false,
      },
      newfiltersInitialState: {},
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const agentId = match.params.id;
    const newfiltersInitialState = filtersInitialState;
    newfiltersInitialState.agentList = { [agentId]: true };
    this.setState({
      newfiltersInitialState,
    });
    this._requestAgentDetail();
    this._requestOrderList({
      filters: newfiltersInitialState,
      take: ITEMS_PER_PAGE,
      skip: 0,
      orderBy: {
        attribute: 'id',
        value: 'DESC',
      },
    });
  }

  componentWillUnmount() {
    filtersInitialState.agentList = {};
    this._requestOrderList({
      filters: filtersInitialState,
      take: ITEMS_PER_PAGE,
      skip: 0,
      orderBy: {
        attribute: 'id',
        value: 'DESC',
      },
    });
  }

  _requestAgentDetail = async () => {
    const { match, dispatch } = this.props;
    const agentId = match.params.id;
    try {
      this.setState(({ agentDetail }) => ({
        agentDetail: {
          ...agentDetail,
          loading: true,
        },
      }));

      const { data } = await AgentService.fetchAgentDetail(agentId);

      this.setState(({ agentDetail }) => ({
        agentDetail: {
          ...agentDetail,
          loading: false,
          data,
        },
      }));
    } catch (e) {
      this.setState(({ agentDetail }) => ({
        agentDetail: {
          ...agentDetail,
          loading: false,
        },
      }));
      dispatch(showErrorToast(e.message));
    }
  };

  _requestOrderList = async (data) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ orderList }) => ({
        orderList: {
          ...orderList,
          loading: true,
        },
      }));

      const { data: { list, total } } = await OrderService.fetchOrderList(data);

      this.setState(({ orderList }) => ({
        orderList: {
          ...orderList,
          loading: false,
          list,
          total,
        },
      }));
    } catch (e) {
      this.setState(({ orderList }) => ({
        orderList: {
          ...orderList,
          loading: false,
        },
      }));
      dispatch(showErrorToast(e.message));
    }
  }

  _requestStockPermissionList = async (data) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ stockPermissionList }) => ({
        stockPermissionList: {
          ...stockPermissionList,
          loading: true,
        },
      }));

      const { data: { list, total } } = await StockService.fetchStockPermissionByAgentList(data);

      this.setState(({ stockPermissionList }) => ({
        stockPermissionList: {
          ...stockPermissionList,
          loading: false,
          list,
          total,
        },
      }));
    } catch (e) {
      this.setState(({ stockPermissionList }) => ({
        stockPermissionList: {
          ...stockPermissionList,
          loading: false,
        },
      }));
      dispatch(showErrorToast(e.message));
    }
  }

  _requestRemoveAllStockPermissionAgent = async (agentId) => {
    const { dispatch } = this.props;
    try {
      this.setState((prevState) => ({
        requestDialog: {
          ...prevState.requestDialog,
          loading: true,
          isOpenDialog: true,
        },
      }));
      await StockService.requestRemoveAllStockPermissionAgent(agentId);
      this.setState((prevState) => ({
        requestDialog: {
          ...prevState.requestDialog,
          loading: false,
          isOpenDialog: false,
        },
      }));

      await this._requestStockPermissionList({
        skip: 0,
        take: ITEMS_PER_PAGE,
        userId: agentId,
      });
      dispatch(showSuccessToast('Permissão de estoque removida!'));
    } catch (e) {
      this.setState((prevState) => ({
        requestDialog: {
          ...prevState.requestDialog,
          loading: false,
          isOpenDialog: false,
        },
      }));
      dispatch(showErrorToast(e.message));
    }
  }

  _requestRemoveStockPermission = async (id, agentId) => {
    const { dispatch } = this.props;
    try {
      this.setState((prevState) => ({
        requestDialog: {
          ...prevState.requestDialog,
          loading: true,
          isOpenDialog: true,
        },
      }));
      await StockService.requestRemoveStockPermissionAgent(agentId, id);
      this.setState((prevState) => ({
        requestDialog: {
          ...prevState.requestDialog,
          loading: false,
          isOpenDialog: false,
        },
      }));

      await this._requestStockPermissionList({
        skip: 0,
        take: ITEMS_PER_PAGE,
        userId: agentId,
      });
      dispatch(showSuccessToast('Permissão de estoque removida!'));
    } catch (e) {
      this.setState((prevState) => ({
        requestDialog: {
          ...prevState.requestDialog,
          loading: false,
          isOpenDialog: false,
        },
      }));
      dispatch(showErrorToast(e.message));
    }
  }

  _requestRemoveAgent = async (agentId) => {
    const { dispatch } = this.props;
    try {
      this.setState((prevState) => ({
        requestDialog: {
          ...prevState.requestDialog,
          loading: true,
          isOpenDialog: true,
        },
      }));
      await AgentService.requestRemoveAgent(agentId);
      this.setState((prevState) => ({
        requestDialog: {
          ...prevState.requestDialog,
          loading: false,
          isOpenDialog: false,
        },
      }));
      dispatch(showSuccessToast('Representante removido!'));
      setTimeout(() => {
        window.history.back();
      }, 2000);
    } catch (e) {
      this.setState((prevState) => ({
        requestDialog: {
          ...prevState.requestDialog,
          loading: false,
          isOpenDialog: false,
        },
      }));
      dispatch(showErrorToast(e.message));
    }
  };

  _requestOrderDetail = async (id) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ orderDetailData }) => ({
        orderDetailData: {
          ...orderDetailData,
          loading: true,
          data: {},
        },
      }));

      const { data } = await OrderService.fetchOrderDetail(id);

      this.setState(({ orderDetailData }) => ({
        orderDetailData: {
          ...orderDetailData,
          loading: false,
          data,
        },
      }));
      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: false,
          finished: false,
        },
      }));
    } catch (e) {
      this.setState(({ orderDetailData }) => ({
        orderDetailData: {
          ...orderDetailData,
          loading: false,
          data: {},
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  _requestApproveOrder = async (id) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: true,
          finished: false,
        },
      }));

      await OrderService.approveOrder(id);

      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: false,
          finished: true,
        },
      }));
    } catch (e) {
      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: false,
          finished: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  _requestRefuseOrder = async (id) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: true,
          finished: false,
        },
      }));

      await OrderService.refuseOrder(id);

      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: false,
          finished: true,
        },
      }));
    } catch (e) {
      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: false,
          finished: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  _requestCancelOrder = async (id) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: true,
          finished: false,
        },
      }));

      await OrderService.cancelOrder(id);

      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: false,
          finished: true,
        },
      }));
    } catch (e) {
      this.setState(({ orderOnApproval }) => ({
        orderOnApproval: {
          ...orderOnApproval,
          loading: false,
          finished: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  _downloadSelectedData = async (orders) => {
    const { dispatch } = this.props;

    try {
      this.setState({
        loadingExport: true,
      });

      await OrderService.downloadSelectedData(
        orders.map((order) => order.data.id),
        'export.csv',
      );

      dispatch(showSuccessToast(`${orders.length} Pedido${orders.length > 1 ? 's exportados' : ' exportado'} com sucesso!`));

      this.setState({
        loadingExport: false,
      });
    } catch (e) {
      this.setState({
        loadingExport: false,
      });

      dispatch(showErrorToast(e.message));
    }
  }

  _downloadFilteredData = async (filters, search) => {
    const { dispatch } = this.props;

    try {
      this.setState({
        loadingExport: true,
      });

      await OrderService.downloadFilteredData(filters, 'export.csv', search);

      dispatch(showSuccessToast('Pedidos exportados com sucesso!'));

      this.setState({
        loadingExport: false,
      });
    } catch (e) {
      this.setState({
        loadingExport: false,
      });

      dispatch(showErrorToast(e.message));
    }
  }

  render() {
    const {
      userData,
    } = this.props;

    const {
      agentDetail,
      orderList,
      stockPermissionList,
      requestDialog,
      orderOnApproval,
      orderDetailData,
      newfiltersInitialState,
      loadingExport,
    } = this.state;

    return (
      <AgentDetailLayout
        userData={userData}
        agentDetailData={agentDetail.data}
        requestDialog={requestDialog}
        loading={agentDetail.loading}
        loadingExport={loadingExport}
        orderList={orderList}
        stockPermissionList={stockPermissionList}
        onRequestStockPermissionList={this._requestStockPermissionList}
        handleRemoveMaterialPermissionDialog={this._requestRemoveStockPermission}
        handleRemoveAllStockPermissionAgent={this._requestRemoveAllStockPermissionAgent}
        handleRemoveAgent={this._requestRemoveAgent}
        onRequestOrderDetail={this._requestOrderDetail}
        handleConfirmApproveDialog={this._requestApproveOrder}
        handleConfirmRefuseDialog={this._requestRefuseOrder}
        handleConfirmCancelDialog={this._requestCancelOrder}
        loadingOrderDialog={orderOnApproval.loading}
        orderOnApprovalFinished={orderOnApproval.finished}
        orderDetailData={orderDetailData}
        newfiltersInitialState={newfiltersInitialState}
        onRequestOrderList={this._requestOrderList}
        onExportFilteredData={this._downloadFilteredData}
        onExportSelectedData={this._downloadSelectedData}
      />
    );
  }
}

const mapStateToProps = (data) => data;

export default connect(mapStateToProps)(AgentDetailPage);
