import React from 'react';
import styled from 'styled-components';

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { Colors } from '../../../../../constants';

const ArrowButton = styled.div`
  width: 32px;
  height: 32px;
  border: 1px solid ${Colors.GRAY_LIGHTER};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ active }) => active ? 'white' : Colors.GRAY_DARKER};
  background-color: ${({ active }) => active ? Colors.PRIMARY_BLUE : Colors.GRAY_LIGHT};
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: -40px;
  transform: translateY(-50%); 
  z-index: 10;
  border: none;
  cursor: pointer;
  ${({ left }) => left && 'right: 48px'};
  ${({ right }) => right && 'right: 0px'};
  transition: all .3s ease-in-out;

  &:hover {
    color: ${({ active }) => active ? 'white' : Colors.GRAY_DARKER};
    background-color: ${({ active }) => active ? Colors.SECONDARY_MEDIUM : Colors.GRAY_LIGHT};
  }
`;

export const NextArrow = (props, active) => {
  const { onClick, nextArrowActive } = props;

  return (
    <ArrowButton
      onClick={nextArrowActive ? onClick : () => { }}
      active={nextArrowActive}
      right
    >
      <KeyboardArrowRightIcon
        onClick={nextArrowActive ? onClick : () => { }}
      />
    </ArrowButton>
  );
};

export const PrevArrow = (props) => {
  const { onClick, prevArrowActive } = props;

  return (
    <ArrowButton
      onClick={prevArrowActive ? onClick : () => { }}
      active={prevArrowActive}
      left
    >
      <KeyboardArrowLeftIcon
        onClick={prevArrowActive ? onClick : () => { }}
      />
    </ArrowButton>
  );
};
