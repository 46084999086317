export const columnType = {
  AGENT: 'name',
  CITY: 'city',
  TOTAL_ORDERS: 'totalOrders',
  TAGS: 'tags',
  ACTION: 'action',
};

export const columnLabel = {
  [columnType.AGENT]: 'Representante',
  [columnType.CITY]: 'Cidade - UF',
  [columnType.TOTAL_ORDERS]: 'Pedidos em andamento',
  [columnType.TAGS]: 'Tags',
};

export const columnDetailType = {
  MATERIAL_NAME: 'name',
  TYPE: 'materialType',
  AUTHORIZED_QUANTITY: 'authorizedQuantity',
  CURRENT_BALANCE: 'currentBalance',
  AVAILABLE_QUANTITY: 'availableStock',
  STATUS: 'status',
  ACTION: 'action',
};

export const columnDetailLabel = {
  [columnDetailType.MATERIAL_NAME]: 'Material',
  [columnDetailType.TYPE]: 'Tipo',
  [columnDetailType.AUTHORIZED_QUANTITY]: 'Qtde Autorizada',
  [columnDetailType.CURRENT_BALANCE]: 'Saldo atual',
  [columnDetailType.AVAILABLE_QUANTITY]: 'Estoque disponível',
  [columnDetailType.STATUS]: 'Status',
};
