import React, { useState } from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import ErrorIcon from '@material-ui/icons/Error';
import ModalTableCheckboxSearch from '../../../../components/ModalTableCheckboxSearch';
import { materialType, materialTypeOptionList } from '../../../../constants/order';
import { materialTableHeader, materialTableKey } from './constants';
import { Text } from '../../../../components';
import { Colors } from '../../../../constants';

const StyledQuantityText = styled(Text)`
  display: flex;
  align-items: center;
  color: ${Colors.GRAY};
`;

const TextStyled = styled(Text)`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 190px;
`;

const StyledIcon = styled(ErrorIcon)`
  margin-right: 4px;
`;

const MaterialModalTable = ({
  handleRefresh,
  ...props
}) => {
  const [isRefresh, setIsRefresh] = useState(false);
  const _renderBasicTextCell = (value, list, index) => (
    <>
      {list[index].availableQuantity > 0 ? (
        <TextStyled>
          {(value === parseInt(value, 10)) ? (
            <NumberFormat
              value={value}
              displayType="text"
              decimalSeparator=","
              thousandSeparator={true, '.'}
            />
          ) : (
            value
          )}
        </TextStyled>
      ) : (
        <>
          {(value === parseInt(value, 10)) ? (
            <StyledQuantityText>
              <StyledIcon
                fontSize="small"
              />
              INDISPONÍVEL
            </StyledQuantityText>
          ) : (
            <StyledQuantityText>
              {value}
            </StyledQuantityText>
          )}
        </>
      )}
    </>
  );

  const columnConfig = {
    [materialTableKey.MATERIAL]: {
      order: 1,
    },
    [materialTableKey.MATERIAL_TYPE]: {
      order: 2,
    },
    [materialTableKey.QUANTITY]: {
      order: 3,
    },
  };

  const components = {
    [materialTableKey.MATERIAL]: _renderBasicTextCell,
    [materialTableKey.MATERIAL_TYPE]: _renderBasicTextCell,
    [materialTableKey.QUANTITY]: _renderBasicTextCell,
  };

  return (
    <ModalTableCheckboxSearch
      title="Selecione um material"
      placeholder={(option) => materialType.NAME === option.type ? 'Nome do material' : 'Código do material'}
      tableColumnConfig={columnConfig}
      tableComponents={components}
      searchTypeList={materialTypeOptionList}
      tableHandleHeaderTitle={(key) => materialTableHeader[key]}
      handleRefresh={() => {
        handleRefresh();
        setIsRefresh(true);
      }}
      isRefresh={isRefresh}
      disableConfirm
      {...props}
    />
  );
};

export default MaterialModalTable;
