import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import {
  showErrorToast,
  showSuccessToast,
} from '../../../../components';
import {
  AgentService,
  TagService,
  ThirdPartService,
} from '../../../../services';
import FormAgentLayout from '../../../shared/agent/form-agent-layout';

class NewAgentPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      requestDialog: {
        loading: false,
      },
      tagBusinessUnityList: {
        loading: false,
        list: [],
        total: 0,
      },
      tagLineList: {
        loading: false,
        list: [],
        total: 0,
      },
      tagGroupSectorList: {
        loading: false,
        list: [],
        total: 0,
      },
      tagSectorList: {
        loading: false,
        list: [],
        total: 0,
      },
      newTag: {
        loading: false,
        id: '',
        name: '',
      },
      zipCodeInfo: {
        loading: false,
      },
    };
  }

  componentDidMount() {
    const commomParams = {
      skip: 0,
      take: 999,
    };
    const buParams = {
      commomParams,
      type: 1,
    };
    const lineParams = {
      commomParams,
      type: 2,
    };
    const groupSectorParams = {
      commomParams,
      type: 3,
    };
    const sectorParams = {
      commomParams,
      type: 4,
    };
    this._requestTagBusinessUnityList(buParams);
    this._requestTagLineList(lineParams);
    this._requestTagGroupSectorList(groupSectorParams);
    this._requestTagSectorList(sectorParams);
  }

  _requestTagBusinessUnityList = async (data) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ tagBusinessUnityList }) => ({
        tagBusinessUnityList: {
          ...tagBusinessUnityList,
          loading: true,
        },
      }));

      const { data: { list, total } } = await TagService.fetchTagList(data);

      this.setState(({ tagBusinessUnityList }) => ({
        tagBusinessUnityList: {
          ...tagBusinessUnityList,
          loading: false,
          list,
          total,
        },
      }));
    } catch (e) {
      this.setState(({ tagBusinessUnityList }) => ({
        tagBusinessUnityList: {
          ...tagBusinessUnityList,
          loading: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  };

  _requestTagLineList = async (data) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ tagLineList }) => ({
        tagLineList: {
          ...tagLineList,
          loading: true,
        },
      }));

      const { data: { list, total } } = await TagService.fetchTagList(data);

      this.setState(({ tagLineList }) => ({
        tagLineList: {
          ...tagLineList,
          loading: false,
          list,
          total,
        },
      }));
    } catch (e) {
      this.setState(({ tagLineList }) => ({
        tagLineList: {
          ...tagLineList,
          loading: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  };

  _requestTagGroupSectorList = async (data) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ tagGroupSectorList }) => ({
        tagGroupSectorList: {
          ...tagGroupSectorList,
          loading: true,
        },
      }));

      const { data: { list, total } } = await TagService.fetchTagList(data);

      this.setState(({ tagGroupSectorList }) => ({
        tagGroupSectorList: {
          ...tagGroupSectorList,
          loading: false,
          list,
          total,
        },
      }));
    } catch (e) {
      this.setState(({ tagGroupSectorList }) => ({
        tagGroupSectorList: {
          ...tagGroupSectorList,
          loading: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  };

  _requestTagSectorList = async (data) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ tagSectorList }) => ({
        tagSectorList: {
          ...tagSectorList,
          loading: true,
        },
      }));

      const { data: { list, total } } = await TagService.fetchTagList(data);

      this.setState(({ tagSectorList }) => ({
        tagSectorList: {
          ...tagSectorList,
          loading: false,
          list,
          total,
        },
      }));
    } catch (e) {
      this.setState(({ tagSectorList }) => ({
        tagSectorList: {
          ...tagSectorList,
          loading: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  };

  _createNewTag = async (tagName, tagGroup) => {
    const { dispatch } = this.props;
    try {
      this.setState(({ newTag }) => ({
        newTag: {
          ...newTag,
          loading: true,
        },
      }));

      const { data: { id, name } } = await TagService.requestCreateTag({ name: tagName, tagGroup });

      this.setState(({ newTag }) => ({
        newTag: {
          ...newTag,
          loading: false,
          id,
          name,
        },
      }));
    } catch (e) {
      this.setState(({ newTag }) => ({
        newTag: {
          ...newTag,
          loading: false,
        },
      }));
      dispatch(showErrorToast(e.message));
    }
  };

  _requestNewAgent = async (data, tags) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ requestDialog }) => ({
        requestDialog: {
          ...requestDialog,
          loading: true,
        },
      }));

      await AgentService.requestNewAgent(data, tags);

      this.setState(({ requestDialog }) => ({
        requestDialog: {
          ...requestDialog,
          loading: false,
        },
      }));
      dispatch(showSuccessToast(`Representante ${data.name} criado com sucesso!`));
      setTimeout(() => {
        window.history.back();
      }, 2000);
    } catch (e) {
      this.setState(({ requestDialog }) => ({
        requestDialog: {
          ...requestDialog,
          loading: false,
        },
      }));
      dispatch(showErrorToast(e.message));
    }
  };

  _requestZipCodeInfo = async (zipNumber) => {
    const { dispatch } = this.props;
    try {
      this.setState(({ zipCodeInfo }) => ({
        zipCodeInfo: {
          ...zipCodeInfo,
          loading: true,
        },
      }));

      const {
        cep,
        logradouro,
        complemento,
        bairro,
        localidade,
        uf,
        erro,
      } = await ThirdPartService.requestZipCodeInfo(zipNumber);
      if (erro) {
        this.setState(({ zipCodeInfo }) => ({
          zipCodeInfo: {
            ...zipCodeInfo,
            loading: false,
            cep: '',
            street: '',
            addressDetail: '',
            neighborhood: '',
            city: '',
            uf: '',
          },
        }));
        dispatch(showErrorToast('CEP não encontrado. Por favor, tente novamente.'));
      } else {
        this.setState(({ zipCodeInfo }) => ({
          zipCodeInfo: {
            ...zipCodeInfo,
            loading: false,
            cep,
            street: logradouro,
            addressDetail: complemento,
            neighborhood: bairro,
            city: localidade,
            uf,
          },
        }));
      }
    } catch (e) {
      this.setState(({ zipCodeInfo }) => ({
        zipCodeInfo: {
          ...zipCodeInfo,
          loading: false,
        },
      }));
      dispatch(showErrorToast(e.message));
    }
  }

  _getValidations = () => Yup.object().shape({
    name: Yup.string()
      .required('Digite seu nome')
      .matches(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/, 'Digite seu nome corretamente'),
    email: Yup.string().email('Digite seu e-mail corretamente').required('Digite seu e-mail'),
    phone1: Yup.string().required('Digite seu telefone').min(14, 'Digite seu telefone corretamente'),
    phone2: Yup.string().min(14, 'Digite seu telefone corretamente'),
    cep: Yup.string().required('Digite seu cep').min(9, 'Digite seu CEP corretamente'),
    street: Yup.string().required('Digite seu endereço'),
    number: Yup.string().required('Digite seu número'),
    city: Yup.string().required('Digite sua cidade'),
    neighborhood: Yup.string().required('Digite seu bairro'),
    uf: Yup.string().required('Selecione seu estado'),
  });

  render() {
    const {
      userData,
    } = this.props;

    const {
      loading,
      requestDialog,
      tagBusinessUnityList,
      tagLineList,
      tagSectorList,
      tagGroupSectorList,
      newTag,
      zipCodeInfo,
    } = this.state;

    return (
      <FormAgentLayout
        title="Novo representante"
        userData={userData}
        loading={loading}
        requestDialog={requestDialog}
        tagBusinessUnityList={tagBusinessUnityList}
        tagLineList={tagLineList}
        tagGroupSectorList={tagGroupSectorList}
        tagSectorList={tagSectorList}
        handleValidations={this._getValidations}
        handleAgentSubmit={this._requestNewAgent}
        handleNewTag={this._createNewTag}
        newTag={newTag}
        handleZipCodeInfo={this._requestZipCodeInfo}
        zipCodeInfo={zipCodeInfo}
      />
    );
  }
}

const mapStateToProps = (data) => data;

export default connect(mapStateToProps)(NewAgentPage);
