import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Checkbox,
  FormControlLabel,
  FormControl,
  MenuItem,
} from '@material-ui/core/';
import isEqual from 'lodash.isequal';

import Dropdown from '../Dropdown';
import { BreakpointValues } from '../../constants';
import { useWindowSize } from '../../utils';

const propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  optionList: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  })).isRequired,
  handleOutput: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
};
const defaultProps = {
};

const FormControlField = styled(FormControl)`
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  display: -webkit-box;
`;

const ListItem = styled(MenuItem)`
  padding-top: 0;
  padding-bottom: 0;
  min-height: 48px;
`;

const ListItemLabel = styled(FormControlLabel)`
  padding-top: 4px;
  padding-bottom: 4px;
`;

export const checkboxDefaultInitialState = (optionList) => optionList.reduce((acc, option) => ({
  ...acc,
  [option.value]: false,
}), {});

const FilterCheckbox = ({
  optionList = [],
  name,
  label,
  handleOutput,
  value,
  appliedFilters,
  fieldNames,
}) => {
  const [state, setState] = useState(value);
  const currentWindowSize = useWindowSize();

  useEffect(() => {
    if (!isEqual(state, value)) {
      setState(value);
    }
  }, [value]);

  const handleChangeCheck = (event, checked, optValue) => {
    setState({ ...state, [optValue]: checked });
  };

  return (
    <Dropdown
      labelText={label}
      hasConfirmButtons
      handleConfirm={() => {
        handleOutput(state);
      }}
      handleDismiss={() => {
        setState(value);
      }}
      placement={currentWindowSize.width <= BreakpointValues.md ? 'bottom' : 'bottom-start'}
      appliedFilters={appliedFilters}
      fieldNames={fieldNames}
      name={name}
    >
      <FormControlField component="fieldset">
        {optionList.map((option) => (
          <ListItem key={`${name}-${option.value}`}>
            <ListItemLabel
              control={(
                <Checkbox
                  checked={state ? state[option.value] : false}
                  onChange={(event, checked) => handleChangeCheck(event, checked, option.value)}
                  name={name}
                  color="primary"
                />
              )}
              label={option.label}
            />
          </ListItem>
        ))}
      </FormControlField>
    </Dropdown>
  );
};

FilterCheckbox.propTypes = propTypes;
FilterCheckbox.defaultProps = defaultProps;

export default FilterCheckbox;
