import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  Container,
  Breadcrumbs,
  Grid,
  Link,
} from '@material-ui/core/';
import { Edit, ShoppingCart } from '@material-ui/icons';
import { CropImage } from './CropImage';
import {
  Button,
  Header,
  Footer,
  DrawerWindow,
  showErrorToast,
  DialogModal,
} from '.';
import { RoutePaths, OrderRoutePaths, AgentRoutePaths } from '../constants/RoutePaths';
import { Colors, USER_ROLES, BreakpointValues } from '../constants';
import MaterialUploadSlider from './materialUploadSlider';
import PermissionForm from '../flows/shared/stock/stock-permission-detail/permission-detail-layout/permissionForm';
import { mediaHelper, useWindowSize } from '../utils';

const PageWrapper = styled.div`
  background-color: ${({ isDetailPage }) => isDetailPage ? 'white' : Colors.GRAY_LIGHEST_LIGHT};
`;

const Content = styled(Container)`
  margin-top: 24px;
  padding-bottom: 24px;

  ${mediaHelper.lg`
    padding-bottom: 60px;
  `}
`;

const PageBreadcrumbs = styled(Breadcrumbs)`
  display: flex;
  margin-top: 8px !important;
  color: ${Colors.GRAY_DARKER};
  font-size: 12px !important;

  ${mediaHelper.lg`
    margin-top: 48px !important;
    font-size: 16px !important;
  `}

  li, a {
    color: ${Colors.GRAY_DARKER};
  }
`;

const PageStep = styled.span`
  color: ${Colors.GRAY_DARKEST};
`;

const DetailTopWrapper = styled.div`
  margin-top: 8px;
`;

const DetailTitleText = styled.div`
  font-size: 24px;
  color: ${Colors.SECONDARY_MEDIUM};

  ${mediaHelper.xl`
    font-size: 34px;
  `};
`;

const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > button {
    margin-bottom: 24px;
    margin-right: 8px;
  }

  ${mediaHelper.md`
    flex-direction: row;
  `}

  ${mediaHelper.lg`
    align-items: center;
    justify-content: flex-end;
    margin-right: 0;
  `}

`;

const CodeNumber = styled.div`
  font-size: 14px;
  color: ${Colors.PRIMARY_BLUE};
  margin-top: 8px;
`;

const DescriptionText = styled.div`
  color: ${Colors.GRAY_DARKEST};
  font-size: 16px;
  margin-top: 16px;
`;

const ContentDetail = styled.div`
  min-height: 30vh;
  background-color: ${({ isDetailPage }) => isDetailPage ? 'white' : Colors.GRAY_LIGHTER};
`;

const DetailEditWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  background-color: ${Colors.BLUE_LIGHT};
  padding-top: 24px;

  & ${ActionWrapper} {
    flex-direction: row;
  }

  ${mediaHelper.lg`
    height: 92px;
  `}
`;

const DrawerContent = styled.div`
  display: flex;
  width: 100%;
  margin-top: 32px;
  min-height: 300px;
  max-height: 360px;
  overflow-y: auto;
  padding-top: 8px;

  ${mediaHelper.xl`
    min-height: 200px;
    max-height: initial;
    overflow-y: visible;
  `}
`;

const StyledUploadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 24px;
`;

const ActionUploadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledFileInput = styled.input`
  height: 0;
  visibility: hidden;
`;

const InfoSize = styled.span`
  width: 100%;
  color: ${Colors.GRAY_DARKER};
  font-size: 12px;
  margin-top: 8px;
`;

const EditStyled = styled(Edit)`
  margin-left: 8px;
`;

const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: -8px;
`;

const Tag = styled.div`
  background-color: ${Colors.PRIMARY_BLUE};
  border-radius: 16px;
  padding: 5px 10px;
  color: white;
  font-size: 14px;
  margin-right: 8px;
  margin-bottom: 8px;
`;

const PersonalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Colors.GRAY_LIGHT};
  padding: 24px;
  border-radius: 8px;
  margin: 8px 0 32px 0;
`;

const TitleText = styled.div`
  width: 100%;
  font-size: 24px;
  color: ${Colors.PRIMARY_BLUE};
  margin-bottom: 16px;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
`;

const LabelText = styled.div`
  font-size: 14px;
  color: ${Colors.GRAY_DARKER};
  margin-bottom: 8px;
`;

const ValueText = styled.div`
  font-size: 16px;
  color: ${Colors.GRAY_DARKEST};
`;

const PageDetail = ({
  title,
  description,
  materialCode,
  materialData,
  availableQuantity,
  materialId,
  handleValidations,
  handleOpenDialog,
  userData,
  sliderData,
  agentDetailData,
  children,
  originStockSearch,
  newMaterialImages,
  handleDeleteImage,
  originPath,
  originPathName,
  isDetailPage,
  isPermissions,
  isAgentDetail,
  isNewPermission,
  setFieldValue,
  handleSubmit,
  handleCancel,
  handleMaterialAutomatic,
  isAutomaticQuantity,
  materialPermission,
  handlePermissionActive,
  handleQuantityDistribution,
  agentsTotalAuthorized,
  agentsTotalBalance,
  totalAgents,
  hasErrorQuantity,
  values,
  errors,
  isPermissionValid,
  recurrenceIntervalValue,
  isStockPermission,
  onRemoveAgentDialog,
}) => {
  const [openDrawer, setState] = useState(false);
  const { search: locationSearch } = useLocation();
  const history = useHistory();
  const inputField = useRef(null);
  const [newSliderData, setNewSliderData] = useState(sliderData);
  const [filesToUpload, setFilesToUpload] = useState({});
  const [filesToPreview, setFilesToPreview] = useState([]);
  const [isOpenCancelDialog, setIsOpenCancelDialog] = useState(false);
  const [currentImageId, setCurrentImageId] = useState(null);
  const [currentImageName, setCurrentImageName] = useState(null);
  const [isDeleteImage, setIsDeleteImage] = useState(false);
  const currentWindowSize = useWindowSize();
  const [openCropModal, setOpenCropTool] = useState(false);
  const [crop, setCrop] = useState();
  const [imgToCrop, setImgToCrop] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setNewSliderData(sliderData);
  }, [sliderData]);

  useEffect(() => {
    if (Object.keys(newMaterialImages).length !== 0 && newMaterialImages.data.length > 0 && !isDeleteImage) {
      setNewSliderData((newSliderData) => [...newSliderData, ...newMaterialImages.data]);
    }
  }, [
    newMaterialImages.data,
  ]);

  useEffect(() => {
    if (isDeleteImage) {
      if (currentImageId) {
        const sliderDataImageRemoved = newSliderData.filter((image) => image.id !== currentImageId);
        setNewSliderData(sliderDataImageRemoved);
      } else if (currentImageName) {
        const sliderDataImageRemoved = newSliderData.filter((image) => image.name !== currentImageName);
        setNewSliderData(sliderDataImageRemoved);
      }

      const filesToPreviewImageRemoved = filesToPreview.filter((image) => image.name !== currentImageName);
      setFilesToPreview(filesToPreviewImageRemoved);
    }
  }, [
    isDeleteImage,
  ]);

  useEffect(() => {
    const newArray = [...newSliderData];
    const filteredFiles = filesToPreview.reduce((unique, o) => {
      const existingFile = unique.find((obj) => obj.name === o.name);

      if (existingFile) {
        if (o.lastModified > existingFile.lastModified) {
          const index = unique.indexOf(existingFile);
          unique[index] = o;
        }
      } else {
        unique.push(o);
      }

      return unique;
    }, []);
    filteredFiles.forEach((item) => {
      const existingFile = newArray.find(({ name }) => name === item.name);

      if (existingFile) {
        if (item.lastModified > existingFile.lastModified) {
          const index = newArray.indexOf(existingFile);
          newArray[index] = {
            url: URL.createObjectURL(item),
            name: item.name,
            lastModified: item.lastModified,
          };
        }
      } else {
        newArray.push({
          url: URL.createObjectURL(item),
          name: item.name,
          lastModified: item.lastModified,
        });
      }
    });
    setNewSliderData(newArray);
    setFilesToUpload({ ...filteredFiles });
  }, [
    filesToPreview,
  ]);

  const _onOpenDrawer = () => {
    setState(true);
  };

  const _onCloseDrawer = () => {
    setState(false);
  };

  const _handleCurrentData = (data) => {
    setCurrentImageId(data.imageId);
    setCurrentImageName(data.name);
    setIsDeleteImage(false);
  };

  const _onDeleteImage = () => {
    if (currentImageId) {
      handleDeleteImage(currentImageId);
      setIsDeleteImage(true);
    } else if (currentImageName) {
      setIsDeleteImage(true);
    }
  };

  const _onSubmitMaterialDetail = (values, materialId, filesToUpload) => {
    handleOpenDialog(values, materialId, filesToUpload);
  };

  function handleCropChange(newCrop) {
    setCrop(newCrop);
  }

  function updateWithCroppedImage(newFileToPreview) {
    setFilesToPreview([...filesToPreview, newFileToPreview]);
  }

  function handleEditImage() {
    setOpenCropTool(true);
    setImgToCrop(() => filesToPreview.filter((image) => image.name === currentImageName)[0]);
  }

  function handleCropTool(status) {
    setOpenCropTool(status);
  }

  return (
    <>
      <PageWrapper
        isDetailPage={isDetailPage}
      >
        <Header
          userData={userData}
          hideChangeView
        />
        <Content maxWidth="lg">
          <PageBreadcrumbs aria-label="breadcrumb">
            <Link href={`${originPath}${originStockSearch ? `${originStockSearch}` : ''}`}>
              {originPathName}
            </Link>
            <PageStep>
              {title ? title : '-'}
            </PageStep>
          </PageBreadcrumbs>
          <DetailTopWrapper>
            <Grid container spacing={3}>
              <Grid
                item
                lg={isStockPermission ? 6 : 7}
                md={isStockPermission ? 6 : 6}
                xs={isStockPermission ? 12 : 12}
              >
                <DetailTitleText>
                  {title ? title : '-'}
                </DetailTitleText>
                {materialCode && (
                  <CodeNumber>
                    Código
                    {' '}
                    {materialCode ? materialCode : '-'}
                  </CodeNumber>
                )}
                {description && (
                  <Grid container spacing={2}>
                    <Grid
                      item
                      lg={10}
                      md={10}
                      xs={12}
                    >
                      <DescriptionText>
                        {description ? description : '-'}
                      </DescriptionText>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <>
                {isPermissions && materialPermission ? (
                  <PermissionForm
                    materialPermission={materialPermission}
                    handleSubmit={handleSubmit}
                    handleCancel={handleCancel}
                    setFieldValue={setFieldValue}
                    isNewPermission={isNewPermission}
                    handleMaterialAutomatic={handleMaterialAutomatic}
                    isAutomaticQuantity={isAutomaticQuantity}
                    handlePermissionActive={handlePermissionActive}
                    availableQuantity={availableQuantity}
                    materialId={materialId}
                    handleQuantityDistribution={handleQuantityDistribution}
                    values={values}
                    errors={errors}
                    agentsTotalAuthorized={agentsTotalAuthorized}
                    agentsTotalBalance={agentsTotalBalance}
                    totalAgents={totalAgents}
                    isValid={isPermissionValid}
                    hasErrorQuantity={hasErrorQuantity}
                    recurrenceIntervalValue={recurrenceIntervalValue}
                  />
                ) : isAgentDetail ? (
                  <>
                    <Grid
                      item
                      lg={5}
                      md={6}
                      xs={12}
                    >
                      <ActionWrapper>
                        <Button
                          size="large"
                          color="secondary"
                          variant="outlined"
                          handleClick={onRemoveAgentDialog}
                        >
                          Excluir
                        </Button>
                        <Button
                          size="large"
                          color="secondary"
                          variant="contained"
                          handleClick={() => { history.push(`${AgentRoutePaths.UPDATE}/${agentDetailData.id}`); }}
                        >
                          Editar
                          <EditStyled
                            fontSize="small"
                          />
                        </Button>
                      </ActionWrapper>
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      xs={12}
                    >
                      <TagWrapper>
                        {agentDetailData.tags && agentDetailData.tags.length ? agentDetailData.tags.map((tag) => (
                          <Tag key={`agent-tag-${tag.id}`}>{tag.name}</Tag>
                        )) : null}
                      </TagWrapper>
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      xs={12}
                    >
                      <PersonalWrapper>
                        <TitleText>
                          Dados pessoais
                        </TitleText>
                        <InfoWrapper>
                          <InfoBlock>
                            <LabelText>
                              Endereço
                            </LabelText>
                            <ValueText>
                              {agentDetailData.street ? agentDetailData.street : 'Sem endereço'}
                              {', '}
                              {agentDetailData.number && agentDetailData.number}
                              {', '}
                              {agentDetailData.neighborhood && agentDetailData.neighborhood}
                              {', '}
                              {agentDetailData.addressDetail && agentDetailData.addressDetail}
                              <br />
                              {`${agentDetailData.city ? agentDetailData.city : ''} ${agentDetailData.uf ? `- ${agentDetailData.uf}` : ''} ${agentDetailData.cep ? `- Cep ${agentDetailData.cep}` : ''}`}
                            </ValueText>
                          </InfoBlock>
                          <InfoBlock>
                            <LabelText>
                              Telefone(s)
                            </LabelText>
                            {agentDetailData.phone1 && (
                              <ValueText>
                                {agentDetailData.phone1}
                              </ValueText>
                            )}
                            {agentDetailData.phone2 && (
                              <ValueText>
                                {agentDetailData.phone2}
                              </ValueText>
                            )}
                            {!agentDetailData.phone1 && !agentDetailData.phone2 && (
                              'Sem telefone'
                            )}
                          </InfoBlock>
                          <InfoBlock>
                            <LabelText>
                              E-mail
                            </LabelText>
                            <ValueText>
                              {agentDetailData.email ? agentDetailData.email : 'Sem e-mail'}
                            </ValueText>
                          </InfoBlock>
                        </InfoWrapper>
                      </PersonalWrapper>
                    </Grid>
                  </>
                ) : (
                  <Grid
                    item
                    lg={5}
                    md={6}
                    xs={12}
                  >
                    <ActionWrapper>
                      {userData.userRole === USER_ROLES.COMPANY && (
                        <>
                          <Button
                            size="large"
                            color="secondary"
                            variant="outlined"
                            handleClick={_onOpenDrawer}
                          >
                            Editar
                            <EditStyled
                              fontSize="small"
                            />
                          </Button>
                        </>
                      )}
                      {userData.userRole !== USER_ROLES.MASTER && (
                        <Button
                          size="large"
                          color="secondary"
                          variant="contained"
                          onClick={() => {
                            history.push(
                              `${RoutePaths.ORDER}${OrderRoutePaths.NEW}`,
                              {
                                originStockSearch: locationSearch,
                                material: {
                                  name: materialData.name,
                                  availableQuantity,
                                  displayBatch: materialData.displayBatch,
                                  id: materialData.id,
                                  image: materialData.image,
                                  materialType: materialData.materialType,
                                  mode: 2,
                                  qty: 0,
                                  reservedQuantity: materialData.reservedQuantity,
                                  totalQuantity: materialData.totalQuantity,
                                  agentList: userData.userRole === USER_ROLES.AGENT ? [{
                                    id: userData.id,
                                    name: userData.name,
                                    active: true,
                                    qty: 0,
                                  }] : [],
                                },
                              },
                            );
                          }}
                          disabled={availableQuantity <= 0}
                        >
                          Fazer pedido&nbsp;
                          <ShoppingCart
                            fontSize="small"
                          />
                        </Button>
                      )}
                    </ActionWrapper>
                  </Grid>
                )}
              </>
            </Grid>
          </DetailTopWrapper>
        </Content>
        <ContentDetail
          isDetailPage={isDetailPage}
        >
          {children}
        </ContentDetail>
        <Footer />
      </PageWrapper>
      <DrawerWindow
        openDrawer={openDrawer}
        handleCloseDrawer={_onCloseDrawer}
      >
        <DetailEditWrapper>
          <Formik
            initialValues={{
              // alias: title,
              description: materialData.description ? materialData.description : '',
              files: null,
            }}
            validationSchema={handleValidations}
            enableReinitialize
          >
            {({ values, resetForm }) => (
              <>
                <DrawerHeader>
                  <Container maxWidth="lg">
                    <Grid container spacing={3}>
                      <Grid
                        item
                        lg={7}
                        md={7}
                        xs={12}
                      >
                        <DetailTitleText>
                          Editar dados do material promocional
                        </DetailTitleText>
                      </Grid>
                      <Grid
                        item
                        lg={5}
                        md={5}
                        xs={12}
                      >
                        <ActionWrapper>
                          <Button
                            size={currentWindowSize.width >= BreakpointValues.md ? 'large' : 'small'}
                            color="secondary"
                            variant="outlined"
                            handleClick={() => {
                              if ((values.description === '' || values.description === materialData.description) && Object.values(filesToUpload).length === 0) {
                                _onCloseDrawer();
                              } else {
                                setIsOpenCancelDialog(true);
                              }
                            }}
                          >
                            Fechar
                          </Button>
                          <Button
                            size={currentWindowSize.width >= BreakpointValues.md ? 'large' : 'small'}
                            color="secondary"
                            variant="contained"
                            disabled={(values.description === '' || values.description === materialData.description) && Object.values(filesToUpload).length === 0}
                            handleClick={() => { _onSubmitMaterialDetail(values, materialId, filesToUpload); }}
                          >
                            Salvar
                          </Button>
                        </ActionWrapper>
                      </Grid>
                    </Grid>
                  </Container>
                </DrawerHeader>
                <DrawerContent>
                  <Container maxWidth="lg">
                    {openCropModal && imgToCrop ? (
                      <Grid container spacing={3}>
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xs={12}
                        >
                          {imgToCrop && (
                            <CropImage
                              imgToCrop={imgToCrop}
                              currentImageName={currentImageName}
                              crop={crop}
                              handleCropTool={handleCropTool}
                              setCrop={handleCropChange}
                              updateWithCroppedImage={updateWithCroppedImage}
                            />
                          )}
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container spacing={3}>
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xs={12}
                        >
                          <MaterialUploadSlider
                            sliderData={newSliderData.length > 0 ? newSliderData : []}
                            handleCurrentData={(data) => {
                              _handleCurrentData(data);
                            }}
                            showLastUploadedImage={filesToPreview.length > 0
                              ? newSliderData.length : null}
                          />
                          <StyledFileInput
                            id="files"
                            accept="image/*"
                            name="files"
                            type="file"
                            multiple
                            ref={inputField}
                            onChange={(event) => {
                              if ((event.currentTarget.files[0].size / (1024 * 1024)) <= 1) {
                                const files = Object.values(event.currentTarget.files);
                                setFilesToPreview([...filesToPreview, ...files]);
                              } else {
                                dispatch(showErrorToast(`O tamanho máximo suportado para imagens é de 1 MB. O arquivo escolhido tem ${(event.currentTarget.files[0].size / (1024 * 1024)).toFixed(2)} MB`));
                              }
                              document.getElementById('files').value = '';
                            }}
                          />
                          <StyledUploadWrapper>
                            <ActionUploadWrapper>
                              {newSliderData.length > 0 && (
                                <>
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    color="primary"
                                    handleClick={() => { _onDeleteImage(); }}
                                  >
                                    Excluir
                                  </Button>
                                  {currentImageName && (
                                    <Button
                                      size="small"
                                      variant="contained"
                                      color="primary"
                                      handleClick={() => {
                                        handleEditImage();
                                      }}
                                    >
                                      Editar
                                    </Button>
                                  )}
                                </>
                              )}
                              <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                handleClick={() => {
                                  inputField.current.click();
                                }}
                              >
                                Adicionar foto
                              </Button>
                            </ActionUploadWrapper>
                            <InfoSize>
                              Tamanho máximo do arquivo: 1 MB.
                            </InfoSize>
                          </StyledUploadWrapper>
                        </Grid>
                        <Grid
                          item
                          lg={1}
                          md={1}
                          xs={12}
                        />
                        <Grid
                          item
                          lg={6}
                          md={6}
                          xs={12}
                        >
                          <Field
                            label="Descrição do material"
                            name="description"
                            variant="outlined"
                            multiline
                            rows={5}
                            fullWidth
                            component={TextField}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Container>
                </DrawerContent>
                <DialogModal
                  title="Fechar edição"
                  description="As alterações feitas serão perdidas. Tem certeza que deseja fechar?"
                  confirmButtonText="Fechar"
                  cancelButtonText="Voltar"
                  handleCloseDialog={() => { setIsOpenCancelDialog(false); }}
                  handleConfirmDialog={() => {
                    _onCloseDrawer();
                    resetForm();
                    setIsOpenCancelDialog(false);
                    setNewSliderData(sliderData);
                    setFilesToPreview([]);
                    setFilesToUpload([]);
                  }}
                  isOpenDialog={isOpenCancelDialog}
                />
              </>
            )}
          </Formik>
        </DetailEditWrapper>
      </DrawerWindow>
    </>
  );
};

PageDetail.propTypes = {
  userData: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  materialId: PropTypes.number,
  materialCode: PropTypes.string,
  materialData: PropTypes.object,
  availableQuantity: PropTypes.number,
  handleValidations: PropTypes.func,
  handleOpenDialog: PropTypes.func,
  children: PropTypes.node,
  sliderData: PropTypes.array,
  newMaterialImages: PropTypes.object,
  originPath: PropTypes.string,
  originPathName: PropTypes.string,
  isPermissions: PropTypes.bool,
  isAgentDetail: PropTypes.bool,
  agentDetailData: PropTypes.object,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleMaterialAutomatic: PropTypes.func,
  materialPermission: PropTypes.object,
  isStockPermission: PropTypes.bool,
};

PageDetail.defaultProps = {
  userData: {},
  title: '',
  description: '',
  materialId: null,
  materialCode: null,
  materialData: {},
  availableQuantity: 0,
  handleValidations: () => { },
  handleOpenDialog: () => { },
  children: [],
  sliderData: [],
  newMaterialImages: {},
  originPath: '',
  originPathName: '',
  isPermissions: false,
  isAgentDetail: false,
  agentDetailData: {},
  handleSubmit: () => {},
  setFieldValue: () => {},
  handleMaterialAutomatic: () => {},
  materialPermission: {},
  isStockPermission: false,
};

export default PageDetail;
