import React, { Component } from 'react';
import { connect } from 'react-redux';

import StockPermissionsLayout, { updateQueryStringInPermissionsList } from './stock-permissions-layout';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../components';
import {
  OrderService,
  MaterialService,
  StockService,
} from '../../../../services';

class StockPermissionsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      requestPageConfig: {
        loading: false,
      },
      materialList: {
        loading: false,
        list: [],
      },
      agentList: {
        loading: false,
        list: [],
      },
      permissionsList: {
        loading: false,
        list: [],
      },
    };
  }

  componentDidMount() {
    this._requestReset();
  }

  _requestReset = () => {
    const commomParams = {
      skip: 0,
      take: 24,
    };

    this._requestMaterialList(commomParams);
  }

  _requestMaterialList = async (data, increase) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ materialList }) => ({
        materialList: {
          ...materialList,
          loading: true,
        },
      }));

      const { data: { list, total } } = await MaterialService.fetchMaterialList({
        ...data,
        noImages: true,
        stockPermission: true,
      });

      this.setState(({ materialList }) => ({
        materialList: {
          ...materialList,
          loading: false,
          list: increase ? [
            ...materialList.list,
            ...list,
          ] : list,
          total,
        },
      }));
    } catch (e) {
      this.setState(({ materialList }) => ({
        materialList: {
          ...materialList,
          loading: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  };

  _requestAgentList = async (data) => {
    const { dispatch } = this.props;

    try {
      this.setState(({ agentList }) => ({
        agentList: {
          ...agentList,
          loading: true,
        },
      }));

      const { data: { list, total } } = await OrderService.fetchAgentList(data);

      this.setState(({ agentList }) => ({
        agentList: {
          ...agentList,
          loading: false,
          list,
          total,
        },
      }));
    } catch (e) {
      this.setState(({ agentList }) => ({
        agentList: {
          ...agentList,
          loading: false,
        },
      }));

      dispatch(showErrorToast(e.message));
    }
  }

  _requestPermissionsList = async (data) => {
    const { dispatch, history } = this.props;
    try {
      this.setState(({ permissionsList }) => ({
        permissionsList: {
          ...permissionsList,
          loading: true,
        },
      }));
      updateQueryStringInPermissionsList(history, data);
      const { data: { list, total } } = await StockService.fetchStockPermissionList(data);
      this.setState(({ permissionsList }) => ({
        permissionsList: {
          ...permissionsList,
          list,
          total,
          loading: false,
        },
      }));
    } catch (e) {
      this.setState(({ permissionsList }) => ({
        permissionsList: {
          ...permissionsList,
          loading: false,
        },
      }));
      dispatch(showErrorToast(e.message));
    }
  }

  _requestRemoveStockPermission = async (id) => {
    const { dispatch } = this.props;
    try {
      this.setState(({ permissionsList }) => ({
        permissionsList: {
          ...permissionsList,
          loading: true,
        },
      }));
      await StockService.requestRemovePermissionStock(id);
      this.setState(({ permissionsList }) => ({
        permissionsList: {
          ...permissionsList,
          loading: false,
        },
      }));
      dispatch(showSuccessToast('Permissão de estoque removida!'));
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (e) {
      this.setState(({ permissionsList }) => ({
        permissionsList: {
          ...permissionsList,
          loading: false,
        },
      }));
      dispatch(showErrorToast(e.message));
    }
  }

  render() {
    const {
      requestPageConfig,
      materialList,
      agentList,
      permissionsList,
    } = this.state;
    const {
      userData,
    } = this.props;
    return (
      <StockPermissionsLayout
        userData={userData}
        title="Permissões de estoque"
        loading={requestPageConfig.loading}
        materialList={materialList}
        agentList={agentList}
        onRequestAgentList={this._requestAgentList}
        permissionsList={permissionsList}
        onRequestPermissionsList={this._requestPermissionsList}
        onRequestMaterialList={this._requestMaterialList}
        onRequestRemovePermission={this._requestRemoveStockPermission}
      />
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  userData: auth.userData,
  isLogged: auth.sessionToken ? true : false,
});

export default connect(mapStateToProps)(StockPermissionsPage);
