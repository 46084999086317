import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Checkbox,
  FormControlLabel,
  FormControl,
  MenuItem,
} from '@material-ui/core/';
import isEqual from 'lodash.isequal';

import Dropdown from '../Dropdown';
import Spinner from '../Spinner';
import FilterSearchForm from './search/searchForm';
import { Colors, BreakpointValues } from '../../constants';
import { useWindowSize } from '../../utils';

const propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  optionList: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  })).isRequired,
  handleOutput: PropTypes.func.isRequired,
  value: PropTypes.any,
  optionMap: PropTypes.func,
  minChar: PropTypes.number,
};
const defaultProps = {
  optionMap: (option) => option,
  minChar: 0,
  value: null,
};

const FormControlField = styled(FormControl)`
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  display: -webkit-box;
`;

const ListItem = styled(MenuItem)`
  padding-top: 0;
  padding-bottom: 0;
  max-height: 300px;
  min-height: 48px;
`;

const ListItemLabel = styled(FormControlLabel)`
  padding-top: 4px;
  padding-bottom: 4px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Colors.GRAY};
  margin-bottom: 8px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  padding: 16px;
  justify-content: center;
`;

export const checkboxDefaultInitialState = (optionList) => optionList.reduce((acc, option) => ({
  ...acc,
  [option.value]: false,
}), {});

const FilterCheckboxSearch = ({
  name,
  label,
  placeholder,
  value,
  loading,
  optionList = [],
  optionMap,
  searchTypeList = [],
  handleOutput,
  onSearch,
  submitOnChange,
  onChange,
  minChar,
  appliedFilters,
  fieldNames,
}) => {
  const typeInitialState = searchTypeList && searchTypeList.length && searchTypeList[0].value;
  const [checkboxState, setCheckboxState] = useState(value);
  const [type, setType] = useState(typeInitialState);
  const [term, setTerm] = useState('');
  const currentWindowSize = useWindowSize();

  useEffect(() => {
    if (!isEqual(checkboxState, value)) {
      setCheckboxState(value);
      setType(typeInitialState);
      setTerm('');
    }
  }, [value]);

  const handleChangeCheck = (event, checked, optValue) => {
    setCheckboxState({ ...checkboxState, [optValue]: checked });
  };

  const handleChangeTypeRadio = (event) => {
    setType(event.target.value);
  };

  const handleChangeTerm = (event) => {
    setTerm(event.target.value);
  };

  const handleSearchSubmit = () => {
    onSearch({
      type,
      term,
    });
  };

  return (
    <Dropdown
      labelText={label}
      hasConfirmButtons={!!optionList.length}
      size={currentWindowSize.width <= BreakpointValues.md ? '280px' : '360px'}
      handleConfirm={(e) => {
        if (submitOnChange) {
          handleOutput(checkboxState);
        } else {
          onChange(checkboxState, e);
        }
      }}
      handleDismiss={() => {
        setCheckboxState(value);
      }}
      placement={currentWindowSize.width <= BreakpointValues.md ? 'bottom' : 'bottom-start'}
      appliedFilters={appliedFilters}
      fieldNames={fieldNames}
      name={name}
    >
      <FilterSearchForm
        typeList={searchTypeList}
        placeholder={placeholder}
        name={name}
        value={{
          type,
          term,
        }}
        handleOutput={handleSearchSubmit}
        onChangeType={handleChangeTypeRadio}
        onChangeTerm={handleChangeTerm}
        minChar={minChar}
      />
      {optionList.length || loading ? (
        <Divider />
      ) : null}
      {loading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : null}
      {optionList.length && !loading ? (
        <FormControlField component="fieldset">
          {optionList.map((option) => (
            <ListItem key={`${name}-${optionMap(option).value}`}>
              <ListItemLabel
                control={(
                  <Checkbox
                    checked={checkboxState ? checkboxState[optionMap(option).value] : false}
                    onChange={(event, checked) => handleChangeCheck(event, checked, optionMap(option).value)}
                    name={name}
                    color="primary"
                  />
                )}
                label={optionMap(option).label}
              />
            </ListItem>
          ))}
        </FormControlField>
      ) : null}
    </Dropdown>
  );
};

FilterCheckboxSearch.propTypes = propTypes;
FilterCheckboxSearch.defaultProps = defaultProps;

export default FilterCheckboxSearch;
