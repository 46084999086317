import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Grow,
  Paper,
  Popper,
  MenuList,
} from '@material-ui/core/';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import {
  ExpandMore,
  ExpandLess,
  FiberManualRecord,
} from '@material-ui/icons';

import { Colors, Theme } from '../constants';
import { Button } from '.';
import { mediaHelper } from '../utils';

const propTypes = {
  icon: PropTypes.node,
  customLabel: PropTypes.node,
  labelText: PropTypes.string,
  placement: PropTypes.string,
  size: PropTypes.string,
  hasConfirmButtons: PropTypes.bool,
  toggleIsSearch: PropTypes.func,
  handleConfirm: PropTypes.func,
  handleDismiss: PropTypes.func,
};

const defaultProps = {
  icon: [],
  customLabel: null,
  labelText: '',
  placement: 'bottom-start',
  size: '250px',
  hasConfirmButtons: false,
  toggleIsSearch: () => { },
  handleConfirm: () => { },
  handleDismiss: () => { },
};

const DropdownContent = styled.div`
  position: relative;

  label {
    width: 100%;
    margin: 0;
  }
`;

const DropdownWrapper = styled(Popper)`
  margin-top: 8px;
  min-width: ${({ size }) => size ? size : '250px'};
  z-index: 10;
`;

export const DropdownLabel = styled.span`
  display: flex;
  align-items: center;
  font-size: 16px;
  height: 32px;
  cursor: pointer;
  font-weight: ${({ active }) => active ? 'bold' : 'normal'};
  margin-left: 8px;

  &:hover {
    color: ${({ active }) => active ? 'black' : Colors.PRIMARY_BLUE};
  }

  svg {
    color: ${Colors.PRIMARY_BLUE}
  }

  ${mediaHelper.lg`
    margin-right: 16px;
  `}
`;

const LabelChild = styled.div`
  width: auto;
  padding-top: 5px;

  ${mediaHelper.md`
    padding-top: 0px;
  `}
`;

const FilterActionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 44px;
  padding: 4px 8px;
  border-top: 1px solid ${Colors.GRAY_LIGHT};
  
  & > button {
    margin-left: 8px;
  }
`;

const FiberManualRecordStyled = styled(FiberManualRecord)`
  font-size: 16px;
  margin-left: 4px;
  margin-top: 2px;
`;

const Dropdown = ({
  icon,
  customLabel,
  labelText,
  placement,
  size,
  hasConfirmButtons,
  toggleIsSearch,
  children,
  handleConfirm,
  handleDismiss,
  searchOpen,
  appliedFilters,
  fieldNames,
  name,
}) => {
  const [open, setOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (appliedFilters && name && fieldNames) {
      if (name === fieldNames.DUE_DATE
        || name === fieldNames.CREATE_DATE
        || name === fieldNames.DELIVERY_DATE
      ) {
        setIsActive(appliedFilters[`${name}`].from !== null ? true : false);
      }
      if (name === fieldNames.TYPE
        || name === fieldNames.MATERIAL_TYPE
        || name === fieldNames.AGENT
        || name === fieldNames.STATUS
        || name === fieldNames.PROMOTIONAL_CICLE
        || name === fieldNames.MATERIAL
        || name === fieldNames.PRODUCT
        || name === fieldNames.ALERT
        || name === fieldNames.TAG
        || name === fieldNames.REGION
      ) {
        setIsActive(Object.values(appliedFilters[`${name}`]).some((obj) => obj === true));
      }
      if (name === fieldNames.AVAILABILITY || name === fieldNames.CITY) {
        setIsActive(appliedFilters[`${name}`] && appliedFilters[`${name}`] !== null ? true : false);
      }
    }
  }, [appliedFilters]);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    toggleIsSearch();
    handleDismissInternal();
    setOpen(false);
  };

  const handleConfirmInternal = () => {
    handleConfirm();
    setOpen(false);
  };

  const handleDismissInternal = () => {
    handleDismiss();
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const renderIcon = (iconToRender, isOpen, isActive) => {
    if (iconToRender) {
      if (isActive) {
        return <FiberManualRecordStyled />;
      }
      if (isOpen) {
        return <ExpandLess />;
      }

      return <ExpandMore />;
    }

    return iconToRender;
  };

  return (
    <DropdownContent
      ref={anchorRef}
      aria-controls={searchOpen !== undefined ? searchOpen : open ? 'menu-list-grow' : undefined}
      aria-haspopup="true"
      active={searchOpen !== undefined ? searchOpen : open}
    >
      {!customLabel ? (
        <DropdownLabel
          active={searchOpen !== undefined ? searchOpen : isActive ? true : open}
          onClick={handleOpen}
        >
          {labelText}
          {renderIcon(icon, open, isActive)}
        </DropdownLabel>
      ) : (
        <LabelChild
          onClick={handleOpen}
        >
          {customLabel}
        </LabelChild>
      )}
      <DropdownWrapper
        variant="popover"
        placement={placement}
        size={size}
        open={searchOpen !== undefined ? searchOpen : open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, newPlacement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: newPlacement === 'bottom' ? 'center bottom' : 'center top' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList role="menu" autoFocusItem={searchOpen !== undefined ? searchOpen : open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {children}
                </MenuList>
              </ClickAwayListener>
              {hasConfirmButtons ? (
                <FilterActionWrapper>
                  <Button
                    theme={Theme}
                    color="secondary"
                    size="medium"
                    handleClick={handleDismissInternal}
                  >
                    Cancelar
                  </Button>
                  <Button
                    theme={Theme}
                    color="secondary"
                    size="medium"
                    handleClick={handleConfirmInternal}
                  >
                    Confirmar
                  </Button>
                </FilterActionWrapper>
              ) : null}
            </Paper>
          </Grow>
        )}
      </DropdownWrapper>
    </DropdownContent>
  );
};

Dropdown.propTypes = propTypes;
Dropdown.defaultProps = defaultProps;

export default Dropdown;
