export const LOCAL_STORAGE_TOKEN = 'luft-promo/SessionToken';
export const LOCAL_STORAGE_USER_DATA = 'luft-promo/UserData';
export const LOCAL_STORAGE_COMPANY_LIST = 'luft-promo/CompanyList';

export function saveSessionUserData(userData) {
  window.localStorage.setItem(
    LOCAL_STORAGE_USER_DATA,
    JSON.stringify(userData),
  );
}

export function getSessionUserData() {
  return window.localStorage.getItem(LOCAL_STORAGE_USER_DATA);
}

export function saveSessionToken(token) {
  window.localStorage.setItem(LOCAL_STORAGE_TOKEN, token);
}

export function getSessionToken() {
  return window.localStorage.getItem(LOCAL_STORAGE_TOKEN);
}

export function clearSession() {
  window.localStorage.removeItem(LOCAL_STORAGE_TOKEN);
  window.localStorage.removeItem(LOCAL_STORAGE_USER_DATA);
  window.localStorage.removeItem(LOCAL_STORAGE_COMPANY_LIST);
}

export function handleLogout() {
  clearSession();
  window.location.href = '/login';
}

export async function handleSessionExpired() {
  handleLogout();
}
