/**
 * AGENTS MODAL TABLE
 */

export const agentTableKey = {
  AGENT: 'name',
  CITY: 'city',
  QUANTITY: 'qty',
  TAG: 'tags',
};

export const agentTableHeader = {
  [agentTableKey.AGENT]: 'Representante',
  [agentTableKey.CITY]: 'Cidade - UF',
  [agentTableKey.QUANTITY]: 'Quantidade',
  [agentTableKey.TAG]: 'Tag',
};

/**
 * MATERIAL MODAL TABLE
 */

export const materialTableKey = {
  MATERIAL: 'name',
  MATERIAL_TYPE: 'materialType',
  QUANTITY: 'availableQuantity',
};

export const materialTableHeader = {
  [materialTableKey.MATERIAL]: 'Material',
  [materialTableKey.MATERIAL_TYPE]: 'Tipo',
  [materialTableKey.QUANTITY]: 'Quantidade',
};

/**
 * PRODUCT MODAL TABLE
 */

export const productTableKey = {
  PRODUCT: 'name',
};

export const productTableHeader = {
  [productTableKey.PRODUCT]: 'Material',
};

/**
 * PRODUCT TYPES
 */
export const productType = {
  NAME: 'name',
  CODE: 'code',
};

export const productTypeLabel = {
  [productType.NAME]: 'Nome',
  [productType.CODE]: 'Código',
};

export const productTypeOptionList = [
  {
    value: productType.NAME,
    label: productTypeLabel[productType.NAME],
  },
  {
    value: productType.CODE,
    label: productTypeLabel[productType.CODE],
  },
];
