import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SearchIcon from '@material-ui/icons/Search';
import {
  RadioGroup,
  Radio,
  TextField,
  FormControlLabel,
  FormControl,
} from '@material-ui/core';

import { Colors } from '../../../constants';
import Button from '../../Button';
import { mediaHelper } from '../../../utils';

const propTypes = {
  name: PropTypes.string.isRequired,
  typeList: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  handleOutput: PropTypes.func.isRequired,
  value: PropTypes.exact({
    type: PropTypes.any,
    term: PropTypes.string,
  }),
};
const defaultProps = {
  typeList: [],
  placeholder: '',
  value: {
    type: undefined,
    term: '',
  },
};

const FormControlField = styled(FormControl)`
  width: 100%;
`;

const ListItemLabel = styled(FormControlLabel)`
  padding-top: 4px;
  padding-bottom: 4px;
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SearchTypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 47px;
`;

const SearchTypeLabel = styled.div`
  height: 20px;
  margin-left: 16px;
  font-size: 16px;
  color: ${Colors.SECONDARY_MEDIUM};
  font-weight: bold;
  white-space: nowrap;
`;

const SearchTypeRadioGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  margin-right: 16px;
`;

const SearchFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 16px 16px;
`;

const FieldWrapper = styled.div`
  flex: 1;
`;

const SearchButton = styled(Button)`
  margin-left: 16px;

  ${({ disabled }) => disabled && `
    margin-bottom: 20px;
  `}
`;

const FilterSearchForm = ({
  typeList,
  placeholder,
  name,
  value,
  handleOutput,
  onChangeType,
  onChangeTerm,
  minChar,
}) => {
  const [error, setError] = useState(null);
  const _getPlaceholder = () => {
    if (typeof placeholder === 'function') {
      return placeholder(value);
    }

    return placeholder;
  };

  const _onChangeTerm = (e) => {
    const termVal = e.target.value;

    if (error && minChar && termVal.length >= minChar) {
      setError(false);
    }

    onChangeTerm(e);
  };

  const _onSearch = (e) => {
    if (minChar && value.term.length < minChar) {
      setError(true);
    } else {
      setError(false);
      handleOutput(e);
    }
  };

  return (
    <SearchWrapper>
      <FormControlField component="fieldset">
        <RadioGroup
          aria-label={`${name}-type`}
          name={`${name}-type`}
          value={value.type || (typeList.length && typeList[0].value)}
          onChange={onChangeType}
        >
          <SearchTypeWrapper>

            <SearchTypeLabel>
              Buscar por
            </SearchTypeLabel>

            {typeList.length ? (
              <SearchTypeRadioGroup>
                {typeList.map((typeItem) => (
                  <ListItemLabel
                    key={`${name}-${typeItem.value}`}
                    value={typeItem.value}
                    control={<Radio color="primary" />}
                    label={typeItem.label}
                  />
                ))}
              </SearchTypeRadioGroup>
            ) : null}
          </SearchTypeWrapper>

          <SearchFieldWrapper>
            <FieldWrapper>
              <TextField
                label={_getPlaceholder()}
                name={name}
                value={value.term}
                onChange={_onChangeTerm}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    _onSearch(e);
                  }
                }}
                fullWidth
                error={error}
                helperText={error ? `Min. ${minChar} caracteres` : ''}
              />
            </FieldWrapper>
            <SearchButton
              color="primary"
              variant="contained"
              handleClick={_onSearch}
              type="button"
              disabled={error}
            >
              Buscar&nbsp;
              <SearchIcon fontSize="small" />
            </SearchButton>
          </SearchFieldWrapper>

        </RadioGroup>
      </FormControlField>
    </SearchWrapper>
  );
};

FilterSearchForm.propTypes = propTypes;
FilterSearchForm.defaultProps = defaultProps;

export default FilterSearchForm;
