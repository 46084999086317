import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mediaHelper } from '../utils';

const DrawerWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: white;
  transition: transform .3s ease-in-out, opacity .3s ease-in-out;
  transform: ${({ openDrawer }) => openDrawer ? 'translateY(0)' : 'translateY(100%)'};
  opacity: ${({ openDrawer }) => openDrawer ? '1' : '0'};
  box-shadow: 0px 0px 8px rgba(0,0,0,.25);
  overflow-y: scroll;

  ${mediaHelper.lg`
    top: auto;
    overflow-y: hidden;
  `}
`;

const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0,0,0, .5);
  transition: opacity .3s ease-in-out;
  pointer-events: ${({ openDrawer }) => openDrawer ? 'all' : 'none'};
  opacity: ${({ openDrawer }) => openDrawer ? '1' : '0'};
  display: ${({ hideBackdrop }) => hideBackdrop ? 'none' : 'flex'};
`;

const DrawerWindow = ({
  children,
  openDrawer,
  hideBackdrop,
}) => (
  <>
    <DrawerWrapper
      openDrawer={openDrawer}
    >
      {children}
    </DrawerWrapper>
    <Backdrop
      openDrawer={openDrawer}
      hideBackdrop={hideBackdrop}
    />
  </>
);

DrawerWindow.propTypes = {
  children: PropTypes.node.isRequired,
  openDrawer: PropTypes.bool,
  hideBackdrop: PropTypes.bool,
};

DrawerWindow.defaultProps = {
  openDrawer: false,
  hideBackdrop: false,
};

export default DrawerWindow;
