import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import ErrorIcon from '@material-ui/icons/Error';
import ModalTableCheckboxSearch from '../../../../../../components/ModalTableCheckboxSearch';
import { materialType, materialTypeOptionList } from '../../../../../../constants/order';
import { materialTableHeader, materialTableKey } from './constants';
import { Text, Button } from '../../../../../../components';
import { Colors } from '../../../../../../constants';
import { StockPermissionRoutePaths } from '../../../../../../constants/RoutePaths';

const StyledQuantityText = styled(Text)`
  display: flex;
  align-items: center;
  color: ${Colors.GRAY};
`;

const StyledText = styled(Text)`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(ErrorIcon)`
  margin-right: 4px;
`;

const ButtonStyled = styled(Button)`
`;

const WrapperButtonCell = styled.div`
  display: flex;
  flex-direction: row;
`;

const MaterialModalTable = ({
  handleRefresh,
  ...props
}) => {
  const [isRefresh, setIsRefresh] = useState(false);
  const history = useHistory();
  const { search: locationSearch } = useLocation();

  const _renderBasicTextCell = (value, list, index) => (
    <>
      {list[index].availableQuantity > 0 ? (
        <Text>
          {(value === parseInt(value, 10)) ? (
            <NumberFormat
              value={value}
              displayType="text"
              decimalSeparator=","
              thousandSeparator={true, '.'}
            />
          ) : (
            value
          )}
        </Text>
      ) : (
        <>
          {(value === parseInt(value, 10)) ? (
            <StyledQuantityText>
              <StyledIcon
                fontSize="small"
              />
              INDISPONÍVEL
            </StyledQuantityText>
          ) : (
            <StyledQuantityText>
              {value}
            </StyledQuantityText>
          )}
        </>
      )}
    </>
  );

  const _renderQuantityCell = (value, list, index) => (
    <>
      {list[index].hasStockPermission ? (
        <ButtonStyled
          size="small"
          variant="contained"
          onClick={() => { history.push(`${StockPermissionRoutePaths.PERMISSIONS}/${list[index].stockPermissionId}`, { originStockSearch: locationSearch }); }}
        >
          Ver permissão
        </ButtonStyled>
      ) : (
        list[index].availableQuantity > 0 ? (
          <Text>
            {(value === parseInt(value, 10)) ? (
              <NumberFormat
                value={value}
                displayType="text"
                decimalSeparator=","
                thousandSeparator={true, '.'}
              />
            ) : (
              value
            )}
          </Text>
        ) : (
          <>
            {(value === parseInt(value, 10)) ? (
              <StyledQuantityText>
                <StyledIcon
                  fontSize="small"
                />
                INDISPONÍVEL
              </StyledQuantityText>
            ) : (
              <StyledQuantityText>
                {value}
              </StyledQuantityText>
            )}
          </>
        )
      )}
    </>
  );

  const _renderMaterialCell = (value, list, index) => (
    <WrapperButtonCell>
      {list[index].availableQuantity > 0 ? (
        <StyledText>
          {value}
        </StyledText>
      ) : (
        <StyledQuantityText>
          {value}
        </StyledQuantityText>
      )}
    </WrapperButtonCell>
  );

  const columnConfig = {
    [materialTableKey.MATERIAL]: {
      order: 1,
    },
    [materialTableKey.MATERIAL_TYPE]: {
      order: 2,
    },
    [materialTableKey.QUANTITY]: {
      order: 3,
    },
  };

  const components = {
    [materialTableKey.MATERIAL]: _renderMaterialCell,
    [materialTableKey.MATERIAL_TYPE]: _renderBasicTextCell,
    [materialTableKey.QUANTITY]: _renderQuantityCell,
  };

  return (
    <ModalTableCheckboxSearch
      title="Selecione um material"
      name="material"
      placeholder={(option) => materialType.NAME === option.type ? 'Nome do material' : 'Código do material'}
      tableColumnConfig={columnConfig}
      tableComponents={components}
      searchTypeList={materialTypeOptionList}
      tableHandleHeaderTitle={(key) => materialTableHeader[key]}
      handleRefresh={() => {
        handleRefresh();
        setIsRefresh(true);
      }}
      isRefresh={isRefresh}
      radiobutton
      {...props}
    />
  );
};

export default MaterialModalTable;
