import React, { Component, Suspense } from 'react';
import {
  BrowserRouter as Router, Route, Switch, Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import store from './redux/store';

import { HttpService } from './services';
import {
  Toast,
} from './components';
import LoginPage from './flows/login';
import { hydrateApplication } from './redux';
import { USER_ROLES } from './constants/UsersRoles';
import { RoutePaths } from './constants/RoutePaths';
import LuftRoutes from './flows/luft-view/LuftRoutes';
import ResetPasswordPage from './flows/reset-password';
import ForgotPasswordPage from './flows/forgot-password';
import AgentRoutes from './flows/agent-view/AgentRoutes';
import CompanyRoutes from './flows/company-view/CompanyRoutes';

class App extends Component {
  constructor(props) {
    super(props);

    HttpService.store = store;

    this.state = {
      hydrated: false,
    };
  }

  componentDidMount() {
    this._init();
  }

  _init = async () => {
    const { dispatch } = this.props;
    const urlParams = HttpService.getParams();

    await dispatch(hydrateApplication(urlParams));

    this.setState({
      hydrated: true,
    });
  };

  _navigateHome = () => {
    const { history } = this.props;
    history.push('/');
  };

  render() {
    const { hydrated } = this.state;
    const {
      init,
      userData,
    } = this.props;

    if (!init) return null;

    if (hydrated) {
      return (
        <Suspense fallback="">
          <Router>
            <Switch>
              <Route exact path={`${RoutePaths.LOGIN}`} component={LoginPage} />
              <Route exact path={`${RoutePaths.FORGOT_PASSWORD}`} component={ForgotPasswordPage} />
              <Route path={`${RoutePaths.RESET_PASSWORD}`} component={ResetPasswordPage} />
              {userData && userData.userRole === USER_ROLES.COMPANY && CompanyRoutes({ userData })}
              {userData && userData.userRole === USER_ROLES.MASTER && LuftRoutes({ userData })}
              {userData && userData.userRole === USER_ROLES.AGENT && AgentRoutes({ userData })}
              <Redirect to={RoutePaths.LOGIN} />
            </Switch>
          </Router>
          <Toast />
        </Suspense>
      );
    }
    return null;
  }
}

// Redux
const mapStateToProps = (state) => ({
  init: state.init,
  userData: state.auth.userData,
});

export default connect(mapStateToProps)(App);
