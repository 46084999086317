import React, {
  useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';
import { Field } from 'formik';

import { FilterList } from '@material-ui/icons';
import {
  FilterCheckbox,
  FilterDate,
  FilterRadio,
  FilterSearch,
  FilterWrapper,
  FilterCheckboxSearch,
} from '../../../../../components/Filter';
import {
  fieldNames,
  luftFieldNames,
  materialTypeOptionList,
  orderStatusOptionList,
  agentTypeOptionList,
  agentType,
  materialType,
  orderBySuggestionList,
} from '../../../../../constants/order';
import FastFieldWrapper from '../../../../../components/form/FastFieldWrapper';
import { DropdownLabel } from '../../../../../components/Dropdown';
import { USER_ROLES, BreakpointValues } from '../../../../../constants';
import { useRect, mediaHelper, useWindowSize } from '../../../../../utils';

const SingleFilterContainer = styled.div`
  margin-bottom: 16px;
  transition: opacity ease-out 0.3s;
  opacity: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0;

  ${mediaHelper.md`
    width: auto;
    display: block;
    margin-top: 0;
    margin-left: 24px;
  `}

  ${({ allFiltersRefSetted }) => !allFiltersRefSetted && `
    opacity: 0;
  `}
`;

const FiltersContainer = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  transition: height ease-out 0.3s;
  flex-direction: column;
`;

const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`;

const HiddenFiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  transition: opacity ease-out 0.3s, height ease-out 0.3s;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  height: 0;

  ${mediaHelper.sm`
    padding-top: 0;
  `}

  ${({ show }) => show && `
    opacity: 1;
    height: auto;
    pointer-events: auto;
    overflow: visible;
    padding-top: 16px;
  `}
`;

const StyledFilterList = styled(({ active, ...props }) => <FilterList {...props} />)`
  transition: transform ease-out 0.3s;

  ${({ active }) => active && `
    transform: rotate(180deg);
  `}
`;

const StyledFilterWrapper = styled(FilterWrapper)`
  ${mediaHelper.sm`
    transition: height ease-out 0.3s;
  `}

  ${({ showHiddenFilters, totalHeight }) => showHiddenFilters && `
    height: auto;
    ${mediaHelper.md`
      height: ${totalHeight}px;
    `}
  `}
`;

const DropdownLabelStyled = styled(DropdownLabel)`
  margin: 0 auto 8px auto;
`;

const Filters = ({
  userData,
  agentList,
  promotionalCicleList,
  materialList,
  productList,
  companyList,
  onRequestAgentList,
  onRequestPromotionalCicleList,
  onRequestMaterialList,
  onRequestProductList,
  onRequestCompanyList,
  onSearch,
  values,
  onChangeDataOrderSuggestion,
  pairingActive,
  appliedFilters,
}) => {
  const [showHiddenFilters, setShowHiddenFilters] = useState(false);
  const filtersContainerRef = useRef();
  const hiddenFiltersContainerRef = useRef();
  const filtersBounding = useRect(filtersContainerRef);
  const hiddenFiltersBounding = useRect(hiddenFiltersContainerRef);
  const currentWindowSize = useWindowSize();

  const [allFiltersRefSetted, setAllFiltersRefSetted] = useState(false);
  const filtersRef = {
    ...(userData.userRole !== USER_ROLES.AGENT && { [luftFieldNames.COMPANY]: useRef() }),
    [fieldNames.CREATE_DATE]: useRef(),
    [fieldNames.DELIVERY_DATE]: useRef(),
    ...(userData.userRole !== USER_ROLES.AGENT && { [fieldNames.AGENT]: useRef() }),
    [fieldNames.STATUS]: useRef(),
    [fieldNames.PROMOTIONAL_CICLE]: useRef(),
    [fieldNames.MATERIAL]: useRef(),
    [fieldNames.PRODUCT]: useRef(),
  };
  const [filtersVisibilityControl, setFiltersVisibilityControl] = useState({
    ...(userData.userRole !== USER_ROLES.AGENT && { [luftFieldNames.COMPANY]: true }),
    [fieldNames.CREATE_DATE]: true,
    [fieldNames.DELIVERY_DATE]: true,
    ...(userData.userRole !== USER_ROLES.AGENT && { [fieldNames.AGENT]: true }),
    [fieldNames.STATUS]: true,
    [fieldNames.PROMOTIONAL_CICLE]: true,
    [fieldNames.MATERIAL]: true,
    [fieldNames.PRODUCT]: true,
  });
  const isLuftCompleteView = userData && userData.userRole === USER_ROLES.MASTER && !userData.companyName;
  const isAgentView = userData && userData.userRole === USER_ROLES.AGENT;

  useEffect(() => {
    const allSetted = Object.entries(filtersRef).every(([key, ref]) => {
      if ((isLuftCompleteView && key === fieldNames.PRODUCT)
        || (!isLuftCompleteView && key === luftFieldNames.COMPANY)
        || (isAgentView)) {
        return true;
      }
      return !!ref.current;
    });
    if (allSetted) {
      setAllFiltersRefSetted(true);

      // If the distance from the top of the parent container is greater than the its own height, then this component must be hided
      setFiltersVisibilityControl(
        Object.entries(filtersRef).reduce((obj, [key, ref]) => ({
          ...obj,
          [key]: ref.current && ref.current.offsetTop < ref.current.offsetHeight,
        }), {}),
      );
    }
  }, [
    userData,
    filtersRef[luftFieldNames.COMPANY],
    filtersRef[fieldNames.CREATE_DATE],
    filtersRef[fieldNames.DELIVERY_DATE],
    filtersRef[fieldNames.AGENT],
    // filtersRef[fieldNames.STATUS],
    filtersRef[fieldNames.PROMOTIONAL_CICLE],
    filtersRef[fieldNames.MATERIAL],
    filtersRef[fieldNames.PRODUCT],
    filtersBounding.rect.right,
  ]);

  const invertedFiltersVisibilityControl = Object.entries(filtersVisibilityControl).reduce((obj, [key, value]) => ({
    ...obj,
    [key]: !value,
  }), {});

  const defaultDataListMap = ({
    id,
    name,
  }) => ({
    label: name,
    value: id,
  });

  const renderFilters = (showFilters) => (
    <>
      {showFilters[luftFieldNames.COMPANY] && isLuftCompleteView ? (
        <SingleFilterContainer
          ref={filtersRef[luftFieldNames.COMPANY]}
          allFiltersRefSetted={allFiltersRefSetted}
          show={filtersVisibilityControl[luftFieldNames.COMPANY]}
        >
          <FastFieldWrapper
            name={luftFieldNames.COMPANY}
            label="Empresa"
            component={FilterCheckboxSearch}
            submitOnChange
            optionList={companyList ? companyList.list : []}
            optionMap={defaultDataListMap}
            onSearch={onRequestCompanyList}
            loading={companyList && companyList.loading}
            appliedFilters={appliedFilters}
            fieldNames={fieldNames}
          />
        </SingleFilterContainer>
      ) : null}
      {showFilters[fieldNames.CREATE_DATE] ? (
        <SingleFilterContainer
          ref={filtersRef[fieldNames.CREATE_DATE]}
          allFiltersRefSetted={allFiltersRefSetted}
          show={filtersVisibilityControl[fieldNames.CREATE_DATE]}
        >
          <FastFieldWrapper
            name={fieldNames.CREATE_DATE}
            label="Data de criação"
            component={FilterDate}
            submitOnChange
            appliedFilters={appliedFilters}
            fieldNames={fieldNames}
          />
        </SingleFilterContainer>
      ) : null}
      {showFilters[fieldNames.DELIVERY_DATE] ? (
        <SingleFilterContainer
          ref={filtersRef[fieldNames.DELIVERY_DATE]}
          allFiltersRefSetted={allFiltersRefSetted}
          show={filtersVisibilityControl[fieldNames.DELIVERY_DATE]}
        >
          <FastFieldWrapper
            name={fieldNames.DELIVERY_DATE}
            label="Data de entrega"
            component={FilterDate}
            submitOnChange
            appliedFilters={appliedFilters}
            fieldNames={fieldNames}
          />
        </SingleFilterContainer>
      ) : null}
      {showFilters[fieldNames.AGENT] && !isAgentView ? (
        <SingleFilterContainer
          ref={filtersRef[fieldNames.AGENT]}
          allFiltersRefSetted={allFiltersRefSetted}
          show={filtersVisibilityControl[fieldNames.AGENT]}
        >
          <FastFieldWrapper
            name={fieldNames.AGENT}
            label="Representante"
            placeholder={(selectedType) => selectedType.type === agentType.NAME ? `Nome do ${currentWindowSize.width >= BreakpointValues.md ? 'representante' : 'rep.'}` : 'Tag do representante'}
            component={FilterCheckboxSearch}
            submitOnChange
            optionList={agentList.list}
            optionMap={defaultDataListMap}
            searchTypeList={agentTypeOptionList}
            loading={agentList.loading}
            onSearch={onRequestAgentList}
            appliedFilters={appliedFilters}
            fieldNames={fieldNames}
          />
        </SingleFilterContainer>
      ) : null}
      {showFilters[fieldNames.STATUS] ? (
        <SingleFilterContainer
          ref={filtersRef[fieldNames.STATUS]}
          allFiltersRefSetted={allFiltersRefSetted}
          show={filtersVisibilityControl[fieldNames.STATUS]}
        >
          <FastFieldWrapper
            name={fieldNames.STATUS}
            label="Status"
            component={FilterCheckbox}
            submitOnChange
            optionList={orderStatusOptionList}
            appliedFilters={appliedFilters}
            fieldNames={fieldNames}
          />
        </SingleFilterContainer>
      ) : null}
      {showFilters[fieldNames.PROMOTIONAL_CICLE] ? (
        <SingleFilterContainer
          ref={filtersRef[fieldNames.PROMOTIONAL_CICLE]}
          allFiltersRefSetted={allFiltersRefSetted}
          show={filtersVisibilityControl[fieldNames.PROMOTIONAL_CICLE]}
        >
          <FastFieldWrapper
            name={fieldNames.PROMOTIONAL_CICLE}
            label="Ciclo promocional"
            placeholder="Ciclo promocional"
            component={FilterCheckboxSearch}
            submitOnChange
            optionList={promotionalCicleList.list}
            optionMap={({ name }) => ({
              label: name,
              value: name,
            })}
            onSearch={onRequestPromotionalCicleList}
            loading={promotionalCicleList.loading}
            appliedFilters={appliedFilters}
            fieldNames={fieldNames}
          />
        </SingleFilterContainer>
      ) : null}
      {showFilters[fieldNames.MATERIAL] ? (
        <SingleFilterContainer
          ref={filtersRef[fieldNames.MATERIAL]}
          allFiltersRefSetted={allFiltersRefSetted}
          show={filtersVisibilityControl[fieldNames.MATERIAL]}
        >
          <FastFieldWrapper
            name={fieldNames.MATERIAL}
            label="Material"
            placeholder={(selectedType) => selectedType.type === materialType.NAME ? 'Nome do material' : 'Cód. do material'}
            component={FilterCheckboxSearch}
            submitOnChange
            optionList={materialList.list}
            optionMap={defaultDataListMap}
            searchTypeList={materialTypeOptionList}
            onSearch={onRequestMaterialList}
            loading={materialList.loading}
            appliedFilters={appliedFilters}
            fieldNames={fieldNames}
          />
        </SingleFilterContainer>
      ) : null}
      {showFilters[fieldNames.PRODUCT] && !isLuftCompleteView ? (
        <SingleFilterContainer
          ref={filtersRef[fieldNames.PRODUCT]}
          allFiltersRefSetted={allFiltersRefSetted}
          show={filtersVisibilityControl[fieldNames.PRODUCT]}
        >
          <FastFieldWrapper
            name={fieldNames.PRODUCT}
            label="Produto"
            placeholder="Produto vinculado"
            component={FilterCheckboxSearch}
            submitOnChange
            optionList={productList.list}
            optionMap={defaultDataListMap}
            onSearch={onRequestProductList}
            loading={productList.loading}
            appliedFilters={appliedFilters}
            fieldNames={fieldNames}
          />
        </SingleFilterContainer>
      ) : null}
    </>
  );

  return (
    <StyledFilterWrapper
      showHiddenFilters={showHiddenFilters}
      totalHeight={filtersBounding.rect.height + hiddenFiltersBounding.rect.height}
      renderLeft={() => (
        <>
          <FiltersContainer>
            <FiltersWrapper ref={filtersContainerRef}>
              {renderFilters(filtersVisibilityControl)}
            </FiltersWrapper>
            <HiddenFiltersWrapper
              ref={hiddenFiltersContainerRef}
              show={showHiddenFilters}
            >
              {renderFilters(invertedFiltersVisibilityControl)}
            </HiddenFiltersWrapper>
          </FiltersContainer>
          {Object.values(invertedFiltersVisibilityControl).includes(true) && (
            <DropdownLabelStyled
              active={showHiddenFilters}
              onClick={() => {
                setShowHiddenFilters(!showHiddenFilters);
              }}
            >
              {showHiddenFilters ? 'Menos filtros' : 'Mais filtros'}
              <StyledFilterList active={showHiddenFilters} />
            </DropdownLabelStyled>
          )}
        </>
      )}
      renderRight={() => (
        <>
          {pairingActive && (
            <Field
              name={fieldNames.ORDER_BY_SUGGESTION}
              label="Ordernar"
              value={values[fieldNames.ORDER_BY_SUGGESTION]}
              component={FilterRadio}
              submitOnChange
              optionList={orderBySuggestionList}
              handleOutput={onChangeDataOrderSuggestion}
            />
          )}
          <FilterSearch
            name={fieldNames.SEARCH}
            placeholder="Código do pedido"
            component={FilterSearch}
            submitOnChange
            handleOutput={onSearch}
            minChar={1}
            placement={currentWindowSize.width <= BreakpointValues.md ? 'bottom' : 'bottom-end'}
          />
        </>
      )}
    />
  );
};

export default Filters;
