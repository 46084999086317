import React from 'react';
import styled from 'styled-components';

import { Container } from '@material-ui/core/';
import { HelpOutline } from '@material-ui/icons';
import { Colors } from '../constants';
import { StaticRoutes } from '../constants/RoutePaths';
import { mediaHelper } from '../utils';

const FooterWrapper = styled.footer`
  width: 100%;
  background-color: ${Colors.SECONDARY_MEDIUM};
  box-shadow: rgba(0,0,0,.25) 0px 0px 16px;
  height: auto;
  padding: 24px 0 16px 0;

  ${mediaHelper.lg`
    height: 64px;
    padding: 0;
  `}
`;

const FooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;

  ${mediaHelper.lg`
    height: 64px;
  `}
`;

const FooterText = styled.span`
  font-size: 14px;
  color: white;
  margin-bottom: 8px;

  ${mediaHelper.lg`
    margin-bottom: 0;
  `}
`;

const LinkStyled = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  margin-left: 8px;
  height: 20px;
  color: ${Colors.PRIMARY_BLUE};
`;


const FooterLeft = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaHelper.md`
    flex-direction: row;
  `}
`;

const _handleLink = (url) => {
  window.open(url, '_blank');
};

const Footer = () => (
  <FooterWrapper>
    <Container maxWidth="lg">
      <FooterContent>
        <FooterLeft>
          <FooterText>
            Plataforma Luft Promo - 2021
          </FooterText>
        </FooterLeft>

        <LinkStyled
          onClick={() => { _handleLink(StaticRoutes.MANUAL); }}
          title="Manual"
        >
          Ajuda
          <IconWrapper>
            <HelpOutline fontSize="small" />
          </IconWrapper>
        </LinkStyled>
      </FooterContent>
    </Container>
  </FooterWrapper>
);

export default Footer;
