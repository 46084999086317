import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import {
  makeStyles,
  Card,
  CardActions,
  CardContent,
  CardMedia,
} from '@material-ui/core/';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { Colors, USER_ROLES } from '../../../../../../constants';
import { Button, EmptyState } from '../../../../../../components';
import emptyImage from '../../images/icon-no-image.svg';
import { RoutePaths, OrderRoutePaths } from '../../../../../../constants/RoutePaths';

const propTypes = {
  item: PropTypes.object,
};

const defaultProps = {
  item: {},
};

const CardContainer = styled.div`
  perspective: 1000px;
`;

const CardContentWrapper = styled(CardContent)`
  border-top: 1px solid ${Colors.GRAY_LIGHT};
  min-height: 125px;
  max-height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const CardContentBack = styled(CardContent)`
  position: relative;
  padding-right: 0 !important;
  padding-left: 0 !important;
  padding-bottom: 12px !important;
`;

const CardFront = styled(Card)`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  backface-visibility: hidden;
  z-index: 1;
`;

const CardFrontActionsWrapper = styled(CardActions)`
  display: flex;
  padding: 0px 8px 8px 8px !important;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 16px);

  & > button {
    width: 100%;
  }
`;

const CardBackActionsWrapper = styled(CardActions)`
  display: flex;
  padding: 8px 16px 0 16px !important;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 32px);
`;

const CardBack = styled(Card)`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${Colors.BLUE_LIGHT} !important;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  z-index: 0;
`;

const CardFlipWrapper = styled.div`
  width: 100%;
  min-height: 404px;
  position: relative;
  transition: transform .8s;
  transform-style: preserve-3d;
  -moz-backface-visibility: hidden;
    
  &.active {
    transform: rotateY( 180deg );

    ${CardFront} {
      z-index: 0;
    }

    ${CardBack} {
      z-index: 1;
    }
  }
`;

const MaterialTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
  margin-top: 0;
  color: ${Colors.PRIMARY_BLUE};
  width: 100%;
`;

const TitleText = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0;
  cursor: pointer;
`;

const MaterialAmount = styled.div`
  display: flex;
  flex-direction: column;
`;

const AmountItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 0 16px;
  margin-bottom: 8px;
  color: ${({ lightColor, mediumColor, darkColor }) => lightColor ? Colors.GRAY_LIGHT
    : mediumColor ? Colors.GRAY_DARKER
      : darkColor ? Colors.GRAY_DARKEST
        : Colors.GRAY_DARKER};
`;

const AmountType = styled.span`
  font-weight: bold;
`;

const AmountNumber = styled.span`
  font-weight: normal;
`;

const TagButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 105px;
  padding: 0 8px;
  height: 32px;
  left: ${({ leftPos }) => leftPos ? 0 : 'auto'};
  right: ${({ leftPos }) => leftPos ? 'auto' : 0};
  top: 18px;
  font-size: 12px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  background-color: ${Colors.PRIMARY_BLUE};
  z-index: 1;
  cursor: pointer;
  transition: background-color .3s ease-in-out;
  border-top-right-radius: ${({ leftPos }) => leftPos ? '4px' : '0'};
  border-bottom-left-radius: ${({ leftPos }) => leftPos ? '0' : '4px'};
  border-top-left-radius: ${({ leftPos }) => leftPos ? '0' : '4px'};
  border-bottom-right-radius: ${({ leftPos }) => leftPos ? '4px' : '0'};
  border: none;
  
  &:hover {
    background-color: ${Colors.SECONDARY_MEDIUM};
  }

  & svg {
    width: 12px;
    height: 12px;
    margin-left: ${({ leftPos }) => leftPos ? '0' : '8px'};
    margin-right: ${({ leftPos }) => leftPos ? '8px' : '0'};
  }
`;

const CardBatchList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  min-height: 270px;
  max-height: 270px;
  overflow-x: hidden;
  overflow-y: auto;
  border-bottom: 1px solid ${Colors.GRAY_LIGHT};
  scroll-behavior: smooth;
`;

const CardBatchItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid ${Colors.GRAY_LIGHT};

  &:last-child {
    border-bottom: none;
  }
`;

const CardBatchLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
`;

const CardBatchRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  height: 44px;
  width: 50%;
`;

const BatchNumber = styled.div`
  color: ${Colors.GRAY_DARKEST};
`;

const BatchValidity = styled.div`
  color: ${Colors.GRAY_DARKER};
`;

const BatchUnits = styled.div`
  color: ${Colors.PRIMARY_BLUE};
`;

const LoadingWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(233, 239, 244, .5);
`;

const CardMediaStyled = styled(CardMedia)`
  cursor: pointer;
  background-size: contain;
  background-color: #F3F4F4;
`;

const useStyles = makeStyles({
  root: {
    borderRadius: 8,
  },
  media: {
    height: 180,
  },
});

const MaterialItem = ({
  item,
  onRequestMaterialBatches,
  enableSearchBatches,
  userData,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { search: locationSearch } = useLocation();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const goToDetail = (id) => {
    history.push(`${RoutePaths.STOCK}/${id}`, { originStockSearch: locationSearch });
  };

  const [takeItems, setTakeItems] = useState(4);
  const [fetchAllow, setFetchAllow] = useState(true);

  const handlePageBatches = async () => {
    await onRequestMaterialBatches(item.id, takeItems, true);
    scrollToBottom();
  };

  const batchesScrollView = useRef(null);

  const scrollToBottom = () => {
    batchesScrollView.current.scrollTop = batchesScrollView.current.scrollHeight;
  };

  return (
    <CardContainer>
      <CardFlipWrapper className={`${open ? 'active' : ''}`}>
        <CardFront className={classes.root}>
          {item.displayBatch && (
            <TagButton
              onClick={() => {
                onRequestMaterialBatches(item.id, 0, fetchAllow);
                handleToggle();
                setFetchAllow(false);
              }}
              disabled={!enableSearchBatches}
            >
              Ver lotes
              <ArrowForwardIosIcon />
            </TagButton>
          )}
          <CardMediaStyled
            className={classes.media}
            image={item.image ? item.image : emptyImage}
            title={item.name ? item.name : 'Sem foto'}
            onClick={() => { goToDetail(item.id); }}
          />
          <CardContentWrapper>
            <MaterialTitle>
              <TitleText
                onClick={() => { goToDetail(item.id); }}
              >
                {item.name ? item.name : '-'}
              </TitleText>
            </MaterialTitle>
            <MaterialAmount>
              { (userData.userRole === USER_ROLES.COMPANY || userData.userRole === USER_ROLES.MASTER) && (
                <AmountItem mediumColor>
                  <AmountType>
                    Armazenado
                  </AmountType>
                  <AmountNumber>
                    <NumberFormat
                      value={item.totalQuantity ? item.totalQuantity : 0}
                      displayType="text"
                      decimalSeparator=","
                      thousandSeparator={true, '.'}
                    />
                  </AmountNumber>
                </AmountItem>
              )}
              <AmountItem lightColor>
                <AmountType>
                  { userData.userRole === USER_ROLES.AGENT ? 'Encomendado' : 'Reservado'}
                </AmountType>
                <AmountNumber>
                  <NumberFormat
                    value={item.reservedQuantity ? item.reservedQuantity : 0}
                    displayType="text"
                    decimalSeparator=","
                    thousandSeparator={true, '.'}
                  />
                </AmountNumber>
              </AmountItem>
              <AmountItem darkColor>
                <AmountType>
                  Disponível
                </AmountType>
                <AmountNumber>
                  <NumberFormat
                    value={item.availableQuantity ? item.availableQuantity : 0}
                    displayType="text"
                    decimalSeparator=","
                    thousandSeparator={true, '.'}
                  />
                </AmountNumber>
              </AmountItem>
            </MaterialAmount>
          </CardContentWrapper>
          <CardFrontActionsWrapper>
            <Button
              size="large"
              color="secondary"
              variant="outlined"
              handleClick={() => { goToDetail(item.id); }}
            >
              Detalhe
            </Button>
            {(userData.userRole === USER_ROLES.COMPANY || userData.userRole === USER_ROLES.AGENT) && (
              <Button
                size="large"
                color="secondary"
                variant="contained"
                onClick={() => {
                  history.push(
                    `${RoutePaths.ORDER}${OrderRoutePaths.NEW}`,
                    {
                      originStockSearch: locationSearch,
                      material: {
                        name: item.name,
                        availableQuantity: item.availableQuantity,
                        displayBatch: item.displayBatch,
                        id: item.id,
                        image: item.image,
                        materialType: item.materialType,
                        mode: 2,
                        qty: 0,
                        reservedQuantity: item.reservedQuantity,
                        totalQuantity: item.totalQuantity,
                        agentList: userData.userRole === USER_ROLES.AGENT ? [{
                          id: userData.id,
                          name: userData.name,
                          active: true,
                          qty: 0,
                        }] : [],
                      },
                    },
                  );
                }}
                disabled={item.availableQuantity <= 0}
              >
                Pedir
              </Button>
            )}
          </CardFrontActionsWrapper>
        </CardFront>
        <CardBack className={classes.root}>
          <TagButton onClick={handleToggle} leftPos>
            <ArrowBackIosIcon />
            {' '}
            Esconder lotes
          </TagButton>
          <CardContentBack>
            <CardBatchList
              ref={batchesScrollView}
            >
              {item.batches ? (
                <>
                  {item.batches.loading && (
                    <LoadingWrapper>
                      <CircularProgress />
                    </LoadingWrapper>
                  )}
                  {item.batches && item.batches.list.length > 0 ? (
                    <>
                      {item.batches.list.map((subItem) => (
                        <CardBatchItem
                          key={subItem.id}
                        >
                          <CardBatchLeft>
                            <BatchNumber>
                              Lt
                              {' '}
                              {subItem.id}
                            </BatchNumber>
                            <BatchValidity>
                              {subItem.dueDate ? `Val ${subItem.dueDate}` : 'Sem validade'}
                            </BatchValidity>
                          </CardBatchLeft>
                          <CardBatchRight>
                            <BatchUnits>
                              <NumberFormat
                                value={subItem.availableQuantity ? subItem.availableQuantity : 0}
                                displayType="text"
                                decimalSeparator=","
                                thousandSeparator={true, '.'}
                              />
                              {' '}
                              un
                            </BatchUnits>
                          </CardBatchRight>
                        </CardBatchItem>
                      ))}
                    </>
                  ) : (
                    <>
                      {!item.batches.loading && (
                        <EmptyState
                          text="Nenhum lote para exibir :("
                        />
                      )}
                    </>
                  )}
                </>
              ) : (
                <EmptyState
                  text="Nenhum lote para exibir :("
                />
              )}
            </CardBatchList>
          </CardContentBack>
          <CardBackActionsWrapper>
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              handleClick={handleToggle}
            >
              Voltar
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              disabled={item.batches && takeItems >= item.batches.total ? true : false}
              handleClick={() => {
                handlePageBatches();
                setTakeItems(takeItems + 4);
              }}
            >
              Ver mais lotes
            </Button>
          </CardBackActionsWrapper>
        </CardBack>
      </CardFlipWrapper>
    </CardContainer>
  );
};

MaterialItem.propTypes = propTypes;
MaterialItem.defaultProps = defaultProps;

export default MaterialItem;
