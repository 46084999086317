import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { ToastStyles } from './reducer';
import { dismissToast } from './actions';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const TOAST_TIMEOUT = 6000;
const TOAST_AUTO_HIDE_DURATION = 6000;

const StyledSnackbar = styled(Snackbar)`
  z-index: 999999;
`;

const Toast = ({ dispatch, toast: { type, message, active }, ...props }) => {
  const isError = type === ToastStyles.DANGER;
  const isSuccess = type === ToastStyles.SUCCESS;

  useEffect(() => {
    if (active) {
      setTimeout(() => {
        dispatch(dismissToast());
      }, TOAST_TIMEOUT);
    }
  }, [active, dispatch]);

  return (
    <StyledSnackbar
      autoHideDuration={TOAST_AUTO_HIDE_DURATION}
      open={active}
      {...props}
    >
      <Alert severity={isError ? 'error' : isSuccess ? 'success' : 'info'}>
        {message}
      </Alert>
    </StyledSnackbar>
  );
};

// Redux
const mapStateToProps = (state) => ({ toast: state.toast });

export default connect(mapStateToProps)(Toast);
