export const RoutePaths = {
  LOGIN: '/login',
  HOME: '/home',
  ORDER: '/orders',
  STOCK: '/stock',
  PAIRING: '/pairing',
  RESET_PASSWORD: '/reset-password',
  FORGOT_PASSWORD: '/forgot-password',
  AGENT: '/agents',
};

export const OrderRoutePaths = {
  NEW: '/new',
};

export const AgentRoutePaths = {
  NEW: '/agent-new',
  UPDATE: '/agent-update',
};

export const StockPermissionRoutePaths = {
  PERMISSIONS: '/stock-permissions',
  NEW: '/stock-permissions-new',
};

export const StaticRoutes = {
  MANUAL: '/manual',
};
