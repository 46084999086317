import { HttpService } from './http';
import { API_BASE_URL } from '../constants/API';

const Endpoint = {
  fetchMaterialList: `${API_BASE_URL}/materials/list`,
  fetchMaterialUpload: `${API_BASE_URL}/materialimages`,
};

export class MaterialService {
  /**
   * @param {Object} request
   * @param {boolean} request.skip - Skip the results
   * @param {boolean} request.take - Take a certain number of results
   * @param {Object} request.filters
   * @param {{ attribute: string, value: string | number | boolean }[]} request.filters.standard
   * @param {{ attribute: string, name: string }} request.orderBy
   */
  static async fetchMaterialList(request) {
    const response = await HttpService.post(Endpoint.fetchMaterialList, request);
    return response;
  }

  /**
   * @param {{
   *  id: string | number,
   *  skip: number,
   *  take: number
   * }} data
   */
  static async requestMaterialBatches(data) {
    const response = await HttpService.post(`${API_BASE_URL}/batches/${data.id}/list`,
      {
        skip: data.skip,
        take: data.take,
      },
    );
    return response;
  }

  /**
   * @param {string | number} id
   */
  static async fetchMaterialDetail(id) {
    const response = await HttpService.get(`${API_BASE_URL}/materials/${id}`);
    return response;
  }

  /**
   * @param {{
   *  id: string | number,
   *  description: string,
   * }} data
   */
  static async requestMaterialChangeData(data) {
    await HttpService.patch(`${API_BASE_URL}/materials/${data.id}`,
      {
        description: data.description,
      },
    );
  }

  /**
   * @param {{
  *  materialId: string | number,
  *  files: FileList,
  * }} data
  */
  static async requestMaterialUpload(data) {
    const formData = new FormData();
    for (const name in data.files) {
      formData.append('files', data.files[name]);
    }
    const response = await HttpService.upload({
      endpoint: `${Endpoint.fetchMaterialUpload}/upload/${data.materialId}`,
      data: formData,
    });
    return response;
  }

  /**
   * @param {{
    *  imageId: string | number,
    * }} data
  */
  static async requestDeteleImageMaterial(imageId) {
    const response = await HttpService.delete(`${Endpoint.fetchMaterialUpload}/${imageId}`);
    return response;
  }
}

export default MaterialService;
