import React, { useState } from 'react';
import { Text } from '../../../../components';

import ModalTableCheckboxSearch from '../../../../components/ModalTableCheckboxSearch';
import { productTableKey, productTypeLabel } from './constants';

const ProductModalTable = ({
  handleRefresh,
  ...props
}) => {
  const [isRefresh, setIsRefresh] = useState(false);
  const _renderBasicTextCell = (value) => (
    <Text>
      {value || '--'}
    </Text>
  );

  const columnConfig = {
    [productTableKey.PRODUCT]: {
      order: 1,
    },
  };

  const components = {
    [productTableKey.PRODUCT]: _renderBasicTextCell,
  };

  return (
    <ModalTableCheckboxSearch
      title="Selecione um produto"
      placeholder="Nome do produto"
      tableColumnConfig={columnConfig}
      tableComponents={components}
      tableHandleHeaderTitle={(key) => productTypeLabel[key]}
      handleRefresh={() => {
        handleRefresh();
        setIsRefresh(true);
      }}
      isRefresh={isRefresh}
      disableConfirm
      {...props}
    />
  );
};

export default ProductModalTable;
